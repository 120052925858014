import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'reactstrap';

import * as routes from '../../../../constants/routes';

import uparrow from '../../../../images/uparrow.png';
import downarrow from '../../../../images/downarrow.png';
import {DEV_SERVER} from '../../../../services/servicesConfig';

import {
  checkAvailableAccess,
} from '../../../../constants/localMessage/errorMessage';

import './NavigationItems.css';


export default function NavigationItem({
  path = '/',
  icon,
  name = 'Link',
  active = false,
  disabled = false,
  history

}) {
  let hideContent = true;

  let getData = checkAvailableAccess ('marketplace');

  if (
    getData && getData !== 'marketplace') {
    hideContent = false;
  }

  const [showDropDown, toggleDropDown] = useState (false);
  const [showDropDownService, setshowDropDownService] = useState (false);

  const hideOverlay = () => {
    var element = document.getElementById ('sidebarmenu');
    element.classList.add ('menuDisplayHide');
    // var element = document.getElementById ('overlay');
    // element.classList.add ('hideoverlay');
  };

  let activePage = '';
  if (window.location.pathname.includes ('marketplace')) {
    activePage = 'marketplaceorders';
  }
  if (window.location.pathname.includes ('marketplacesearch')) {
    activePage = 'marketplacesearch';
  }
  if (
    window.location.pathname.includes ('marketplacesellerconsole') ||
    window.location.pathname.includes ('marketplacesellerproduct')
  ) {
    activePage = 'marketplacesellerconsole';
  }
  if (window.location.pathname.includes ('serviceOnDemand')) {
    activePage = 'serviceOnDemandsearch';
  }
  if (window.location.pathname.includes ('serviceOnDemandMyRequest')) {
    activePage = 'serviceOnDemandMyRequest';
  }
  if (
    window.location.pathname.includes ('serviceOnDemandRequestRecieved') ||
    window.location.pathname.includes ('serviceOnDemandRecievedRequestDetail')
  ) {
    activePage = 'serviceOnDemandRequestRecieved';
  }

  const redirectToPage = (path) => {
    history.push(path)

  }
  return (
    <div>
      <Container
        style={{cursor:'pointer'}}
        className={
          'navigation-item ' +
            (disabled ? 'disabled' : '') +
            (showDropDown ? 'is-open' : '') +
            (active ? 'sideMenuGradient' : '')
        }
        // className={"navigation-item " + ( name === 'Marketplace' && (activePage=== 'marketplaceorders' ||  activePage=== 'marketplacesearch' || activePage=== 'marketplacesellerconsole') ? 'activebackground' : '')}
        // style={{background: active ? name !== 'ServiceOnDemand' ? '#4453D6' : '#4453D6' : '' }}
        onClick={() => {
          if (name === 'Marketplace' && hideContent) {
            toggleDropDown (!showDropDown);
          }

          // if (name === 'ServiceOnDemand') {
          //   setshowDropDownService(!showDropDownService);
          // }
          // if(name !== 'Marketplace' && name !== 'ServiceOnDemand'){
          if (name !== 'Marketplace' || !hideContent) {
            redirectToPage(path)
            hideOverlay ();
          }
        }}

        //
      >
        <Row>
          <Col xs="2" className="image-container">
            {icon}
          </Col>
          <Col xs="8">

            {name !== 'Marketplace' &&
              name !== 'Expert' &&
              <span to={path} style={{color: '#FFFFFF'}}>
                {name}
              </span>}

            {!hideContent &&
              name === 'Marketplace' &&
              name !== 'Expert' &&
              <span to={path} style={{color: '#FFFFFF'}}>
                {name}
              </span>}

            {hideContent &&
              name === 'Marketplace' &&
              <a className="dropDownWrapperMenu">
                <div style={{color: '#FFFFFF'}}>{name}</div>
                {name === 'Marketplace' &&
                  <img
                    className="dropDownIcon"
                    width="40"
                    src={showDropDown ? uparrow : downarrow}
                    alt="icon"
                  />}
              </a>}

            {name === 'Expert' &&
              <a className="dropDownWrapperMenu">

                <span to={path} style={{color: '#FFFFFF'}}>
                  {name}
                </span>
                <img
                className="dropDownIcon"
                width="40"
                src={downarrow}
                alt="icon"
              />

                {/*   <div style={{ color: '#FFFFFF' }}>{name}</div> {name === 'ServiceOnDemand' && <img className="dropDownIcon"  width="40" src={showDropDownService ? uparrow : downarrow} alt="icon" />}*/}
              </a>}

          </Col>
        </Row>
      </Container>
      {name === 'Expert' &&
        showDropDownService &&
        <div
          onClick={() => hideOverlay ()}
          className={
            'marketplacesubmenuItemView ' +
              (showDropDownService ? 'is-open' : '')
          }
        >
          <Row
            className="marketplacesubmenuItem"
            style={{
              background: activePage == 'serviceOnDemandsearch'
                ? '#4453D6'
                : '',
            }}
          >
            <Col xs="2" className="image-container" />
            <Col xs="10" style={{margin: 'auto', paddingLeft: 0}}>
              <Link to={path} style={{color: disabled ? '#353c4b' : '#FFFFFF'}}>
                Search
              </Link>
            </Col>
          </Row>
          <Row
            className="marketplacesubmenuItem"
            style={{
              background: activePage == 'serviceOnDemandMyRequest'
                ? '#4453D6'
                : '',
            }}
          >
            <Col xs="2" className="image-container" />
            <Col xs="10" style={{margin: 'auto', paddingLeft: 0}}>
              <Link
                to={routes.SERVICEONDEMANDMYREQUEST}
                style={{color: disabled ? '#353c4b' : '#FFFFFF'}}
              >
                My Placed Requests
              </Link>
            </Col>
          </Row>
          <Row
            className="marketplacesubmenuItem"
            style={{
              background: activePage == 'serviceOnDemandRequestRecieved'
                ? '#4453D6'
                : '',
            }}
          >
            <Col xs="2" className="image-container" />
            <Col xs="10" style={{margin: 'auto', paddingLeft: 0}}>
              <Link
                to={routes.SERVICEONDEMAND_REQUESTRECIEVED}
                style={{color: disabled ? '#353c4b' : '#FFFFFF'}}
              >
                Service Provider Dashboard
              </Link>
            </Col>
          </Row>
        </div>}
      {name === 'Marketplace' &&
        showDropDown &&
        <div
          onClick={() => hideOverlay ()}
          className={
            'marketplacesubmenuItemView ' + (showDropDown ? 'is-open' : '')
          }
        >
          <Row
            className="marketplacesubmenuItem"
            style={{
              background: activePage == 'marketplacesearch' ? '#4453D6' : '',
            }}
          >
            <Col xs="2" className="image-container" />
            <Col xs="10" style={{margin: 'auto', paddingLeft: 0}}>
              <Link to={path} style={{color: disabled ? '#353c4b' : '#FFFFFF'}}>
                Search
              </Link>
            </Col>
          </Row>
          <Row
            className="marketplacesubmenuItem"
            style={{
              background: activePage == 'marketplaceorders' ? '#4453D6' : '',
            }}
          >
            <Col xs="2" className="image-container" />
            <Col xs="10" style={{margin: 'auto', paddingLeft: 0}}>
              <Link
                to={routes.PLACED_ORDERS}
                style={{color: disabled ? '#353c4b' : '#FFFFFF'}}
              >
                Placed Orders
              </Link>
            </Col>
          </Row>
          <Row
            className="marketplacesubmenuItem"
            style={{
              background: activePage == 'marketplacesellerconsole'
                ? '#4453D6'
                : '',
            }}
          >
            <Col xs="2" className="image-container" />
            <Col xs="10" style={{margin: 'auto', paddingLeft: 0}}>
              <Link
                to={routes.SELLER_CONSOLE}
                style={{color: disabled ? '#353c4b' : '#FFFFFF'}}
              >
                Seller Console
              </Link>
            </Col>
          </Row>

        </div>}
    </div>
  );
}

NavigationItem.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.element,
  name: PropTypes.string,
  active: PropTypes.bool,
  signOutFunction: PropTypes.bool,
  history: PropTypes.any,

  

};
