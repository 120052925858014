
// import { sendUserNotification } from 'action/notification'
import { encryptMessage, decryptMessage } from './encryption'
import { ShowNameAccordingToLength } from './stringFunctions';
import firebase from 'firebase';
import { _handlelastSenderName } from '../utility/forward';
import _ from 'lodash';

  export  const sendP2PMessageForward = async (obj, messageInfo, props) => {

    const database = firebase.database();

    let key = obj.messengerKey,
    senderId =  obj.senderId,
    oppositeUserUid = obj.oppositeUserUid,
    oppositeProfilePictureURL = obj.oppositeProfilePictureURL,
    oppositeUserDisplayName = obj.oppositeUserDisplayName,
    secretKey = obj.secretKey.toString(),
    decryptSecretKey = obj.decryptSecretKey,
    lastSenderName = _handlelastSenderName(messageInfo);


      let fileName = _.get(messageInfo, 'type', '') == 'attach' 
        && _.get(messageInfo, 'fileName', '') 
            ? decryptMessage(_.get(messageInfo, 'fileName', '').toString(), decryptSecretKey) 
            : '';

      let text = (_.get(messageInfo, 'text') !== ''
        && _.get(messageInfo, 'text') !== null
        && _.get(messageInfo, 'text') !== undefined)
        && typeof(_.get(messageInfo, 'text')) == 'string'
            ? decryptMessage(_.get(messageInfo, 'text').toString(), decryptSecretKey)
            : '';

      let attachSource = _.get(messageInfo, 'type', '') == 'attach' 
        && _.get(messageInfo, 'attachSource', '') 
            ? decryptMessage(_.get(messageInfo, 'attachSource', '').toString(), decryptSecretKey) 
            : '';
            

    if (secretKey == '' || secretKey == null || secretKey == undefined) {
      return;
      // props.dispatch(ToastActionsCreators.displayInfo('Please check your connection'))
    }
    // console.log("props>>>>>>>>>", props)
    let timeStamp = Math.floor(Date.now());
    const message = {
      sender: senderId,
      senderName: _.get(props.user, 'personalDetails.firstName', ''),
      senderProfilePic: _.get(props.user, 'personalDetails.profilePictureURL', null),
      text: encryptMessage(text, secretKey),
      type: 'text',
      sendType: 'forward', // normal, quote
      chatType: "singleChat",
      lastSenderName: lastSenderName,
      lastTimeStamp: _.get(messageInfo, 'sendType') == 'forward' ? _.get(messageInfo, 'lastTimeStamp') : _.get(messageInfo, 'timeStamp'),
      timeStamp: timeStamp,
      read: false,
      sent: true
    };

    var nameToShowInNotification = ShowNameAccordingToLength(_.get(props,'userinfo.user.Data.userDetails[0].personalDetails.firstName','') + " " + _.get(props,'userinfo.user.Data.userDetails[0].personalDetails.lastName',''))

    if (text != '' && attachSource == '') {
      await database
        .ref('Messages')
        .child(key)
        .push(message).key
    }
    
    if (attachSource != '') {

      let ref = await database
        .ref('Messages')
        .child(key)
        .push().key;

      const attach = {
        sender: senderId,
        senderName: _.get(props.user, 'personalDetails.firstName', ''),
        senderProfilePic: _.get(props.user, 'personalDetails.profilePictureURL', null),
        attachSource: encryptMessage(attachSource.toString(), secretKey),
        fileName: encryptMessage(fileName.toString(), secretKey),
        fileSize: _.get(messageInfo, 'fileSize', null),
        type: _.get(messageInfo, 'type', text),
        sendType: 'forward', // normal, quote
        lastSenderName: lastSenderName,
        lastTimeStamp: _.get(messageInfo, 'sendType') == 'forward' ? _.get(messageInfo, 'lastTimeStamp') : _.get(messageInfo, 'timeStamp'),
        mediaType: _.get(messageInfo, 'mediaType', ''),
        text: encryptMessage(text.toString(), secretKey),
        timeStamp: timeStamp,
        sent: true,
        chatType: 'singleChat',
      }

      await database.ref(`Messages/${key}/${ref}`).update(attach);

    }

    // creating new node chatList in the Users node and saving data into it

    await database
      .ref(`Users/${oppositeUserUid}/chatList/${key}`).update({
        "timeStamp": message.timeStamp,
        "oppositeUserUid": senderId,
        "profilePictureURL": _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', null) !== null ? _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', null) : '',
        "displayName": nameToShowInNotification,
        "read": false,
        'chatType': 'singleChat',
        "lastMsg": _.get(messageInfo, 'mediaType', '') ? `${_.get(props,'userinfo.user.Data.userDetails[0].personalDetails.firstName','')} has shared ${_.get(messageInfo, 'mediaType', '')}` : text
      })

    await database
      .ref(`Users/${senderId}/chatList/${key}`).update({
        "timeStamp": message.timeStamp,
        "oppositeUserUid": oppositeUserUid,
        "profilePictureURL": oppositeProfilePictureURL !== null ? oppositeProfilePictureURL : '',
        "displayName": oppositeUserDisplayName,
        "lastMsgSentBy": senderId,
        "read": true,
        'chatType': 'singleChat',
        "lastMsg": _.get(messageInfo, 'mediaType', '') ? `You have shared ${_.get(messageInfo, 'mediaType', '')}` : text
      })

    let arrTargetId = [oppositeUserUid]
    // sending notification on sending message 
    let objReq = {
      "data": {
        "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
        "sourceUID": oppositeUserUid,
        "title": `RDEE Messenger`,
        "body": `${nameToShowInNotification} sent you a message`,
        "targetUID": arrTargetId,
        "chatType": "singleChat",
        "messengerKey": key,
        "thread_id": key,
        "imageURL": `${_.get(props.user,'personalDetails.profilePictureURL', '')}`,
        "profilePictureURL": _.get(props.user,'personalDetails.profilePictureURL', ''),
        "titleChat": nameToShowInNotification,
        "oppositeUserUid": senderId, // oppositeUserUid,
        "screen": "singleChat" // // for push notification body
      }
    }
    
    // sendUserNotification(props.user.userToken, objReq, props)
    // ends here ...sending notification on sending message 
  }


  export const sendGroupChatMessageForward = async (obj, messageInfo, props) => {
    // console.log("sendGroupChatMessageForwardobj>>>>>", obj)
    const database = firebase.database();

    let messengerKey = obj.messengerKey,
      senderId = _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null), //  this was added later
      addedContactsArray = obj.addedContactsArray,
      groupName = obj.groupName,
      groupIcon = obj.groupIcon,
      secretKey = obj.secretKey.toString(),
      decryptSecretKey = obj.decryptSecretKey,
      lastSenderName = _handlelastSenderName(messageInfo);

      let fileName = _.get(messageInfo, 'type', '') == 'attach' 
      && _.get(messageInfo, 'fileName', '') 
          ? decryptMessage(_.get(messageInfo, 'fileName', '').toString(), decryptSecretKey) 
          : '';

    let text = (_.get(messageInfo, 'text') !== ''
      && _.get(messageInfo, 'text') !== null
      && _.get(messageInfo, 'text') !== undefined)
      && typeof(_.get(messageInfo, 'text')) == 'string'
          ? decryptMessage(_.get(messageInfo, 'text').toString(), decryptSecretKey)
          : '';

    let attachSource = _.get(messageInfo, 'type', '') == 'attach' 
      && _.get(messageInfo, 'attachSource', '') 
          ? decryptMessage(_.get(messageInfo, 'attachSource', '').toString(), decryptSecretKey) 
          : '';

  let nameToShowInNotification = ShowNameAccordingToLength(_.get(props,'userinfo.user.Data.userDetails[0].personalDetails.firstName','') + " " + _.get(props,'userinfo.user.Data.userDetails[0].personalDetails.lastName',''))

  const message = {
    sender: senderId,
    text: encryptMessage(text.toString(), secretKey),
    type: _.get(messageInfo, 'type', "text"),
    timeStamp: Math.floor(Date.now()),
    read: false,
    sendType: 'forward', // normal, quote
    chatType: "groupChat",
    lastSenderName: lastSenderName,
    lastTimeStamp: _.get(messageInfo, 'sendType') == 'forward' ? _.get(messageInfo, 'lastTimeStamp') : _.get(messageInfo, 'timeStamp'),
    senderProfilePic: _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', null),
    senderName: nameToShowInNotification,
    sent: true,
  }
  if (text != '' && attachSource == '') {
    await database
      .ref('GroupMessages')
      .child(messengerKey)
      .push(message).key
  }

  if (attachSource != '') {

    let ref = await database
      .ref('GroupMessages')
      .child(messengerKey)
      .push().key;


    const attach = {
      sender: senderId,
      attachSource: encryptMessage(attachSource.toString(), secretKey),
      fileName: encryptMessage(fileName.toString(), secretKey),
      fileSize: _.get(messageInfo, 'fileSize', null),
      type: _.get(messageInfo, 'type', "text"),
      mediaType: _.get(messageInfo, 'mediaType', ''),
      text: encryptMessage(text.toString(), secretKey),
      timeStamp: Math.floor(Date.now()),
      read: false,
      sendType: 'forward', // normal, quote
      sent: true,
      chatType: 'groupChat',
      lastSenderName: lastSenderName,
      lastTimeStamp: _.get(messageInfo, 'sendType') == 'forward' ? _.get(messageInfo, 'lastTimeStamp') : _.get(messageInfo, 'timeStamp'),
      senderProfilePic: _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', null),
      senderName: _.get(props,'userinfo.user.Data.userDetails[0].personalDetails.firstName','')
    }

    await database.ref(`GroupMessages/${messengerKey}/${ref}`).update(attach);

  }

  // creating new node chatList in the Users node and saving data into it
  addedContactsArray.map((val) => {
    database
      .ref(`Users/${val.docID}/chatList/${messengerKey}`).update({
        "lastMsg": _.get(messageInfo, 'mediaType', '') ? (val.docID == senderId) ? `You have shared ${_.get(messageInfo, 'mediaType', '')}` : `${val.firstName} has shared ${_.get(messageInfo, 'mediaType', '')}` : text,
        "lastMsgSenderName": nameToShowInNotification,
        "lastMsgSenderId": senderId,
        "timeStamp": Math.floor(Date.now()),
        "read": val.docID == senderId ? true : false,

        // these fields are added later for delete messenger list 
        chatType: "groupChat",
        displayName: groupName,
        groupIcon: groupIcon,
        groupId: messengerKey,
        // till here
      })

  })

  let pushNotiFicationTargetsArr = [];
  addedContactsArray.filter((val) => {
    if (val.docID !== senderId) {
      pushNotiFicationTargetsArr.push(val.docID)
    }
  })

  // console.log("pushNotiFicationTargetsArr>>>",pushNotiFicationTargetsArr)

  // sending notification on sending message 
  let objReq = {
    "data": {
      "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
      "MessengerGroupID": messengerKey,
      "title": `RDEE Messenger`,
      "body": `${nameToShowInNotification} sent a message`,
      "targetUID": pushNotiFicationTargetsArr,
      "messengerGroupID": messengerKey,   // ---> THIS IS FOR MUTES,
      "chatType": "groupChat",
      "imageURL": `${groupIcon}`,
      "titleChat": groupName,
      "profilePictureURL": groupIcon,
      "screen": "groupChat", // for push notification body
      "messengerKey": messengerKey// for push notification body
    }
  }

  // sendUserNotification(props.user.userToken, objReq, props)
  // ends here ...sending notification on sending message     
}

export const shareButtonClickP2P = async (obj, props) => {
  // console.log("shareButtonClickP2P>>>>", obj)
  let key = obj.key,
  userOwnDetail = obj.userOwnDetail,
  oppositeUserUid = obj.oppositeUserUid,
  oppositeProfilePictureURL = obj.oppositeProfilePictureURL,
  oppositeUserDisplayName = obj.oppositeUserDisplayName,
  secretKey = obj.secretKey,
  productDetail = obj.productDetail,
  messageInfo = obj.messageInfo;

  let lastSenderName = _handlelastSenderName(messageInfo);
  // props = obj.props

  

  // console.log("chat link click>>>>>", key, userOwnDetail, oppositeUserUid, profilePictureURL, oppositeUserDisplayName, secretKey, text, productDetail)
  const database = firebase.database();
  const message = {
    sender: userOwnDetail.personalDetails.docID,
    senderName: _.get(userOwnDetail, 'personalDetails.firstName', ''),
      senderProfilePic: _.get(userOwnDetail, 'personalDetails.profilePictureURL', null),
    text: _.get(productDetail, 'productCategoryType', '') == "Non-Radiology Products" ? encryptMessage(_.get(productDetail, 'productName', '').toString(), secretKey) : encryptMessage(_.get(productDetail, 'deviceSaleName', '').toString(), secretKey),
    productDetail: productDetail,
    type: 'productLink',
    sendType: 'forward', // normal, quote
    chatType: "singleChat",
    lastSenderName: lastSenderName,
    lastTimeStamp: _.get(messageInfo, 'sendType') == 'forward' ? _.get(messageInfo, 'lastTimeStamp') : _.get(messageInfo, 'timeStamp'),
    timeStamp: Math.floor(Date.now()),
    read: false,
    sent: true
  }
  let nameToShowInNotification = ShowNameAccordingToLength(userOwnDetail.personalDetails.firstName + " " + userOwnDetail.personalDetails.lastName)

  await database
    .ref('Messages')
    .child(key)
    .push(message).key

  // creating new node chatList in the Users node and saving data into it

  await database
    .ref(`Users/${oppositeUserUid}/chatList/${key}`).update({
      "timeStamp": message.timeStamp,
      "oppositeUserUid": userOwnDetail.personalDetails.docID,
      "profilePictureURL": userOwnDetail.personalDetails.profilePictureURL !== null ? userOwnDetail.personalDetails.profilePictureURL : '',
      "displayName": nameToShowInNotification,
      "read": false,
      'chatType': 'singleChat',
      "lastMsg": "Product is shared with you"
    })

  await database
    .ref(`Users/${userOwnDetail.personalDetails.docID}/chatList/${key}`).update({
      "timeStamp": message.timeStamp,
      "oppositeUserUid": oppositeUserUid,
      "profilePictureURL": oppositeProfilePictureURL !== null ? oppositeProfilePictureURL : '',
      "displayName": oppositeUserDisplayName,
      "lastMsgSentBy": userOwnDetail.personalDetails.docID,
      "read": true,
      'chatType': 'singleChat',
      "lastMsg": "You have shared a product"
    })

  let arrTargetId = [oppositeUserUid]

  // sending notification on sending message 
  let objReq = {
    "data": {
      "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
      "sourceUID": oppositeUserUid,
      "title": `RDEE Messenger`,
      "body": `${nameToShowInNotification} shared a product with you`,
      "targetUID": arrTargetId,
      "chatType": "singleChat",
      "messengerKey": key,
      "thread_id": key,
      "imageURL": `${userOwnDetail.personalDetails.profilePictureURL}`,
      "profilePictureURL": userOwnDetail.personalDetails.profilePictureURL,
      "titleChat": nameToShowInNotification,
      "oppositeUserUid": oppositeUserUid,
      "dynamicLink": _.get(productDetail, 'docID', ''),
    }
  }
  // sendUserNotification(userOwnDetail.userToken, objReq, props)

}

export const shareButtonClickGroupChat = async (obj, props) => {
  let addedContactsArray = obj.addedContactsArray,
  messengerKey = obj.messengerKey,
  userOwnDetail = obj.userOwnDetail, 
  groupName = obj.groupName,
  groupIcon = obj.groupDescription,
  // groupDescription = obj.groupDescription,
  secretKey = obj.secretKey,
  productDetail = obj.productDetail,
  messageInfo = obj.messageInfo;

  let lastSenderName = _handlelastSenderName(messageInfo);
  
  let nameToShowInNotification = ShowNameAccordingToLength(userOwnDetail.personalDetails.firstName + " " + userOwnDetail.personalDetails.lastName)
  // console.log("_.get(productDetail,'productCategoryType','')>>>>", _.get(productDetail,'productCategoryType',''))
  const database = firebase.database();
  const message = {
    sender: userOwnDetail.personalDetails.docID,
    text: _.get(productDetail, 'productCategoryType', '') == "Non-Radiology Products" 
      ? encryptMessage(_.get(productDetail, 'productName', '').toString(), secretKey) 
      : encryptMessage(_.get(productDetail, 'deviceSaleName', '').toString(), secretKey),
    productDetail: productDetail,
    type: 'productLink',
    timeStamp: Math.floor(Date.now()),
    sendType: 'forward', // normal, quote
    chatType: "groupChat",
    lastSenderName: lastSenderName,
    lastTimeStamp: _.get(messageInfo, 'sendType') == 'forward' ? _.get(messageInfo, 'lastTimeStamp') : _.get(messageInfo, 'timeStamp'),
    read: false,
    senderProfilePic: userOwnDetail.personalDetails.profilePictureURL,
    senderName: nameToShowInNotification,
    sent: true
  }

  await database
    .ref('GroupMessages')
    .child(messengerKey)
    .push(message).key

  // creating new node chatList in the Users node and saving data into it
  addedContactsArray.map((val) => {
    database
      .ref(`Users/${val.docID}/chatList/${messengerKey}`).update({
        "lastMsg": val.docID == userOwnDetail.personalDetails.docID ? " You have shared a Product" : "Product is shared",
        "lastMsgSenderName": nameToShowInNotification,
        "lastMsgSenderId": userOwnDetail.personalDetails.docID,
        "timeStamp": Math.floor(Date.now()),
        "read": val.docID == userOwnDetail.personalDetails.docID ? true : false,
      })

  })

  let pushNotiFicationTargetsArr = [];
  addedContactsArray.filter((val) => {
    if (val.docID !== userOwnDetail.personalDetails.docID) {
      pushNotiFicationTargetsArr.push(val.docID)
    }
  })

  // sending notification on sending message 
  let objReq = {
    "data": {
      "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
      "MessengerGroupID": messengerKey,
      "title": `RDEE Messenger`,
      "body": `${nameToShowInNotification} sent you a message`,
      "targetUID": pushNotiFicationTargetsArr,
      "messengerGroupID": messengerKey,   // ---> THIS IS FOR MUTES,
      "chatType": "groupChat",
      "imageURL": `${groupIcon}`,
      "titleChat": groupName,
      "profilePictureURL": groupIcon,
      "dynamicLink": _.get(productDetail, 'docID', ''),
    }
  }

  // sendUserNotification(userOwnDetail.userToken, objReq, props)
}