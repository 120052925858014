import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import { SignInLink } from "./SignIn";
import StarRatingComponent from 'react-star-rating-component';
import userimg from '../../images/profile/userimg.png';

import {Row, Col, Button, Media} from 'reactstrap';
import repuimg from '../../images/profile/repuimg.png';
import repuimg2 from '../../images/profile/repuimg2.png';
import repuimg3 from '../../images/profile/repuimg3.png';
import localtion from '../../images/profile/location.svg';
import building from '../../images/profile/building.svg';
import work from '../../images/profile/work.svg';
import editTaglineIcon from '../../images/icons/editTaglineIcon.svg';




import profilePlaceholder from '../../images/profile_placeholder.png';
import UserContactListPage from './../contacts/usercontacts/userList';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './profile.css';
import {getUserDetail, getSearchUserList ,updateUser} from '../../actions/contacts';

import leftbackarrow from '../../images/icons/leftbackarrow.png';
import moment from 'moment';

import * as routes from '../../constants/routes';
import  RightSideContainer from '../../components/UI/rightSideContainer/rightSideContainer';
import { ShowNameAccordingToLength } from '../../utility/stringFunctions';

import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';

class ProfilePage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      personalDetail: [],
      educationDetail: [],
      professionalDetail: [],
      businessDetail: '',
      userid: '',
      searchText: '',
      searchUserList: [],
      reputationDetails: [],
      editTagline: false,
      taglineMessage:'',
      isChangeTag : false,
      isAccess:true

    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      // if (
      //   nextProps.contacts &&
      //   nextProps.contacts.searchcontactList &&
      //   nextProps.contacts.searchcontactList.userListResult
      // ) {
      //   this.setState ({
      //     searchUserList: nextProps.contacts.searchcontactList.userListResult,
      //   });
      // } else {
      //   this.setState ({
      //     searchUserList: [],
      //   });
      // }

      if (
        nextProps.contacts &&
        nextProps.contacts.contactDetail &&
        nextProps.contacts.contactDetail.length > 0
      ) {
        let getData = nextProps.contacts.contactDetail;
        this.setState ({
          personalDetail: getData[0].personalDetails,
          professionalDetail: getData[1].professionalDetails,
          educationDetail: getData[2].educationDetails,
          businessDetail: getData[3] && getData[3].businessProfile
            ? getData[3].businessProfile
            : '',
          reputationDetails: getData[4] && getData[4].reputationDetails
            ? getData[4].reputationDetails
            : '',
        },()=>{
          if(!this.state.isChangeTag){
          // console.log(this.state.personalDetail.taglineMessage);

            this.setState({
              taglineMessage: this.state.personalDetail.taglineMessage? this.state.personalDetail.taglineMessage:''
            })
          }
         
        });
      }
    }
  }

  componentDidMount () {

    // let checkAccess= false;
    // let getData=checkAvailableAccess ("profile");


    // if (getData && getData.available===true &&  getData.comingSoon===false &&  getData.maintenance===false) {
    //   checkAccess= true;
    // }


    if(checkAvailableAccess ("profile") && checkAvailableAccess ("profile")=== 'profile'){
      this.setState({
        isAccess:true
      },

      () => {

            let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
            if (loginUser) {
              this.setState ({
                userid: loginUser.docID,
              });
              let sendRequest = {
                data: {
                  getPersonalDetails: true,
                  getProfessionalDetails: true,
                  getEducationalDetails: true,
                  getBusinessProfile: true,
                  getReputationDetails: true,
                  docID: loginUser.docID,
                },
              };
              this.props.getUserDetail (sendRequest);
            }
        })
      }else{
      this.setState({
        isAccess:false
      })
    }
  }

  goToprofile (item) {
    this.props.history.push (routes.PROFILEDETAIL + `/${item.docID}`);
  }
  goToBusinessprofile () {
    const {businessDetail} = this.state;
    this.props.history.push (routes.BUSINESSDETAIL + `/${businessDetail.documentID}`);
  }

  goEditprofile () {
    this.props.history.push (routes.EDITPROFILE + `/${this.state.userid}`);
  }

  goEditEducational () {
    this.props.history.push (routes.EDITEDUCATION + `/${this.state.userid}`);
  }
  goEditProfessional () {
    this.props.history.push (routes.EDITPROFESSIONAL);
  }
  goReputationDetail () {
    this.props.history.push (
      routes.REPUTATION_DETAILS + `/${this.state.userid}`
    );
  }
 

  doEditTagline(){
    const {personalDetail} = this.state;
    this.setState({
      editTagline:true,
    })
  }

  doSubmitTagline(){
    const {taglineMessage} = this.state;
    this.setState({
      editTagline:false,
      isChangeTag:true
    })
    let sendRequest = {
      data: {
        taglineMessage: taglineMessage,
      },
    };

    this.props.updateUser (sendRequest);
  }

  render () {
    const {
      personalDetail,
      professionalDetail,
      educationDetail,
      businessDetail,
      searchUserList,
      searchText,
      reputationDetails,
      getResponseData,
      editTagline,
      taglineMessage,
      isAccess
    } = this.state;
    return (
      <div className="flex">
        {isAccess ?
          <div>
          <div className="profilemainContainer" id="profilePage">

          <div style={{textAlign: 'right'}}>
            <Button
              className="Profilebackbutton"
              onClick={() => this.props.history.goBack ()}
              data-rh="Go back"
              id="profilePageProfilebackbutton"
              
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>

              Go <span> Back</span>
            </Button>
          </div>


          <div className="innerContainer" id="profilePageinnerContainer">
            {!searchText &&
              <div>
                <div className="userProfileBoxp widthInfoCard" id="profilePageuserProfileBoxp">


                  <div style={{display:'inline-flex'}}>
                    <div className="profileImageCircle">
                      <img
                        id="profilePageuserprofileImage"
                        className="profileImageCircleImg"
                        src={
                          personalDetail && personalDetail.profilePictureURL
                            ? personalDetail.profilePictureURL
                            : profilePlaceholder
                        }
                        alt="Generic placeholder image"
                      />
                    </div>
                    <div style={{width:500}}>
                      <div className="searchAbleName" style={{display:'inline-flex'}} id="profilePageusersearchAbleName">
                        {' '}
                       

                          {personalDetail && personalDetail.searchHandle &&<div style={{marginRight:50}}>
                          {personalDetail && personalDetail.searchHandle
                            ? '@'+ personalDetail.searchHandle
                            : ''}
                            </div>}

                          {personalDetail && personalDetail.statusMessage && <div className="userStatusText" style={{display:'inline-flex'}}>
                              {(personalDetail.statusMessage==="Active" || personalDetail.statusMessage==="active") &&<div className="userActiveStatusCircle" style={{background:'#4AEA87',marginTop:7}} />}
                              {personalDetail.statusMessage==="Away" &&<div className="userActiveStatusCircle" style={{background:'#FFBF00',marginTop:7}} />}
                              {personalDetail.statusMessage==="Do Not Disturb" &&<div className="userActiveStatusCircle" style={{background:'#ff0b34',marginTop:7}} />}
                              {personalDetail.statusMessage==="Invisible" &&<div className="userActiveStatusCircle" style={{border:'1px solid #4AEA87',marginTop:7}} />}
                              <span style={{opacity:0.5}}>
                              Status: { personalDetail.statusMessage}
                              </span>
                              </div>}
                      </div>
                      

                      <p className="usernamep" id="profilePageusernamep">

                        {personalDetail && personalDetail.title
                          ? personalDetail.title
                          : ' '}
                        {' '}
                        {personalDetail && personalDetail.firstName ? ShowNameAccordingToLength(personalDetail.firstName+ " "+ personalDetail.lastName ):''}
                      

                      </p>
                      <div>
                            {editTagline ?<div className="editTagline" id="profilePageeditTagline">

                            <input id="profilePageeditTaglineInputBox" className="editTaglineInputBox" value={taglineMessage}   onChange={e =>
                              this.setState ( {taglineMessage: e.target.value})} type="text" placeholder="tagline"/>

                            <Button disabled={!taglineMessage} onClick={()=>this.doSubmitTagline()} className="saveTagLine"> Save Tagline</Button>
                              
                            </div> : <div className="profileTaglineMessage"> {taglineMessage ? `“${taglineMessage}”`: ' '} <Button onClick={()=>this.doEditTagline()} className="editTagLineButton"><img src={editTaglineIcon} alt="editTaglineIcon"/></Button></div>}

                              
                      </div>

                      <div className="workplace" id="profilePageworkplace">
                        {' '}<img alt="work" src={work} />
                       
                          {personalDetail &&  personalDetail.professionalTitle
                            ? personalDetail.professionalTitle
                            : 'N/A'}

                      </div>
                      <div className="univercityplace1" id="profilePageunivercityplace1">
                        {' '}
                        <img alt="building" src={building} />
                        {' '}
                        

                          {professionalDetail &&  professionalDetail.length>0 && 
                            professionalDetail[professionalDetail.length-1].firmName
                            ? professionalDetail[professionalDetail.length-1].firmName
                            : 'N/A'}

                      </div>
                      <div className="localtion" id="profilePagelocaltion">
                        {' '}
                        <img alt="localtion" src={localtion} />
                        {' '}
                        {personalDetail && personalDetail.location
                          ? personalDetail.location +','
                          : 'N/A'}
                        {' '}
                       
                        {/*{personalDetail && personalDetail.city
                          ? personalDetail.city +','
                          : ' '}
                        {' '}
               
                        {personalDetail && personalDetail.state
                          ? personalDetail.state +','
                          : ' '}
                        {' '}
                      
                        {personalDetail && personalDetail.country 
                          ? personalDetail.country
                          : ' '}
                        {' '}*/}
                        
                        {' '}
                      </div>
                    </div>
                   
                    </div>
                  <div style={{paddingLeft:100}}>
                    <Button
                      className="editProfileButton"
                      onClick={() => this.goEditprofile ()}
                      data-rh="Edit profile"
                      id="profilePageeditMyProfileButton"
                    >
                      Edit My Profile
                    </Button>
                    <div />
            
                  </div>
                  
                </div>

                <div className="padd14">
                  <div style={{display: 'inline-flex'}}>

                    <div className="profileCardBox" id="profilePageprofileCardBox">
                      <div className="headeingname"  id="profilePageprofileheadeingname">
                        PERSONAL <span className="boldText">DETAILS </span>
                      </div>
                      <div className="innerContentBox" id="profilePageprofileinnerContentBox">
                        <div style={{marginTop: 10}}>
                          <div style={{display: 'inline-flex'}}>
                            <div className="profileContentTitleDiv" id="profilePageprofileprofileContentTitleDiv">
                              <p className="titleP titlePColor" id="profilePageprofileprofileTitle">Title</p>
                              <p className="titleP nameStyle" id="profilePageprofileprofileTitle1">
                                {personalDetail && personalDetail.title
                                  ? personalDetail.title
                                  : ' '}
                                {' '}
                              </p>
                            </div>
                            <div className="profileContentDiv" id="profilePageprofileprofileContentDiv">
                              <p className="titleP titlePColor" id="profilePageprofileprofilefname">First Name</p>
                              <p className="titleP nameStyle"  id="profilePageprofileprofilefnamevalue">
                                {personalDetail && personalDetail.firstName
                                  ? personalDetail.firstName
                                  : 'N/A'}
                              </p>
                            </div>
                            <div className="profileContentDiv" id="profilePageprofileprofileContentDiv1">
                              <p className="titleP titlePColor" id="profilePageprofileprofilefname1">Last Name</p>
                              <p className="titleP nameStyle" id="profilePageprofileprofilefnamevalue1">
                                {personalDetail && personalDetail.lastName
                                  ? personalDetail.lastName
                                  : 'N/A'}
                              </p>
                            </div>
                            <div className=" profileContentDiv" id="profilePageprofileprofileContentDiv2">
                              <p className="titleP titlePColor" id="profilePageprofileprofilefname2">
                                Search Handle
                              </p>
                              <p className="titleP nameStyle" id="profilePageprofileprofilefnamevalue2">
                                {personalDetail && personalDetail.searchHandle
                                  ?'@'+personalDetail.searchHandle
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div style={{marginTop: 15}}>
                          <p className="titleP titlePColor" id="profilePageprofileprofileabout">About Me</p>
                          <p className="titleP nameStyle" id="profilePageprofileprofileaboutValue">
                            {personalDetail && personalDetail.aboutMe
                              ? personalDetail.aboutMe
                              : 'N/A'}
                          </p>
                        </div>
                        {/*<div style={{marginTop: 15}}>
                        <p className="titleP titlePColor">Service Offers</p>
                        <p className="titleP nameStyle">
                          {personalDetail && personalDetail.servicesOffered
                            ? personalDetail.servicesOffered
                            : ''}
                        </p>
                        <div className="servicewdklhfs">
                          Neurological Surgery{' '}
                        </div>
                        <div className="servicewdklhfs">
                          Treatment in CNS disorders and diseases{' '}
                        </div>
                      </div>*/}
                      </div>
                    </div>
                    <div>
                      <div className="profileCardBox" id="profilePageprofileReputationprofileCardBox">
                        <div className="reputationHeading" id="profilePageprofileReputationprofileCardBox1">
                          REPUTATION
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <div className="ratingdiv" id="profilePageprofileReputationprofileCardBox2">
                              {reputationDetails && reputationDetails.reputationData &&
                                reputationDetails.reputationData.overallReputationScore &&
                                 reputationDetails.reputationData.overallReputationScore
                                 ? reputationDetails.reputationData.overallReputationScore
                                 : 0}
                              {' '}
                              <span style={{fontSize: 25}}>/ 5.0</span>
                            </div>
                            <div id="profilePageprofileReputationprofileCardBox3">
                              <StarRatingComponent
                                name={'rating'}
                                starCount={5}
                                // value={
                                //   userProfile &&
                                //     userProfile.serviceProfile &&
                                //     userProfile.serviceProfile.totalServicesOffered
                                //     ? userProfile.serviceProfile.totalServicesOffered
                                //     : 0
                                // }
                                emptyStarColor={'#E4E4E4'}
                                starColor={'#F5D028'}
                                style={{fontSize: '80px', margin: 0}}
                                // onStarClick={this.onStarClick}
                                // value={
                                //   reputationDetails &&
                                //     reputationDetails.providerData &&
                                //     reputationDetails.providerData
                                //       .providerRating
                                //     ? reputationDetails.providerData
                                //         .providerRating
                                //     : 0
                                // }


                                value={reputationDetails && reputationDetails.reputationData &&
                                   reputationDetails.reputationData.overallReputationScore &&
                                    reputationDetails.reputationData.overallReputationScore
                                    ? reputationDetails.reputationData.overallReputationScore
                                    : 0
                                }
                              />
                              <p className="reviiewscn" id="profilePageprofileReputationprofileCardBox4">
                                {reputationDetails && reputationDetails.reputationData &&
                                  reputationDetails.reputationData.totalReviewCount &&
                                   reputationDetails.reputationData.totalReviewCount
                                   ? reputationDetails.reputationData.totalReviewCount
                                   : 0}
                                {' '}
                                Reviews
                              </p>
                              {reputationDetails &&
                                reputationDetails.providerData &&
                                reputationDetails.providerData.topTenPercent &&
                                <Button className="top100"> TOP 10%</Button>}
                            </div>
                          </div>
                          <div>
                            <div className="reputationsStatus" id="profilePageprofileReputationprofileCardBox5">
                              <img alt="icon" src={repuimg} style={{width: 30}} />

                              {reputationDetails && reputationDetails.reputationData &&
                                reputationDetails.reputationData.ordersCompleted &&
                                 reputationDetails.reputationData.ordersCompleted
                                 ? reputationDetails.reputationData.ordersCompleted
                                 : 0}
                              <span>
                                {' '} Orders Completed
                              </span>
                            </div>
                            <div className="reputationsStatus" id="profilePageprofileReputationprofileCardBox6">
                              <img alt="repuimg2" src={repuimg2} style={{width: 30}} />

                              {reputationDetails &&
                                reputationDetails.providerData &&
                                reputationDetails.providerData.servicesProvided
                                ? reputationDetails.providerData
                                    .servicesProvided
                                : 0}
                              {' '}
                              <span> Services Provided</span>

                            </div>
                            <div className="reputationsStatus" id="profilePageprofileReputationprofileCardBox7">
                              <img alt="repuimg3" src={repuimg3} style={{width: 30}} />

                              <span>
                                {' '}Typically responds in{' '}
                              </span>
                              {' '}
                              {reputationDetails &&
                                reputationDetails.providerData &&
                                reputationDetails.providerData.responseTime
                                ? reputationDetails.providerData.responseTime
                                : 0}
                              {' '}
                              <span> minutes</span>

                            </div>

                          </div>
                        </div>
                        {reputationDetails &&
                          reputationDetails.latestReview &&
                          reputationDetails.latestReview.length===0 && <div className="noReviewText">
                            No Review

                          </div>}
                        {reputationDetails &&
                          reputationDetails.latestReview &&
                          reputationDetails.latestReview.length>0 && <div>
                          {reputationDetails.latestReview[0].map ((item, i) => (<div key={i}  id={`profilePageprofileReputationprofileCardBoxinfodiv${i}`} className="infodiv" style={{marginTop: 20}}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{display: 'flex'}}>
                                <img
                                  alt="Profile Image"
                                  src={ item && item.reviewerProfilePictureURL ? item.reviewerProfilePictureURL : profilePlaceholder
                                  }
                                  onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = profilePlaceholder;
                                  }}
                                  style={{width: 50, height: 50,borderRadius:86,border:'2px solid #4453D6'}}
                                />
                                <div>
                                  <p className="FrankPa"  id={`profilePageprofileReputationprofileCardBoxinfodivreviewTitle${i}`} >
                                    {item && item.reviewerTitle
                                      ? item.reviewerTitle
                                      : ''}
                                    {' '}

                        {item ? ShowNameAccordingToLength(item.reviewerFirstName + " "+ item.reviewerLastName ):''}


                                  </p>
                                  <p className="date2601" id={`profilePageprofileReputationprofileCardBoxinfodivreviewDate${i}`}>
                                    {item &&
                                      item.reviewDate
                                      ? moment ( item.reviewDate).format ('DD/MM/YYYY ; HH:ss a')
                                      : ''}

                                  </p>
                                </div>
                              </div>
                              <StarRatingComponent
                                name={'rating'}
                                starCount={5}
                                emptyStarColor={'#E4E4E4'}
                                starColor={'#F5D028'}
                                style={{fontSize: '80px', margin: 0}}
                                value={
                                  item && item.reviewScore  ? item.reviewScore : 0
                                }
                              />
                            </div>
                            <div className="timelkcnfjrf" id={`profilePageprofileReputationprofileCardBoxinfodivreviewText${i}`}>
                              {item &&item.reviewText
                                ? item.reviewText
                                : ''}

                            </div>
                          </div>))}
                          </div>}
                       {/*} <div
                          style={{display: 'flex', justifyContent: 'flex-end'}}
                        >
                          <Button
                            className="viewallbuton"
                            onClick={() => this.goReputationDetail ()}
                          >
                            View All
                          </Button>
                              </div>*/}
                      </div>
                    </div>
                  </div>
                  <div style={{marginTop: 20, display: 'inline-flex'}}>
                      <div className="profileeducationCardBox">
                        <div className="educationBlueButton">
                          EDUCATION
                        </div>
                        {educationDetail.map ((item, index) => (
                          <div key={index} className="marginT10" id={`profilePageprofileEducationnprofileCardBox${index}`}>
                            <div className="innerBox"  id={`profilePageprofileEducationnprofileCardBoxinnerBox${index}`}>
                              <p className="titleP nameStyle"  id={`profilePageprofileEducationnprofileCardBoxnameStyle${index}`}>
                                {item.university ? item.university : ''}
                                {' '}
                                -
                                {' '}
                                {item.university ? item.yearCompleted : ''}
                              </p>
                              <p className="titleP titlePColor" id={`profilePageprofileEducationnprofileCardBoxcourseMajor${index}`}>
                                {item.courseMajor ? item.courseMajor : ''}
                                {' '}
                                ,
                                {' '}
                                {item.degreeType ? item.degreeType : ''}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="profileeducationCardBox">
                        <div className="headeingname">
                          <span className="boldText"> WORK </span> EXPERIENCE
                        </div>

                        {professionalDetail.map ((item, index) => (
                          <div key={index} className="marginT10" id={`profilePageprofileprofessionalDetailCardBox${index}`}>
                            <div className="innerBox"  id={`profilePageprofileprofessionalDetailCardBoxinnerBox${index}`}>
                              <p className="titleP nameStyle" id={`profilePageprofileprofessionalDetailCardBoxinnerBoxFname${index}`}>
                                {item.firmName ? item.firmName : ''}

                              </p>
                              <p className="titleP dateText" id={`profilePageprofileprofessionalDetailCardBoxinnerBoxdateText${index}`}>
                                {/*11 july 2007 - 30 july 2012*/}

                                {item && item.startDate
                                  ? moment (new Date (item.startDate)).format (
                                      'MM/YY'
                                    )
                                  : 'N/A'}
                                {' - '}

                                {item && item.currentlyWorking === 'true'
                                  ? 'Present'
                                  : item && item.endDate
                                      ? moment (
                                          new Date (item.endDate)
                                        ).format ('MM/YY')
                                      : 'N/A'}

                              </p>
                              <p className="titleP titlePColor" id={`profilePageprofileprofessionalDetailCardBoxinnerBoxExtype${index}`}>
                                {item.experienceType ? item.experienceType : ''}
                                {' '}
                                -
                                {' '}
                                {' '}
                                {item.position ? item.position : ''}
                              </p>
                            </div>
                          </div>
                        ))}

                      </div>
                      <div className="profileeducationCardBox" id="profilePageBusinessBlackBox">

                        <div>
                          <div className="headeingname" id="profilePageBusinessBlackBoxheadeingname">
                            <span style={{fontWeight: 'bold'}}>BUSINESS </span>
                            {' '}
                            PROFILE
                          </div>
                        </div>
                        {businessDetail.length === 0 &&
                          <div style={{paddingTop: 15}}>

                            <p className="noBusinessYet">
                              No business added yet.
                            </p>
                            <div style={{textAlign: 'center'}}>
                              <Button id="profilePageBusinessBlackBoxaddNewBusiness"  className="addNewBusiness" onClick={()=>this.props.history.push(routes.BUSINESS_REGISTER)}>
                                Add Business
                              </Button>
                            </div>
                          </div>}
                        {businessDetail &&
                          businessDetail.registeredBy &&
                          <div>
                            <div className="profileregisterBoxInnerContent" id="profilePageBusinessBlackBoxprofileregisterBoxInnerContent"  >

                              <p
                                className="titleP titlePColor"
                                style={{marginTop: 10}}
                                id="profilePageBusinessBlackBoxRegisteredBusinessProfile" 
                              >
                                Registered Business Profile
                              </p>
                              <p className="titleP nameStyle" id="profilePageBusinessBlackBoxRegisteredBusinessProfile1" >
                                {businessDetail &&
                                  businessDetail.registeredBusinessName
                                  ? businessDetail.registeredBusinessName
                                  : ''}
                              </p>
                            </div>
                            {/*<div className="marginT10  ">
                              <div className="profileregisterBoxInnerContent">
                                <p className="titleP titlePColor">
                                  Search Handle
                                </p>
                                <p className="titleP nameStyle">
                                  {personalDetail && personalDetail.searchHandle
                                    ? '@'+personalDetail.searchHandle
                                    : ' '}
                                </p>
                              </div>
                                  </div>*/}
                            <div className="marginT10  ">
                              <div className="profileregisterBoxInnerContent" id="userProfileprofileregisterBoxInnerContent1">
                                <Row>
                                  <Col xs="6">
                                    <p className="titleP titlePColor" id="userProfileprofileregisterBoxInnerContentCity">City</p>
                                    <p className="titleP nameStyle" id="userProfileprofileregisterBoxInnerContentCityValue">
                                      {businessDetail &&
                                        businessDetail.businessCity
                                        ? businessDetail.businessCity
                                        : ' '}
                                    </p>
                                  </Col>
                                  <Col xs="6">
                                    <p className="titleP titlePColor" id="userProfileprofileregisterBoxInnerContentState">State</p>
                                    <p className="titleP nameStyle" id="userProfileprofileregisterBoxInnerContentStateValue">
                                      {businessDetail &&
                                        businessDetail.businessState
                                        ? businessDetail.businessState
                                        : ' '}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="marginT10 ">
                              <div className="profileregisterBoxInnerContent">
                                <p className="titleP titlePColor" id="userProfileprofileregisterBoxInnerContentCountry">Country</p>
                                <p className="titleP nameStyle" id="userProfileprofileregisterBoxInnerContentCountryValue">
                                  {businessDetail &&
                                    businessDetail.businessCountry
                                    ? businessDetail.businessCountry
                                    : ' '}
                                </p>
                              </div>
                            </div>
                            {businessDetail && businessDetail.documentID && <div>
                              <button
                                className="BusinessProfilebuttonStyle"
                                onClick={() => this.goToBusinessprofile ()}
                                data-rh="Business profile"
                                id="userProfileprofileregisterBoxInnerContentVisitProfile">
                                Visit Business Profile
                              </button>
                            </div>}
                          </div>}
                    </div>
                  </div>
                </div>

              </div>}
            {searchText &&
              searchText.length > 1 &&
              <div>
                {searchText.length > 0 && searchUserList.length > 0
                  ? <div>
                      {searchUserList.map ((item, index) => (
                        <div
                          key={index}
                          className="userListSearchBox"
                          onClick={() => this.goToprofile (item)}
                        >
                          <Media>
                            <Media left>
                              <Media
                                src={
                                  item && item.profilePictureURL
                                    ? item.profilePictureURL
                                    : profilePlaceholder
                                }
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = profilePlaceholder;
                                }}
                                alt="Generic placeholder image"
                              />
                            </Media>
                            <Media body>
                              <Media heading className="username">

                                {item && item.title ? item.title : ''}
                                {' '}

                                {item ? ShowNameAccordingToLength(item.firstName+ " "+ item.lastName ):''}
                                
                              </Media>
                              <p className="workstatus">
                                {item && item.position ? item.position : ''}

                              </p>
                              <p className="aboutus">

                                {item && item.firmName ? item.firmName : ''}
                              </p>

                            </Media>
                          </Media>
                        </div>
                      ))}

                      {/*<div  className="userListSearchBox">
                    <div className="noDataFound">
                    Sorry! No matches were found for this handle.
                    </div>
                </div>*/}
                    </div>
                  : <div className="userListSearchBox">
                      <div className="noDataFound">
                        Sorry! No matches were found for this handle.
                      </div>
                    </div>}

              </div>}

          </div>
        </div>

          
          <RightSideContainer>
          <UserContactListPage history={this.props.history} />
          </RightSideContainer>
        </div>
        
        :
        
        <CommonAccessContainer bgImage={InfoSyscoming} message={checkAvailableAccess('profile')} />
    
    
    }
       
        {/*<div className="contact-container-parent blueBg">
          <UserContactListPage history={this.props.history} />
              </div>*/}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserDetail,
      getSearchUserList,
      updateUser
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (ProfilePage);
