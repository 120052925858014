import {history} from '../store';
import {getMessage} from '../constants/localMessage/errorMessage';

import {
  productlist
} from '../reducers/Marketplacereducer';
import { getProductService , placeOrderService , placeOrderListService ,marketplaceOrderCancelStatusService , getUserMarketplaceProductList,getUserMarketplaceOrders, createMarketplaceProduct,marketplaceOrderPaidStatus ,marketplaceOrderSellerConfirmStatus ,listMarketplaceProduct,delistMarketplaceProduct, modifyMarketplaceProduct,marketplaceUploadProductImage , marketplaceModifyProductImageList, marketplaceGetLatestProductListService ,marketplaceAddRecentlyViewedProductService ,marketplaceGetRecentlyViewedProductListService ,marketplaceOrderShippedStatusService ,marketplaceOrderDeliveredStatusService ,marketplaceBuyerReviewingSellerService ,marketplaceSellerReviewingBuyerService ,marketplaceGetUserReviewsService ,delistMarketplaceProductService ,deleteMarketplaceProductService,
  marketplaceOrderRefundedStatusService ,marketplaceUploadProductFile,marketplaceGetUploadProductFileHistory,marketplaceOrderVerifiedPaidStatusService,marketplaceOrderDisputeStatusService,marketplaceOrderCloseDisputeStatusService,marketplaceOrderRMAStatusService,marketplaceOrderReturnedStatusService,marketplaceOrderCompleteRefundStatusService,marketplaceGetCountryOfOriginListService} from './../services/Marketplaceservice';

import { payforOrderService ,completeOrderService ,cancelledService,refundPaymentSellerOrderService} from './../services/Paymentservice';
import {createNotification} from '../constants/notificationtoast';
import {GET_PRODUCTS, PLACE_ORDER , PLACE_ORDER_LIST , CANCEL_ORDER ,PAY_ORDER_ERROR ,PAY_ORDER_SUCCESS,SELLER_ORDER_LISTING,SELLER_ORDER_HISTORY,CREATE_SELLER_ORDER ,CONFIRM_SELLER_ORDER,PUBLISH_SELLER_ORDER ,DELETE_SELLER_ORDER ,LATEST_PRODUCT_ITEMS, RECENT_VIEW_PRODUCTS ,LEAVE_BUYER_FEEDBACK,PLACE_ACTIVE_ORDER_LIST,PLACE_ACTION_ORDER_LIST ,MARKET_PLACE_REVIEW ,SELLER_ACTIVE_ORDER ,SELLER_ACTION_ORDER ,MARKET_PRUDUCT_ACTION,BULK_UPLOAD_ERROR_LIST,
  BULK_UPLOAD_SUCCESS,BULK_UPLOAD_HISTORY,MARKETPLACE_COUNTRY_LIST,MANUFACTUREPRODUCT_VALUES,PRODUCT_CATEGORIES } from './../reducers/Marketplacereducer';

import {Loading} from './LoadingAction';

import {addNotificationHistory ,sendNotificationForMarketplace} from '../actions/Messenger';
import * as routes from '../constants/routes';
import firebase from '../config/firebase';

export const getProductListing = request => {
  return dispatch => {
    dispatch (Loading (true));
    getProductService (request)
      .then (res => {
        //console.log(res.data);
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
        
          dispatch ({type: GET_PRODUCTS, payload: res.data.result.Data});
          // if(res.data.result.Data.length==0){
          //   createNotification ('error','No record found');
          // }
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));


          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};


export const doOrder = (request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    placeOrderService (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: PLACE_ORDER, payload:true});
          paramsAddNotiHistory.data.navigateToID=res.data.result.Data.docID;
          paramsAddNotiHistory.data.orderScreenType="seller";
          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));


          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


// ** Start Buyer  Market place orders for fetch Orders  **//
export const getMarketPlaceOrder = request => {
  return dispatch => {
    dispatch (Loading (true));
    placeOrderListService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: PLACE_ORDER_LIST, payload: res.data.result.Data});
          
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getMarketPlaceActiveOrder = request => {
  return dispatch => {
    dispatch (Loading (true));
    placeOrderListService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: PLACE_ACTIVE_ORDER_LIST, payload: res.data.result.Data});
          
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getMarketPlaceActionOrder = request => {
  return dispatch => {
    dispatch (Loading (true));
    placeOrderListService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: PLACE_ACTION_ORDER_LIST, payload: res.data.result.Data});
          
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

// ** End Buyer  Market place orders for fetch Orders  **//


export const cancelPlaceOrder = (request,paramsAddNotiHistory) => {
  return dispatch => {
    
    dispatch (Loading (true));
    marketplaceOrderCancelStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: CANCEL_ORDER, payload: res.data.result.Data});
          createNotification ('success',getMessage(res.data.result.responseCode));
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory,'sellerlanding'));
          }
        
          
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const getSellerInventory = request => {
  return dispatch => {

    dispatch (Loading (true));
    getUserMarketplaceProductList (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          if(res.data.result.Data.length>=0){
            dispatch ({type: SELLER_ORDER_LISTING, payload: res.data.result.Data});

          }else{
            dispatch ({type: SELLER_ORDER_LISTING, payload: [res.data.result.Data]});

          }
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

// *** Seller order History Start ***//

export const getSellerOrder = request => {
  return dispatch => {

    dispatch (Loading (true));
    getUserMarketplaceOrders (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          if(request && request.data && request.data.docID){
            if(res.data.result.Data &&res.data.result.Data.length===0){
              createNotification ('error','The order has been cancelled');
              setTimeout(()=>{
                window.location.href=routes.SELLER_CONSOLE;
              },1500)
            }
          }
          dispatch ({type: SELLER_ORDER_HISTORY, payload: res.data.result.Data});
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getSellerActiveOrder = request => {
  return dispatch => {

    dispatch (Loading (true));
    getUserMarketplaceOrders (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: SELLER_ACTIVE_ORDER, payload: res.data.result.Data});
        } else {
          // if (res && res.data &&  res.data.result && res.data.result){
          //   createNotification ('error',getMessage(res.data.result.responseCode));
          // }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const getSellerActionOrder = request => {
  return dispatch => {

    dispatch (Loading (true));
    getUserMarketplaceOrders (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: SELLER_ACTION_ORDER, payload: res.data.result.Data});
        } else {
          // if (res && res.data &&  res.data.result && res.data.result){
          //   createNotification ('error',getMessage(res.data.result.responseCode));
          // }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

// *** Seller order History End ***//










export const payOrderItem = (request, paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    payforOrderService (request)
      .then (res => {

        console.log(res);
     

        if (res && res.data && res.data.data && res.data.status === 'success') {
        
          dispatch ({type: PAY_ORDER_SUCCESS, payload: res.data.data});

          let sendRequest= {
              data: {
                  docID: request.order_id,
                  transactionID: res.data.data.txDetails.txid,
                  escrowAddress: res.data.data.escrowAddress,
                  paymentTransactionID: res.data.data.txDetails.vin[0].txid,
                  paymentType:'internal'
              }
          }

          dispatch (doMarketplaceOrderPaidStatus (sendRequest));
          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          
          


          // createNotification ('success',res.data.data.message);
        }
        if (
          res &&
          res.error &&
          (res.error.response.status === 500 ||
            res.error.response.status === 400)
        ) {
          if (
            res.error.response.data &&
            res.error.response.data.data &&
            res.error.response.data.data[0]
          ) {
            dispatch ({
              type: PAY_ORDER_ERROR,
              payload: res.error.response.data.data[0].msg,
            });
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const doMarketplaceOrderPaidStatus = (request,addnewNotificationHistory)  => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceOrderPaidStatus (request)
      .then (res => {
        console.log(res);
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          if(addnewNotificationHistory){
            dispatch ({type: PAY_ORDER_SUCCESS, payload: res.data.result.Data});
            dispatch (sendNotificationForMarketplace(addnewNotificationHistory));
          }
        }else{
             if (res && res.data &&  res.data.result && res.data.result){
               if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
                createNotification ('error',getMessage(res.data.result.responseCode));

               }else{
                createNotification ('error',res.data.result.message);
               }
              }
        }

        

        // if (res && res.data &&  res.data.result && res.data.result.status === true) {
        //   console.log(res.data);

    
        //   dispatch ({type: CREATE_SELLER_ORDER, payload: res.data.result.Data});
        //   createNotification ('success',getMessage(res.data.result.responseCode));
        // } else {
        //   if (res && res.data &&  res.data.result && res.data.result){
        //     createNotification ('error',getMessage(res.data.result.responseCode));
        //   }
        // }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const createNewProduct =( request ,selectedImages ,status) => {
  return dispatch => {

    dispatch (Loading (true));
    createMarketplaceProduct (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
        

         

          
          if(selectedImages && selectedImages.length>0){

            let imageUpload=[];

            for(let items of selectedImages){
  
              imageUpload.push({
                productID: res.data.result.Data.docID,
                productImageFilename:items.filename,
                productImageContentType:items.fileType,
                productImage:items.base64,
              })
  
            }
            if(imageUpload && imageUpload.length>0){
              for(let items of imageUpload){
                let sendRequest={
                  data:items
                }
                dispatch (uploadProductImage(sendRequest ,imageUpload,imageUpload.length));
              }
            }

            
            

          }
          if(status){
            let sendRequest1 = {
              data: {
                docID: res.data.result.Data.docID,
              },
            };
            dispatch (publishOrder(sendRequest1));
          }else{
              dispatch ({type: MARKET_PRUDUCT_ACTION, payload: res.data.result.Data});
  

          }




          dispatch ({type: CREATE_SELLER_ORDER, payload: res.data.result.Data});
          createNotification ('success',getMessage(res.data.result.responseCode));
        } else {
          dispatch (Loading (false));

          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const modifyProduct =( request ,selectedImages , sendImageModify ,status ,imgCount) => {
  return dispatch => {

    dispatch (Loading (true));
    modifyMarketplaceProduct (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {

          if(!sendImageModify && selectedImages && selectedImages.length===0){
            dispatch (Loading (false));
          }
       

          let imageUpload=[];

          for(let items of selectedImages){

            imageUpload.push({
              productID: res.data.result.Data.docID,
              productImageFilename:items.filename,
              productImageContentType:items.fileType,
              productImage:items.base64,
            })

          }

          if(selectedImages && selectedImages.length>0){
            for(let items of imageUpload){
              let sendRequest={
                data:items
              }
  

              // if(sendImageModify && sendImageModify.data &&  sendImageModify.data.productImageList){

              //   imageUpload = imageUpload.concat(sendImageModify.data.productImageList);
              // }
              // console.log(imageUpload);
              dispatch (uploadProductImage(sendRequest,imageUpload,imgCount , res.data.result.Data));
            }
          }else{
            dispatch ({type: MARKET_PRUDUCT_ACTION, payload: res.data.result.Data});

          }

          if(sendImageModify){
            dispatch (modifyProductImage(sendImageModify ,imageUpload,res.data.result.Data));

          }
          


          if(status){
            let sendRequest1 = {
              data: {
                docID: res.data.result.Data.docID,
              },
            };
            dispatch (publishOrder(sendRequest1));
          }




          dispatch ({type: CREATE_SELLER_ORDER, payload: res.data.result.Data});
          dispatch ({type: SELLER_ORDER_LISTING, payload: res.data.result.Data});
          createNotification ('success',getMessage(res.data.result.responseCode));
        } else {
           dispatch (Loading (false));

          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const uploadProductImage = (request,imageUploadlength ,imgCount,updateProductData) => {
  return dispatch => {
    let  countCall = 0;
    countCall=countCall+1;
    console.log(countCall);
    console.log('countCall');


    dispatch (Loading (true));
    marketplaceUploadProductImage (request)
      .then (res => {
        console.log(res)
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          let getResponse =res.data.result.Data;

          // sendRequest ,imageUpload


          console.log(getResponse.productImageList);
          console.log(imageUploadlength);
          console.log(imgCount);

          if(getResponse && getResponse.productImageList && getResponse.productImageList.length>0){
            if(imgCount){
              console.log(getResponse.productImageList.length+"==="+imgCount)

              if(getResponse.productImageList.length===imgCount){
                console.log("hide loader0")
                dispatch (Loading (false));
                dispatch ({type: MARKET_PRUDUCT_ACTION, payload: updateProductData});
              }
            }else{
              console.log(getResponse.productImageList.length+"==="+imageUploadlength.length)
              if(getResponse.productImageList.length===imageUploadlength.length){
                console.log("hide loader 1")
                dispatch (Loading (false));
                dispatch ({type: MARKET_PRUDUCT_ACTION, payload: updateProductData});
              }
            }
            
          }else{
            console.log("rejected")
          }



          // if(res.data.result.Data){

          // }

        }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const modifyProductImage = (request,newImageUpload ,updateProductData) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceModifyProductImageList (request)
      .then (res => {

        if(newImageUpload.length===0){
          dispatch (Loading (false));
          dispatch ({type: MARKET_PRUDUCT_ACTION, payload: updateProductData});
        }

   
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};






export const orderSellerConfirmStatus =(request,paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceOrderSellerConfirmStatus (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
         

          dispatch ({type: CONFIRM_SELLER_ORDER, payload: request});
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }
          createNotification ('success',getMessage(res.data.result.responseCode));

          // let sendSellerOrderRequest = {
          //   data: {
          //     docID: this.props.match.params.orderId,
          //   },
          // };
    
          // this.props.getSellerOrder (sendSellerOrderRequest);


          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getSellerOrder (sendSellerOrderRequest));

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const publishOrder =request => {
  return dispatch => {

    // dispatch (Loading (true));
    listMarketplaceProduct (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
       

          dispatch ({type: PUBLISH_SELLER_ORDER, payload: request});
          dispatch ({type: MARKET_PRUDUCT_ACTION, payload: res.data.result.Data});

          createNotification ('success',getMessage(res.data.result.responseCode));
          // let sendInventoryRequest = {
          //   data: {
          //     docID: '',
          //   },
          // };

          // dispatch (getSellerInventory(sendInventoryRequest));

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        // dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const deleteProductOrder =request => {
  return dispatch => {

    dispatch (Loading (true));
    delistMarketplaceProduct (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          

          dispatch ({type: DELETE_SELLER_ORDER, payload: request});
          dispatch ({type: MARKET_PRUDUCT_ACTION, payload: res.data.result.Data});

          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendInventoryRequest = {
            data: {
              docID: '',
            },
          };

          dispatch (getSellerInventory(sendInventoryRequest));

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};






export const getTopLatestProducts = request  => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceGetLatestProductListService (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
    
          dispatch ({type: LATEST_PRODUCT_ITEMS, payload: res.data.result.Data});
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const addRecentViewProduct = request  => {
  return dispatch => {

    // dispatch (Loading (true));
    marketplaceAddRecentlyViewedProductService (request)
      .then (res => {
        // if (res && res.data &&  res.data.result && res.data.result.status === true) {
    
        //   dispatch ({type: LATEST_PRODUCT_ITEMS, payload: res.data.result.Data});
        // }
        // dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const getrecentViewHistory = request  => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceGetRecentlyViewedProductListService (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
    
          dispatch ({type: RECENT_VIEW_PRODUCTS, payload: res.data.result.Data});
        }else{
          dispatch ({type: RECENT_VIEW_PRODUCTS, payload: []});

        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const marketplaceOrderShippedStatus =(request,paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceOrderShippedStatusService (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: CONFIRM_SELLER_ORDER, payload: request});
          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          // dispatch(getMarketPlaceOrder (sendSellerOrderRequest));
          dispatch(getSellerOrder (sendSellerOrderRequest));
          createNotification ('success',getMessage(res.data.result.responseCode));

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const  marketplaceOrderDeliveredStatus =(request,paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceOrderDeliveredStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getMarketPlaceOrder (sendSellerOrderRequest));

          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};







export const  buyerGiveReviewToseller =(request, paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceBuyerReviewingSellerService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: LEAVE_BUYER_FEEDBACK, payload: res.data.result.Data});
          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          dispatch(getMarketPlaceOrder (sendSellerOrderRequest));
          createNotification ('success',getMessage(res.data.result.responseCode));
        
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            // dispatch ({type: LEAVE_BUYER_FEEDBACK, payload: res.data.result.Data});
        
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const  sellerGiveReviewTobuyer =(request , paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceSellerReviewingBuyerService (request)
      .then (res => {

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: LEAVE_BUYER_FEEDBACK, payload: res.data.result.Data});
          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getSellerOrder (sendSellerOrderRequest));
          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }else{
            createNotification ('success',res.data.result.message);
           }
          

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            // dispatch ({type: LEAVE_BUYER_FEEDBACK, payload: res.data.result.Data});
        
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const  marketPlaceReviews =request => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceGetUserReviewsService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: MARKET_PLACE_REVIEW, payload: res.data.result.Data});
        
        }
       
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const  deListMarketPlace =request => {
  return dispatch => {

    dispatch (Loading (true));
    delistMarketplaceProductService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: MARKET_PRUDUCT_ACTION, payload: res.data.result.Data});
          createNotification ('success',getMessage(res.data.result.responseCode));
        } else{
          // dispatch ({type: LEAVE_BUYER_FEEDBACK, payload: res.data.result.Data});
      
          createNotification ('error',getMessage(res.data.result.responseCode));
        }
       
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const  deleteMarketPlace =request => {
  return dispatch => {

    dispatch (Loading (true));
    deleteMarketplaceProductService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: MARKET_PRUDUCT_ACTION, payload: res.data.result.Data});
          createNotification ('success',getMessage(res.data.result.responseCode));
        } 
        else{
      
          createNotification ('error',getMessage(res.data.result.responseCode));
        }
       
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const doCompleteOrderItem = (request ,OrderDeliveredRequest ,paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    completeOrderService (request)
      .then (res => {
     

        if (res && res.data && res.data.data && res.data.status === 'success') {
        
          // dispatch ({type: PAY_ORDER_SUCCESS, payload: res.data.data});

          // let sendRequest= {
          //     data: {
          //         docID: request.order_id,
          //         transactionID: res.data.data.txDetails.txid,
          //         escrowAddress: res.data.data.escrowAddress,
          //         paymentTransactionID: res.data.data.txDetails.vin[0].txid,
          //     }
          // }

          dispatch (marketplaceOrderDeliveredStatus (OrderDeliveredRequest));

          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          

          // createNotification ('success',res.data.data.message);
        }else{
          dispatch (Loading (false));
        }
        if (
          res &&
          res.error &&
          (res.error.response.status === 500 ||
            res.error.response.status === 400)
        ) {
          if (
            res.error.response.data &&
            res.error.response.data.data &&
            res.error.response.data.data[0]
          ) {
            dispatch ({
              type: PAY_ORDER_ERROR,
              payload: res.error.response.data.data[0].msg,
            });
          }
        }
        
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const doCancelledRefundItem = (request ,OrderRefundRequest ,paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    cancelledService (request)
      .then (res => {
     

        if (res && res.data && res.data.data && res.data.status === 'success') {
        
        OrderRefundRequest.data.originalEscrowTransactionID=res.data.data.txDetails.vin[0].txid;
        OrderRefundRequest.data.refundPaymentAddress=res.data.data.escrowAddress;
        OrderRefundRequest.data.paymentTransactionID=res.data.data.txDetails.txid;


          dispatch (marketplaceOrderRefundedStatus (OrderRefundRequest));

          dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          // createNotification ('success',res.data.data.message);
        }
        if (
          res &&
          res.error &&
          (res.error.response.status === 500 ||
            res.error.response.status === 400)
        ) {
          if (
            res.error.response.data &&
            res.error.response.data.data &&
            res.error.response.data.data[0]
          ) {
            dispatch ({
              type: PAY_ORDER_ERROR,
              payload: res.error.response.data.data[0].msg,
            });
          }
        }
        // dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};





export const  sellerVerifyPayment =request => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceOrderVerifiedPaidStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          // createNotification ('success',getMessage(res.data.result.responseCode));

          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getSellerOrder (sendSellerOrderRequest));


          // window.location.href=routes.SELLER_CONSOLE;
          // let sendSellerOrderRequest = {
          //   data: {
          //     docID: request.data.docID,
          //   },
          // };
          // dispatch(getMarketPlaceOrder (sendSellerOrderRequest));

        } else {
          dispatch (Loading (false));
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
       
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const  marketplaceOrderRefundedStatus =(request,paramsAddNotiHistory) => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceOrderRefundedStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          // createNotification ('success',getMessage(res.data.result.responseCode));

          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }else{
            createNotification ('success',res.data.result.message);
           }

          // dispatch(getMarketPlaceOrder (sendOrderRequest));
          // window.location.href=routes.SELLER_CONSOLE;
          let sendSellerOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getSellerOrder (sendSellerOrderRequest,paramsAddNotiHistory));
          // if(paramsAddNotiHistory){
          //   dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));

          // }


        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));

             }else{
              createNotification ('error',res.data.result.message);
             }
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const  uploadProductHistory =request => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceGetUploadProductFileHistory (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          

          dispatch ({
            type: BULK_UPLOAD_HISTORY,
            payload: res.data.result.Data
          });

         

        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            dispatch ({
              type: BULK_UPLOAD_HISTORY,
              payload: []
            });
            // createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};






export const  marketplaceUploadProductFileFunction =request => {
  return dispatch => {

    dispatch (Loading (true));
    marketplaceUploadProductFile (request)
      .then (res => {
        dispatch (Loading (false));

        if(request.data.checkOnly){
          if(res && res.data &&  res.data.result && res.data.result && res.data.result.Data){
            dispatch ({type: BULK_UPLOAD_ERROR_LIST, payload:res.data.result.Data});
            
            if (res && res.data &&  res.data.result && res.data.result.status === true) {
          
            } else {
              if (res && res.data &&  res.data.result && res.data.result){
    
               
                createNotification ('error',getMessage(res.data.result.responseCode));
                dispatch ({type: BULK_UPLOAD_ERROR_LIST, payload:res.data.result.Data});
              }
            }
          }
          

        }else{
          if(res && res.data &&  res.data.result && res.data.result && res.data.result.Data){

            if (res && res.data &&  res.data.result && res.data.result.status === true) {
            
              dispatch ({type: BULK_UPLOAD_SUCCESS, payload:res.data.result.Data});
    
            } else {
              if (res && res.data &&  res.data.result && res.data.result && res.data.result.Data){
    
                // console.log(res.data.result.responseCode);
                 createNotification ('error',getMessage(res.data.result.responseCode));
                // dispatch ({type: BULK_UPLOAD_SUCCESS, payload:res.data.result.Data});
              }
            }
          }
        }
        
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const  marketPlaceRaisedDisputeBuyerSide =(request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    marketplaceOrderDisputeStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getMarketPlaceOrder (sendOrderRequest));
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }
          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }else{
            createNotification ('success',res.data.result.message);
           }

        } else {
          dispatch (Loading (false));
          if (res && res.data &&  res.data.result && res.data.result){

            if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));

             }else{
              createNotification ('error',res.data.result.message);
             }

           
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};


export const  marketPlaceCloseDisputeSellerSide =(request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    marketplaceOrderCloseDisputeStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getSellerOrder (sendOrderRequest));
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }
          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }else{
            createNotification ('success',res.data.result.message);
           }

        } else {
          dispatch (Loading (false));
          if (res && res.data &&  res.data.result && res.data.result){

            if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));

             }else{
              createNotification ('error',res.data.result.message);
             }

           
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};


export const  marketPlaceRefuseDisputeSellerSide =(request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    marketplaceOrderRMAStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getSellerOrder (sendOrderRequest));
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }
          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }
          //  else{
          //   createNotification ('success',res.data.result.message);
          //  }

        } else {
          dispatch (Loading (false));
          if (res && res.data &&  res.data.result && res.data.result){

            if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));

             }else{
              createNotification ('error',res.data.result.message);
             }

           
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};


export const  marketPlaceOrderReturnedBuyerSide =(request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    marketplaceOrderReturnedStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getMarketPlaceOrder (sendOrderRequest));
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }
          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }
          //  else{
          //   createNotification ('success',res.data.result.message);
          //  }

        } else {
          dispatch (Loading (false));
          if (res && res.data &&  res.data.result && res.data.result){

            if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));

             }else{
              createNotification ('error',res.data.result.message);
             }

           
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};



export const  marketPlaceReturnedCompleteBuyerSide =(request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    marketplaceOrderCompleteRefundStatusService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          let sendOrderRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          dispatch(getMarketPlaceOrder (sendOrderRequest));
          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }
          if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
            createNotification ('success',getMessage(res.data.result.responseCode));

           }

        } else {
          dispatch (Loading (false));
          if (res && res.data &&  res.data.result && res.data.result){

            if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));

             }else{
              createNotification ('error',res.data.result.message);
             }

           
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};





export const  marketPlaceInternalRefundSellerSide =(request,paramsAddNotiHistory) => {
  return dispatch => {
    dispatch (Loading (true));
    refundPaymentSellerOrderService (request)
      .then (res => {

        if (res && res.data &&  res.data.status && res.data.status === 'success') {
          

            let sendRequest= {
                data: {
                    docID: request.order_id,
                    originalEscrowTransactionID: res.data.data.txDetails.txid,
                    refundPaymentAddress: res.data.data.escrowAddress,
                    paymentTransactionID: res.data.data.txDetails.vin[0].txid,
                    paymentType:'internal'
                }
            }
            dispatch ({type: PAY_ORDER_SUCCESS, payload: res.data.data});

            dispatch (marketplaceOrderRefundedStatus (sendRequest,paramsAddNotiHistory));
            


          if(paramsAddNotiHistory){
            dispatch (sendNotificationForMarketplace(paramsAddNotiHistory));
          }

        } else {
          dispatch (Loading (false));

          if (
            res &&
            res.error &&
            (res.error.response.status === 500 ||
              res.error.response.status === 400)
          ) {
            if (
              res.error.response.data &&
              res.error.response.data.data &&
              res.error.response.data.data[0]
            ) {
              dispatch ({
                type: PAY_ORDER_ERROR,
                payload: res.error.response.data.data[0].msg,
              });
            }
          }
          // if (res && res.data &&  res.data.result && res.data.result){

          //   if(res.data.result.responseCode && getMessage(res.data.result.responseCode)){
          //     createNotification ('error',getMessage(res.data.result.responseCode));

          //    }else{
          //     createNotification ('error',res.data.result.message);
          //    }

           
          // }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};


export const  marketplaceGetCountryOfOriginList =(request) => {
  return dispatch => {
    // dispatch (Loading (true));
    marketplaceGetCountryOfOriginListService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          // console.log(res.data.result.Data.countryOfOriginList);

          
          dispatch ({type: MARKETPLACE_COUNTRY_LIST, payload:res.data.result.Data.countryOfOriginList});
        }else{
          dispatch ({type: MARKETPLACE_COUNTRY_LIST, payload:[]});

        }
      
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
  };
};

export const fetchProductManufacturer = () => {
  
  return dispatch => {
      const database = firebase.database();
      database.ref(`marketplace_values/manufacturer`).on("value", snapShot => {
        if(snapShot.val()){
          // console.log(snapShot.val());
          dispatch ({type: MANUFACTUREPRODUCT_VALUES, payload: snapShot.val()});
        }else{
          dispatch ({type: MANUFACTUREPRODUCT_VALUES, payload: []});
        }
      })
      database.ref(`marketplace_values/productCategory`).on("value", snapShot => {
        if(snapShot.val()){
          // console.log(snapShot.val());
          dispatch ({type: PRODUCT_CATEGORIES, payload: snapShot.val()});
        }else{
          dispatch ({type: PRODUCT_CATEGORIES, payload: []});
        }
      })
  }
}

export const resetdoOrderValue = () => {
  return dispatch => {
          dispatch ({type: PAY_ORDER_ERROR, payload:''});
          dispatch ({type: PAY_ORDER_SUCCESS, payload:''});
          dispatch ({type: PLACE_ORDER, payload:false});
          dispatch ({type: CREATE_SELLER_ORDER, payload: null});
          dispatch ({type: SELLER_ORDER_LISTING, payload: null});
          dispatch ({type: LEAVE_BUYER_FEEDBACK, payload: null});
          dispatch ({type: MARKET_PRUDUCT_ACTION, payload: null});
          dispatch ({type: CANCEL_ORDER, payload: null});
          dispatch ({type: BULK_UPLOAD_SUCCESS, payload: null});
          dispatch ({type: BULK_UPLOAD_ERROR_LIST, payload: null});
  }
};
export const resetOrderList = () => {
  return dispatch => {
          dispatch ({type: PLACE_ORDER_LIST, payload:''});
          dispatch ({type: SELLER_ORDER_HISTORY, payload:''});


          
      
  }
};






