import * as firebase from "firebase";



//** config DEV server **//

// const config  = {
//     apiKey: "AIzaSyBMeCvEECTCr98nxgI5uhO2CcHAhuX8cpw",
//     authDomain: "radiologexapp.firebaseapp.com",
//     databaseURL: "https://radiologexapp.firebaseio.com",
//     projectId: "radiologexapp",
//     storageBucket: "radiologex",
//     messagingSenderId: "648750072548",
//     appId: "1:648750072548:web:aaaacb8a1f3f802f"
// };



//** config UAT server **//

// const config = {
//     apiKey: "AIzaSyABT5ua9_FeTcWNDWAP2RCCVKycoPFlfrU",
//     authDomain: "rdee-uat.firebaseapp.com",
//     databaseURL: "https://rdee-uat.firebaseio.com",
//     projectId: "rdee-uat",
//     storageBucket: "radiologex-uat",
//     messagingSenderId: "927559149558",
//     appId: "1:927559149558:web:1d6767273f35265edec9d7",
//     measurementId: "G-NS48MXPW95"
//   };




//** config Prod server **//

var config = {
    apiKey: "AIzaSyCvJoTt4wWvTdkPFFU_qG5qtCXKLyO8f2Q",
    authDomain: "rdee-prod.firebaseapp.com",
    databaseURL: "https://rdee-prod.firebaseio.com",
    projectId: "rdee-prod",
    storageBucket: "radiologex-prod",
    messagingSenderId: "1000900694713",
    appId: "1:1000900694713:web:3a1eae540422f46ec18168",
    measurementId: "G-FKP5NE7G0N"
  };






// const fire = firebase.initializeApp(Config);

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

// export default fire;