import React, { Component } from 'react';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import { fetchMessengerList } from '../../../actions/Messenger';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import axios from 'axios';
import _ from 'lodash';
import * as routes from '../../../constants/routes';
import {Loading} from '../../../actions/LoadingAction';

import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import queryString from 'query-string';

import './ConversationList.css';

class ConversationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: this.props.messengerList.messenger,
      searchText: '',
      selectedConversationUserID: '',
      showContacts: false,
      contactsList: this.props.contacts.contacts,
      onNewChatData:''
    };
    this.doCreateGroup = this.doCreateGroup.bind(this);
    this._handleclickOnMessage = this._handleclickOnMessage.bind(this);

  }

  static getDerivedStateFromProps = (nextProps) => {
    const { messengerList, searchText } = nextProps;
    // console.log("messengerList>>> conversation list",messengerList, "nextProps>>>>", nextProps);
    const messages = messengerList.messenger.map(obj => {
      const { chatType } = obj;
      let returnObj;
      if (chatType === "groupChat") {
        returnObj = {
          photo: obj.groupIcon,
          name: obj.displayName,
          text: obj.lastMsg,
          key: obj.groupId,
          chatType,
          // read: obj.read,
          read: (obj.lastMsgSentBy === nextProps.userinfo.user.Data.userDetails[0].personalDetails.docID) ? true : obj.read,
          lastTime: obj.timeStamp,
          type: obj.type
        };
      } else {
        returnObj = {
          photo: obj.profilePictureURL,
          name: obj.displayName,
          text: obj.lastMsg,
          key: obj.key,
          oppositeUserUid: obj.oppositeUserUid,
          chatType,
          read: obj.read,
          lastTime: obj.timeStamp,
          type: obj.type
        };
      }
      return returnObj;
    });
    return {
      conversations: messages,
      // searchText,
    };
  }

  componentDidMount() {
    this.props.fetchMessengerList(this.props.userinfo.user.Data.userDetails[0].personalDetails.docID);

    const {history } = this.props;

    if(history && history.location && history.location.search){
      const {conversationChatId} = queryString.parse (history.location.search);
      if(conversationChatId){
        this.props.Loading(true);
          setTimeout(()=>{
            this._handleclickOnMessage('' , conversationChatId);
          },1000)
          setTimeout(()=>{
            this.props.Loading(false);
          },2000)

        }
    
  }


    // :{ location: {search}}
    // console.log(location);

    // const {conversationId} = queryString.parse (search);
    // console.log(conversationId)
    // this.getConversations();
  }

  componentDidUpdate(prevProps){
    // console.log("prevProps.messengerList.messenger>>>",prevProps.messengerList.messenger,"this.props.messengerList.messenger>>>>>", this.props.messengerList.messenger)
    // if(prevProps.messengerList.messenger.length !== this.props.messengerList.messenger.length){
    //   // this.props.fetchMessengerList(this.props.userinfo.user.Data.userDetails[0].personalDetails.docID);
    // }
    // this.props.fetchMessengerList(this.props.userinfo.user.Data.userDetails[0].personalDetails.docID);
  }

  // getConversations = () => {
  //   axios.get('https://randomuser.me/api/?results=20').then(response => {
  //     console.log("response", response)
  //     this.setState(prevState => {
  //       let conversations = response.data.results.map(result => {
  //         return {
  //           photo: result.picture.large,
  //           name: `${result.name.first} ${result.name.last}`,
  //           text: 'Hello world! This is a long message that needs to be truncated.'
  //         };
  //       });

  //       return { ...prevState, conversations };
  //     });
  //   });
  // }

  _handleOnPressMessenger() {
    // alert("Messenger click")
  }

  changeText = (event) => {
    if (event.target.value) {
      this.setState({
        searchText: event.target.value
      })
    } else {
      this.setState({
        searchText: ''
      })
    }
    // this.setState(
    //     {searchText : event.target.value}
    // );
    // this.searchText(event.target.value)
  }


  searchText(searchText) {
    let text = searchText.toLowerCase();
    // let searchList = [...this.props.messengerList.messenger]
    // let filteredName = searchList.filter((item) => {
    //   if(item && item.groupName){
    //     return item.groupName.toLowerCase().match(text)
    //   } else if(item && item.oppositeUserDisplayName){
    //     return item.oppositeUserDisplayName.toLowerCase().match(text)
    //   }

    // })
    // console.log(filteredName);
    if (text) {
      this.setState({
        searchText: text,
        // conversations: this.props.messengerList.messenger
      })
    }
    // else {
    //   // console.log("update")
    //   this.setState({
    //     // conversations: [],
    //     searchText:''
    //   })
    //   // this.setState({
    //   //   conversations: filteredName
    //   // })
    // }
  }

  _handleclickOnMessage(oppositeUserUid, key) {
    this.setState({
      selectedConversationUserID: key
    })
    _.each(this.props.messengerList.messenger, (data) => {
      let finalObj = {};
      if (data.key === key) {
        data.read = true;
        finalObj = { ...data };
        this.props.onClickConversation(finalObj);
      }

    })

  }

  doCreateGroup() {
    // console.log(routes.MESSENGERCREATEGROUP);
    // console.log(this.props);
    this.props.history.push(routes.MESSENGERCREATEGROUP);
  }

  onMessageClicked(contact) {
    this.goToChat(contact);
    this.setState({ showContacts: false })
  }

  _handleKeyVal(receiver) {
    let loginUser = JSON.parse(localStorage.getItem('loginuser'));
    let keyVal = "";
    let userId = loginUser.docID
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goToChat(contact) {
    const { history } = this.props;

    let userChatInfo = {
      oppositeUserUid: contact.docID,
      profilePictureURL: contact && contact.profilePictureURL
        ? contact.profilePictureURL
        : profilePlaceholder,
      displayName:  contact && contact.firstName
          ? contact.firstName + " " + contact.lastName
          : '',
    };
    // console.log("history in contact list render>>>>>", history)

    // this.props.userId={conversation.key}
    // this.props.selectedConversationUserID={this.state.selectedConversationUserID}
    // this.props.onClick={() => this._handleclickOnMessage(conversation.oppositeUserUid, conversation.key)}
    // history.push(routes.MESSENGER, { userChatInfo: userChatInfo, messengerKey: this._handleKeyVal(contact.docID) });
    // this.setState({
    //   onNewChatData: { userChatInfo: userChatInfo, messengerKey: this._handleKeyVal(contact.docID) }
    // })
    let data={ userChatInfo: userChatInfo, messengerKey: this._handleKeyVal(contact.docID) }
    this.props.creatingNewChat(data);  
  }

  //show all conatcts here
  contactsListRender() {
    const { contactsList ,searchText} = this.state;

    let filterNewChatData = searchText ? contactsList.filter(row =>
      row.contact.contactDetail.firstName ? (row.contact.contactDetail.firstName.toLowerCase()+" "+ row.contact.contactDetail.lastName.toLowerCase()).indexOf(searchText.toLowerCase()) > -1 : ''
    ) : contactsList;


    return (
      <div style={{ paddingBottom: 90 }}>
        <p className="MarcusBar" style={{marginLeft: 10, color: 'black'}}>My Contacts:</p>
        {filterNewChatData.map((contact, index) => {
          
          // console.log("contact>>>>>>", contact.contact.contactDetail)
          const { firstName,lastName, professionalTitle, profilePictureURL } = contact.contact.contactDetail;
          return (
            <div key={index}
              onClick={() => this.onMessageClicked(contact.contact.contactDetail)}
              style={{ display: 'flex', marginTop: 10, marginLeft: 20, marginRight: 20 ,marginBottom: 25 ,cursor:'pointer',alignItems:'center'}}>
              <div>
                <div id={`conversation-photo${index}`} className="conversation-photo">
                  <img
                    alt="profileimg"
                    id={`conversation-photoprofileimg${index}`} 
                    src={profilePictureURL ? profilePictureURL : profilePlaceholder}
                    style={{ width: 50, height: 50, borderRadius: 85 }}
                  />
                </div>
              </div>

              <div>
                <p className="MarcusBar" id={`conversation-photoMarcusBar${index}`}  style={{ color: 'black' }}>
                {ShowNameAccordingToLength(firstName+ " " + lastName)}
               </p>
                <p  id={`conversation-photolastwswef${index}`}  className="lastwswef" style={{ color: 'black' }}>{professionalTitle}</p>
              </div>

            </div>
          )
        })}
      </div>
    )
  }

  render() {

    // console.log("this.state.conversations>>>", this.state.conversations, "contactsList>>>>>", this.state.contactsList);
    const { searchText, conversations } = this.state;

    // let filteredName = this.props.messengerList.messenger.filter((item) => {
    //     if(item && item.groupName){
    //       return item.groupName.toLowerCase().match(text)
    //     } else if(item && item.oppositeUserDisplayName){
    //       return item.oppositeUserDisplayName.toLowerCase().match(text)
    //     }

    //   })
    let filterData = searchText ? conversations.filter(row =>
      row.name ? row.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 : ''
    ) : conversations;
    // console.log("filterData>>>>>",filterData);
    return (
      <div>
        <div>
          <ConversationSearch
            onChange={this.changeText}
            showContacts={() => this.setState({ showContacts: true })}
            createGroup={this.doCreateGroup} />
        </div>
        <div className="conversation-list ">
          {/* <Toolbar
          title="Messenger"
          leftItems={[
            <ToolbarButton key="cog" icon="ion-ios-cog" />
          ]}
          rightItems={[
            <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
          ]}
        /> */}

          {!this.state.showContacts &&
            filterData.map(conversation => {
              return (
                <ConversationListItem
                  userId={conversation.key}
                  selectedConversationUserID={this.state.selectedConversationUserID}
                  onClick={() => this._handleclickOnMessage(conversation.oppositeUserUid, conversation.key)}
                  {...conversation}
                />
              )
            }
            )
          }

          {/* all contacts to be shown here */}
          {this.state.showContacts && this.contactsListRender()}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
    messengerList: state.MessengerReducer,
    contacts: state.ContactsReducer,
    // dialogData: state.ShowDialog.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchMessengerList,
    Loading
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);