import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faHome,
  faComments,
  faWallet,
  faCartArrowDown,
  faBone,
  faFolderOpen,
  faUserPlus,
  faCoins,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import {
  checkAvailableAccess,
} from '../../../constants/localMessage/errorMessage';

import {
  HOME,
  MESSENGER,
  PAYMENT,
  DASH,
  PROFILE_DASHBOARD,
  MARKETPLACE,
  PACS,
  CONTACT,
  SERVICEONDEMAND,
  SERVICEONDEMANDPROFILE,
  SERVICEONDEMANDSEARCH,
  SERVICEONDEMANDMYREQUEST,
  SERVICEONDEMAND_REQUESTRECIEVED,
  CONTENT_APPS,
  INFOSYS,
  FILEDRIVE
} from '../../../constants/routes';
import logo from '../../logo.svg';
import NavigationItem from './NavigationItems';
import './SideBar.css';
import SideBarLogo from './../sideBarLogo/sideBarLogo';
const generateFAIcon = (type, disabled = false) => {
  if (type === faFolderOpen || type === faUserPlus || type === faCoins) {
    return (
      <FontAwesomeIcon
        style={{color: disabled ? '#353c4b' : '#FFFFFF', fontSize: '1.5em'}}
        icon={type}
      />
    );
  }
  return (
    <img
      alt=""
      style={{height: 30, width: 30, opacity: disabled === true ? 0.5 : null}}
      src={type}
    />
  );
};

const getNavigationItems = (activePage,history) => {
  let homeIcon = require ('../../../images/icons/sidebar/dashboard.png'),
    contactIcon = require ('../../../images/sidebar/contacts.png'),
    chatIcon = require ('../../../images/icons/sidebar/messenger.png'),
    rdeePay = require ('../../../images/icons/sidebar/rdpay.png'),
    marketplace = require ('../../../images/icons/sidebar/marketplace.png'),
    pacs = require ('../../../images/icons/sidebar/pacs.png'),
    CISRIS = require ('../../../images/icons/sidebar/CisRis.png'),
    ContentAndApps = require ('../../../images/icons/sidebar/contentApps.png'),
    serviceondemandIcon = require ('../../../images/icons/sidebar/serviceondemand.png'),
    vaultIcon = require ('../../../images/icons/sidebar/vaultIcon.png'),
    managerIcon = require ('../../../images/icons/sidebar/manager.png');
  
  return (
    <Fragment>
    {checkAvailableAccess ('dashboard') !=='not_available' &&  <NavigationItem
        path={PROFILE_DASHBOARD}
        name="Dashboard"
        icon={generateFAIcon (homeIcon, false)}
        active={activePage === PROFILE_DASHBOARD}
        disabled={false}
        key="Dashboard"
        history={history}
      />
  }
      {/*<NavigationItem
        path={CONTACT}
        name="Contacts"
        icon={generateFAIcon (contactIcon, false)}
        active={activePage === CONTACT}
        disabled={false}
        key="Contacts"
      />*/}

      {checkAvailableAccess ('messenger') !=='not_available' && <NavigationItem
        path={MESSENGER}
        name="Connect"
        icon={generateFAIcon (chatIcon, false)}
        active={activePage.includes (MESSENGER)}
        disabled={false}
        key="Messenger"
        history={history}

      />}
      {checkAvailableAccess ('marketplace') !=='not_available' &&  <NavigationItem
        path={MARKETPLACE}
        name="Marketplace"
        icon={generateFAIcon (marketplace, false)}
        active={activePage.includes (MARKETPLACE)}
        disabled={false}
        key="Marketplace"
        history={history}

      />}
      {checkAvailableAccess ('rdeepay') !=='not_available' &&  <NavigationItem
        path={PAYMENT}
        name="Settle"
        icon={generateFAIcon (rdeePay, false)}
        active={activePage === PAYMENT}
        disabled={false}
        key="Settle"
        history={history}

      />}
      
      {checkAvailableAccess ('serviceondemand') !=='not_available' && <NavigationItem
        path={SERVICEONDEMAND}
        name="Expert"
        icon={generateFAIcon (serviceondemandIcon, false)}
        active={
          activePage.includes (SERVICEONDEMAND) ||
            activePage.includes (SERVICEONDEMANDMYREQUEST) ||
            activePage.includes (SERVICEONDEMAND_REQUESTRECIEVED)
        }
        disabled={false}
        key="serviceOnDemand"
        history={history}

      />}


      {checkAvailableAccess ('pacs') !=='not_available' && <NavigationItem
        path={PACS}
        name="Scan"
        icon={generateFAIcon (pacs, false)}
        disabled={false}
        active={activePage.includes (PACS)}
        key="Scan"
        history={history}

      />}
      {checkAvailableAccess ('vault') !=='not_available' &&<NavigationItem
        path={FILEDRIVE}
        name="Vault"
        // icon={generateFAIcon(faFolderOpen, true)}
        icon={generateFAIcon (vaultIcon, false)}
        active={activePage.includes (FILEDRIVE)}
        disabled={false}
        key="FILEDRIVE"
        history={history}

      />}

      
      {checkAvailableAccess ('infosys') !=='not_available' && <NavigationItem
        path={INFOSYS}
        name="Manager"
        // icon={generateFAIcon(faFolderOpen, true)}
        icon={generateFAIcon (managerIcon, false)}
        active={activePage.includes (INFOSYS)}
        disabled={false}
        key="Manager"
        history={history}

      />}

      


      {/*<NavigationItem 
        path={SERVICEONDEMANDSEARCH}
        name="Service-on-demand-Search"
        icon={generateFAIcon(faUserPlus, false)}
        active={(activePage === SERVICEONDEMANDSEARCH)}
        disabled={false}
        key="serviceOnDemandSearch"
       />
       <NavigationItem 
       path={SERVICEONDEMANDMYREQUEST}
       name="Service-on-demand-MyRequest"
       icon={generateFAIcon(faUserPlus, false)}
       active={(activePage === SERVICEONDEMANDMYREQUEST)}
       disabled={false}
       key="serviceOnDemandMyRequest"
        />*/}
      {/* <NavigationItem 
            path={SERVICEONDEMANDPROFILE}
            name="Service-on-demand-Profile"
            icon={generateFAIcon(faUserPlus, false)}
            active={false}
            disabled={false}
            key="serviceOnDemandProfile"
        /> */}
        {checkAvailableAccess ('contents_app') !=='not_available' && <NavigationItem
        path={CONTENT_APPS}
        name="Stream"
        className="inactive"
        icon={generateFAIcon (ContentAndApps, false)}
        active={activePage.includes (CONTENT_APPS)}
        // active={true}
        disabled={false}
        key="Stream"
        history={history}

      />}
    </Fragment>
  );
};

export default function Sidebar({activePage = HOME,history}) {
  const toggleMenu = event => {
    if (event.target.matches ('.sideMenucollapsing')) {
      var element = document.getElementById ('sidebarmenu');
      if (element.matches ('.menuDisplayHide')) {
        element.classList.remove ('menuDisplayHide');
      } else {
        element.classList.add ('menuDisplayHide');
      }
    }
  };
  const toggleSidebar = () => {
    var element = document.getElementById ('sidebarmenu');
    element.classList.add ('menuDisplayHide');
  };

  return (
    <div className="side-drawer">
      <div className="App-logo-container">
      <SideBarLogo />
        {/*<img
          src={logo}
          className="app-logo sideMenucollapsing"
          onClick={toggleMenu}
        />*/}
      </div>
      <div className="innerMenuItem">
        {getNavigationItems (activePage,history)}
      </div>
      <div id="overlay" onClick={() => toggleSidebar ()} />
    </div>
  );
}

Sidebar.propTypes = {
  activePage: PropTypes.string.isRequired,
  history: PropTypes.any
};
