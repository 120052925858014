import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import aboutlogo from '../../images/accountSetting/aboutlogo.png';

class AboutAppPolicy extends Component {
  render () {
    return (
      <div>

        <div >
        <div >
      
          <div>
            <div className="aboutfirsttext">
              Radiologex® Medical Interactive Network™
              <p style={{marginTop:2}}> MVP 1.0</p>
            </div>

            <p className="Theworlds">
              The world’s first Medical Interactive Network™<br />

              The Radiologex® Medical Interactive Network,™ designated as R-DEE™,
               is a technology product dedicated to<br />
              serving multiple facets of the healthcare industry.
            </p>
          </div>
          
        </div>

        <div className="Theworlds">
          <div>
            <p>
              The RMIN,™ features a suite of software applications running on a collaborative, peer-to-peer computer network called a Blockchain. The network has 7 key applications running on top, that facilitate and incentivize different types of users to perform their intended functions in healthcare, in a manner that is time and cost-effective like never before. This is achieved by creating a secure, standardized software network, that brings together different functions such as payments, logistics, marketplaces, data sharing systems, etc. into one self-sustaining and interoperable ecosystem. Built as a modern, industrious tech product, the platform leverages best-in-class security and user-experience primitives in its design and development. The 7 software modules are as follows -
            </p>

          </div>
          {' '}
          <p>
            1. R-DEE Connect<br />
            2. R-DEE Marketplace<br />
            3. R-DEE Content Delivery Network<br />
            4. R-DEE Service-on-Demand<br />
            5. R-DEE Pay<br />
            6. R-DEE PACS<br />
            7. R-DEE RIC/ CIS
          </p>
          <p>
            The R-DEE platform is conceived, designed, and built as an all-inclusive mechanism
            {' '}
            to realign the global medical industry. The project is fully HIPAA compliant and its
            {' '}
            technology has been ONC certified by the US Department of Human Health and Services.
            {' '}
            By allowing medical professionals across the globe to connect, communicate, transact,
            {' '}
            share content, and perform services in a manner that is fast, secure, and convenient,
            {' '}
            R-DEE™ is poised to set the new standard for software in medicine and healthcare.
          </p>
        </div>
      </div>

      </div>
    );
  }
}
export default withRouter (AboutAppPolicy);
