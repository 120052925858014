
import sha256 from 'js-sha256';
// import CryptoJS from "react-native-crypto-js";
import CryptoJS from 'crypto-js'

export const EncryptFunction = (email, password) => {
    let emailLength = email.length;
    let position = email.indexOf("@");
    let salt = `RDEE${email}${emailLength}${position}`
    let finalStringWithPassword = `${salt}${password}`
    return sha256(finalStringWithPassword)
}

// Message encryption //

export const KeyGen = (base, modulo, exponent) => {
    var result = 1;
    while (exponent > 0) {
        if (exponent % 2 == 1) {
            result = (result * base) % modulo;
        }
        base = (base * base) % modulo;
        exponent = exponent >>> 1;
    }
    return result;
}

// this function will generate the user's Shared key
export const userSharedKey = (userPublicKey) => {
    let p = 239423;
    let g = 13;
    return KeyGen(g, p, userPublicKey)
}

// it will generate the final secret key, which will be passed while encrtion or decryption
// opposite shared key and user own public key will calculated the secret key
export const CalculatedSharedSecret = (oppositeUserSharedKey, userOwnPublicKey) => {
    let p = 239423;
    return KeyGen(oppositeUserSharedKey, p, userOwnPublicKey);
}

// it will ENCRYPT the messages 
export const encryptMessage = (message, secretKey) => {
    // console.log("message>>>>>>encryptMessage",message, "secretKey>>>>", secretKey )
    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
    // console.log("ciphertext>>>>>", ciphertext, "message>>>>>", message, ">secretKey>>>", secretKey)
    return ciphertext
}

// it will DECRYPT the messages 
// export const decryptMessage = (encryptedMessage, secretKey) => {
//     // console.log("encryptedMessage>>>>>before process", encryptedMessage, "secretKey>>>>>before process", secretKey)
//     // Decrypt
//     if (encryptedMessage !== undefined && encryptedMessage !== null
//         && encryptedMessage !== '' && secretKey !== undefined
//         && secretKey !== null && secretKey !== '') {
//             try{
//                 let bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
//                 let originalText = bytes.toString(CryptoJS.enc.Utf8);
//                 // console.log("encryptedMessage>>>>>", encryptedMessage, "secretKey>>>>>", secretKey, ">originalText>>>", originalText)
//                 return originalText
//             } catch(error){
//                 console.log("error in decrypt mesage", error);
//                 let bytes = CryptoJS.AES.decrypt(encryptedMessage, '1');
//                 let originalText = bytes.toString(CryptoJS.enc.Utf8);
//                 return originalText
//             }
//             return 'encryptedMessage'
        
//     } else {
//         return ""
//     }

// }

// export const decryptMessage = (encryptedMessage, secretKey) => {
    // //   console.log (
    // //     'encryptedMessage>>>>>before process',
    // //     encryptedMessage,
    // //     'secretKey>>>>>before process',
    // //     secretKey
    // //   );
    //   // Decrypt
    //   let originalText = 'originalText';
    //   try {
    //     if (encryptedMessage && secretKey) {
    //       let bytes = CryptoJS.AES.decrypt (encryptedMessage, secretKey);
    //       originalText = bytes.toString (CryptoJS.enc.Utf8);
    //     }
    //     return originalText;
    //   } catch (error) {
    //     if (encryptedMessage && secretKey) {
    //       let bytes = CryptoJS.AES.decrypt (encryptedMessage, '1');
    //       let originalText = bytes.toString (CryptoJS.enc.Utf8);
    //       return originalText;
    //     } else {
    //       return 'originalText';
    //     }
    //   }
    // };

    // export const decryptMessage = (encryptedMessage, secretKey) => {
    //      console.log("encryptedMessage>>>>>before process",encryptedMessage, "secretKey>>>>>before process", secretKey )
    
    //     // Decrypt
    //     if (encryptedMessage !== undefined && encryptedMessage !== null
    //         && encryptedMessage !== '' && secretKey !== undefined
    //         && secretKey !== null && secretKey !== '') {
    //         let bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
    //         let originalText = bytes.toString(CryptoJS.enc.Utf8);
    //         return originalText
    
    //     } else {
    //         return ""
    //     }
    // }


    export const decryptMessage = (encryptedMessage, secretKey) => {
            let originalText = '';

           try {
                if (encryptedMessage && secretKey) {
                let bytes = CryptoJS.AES.decrypt (encryptedMessage, "1");
                    originalText = bytes.toString (CryptoJS.enc.Utf8);

                    if(!originalText){
                        let bytes = CryptoJS.AES.decrypt (encryptedMessage, secretKey);
                        originalText = bytes.toString (CryptoJS.enc.Utf8);
                    }

                    return originalText;

                }
            } catch (error) {
                    try {
                        if (encryptedMessage && secretKey) {
                        let bytes = CryptoJS.AES.decrypt (encryptedMessage, secretKey);
                            originalText = bytes.toString (CryptoJS.enc.Utf8);

                            return originalText;
        
                        }
                    } catch (error) {
                        let bytes = CryptoJS.AES.decrypt (encryptedMessage, "1");
                        // let bytes = CryptoJS.AES.decrypt (encryptedMessage, secretKey);
                            originalText = bytes.toString (CryptoJS.enc.Utf8);
                            return originalText;

                    }
            }

    
    }