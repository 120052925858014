import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Input} from 'reactstrap';
import InputCustom from '../../InputCustom/InputCustom';
import { getProfileDashboard ,ModifyPersonalInfo } from '../../../actions/Authaction';
import {
  updateUser,
} from '../../../actions/contacts';
import placeholderProfile from '../../../images/profile/pronew.png';

import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import {CapitalizeFirstLetter } from '../../../constants/helper';


import  './userAccountSetting.css'
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class UserAccountSetting extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isSubmit: false,
      editDetailDiv:false,
      detailDiv:true,
      title:'',
      firstName:'',
      lastName:'',
      aboutMe:'',
      phoneNumber:'',
      address:'',
      registeredEmail:'',
      profilepicture:'',
      searchHandle:'',
      displayUsername:'',
      isgetData: false,
      userPlanName:''
    };
  }
  componentWillReceiveProps(nextProps){
    if(nextProps){
      if(nextProps.userInfo && nextProps.userInfo.dashboardprofile && nextProps.userInfo.dashboardprofile.personalDetails){
        const userInfo=nextProps.userInfo.dashboardprofile.personalDetails;
        const subsScriptionInfo=nextProps.userInfo.dashboardprofile.subscriptionInfo;

        this.setState({
          isgetData: true
        })
        this.setState({
          title:userInfo.title,
          firstName:userInfo.firstName,
          lastName:userInfo.lastName,
          aboutMe:userInfo.aboutMe,
          phoneNumber:userInfo.phoneNumber,
          address:userInfo.address1,
          registeredEmail:userInfo.emailAddress,
          profilepicture: userInfo.profilePictureURL,
          searchHandle:userInfo.searchHandle,
          displayUsername: userInfo.displayName,
          isSubmit: false,
          editDetailDiv:false,
          detailDiv:true,
        })

        if(subsScriptionInfo && subsScriptionInfo.subscriptionPlan){
          if(subsScriptionInfo.subscriptionPlan.planName){
            this.setState({
              userPlanName:subsScriptionInfo.subscriptionPlan.planName
            })
          }
          
        }

      }
    }

  }
  componentDidMount(){
    let sendRequestUserProfile = {
      data: {},
    };
    this.props.getProfileDashboard(sendRequestUserProfile);
  }
  openEditDetailDiv = () => {
    this.setState ({
      detailDiv: false,
      editDetailDiv: true,
    });
  };

  updateProfile(){

    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          title,
          firstName,
          lastName,
          aboutMe,
          // phoneNumber,
          address,
        } = this.state;

        const isInvalid =
          title === '' ||
          firstName === '' ||
          lastName === '' ||
          // phoneNumber === '' ||
          address === '' ||
          aboutMe === '';
        // if(emailAddress){
        //   const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //   const result = pattern.test(emailAddress);

        //   if(!result){
        //     this.setState({
        //       invalidEmailFormat: true
        //     })

        //   }else{
        //     this.setState({
        //       invalidEmailFormat: false
        //     })
        //   }
        // }

     
        if (isInvalid) {
          return false;
        }
        this.setState ({
          isSubmitProfile: true,
          aboutMe:aboutMe,
          address: address,
        });

  


        let sendRequest = {
          data: {
            title: title,
            firstName: firstName,
            lastName: lastName,
            // phoneNumber: phoneNumber,
            aboutMe: aboutMe,
            address1: address,
          },
        };

        this.props.ModifyPersonalInfo (sendRequest);
      }
    );


  }
  render () {
    const {
      title,
      firstName,
      lastName,
      aboutMe,
      phoneNumber,
      address,
      registeredEmail,
      isSubmit,
      profilepicture,
      searchHandle,
      userPlanName
    } = this.state;
    return (
      <div>
      {this.state.detailDiv &&
              <div className="accsedetbox">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div className="accsetheading">
                    {' '}
                    ACCOUNT SETTINGS
                  </div>
                  <Button
                    className="editbuttonacdsj"
                    onClick={this.openEditDetailDiv}
                    data-rh="Edit"
                  >
                    Edit Account Settings
                  </Button>
                </div>
                <div style={{display: 'flex'}}>
                  <div>
                    <img alt="" src={profilepicture? profilepicture:placeholderProfile}    onError={e => {
                      e.target.onerror = null;
                      e.target.src = placeholderProfile;
                    }} className="settinguserimg" />
                  </div>
                  <div>
                    <p className="settinghjohn">{searchHandle?'@'+CapitalizeFirstLetter(searchHandle):''}</p>
                    <p className="settingDrJohn">
                    {title ? title:''} {' '}

                    {CapitalizeFirstLetter(ShowNameAccordingToLength(firstName +' ' + lastName))}
                    
                    </p>
                  </div>
                </div>
                {userPlanName && <div style={{display: 'flex'}}>
                <div className="showUserPlanName">
                    {userPlanName}
                </div>
                </div>}
                <div style={{display: 'flex'}}>
                  <div>
                    <p className="settingfiernjd">Title</p>
                    <p className="settibnjhdkjon">  {title ? title:''} {' '}</p>
                  </div>
                  <div>
                    <p className="sescsdingfiernjd">First Name</p>
                    <p className="settibssnjhdkjon">{firstName ? CapitalizeFirstLetter(firstName):''}</p>
                  </div>
                  <div>
                    <p className="sescsdinlastnamgf">Last Name</p>
                    <p className="settibssnalenaxal">{lastName ? CapitalizeFirstLetter(lastName):''}</p>
                  </div>
                </div>
                <div>
                  <p className="settingfiernjd">Address</p>
                  <p className="settibnjhdkjon">
                  {address ? address:''}
                  </p>
                </div>
                <div>
                  <p className="settingfiernjd">
                    Registered Email
                  </p>
                  <p className="settibnjhdkjon">
                  {registeredEmail ? registeredEmail:''}
                  </p>
                </div>
                <div>
                  <p className="settingfiernjd">
                    Phone Number
                  </p>
                  <p className="settibnjhdkjon">
                      {phoneNumber?phoneNumber:''}
                    </p>
                </div>
                <div>
                  <p className="settingfiernjd">
                    About Me
                  </p>
                  <p className="settibnjhdkjon">
                  {aboutMe?CapitalizeFirstLetter(aboutMe):''}
                  </p>
                </div>
                </div>}
       {this.state.editDetailDiv &&  
        <div className="seteditaccsedetbox">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="accsetheading">
              {' '}
              ACCOUNT SETTINGS
            </div>
            <Button className="editbuttonacdsj" onClick={()=>this.updateProfile()}>
              Save Edits
            </Button>
          </div>
          <div style={{display: 'flex',marginTop:10}}>
            <div>
              <img alt="profilepicture" src={profilepicture? profilepicture:placeholderProfile}    onError={e => {
                e.target.onerror = null;
                e.target.src = placeholderProfile;
              }}  className="settinguserimg" />
            </div>
            <div>
              <p className="settinghjohn">{searchHandle?'@'+CapitalizeFirstLetter(searchHandle):''}</p>
              <p className="settingDrJohn"> 
              {title ? title:''} {' '}
              {CapitalizeFirstLetter(ShowNameAccordingToLength(firstName +' '+  lastName))}
              </p>
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div>
              <p className="settingtfirstnsmla">Title</p>
              <div className="inputBackGround" >
                <InputCustom
                  value={title}
                  onSelect={value => {
                  
                    this.setState (byPropKey ('title', value));
                  }}
                  options={[
                    {name: 'Mr.', value: 'Mr.'},
                    {name: 'Dr.', value: 'Dr.'},
                    {name: 'Ms.', value: 'Ms.'},
                    {name: 'Mrs.', value: 'Mrs.'},
                  ]}
                />

              </div>

              {!title &&
                isSubmit &&
                <p className="validationerrorMessage" style={{paddingLeft: 20}}>
                  {' '}Title is required *{' '}
                </p>}
            </div>
            <div>
              <p className="settingtitlelabel">First Name</p>
              <Input
                className="firstnameinput"
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Legal First Name*"
                value={CapitalizeFirstLetter(firstName)}
                disabled={true}
                onChange={e =>
                  this.setState (byPropKey ('firstName', e.target.value))}
              />
              {!firstName &&
                isSubmit &&
                <p className="validationerrorMessage" style={{paddingLeft: 50}}>
                  {' '}Firstname is required *{' '}
                </p>}
            </div>

            <div>
              <p className="settinglastnlabel">Last Name</p>
              <Input
                className="lastnameinput"
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Legal Last Name*"
                value={CapitalizeFirstLetter(lastName)}
                disabled={true}
                onChange={e =>
                  this.setState (byPropKey ('lastName', e.target.value))}
              />
              {!lastName &&
                isSubmit &&
                <p className="validationerrorMessage" style={{paddingLeft: 50}}>
                  {' '}Lastname is required *{' '}
                </p>}
            </div>
          </div>
          <div>
            <p className="settingaboutlabel">Address</p>
            <Input
              className="aboutsssninput"
              type="text"
              name="address"
              id="firstname"
              placeholder="Address*"
              value={CapitalizeFirstLetter(address)}
              onChange={e =>
                this.setState (byPropKey ('address', e.target.value))}
            />
            {!address &&
              isSubmit &&
              <p className="validationerrorMessage" style={{paddingLeft: 50}}>
                {' '}Address is required *{' '}
              </p>}
          </div>
          <div>
            <p className="settingaboutlabel">Registered Email</p>
            <Input
              className="emailjcdkninput"
              type="text"
              name="registeredEmail"
              id="firstname"
              placeholder="Registered Email*"
              value={registeredEmail}
              onChange={e =>
                this.setState (byPropKey ('registeredEmail', e.target.value))}
              disabled={true}
            />
            {!registeredEmail &&
              isSubmit &&
              <p className="validationerrorMessage" style={{paddingLeft: 50}}>
                {' '}Registered Email is required *{' '}
              </p>}
          </div>
          <div>
            <p className="settingaboutlabel">Phone Number</p>
            <Input
              className="phonedkninput"
              type="text"
              name="phoneNumber"
              id="firstname"
              placeholder="PhoneNumber*"
              value={phoneNumber}
              onChange={e =>
                this.setState (byPropKey ('phoneNumber', e.target.value))}

              disabled={true}
            />
            {/* {!phoneNumber &&
              isSubmit &&
              <p className="validationerrorMessage" style={{paddingLeft: 50}}>
                {' '}Phone Number is required *{' '}
              </p>} */}
          </div>
          <div>
            <p className="settingaboutlabel">About Me</p>
            <Input
              className="klsjfkejcdkninput"
              type="text"
              name="aboutMe"
              id="firstname"
              placeholder="About Me*"
              value={CapitalizeFirstLetter(aboutMe)}
              onChange={e =>
                this.setState (byPropKey ('aboutMe', e.target.value))}
            />
            {!aboutMe &&
              isSubmit &&
              <p className="validationerrorMessage" style={{paddingLeft: 50}}>
                {' '}About Me is required *{' '}
              </p>}
          </div>
        </div>}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
    userprofile: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({
    getProfileDashboard,
    ModifyPersonalInfo,
    updateUser
  }, dispatch);
};




export default connect (mapStateToProps, mapDispatchToProps) (
  UserAccountSetting
);
