import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

//this config is being used for both development and production environment. Though, it is a best practice creating a second project and have two configs: one for production (prodConfig) and another for development (devConfig), so you choose the config based on the environment.



//** config DEV server **//

// const config = {
//   apiKey: "AIzaSyBMeCvEECTCr98nxgI5uhO2CcHAhuX8cpw",
//   authDomain: "radiologexapp.firebaseapp.com",
//   databaseURL: "https://radiologexapp.firebaseio.com",
//   projectId: "radiologexapp",
//   storageBucket: "radiologex",
//   messagingSenderId: "648750072548",
//   appId: "1:648750072548:web:aaaacb8a1f3f802f"
// };




// //** config UAT server **//

// const config = {
//   apiKey: "AIzaSyABT5ua9_FeTcWNDWAP2RCCVKycoPFlfrU",
//   authDomain: "rdee-uat.firebaseapp.com",
//   databaseURL: "https://rdee-uat.firebaseio.com",
//   projectId: "rdee-uat",
//   storageBucket: "radiologex-uat",
//   messagingSenderId: "927559149558",
//   appId: "1:927559149558:web:1d6767273f35265edec9d7",
//   measurementId: "G-NS48MXPW95"
// };



//** config Prod server **//

const config = {
  apiKey: "AIzaSyCvJoTt4wWvTdkPFFU_qG5qtCXKLyO8f2Q",
  authDomain: "rdee-prod.firebaseapp.com",
  databaseURL: "https://rdee-prod.firebaseio.com",
  projectId: "rdee-prod",
  storageBucket: "radiologex-prod",
  messagingSenderId: "1000900694713",
  appId: "1:1000900694713:web:f5841c466edcf7d7c18168",
  measurementId: "G-9VMH45X0W7"
};
if (!firebase.apps.length) {
  //initializing with the config object
  firebase.initializeApp(config);
}

//separting database API and authentication
const db = firebase.database();
const auth = firebase.auth();

const facebookProvider = new firebase.auth.FacebookAuthProvider();

export { db, auth, facebookProvider };
