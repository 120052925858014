import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import { SignInLink } from "./SignIn";
import {Button} from 'reactstrap';
import Vector4 from '../../images/Walet_IA.png';
// import * as routes from "../constants/routes";
import PinInput from 'react-pin-input';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Sidebar from './../UI/SideBar/index';
import './Payment.css';
import leftbackarrow from '../../images/icons/leftbackarrow.png';
import PaymentDashBoard from './dashboard';
import TransactionHistory from './transactionHistory';
import {EncryptFunction} from '../../utility/encryption';

import {
  createNewWallet,
  checkRdeePayCheckWalletExists,
} from '../../actions/Paymentaction';
import {createNotification} from '../../constants/notificationtoast';
import {checkAvailableAccess} from './../../constants/localMessage/errorMessage';

import {DEV_SERVER} from '../../services/servicesConfig';
import RDEEPAYcoming from '../../images/comingsoon/paycoming.svg';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';

class PaymentPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      step: 1,
      value: '',
      walletAddress: '',
      userid: '',
      newpin: '',
      confirmnewpin: '',
      isAccess:true
    };
  }
  onChange = value => {
    this.setState ({value});
  };

  goNext = () => {
    this.setState ({
      step: this.state.step + Number (1),
    });
  };

  componentWillReceiveProps (nextProps) {
  
    if (nextProps && nextProps.payment) {
      if (nextProps && nextProps.payment.walletAddress) {
        if (
          nextProps &&
          nextProps.payment.walletAddress &&
          nextProps.payment.walletAddress.walletAddress
        ) {
          this.setState (
            {
              step: 5,
              walletAddress: nextProps.payment.walletAddress.walletAddress,
            },
            () => {
              localStorage.setItem (
                'address',
                nextProps.payment.walletAddress.walletAddress
              );
            }
          );
        } else {
          this.setState ({
            step: 1,
          });
        }
      }
      if (nextProps && nextProps.payment.newcreatewallet) {
        this.setState (
          {
            step: localStorage.getItem ('address') ? 5 : 4,
            walletAddress: nextProps.payment.newcreatewallet.address,
          },
          () => {
            localStorage.setItem (
              'address',
              nextProps.payment.newcreatewallet.address
            );
           
          }
        );
      }
    }
  }

  componentDidMount () {


    if(checkAvailableAccess ("rdeepay") && checkAvailableAccess ("rdeepay")=== 'rdeepay'){
        this.setState({
            isAccess:true

        },()=>{
              let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
              if (loginUser) {
                this.setState ({
                  userid: loginUser.docID,
                });
              }

              let walletAddress = localStorage.getItem ('address');
              if (walletAddress) {
                this.setState (
                  {
                    walletAddress: walletAddress,
                    step: 5,
                  },
                  () => {
                  
                  }
                );
              }
              if(this.state.hideContent) {
              this.props.checkRdeePayCheckWalletExists ({data: {}});
              }
        })
    } else{
      this.setState({
        isAccess:false
      })
    }
  }
  

  doCreate () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    
    let hashKeyPIN = EncryptFunction (
      loginUser.emailAddress,
      this.state.newpin
    );
 
    let sendRequest = {
      user_id: this.state.userid,
      passphrase: hashKeyPIN,
    };

    this.props.createNewWallet (sendRequest);
  }

  goBack () {
    if (this.state.step === 1) {
      this.props.history.goBack ();
    } else {
      this.setState ({
        step: 1,
      });
    }
  }
  render () {
    const { isAccess} = this.state;
    return (
      <div>
      {isAccess &&   <div className="paymentBoxContainer">
        {this.state.step !== 5 &&
          <div style={{textAlign: 'right'}}>
            <Button className="stepGoBackIcon" onClick={() => this.goBack ()} data-rh="Go back">
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>
              Go <span> Back</span>
            </Button>
        </div>}
        {this.state.step !== 5 &&
          <div className="walletBoxCenter">

            <div
              className={this.state.step !== 5 ? '' : ''}
              style={{margin: 'auto'}}
            >

              {this.state.step === 1 &&
                <div className="walletContentBox">

                  <div>
                    <p className="head"> Welcome to</p>
                    <p className="head">
                      <span className="fontWeight700">RDEE</span> Pay
                    </p>
                    <p className="no_margin welcomeBoxTitletext">
                      Your gateway
                    </p>
                    <p className="no_margin welcomeBoxTitletext">
                      to make payments
                    </p>
                    <p className="no_margin welcomeBoxTitletext">
                      on the RDEE Platform.
                    </p>
                  </div>
                  <div className="mTo30">
                    <p className="no_margin welcomeBoxTitletext_bottom">
                      Go ahead and setup your wallet with one simple step.
                    </p>
                    <Button
                      className="letGOButtonClass"
                      onClick={() => {
                        this.goNext ();
                      }}
                      data-rh="Next"
                    >
                      Let's Go
                    </Button>
                  </div>
                </div>}

              {/*this.state.step === 2 &&
            <div className='pinCenter' style={{ textAlign: '-webkit-center' }}>
              <div className="mTo30">
                <p className="no_margin welcomeBoxTitletext_bottom">
                  Go ahead and setup your wallet with one simple step.
                </p>
                <Button
                  className="letGOButtonClass"
                  onClick={() => {
                    this.goNext();
                  }}
                >
                  4-digit PIN
              </Button>
              </div>
                </div>*/}

              {this.state.step === 2 &&
                <div
                  className="pinCenter"
                  style={{textAlign: '-webkit-center'}}
                >
                  <div className="rdeePayTextDiv">
                    <p className="head">
                      {' '}
                      Welcome to
                      {' '}
                      <span className="fontWeight700">R-DEE</span>
                      {' '}
                      Pay
                    </p>
                  </div>
                  <div style={{width: 260}}>
                    <PinInput
                      length={4}
                      focus
                      secret
                      ref={p => (this.pin = p)}
                      type="numeric"
                      style={{
                        padding: '10px',
                        display: 'inline-flex',
                        width: 200,
                      }}
                      inputStyle={{
                        borderColor: 'transparent',
                        borderBottomColor: '#4092B7',
                        borderBottomWidth: 2,
                        borderTopWidth: 0,
                        borderRadius: 0,
                        color: 'white',
                        fontSize: 30,
                      }}
                      inputFocusStyle={{borderBottomColor: '#4092B7'}}
                      // onChange={this.onChange}
                      onComplete={(value, index) => {
                        this.setState ({
                          step: 3,
                          newpin: value,
                        });
                      }}
                    />
                    <p className="no_margin welcomeBoxTitletext_bottom">
                      Enter a four digit PIN to secure your R-DEE Pay transactions.
                    </p>
                  </div>
                </div>}

              {this.state.step === 3 &&
                <div
                  className="pinCenter"
                  style={{textAlign: '-webkit-center'}}
                >

                  <div className="rdeePayTextDiv">
                    <p className="head">
                      {' '}
                      Welcome to
                      {' '}
                      <span className="fontWeight700">R-DEE</span>
                      {' '}
                      Pay
                    </p>

                  </div>

                  <div className="rdeePayTextDiv">
                    <PinInput
                      length={4}
                      focus
                      secret
                      ref={p => (this.pin = p)}
                      type="numeric"
                      style={{
                        padding: '10px',
                        display: 'inline-flex',
                        width: 200,
                      }}
                      inputStyle={{
                        borderColor: 'transparent',
                        borderBottomColor: '#4092B7',
                        borderBottomWidth: 2,
                        borderTopWidth: 0,
                        borderRadius: 0,
                        color: 'white',
                        fontSize: 30,
                      }}
                      inputFocusStyle={{borderBottomColor: '#4092B7'}}
                      onComplete={(value, index) => {
                        if (this.state.newpin !== value) {
                          createNotification ('error', 'Invalid Confrim PIN');
                          return false;
                        }
                        this.setState (
                          {
                            confirmnewpin: value,
                          },
                          () => {
                            this.doCreate ();
                          }
                        );
                      }}
                    />
                    <p className="no_margin welcomeBoxTitletext_bottom">
                      Enter your PIN again to confirm the PIN..
                    </p>
                  </div>

                </div>}

              {this.state.step === 4 &&
                <div
                  className="pinCenter"
                  style={{textAlign: '-webkit-center'}}
                >
                  <div className="rdeePayTextDiv">
                    <p className="head2">
                      {' '}<span className="fontWeight700">R-DEE</span> Pay{' '}
                    </p>
                    <p className="head2">
                      Has Been Activated!
                    </p>
                    <img src={Vector4} style={{width: 100, marginTop: 10}} />
                  </div>
                  <div>
                    <p className="no_margin welcomeBoxTitletext_bottom">
                      Thats it, you’re ready to go!
                    </p>
                    <Button
                      className="letGOButtonClass"
                      onClick={() => {
                        this.goNext ();
                      }}
                      data-rh="Go R-DEE Pay"
                    >
                      Proceed to My R-DEE Pay
                    </Button>
                  </div>
                </div>}
            </div>

          </div>}
        {this.state.step === 5 &&
          // <PaymentDashBoard
          //   walletAddress={this.state.walletAddress}
          //   loginuserId={this.state.userid}
          // />
          <TransactionHistory history={this.props.history} />}
      </div>}

    {!isAccess  && <CommonAccessContainer bgImage={RDEEPAYcoming} message={checkAvailableAccess('rdeepay')} />}

  
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      createNewWallet,
      checkRdeePayCheckWalletExists,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (PaymentPage);
