import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Row, Col, Button, Modal, ModalBody} from 'reactstrap';

import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import ProfileIcon from '../../../images/pay/user.png';
import closeIcon from '../../../images/largecross.png';
import PaymentDashBoard from '../dashboard/index';
import pendingclose from '../../../images/icons/pendingclose.png';
import profile from '../../../images/serviceOnDemandProfile/profile.png';
import {Dropdown} from 'primereact/dropdown';
import moment from 'moment';

import './myRequest.css';

class MyRequest extends Component {
  constructor (props) {
    super (props);
    this.state = {
      typeFilter: 'Request Type',
      typeDropDopwn: [
        {label: 'Request Type', value: 'Request Type'},
        {label: 'Completed', value: 'Completed'},
        {label: 'Rejected', value: 'Rejected'},
        {label: 'Awaiting Payment', value: 'Awaiting Payment'},
      ],
      typeStatus: 'Request Status',
      statusDropDopwn: [
        {label: 'Request Status', value: 'Request Status'},
        {label: 'Send', value: 'Send'},
        {label: 'Recevied', value: 'Recevied'},
      ],
      searching: '',
      detailModal: false,
      showUserInfo: '',
      isDeleted: false,
      isRejected: false,
    };
    this.closeModals = this.closeModals.bind (this);
  }

  closeModals () {
    this.setState ({
      detailModal: false,
      isDeleted: false,
      isRejected: false,
    });
  }

  render () {
    const {
      typeFilter,
      typeStatus,
      showUserInfo,
      detailModal,
      isDeleted,
      isRejected,
    } = this.state;
    return (
      <div style={{display: 'flex'}}>
        <div>
          <div>
            <Button
              className="floatR stepGoBackIcon"
              onClick={() => this.props.history.goBack ()}
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className="myRequestMaindiv">
            <div className="myRequestContantMainDiv">
              <div className="myreqHeaderdiv">
                <div className="myRequestText">
                  MY REQUESTS
                </div>
                <div className="myRequestFiltersDiv myRequestsearchDiv">

                  <div className="myRequestdisplayFlex">
                    {/*this.state.searching && <div className='textCenter'>
                      <div className='RequestType'
                        onClick={() =>
                          this.setState({
                            searching: '',
                          })}>
                        <span className='RequestTypeText'>Request Type: {this.state.searching}  <img src={closeIcon} alt="backIcon" style={{ width: 20 }} /></span>
                      </div>
                        </div>*/}
                    <Dropdown
                      className="typeFilters"
                      value={typeFilter}
                      options={this.state.typeDropDopwn}
                      onChange={e =>
                        this.setState ({
                          typeFilter: e.value,
                          // searching: e.value
                        })}
                    />
                    <Dropdown
                      className="typeFilters"
                      value={typeStatus}
                      options={this.state.statusDropDopwn}
                      onChange={e =>
                        this.setState ({
                          typeStatus: e.value,
                          // searching: e.value
                        })}
                    />
                  </div>

                  <div className="completeHistory">
                    <span className="completeHistoryText">
                      Complete Transaction History{' '}
                    </span>
                  </div>
                </div>
                <div className="myRequestDotBoldDiv" />

              </div>
              {this.state.searching &&
                <div className="textCenter">
                  <p className="noTransactionYetText">No Transaction yet</p>
                </div>}
              {!this.state.searching &&
                <div>
                  <div style={{margin: 10, display: 'flex'}}>
                    <div className="myRequestdisplayFlex nameMyRequestDiv mLeft10">
                      <img src={ProfileIcon} style={{height: 60}} />
                      <div>
                        <p className="nameMyRequest">Tanya Lorenda</p>
                        <p className="postMyRequest">Health Service Manager</p>
                      </div>

                    </div>
                    <div className="statusMyRequestDiv">
                      <div className="statuscircleRejected" />
                      <p className="statusMyRequest">Rejected</p>
                    </div>
                    <div className="dateMyRequestDiv">
                      <p className="dateStatusText">Rejected</p>
                      <p className="dateMyRequest">16/04/2019 - 5:19 PM</p>
                    </div>
                    <div className="dateMyRequestDiv">
                      <p className="amountMyRequest">+USD$ 65000,00</p>
                    </div>
                    <div className="detailsMyRequestDiv">
                      <div
                        className="detailsMyRequest"
                        onClick={() =>
                          this.setState ({
                            showUserInfo: [],
                            detailModal: true,
                          })}
                      >
                        <span>Details </span>
                      </div>
                    </div>
                  </div>
                  <div className="myRequestDotBoldDiv" />
                  <div style={{padding: 10, display: 'flex'}}>
                    <div className="myRequestdisplayFlex nameMyRequestDiv mLeft10">
                      <img src={ProfileIcon} style={{height: 60}} />
                      <div>
                        <p className="nameMyRequest">Tanya Lorenda</p>
                        <p className="postMyRequest">Health Service Manager</p>
                      </div>

                    </div>
                    <div className="statusMyRequestDiv">
                      <div className="statuscircleWait" />
                      <p className="statusMyRequest">Awaiting Payment</p>
                    </div>
                    <div className="dateMyRequestDiv">
                      <p className="dateStatusText">Rejected</p>
                      <p className="dateMyRequest">16/04/2019 - 5:19 PM</p>
                    </div>
                    <div className="dateMyRequestDiv">
                      <p className="amountMyRequest">+USD$ 65000,00</p>
                    </div>
                    <div className="detailsMyRequestDiv">
                      <div
                        className="detailsMyRequest"
                        onClick={() =>
                          this.setState ({
                            showUserInfo: [],
                            detailModal: true,
                          })}
                      >
                        <span>Details </span>
                      </div>
                    </div>
                  </div>
                  <div className="myRequestDotBoldDiv" />
                  <div style={{padding: 10, display: 'flex'}}>
                    <div className="myRequestdisplayFlex nameMyRequestDiv mLeft10">
                      <img src={ProfileIcon} style={{height: 60}} />
                      <div>
                        <p className="nameMyRequest">Tanya Lorenda</p>
                        <p className="postMyRequest">Health Service Manager</p>
                      </div>

                    </div>
                    <div className="statusMyRequestDiv">
                      <div className="statuscircleComplete" />
                      <p className="statusMyRequest">Completed</p>
                    </div>
                    <div className="dateMyRequestDiv">
                      <p className="dateStatusText">Rejected</p>
                      <p className="dateMyRequest">16/04/2019 - 5:19 PM</p>
                    </div>
                    <div className="dateMyRequestDiv">
                      <p className="amountMyRequest">+USD$ 65000,00</p>
                    </div>
                    <div className="detailsMyRequestDiv">
                      <div
                        className="detailsMyRequest"
                        onClick={() =>
                          this.setState ({
                            showUserInfo: [],
                            detailModal: true,
                          })}
                      >
                        <span>Details </span>
                      </div>
                    </div>
                  </div>
                  <div className="myRequestDotBoldDiv" />
                </div>}

            </div>
          </div>
        </div>
        <PaymentDashBoard history={this.props.history}/>
        <Modal isOpen={detailModal} centered={true} style={{width: 450}}>
          {
            <ModalBody style={{backgroundColor: '#FFFFFF', borderRadius: 10}}>
              <div style={{width: 341, margin: 'auto', paddingTop: 20}}>
                {/*<div>
                <img
                  src={closeIcon}
                  className="closeimg"
                  onClick={() => this.closemodal ()}
                />
              </div>*/}
                <div className="detailBorder" />
                <p className="detailModalTitle">REQUEST PENDING PAYMENT</p>

                <div className="detailBorder" />

                <div className="detailModalDate">
                  <div>
                    {showUserInfo && showUserInfo.transaction_date
                      ? moment (
                          new Date (showUserInfo.transaction_date)
                        ).format ('DD/MM/YYYY')
                      : ''}

                  </div>
                  <div>
                    {showUserInfo && showUserInfo.transaction_date
                      ? moment (
                          new Date (showUserInfo.transaction_date)
                        ).format ('h:MM A')
                      : ''}

                  </div>
                </div>
                <div className="detailBorder" />

                <div style={{paddingTop: 15}}>
                  <p className="detaillableTitle">
                    Funds
                    {' '}
                    {showUserInfo.type === 'transfer'
                      ? ' Received From'
                      : ' Transfer To '}
                    {' '}
                    :
                  </p>
                  <div className="detailProfileBox">
                    <div className="imageBox">
                      <img
                        src={
                          showUserInfo.profilePictureURL
                            ? showUserInfo.profilePictureURL
                            : ProfileIcon
                        }
                      />
                    </div>
                    <div>
                      <p className="usernameTitle">
                        {showUserInfo.displayName
                          ? showUserInfo.displayName
                          : ''}
                      </p>
                      <p className="position">
                        {showUserInfo.position ? showUserInfo.position : ''}
                        {' '}
                      </p>

                    </div>
                  </div>
                  <p className="detaillableTitle">
                    Amount: <span>
                      {showUserInfo.type === 'transfer' ? '+' : '-'}
                      {showUserInfo.currency ? showUserInfo.currency : ''}
                      $
                      {' '}
                      {showUserInfo.total_amount
                        ? showUserInfo.total_amount
                        : 0}
                    </span>
                  </p>
                  <p className="detaillableTitle">Purpose:</p>
                  <p className="detailModaldescription">
                    {showUserInfo.transaction_purposes
                      ? showUserInfo.transaction_purposes
                      : ''}
                  </p>

                </div>

                <div style={{display: 'inline-flex'}}>
                  <Button
                    className="editRequestButton"
                    onClick={() =>
                      this.setState ({
                        isRejected: true,
                      })}
                  >
                    {' '}Edit Request
                  </Button>
                  <Button
                    className="deleteRequestButton"
                    onClick={() =>
                      this.setState ({
                        isDeleted: true,
                      })}
                  >
                    {' '}Delete Request
                  </Button>

                </div>

              </div>

            </ModalBody>
          }
        </Modal>

        <Modal
          size="sm"
          isOpen={isDeleted}
          centered={true}
          toggle={this.closeModals}
        >
          <ModalBody className="confirmationModal">
            <div>
              <p className="confirmTitle">Delete Request</p>
              <p className="confirmDescription">
                Are you sure you want to delete your request for
                {' '}
                <span> +USD$ 2,301,217.50 </span>
                {' '}
                from
                {' '}
                <span> Lorenda </span>
                ?
                {' '}

              </p>

              <div className="confirmButtons">
                <Button className="WhiteButton" onClick={this.closeModals}>
                  Back
                </Button>
                <Button className="BlackButton" onClick={this.closeModals}>
                  Delete
                </Button>

              </div>

            </div>

          </ModalBody>
        </Modal>
        <Modal
          size="sm"
          isOpen={isRejected}
          centered={true}
          toggle={this.closeModals}
        >
          <ModalBody className="confirmationModal">
            <div>
              <p className="confirmTitle">Reject Request</p>
              <p className="confirmDescription">
                Are you sure you want to reject
                {' '}
                <span>Mark Beverlyn’s</span>
                {' '}
                request for
                {' '}
                <span> USD$ 27.50 </span>
                ?
                {' '}
              </p>

              <div className="confirmButtons">
                <Button className="WhiteButton" onClick={this.closeModals}>
                  Back
                </Button>
                <Button className="BlackButton" onClick={this.closeModals}>
                  Reject
                </Button>

              </div>

            </div>

          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (MyRequest);
