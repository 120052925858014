import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Button, ModalHeader, Modal, ModalBody, Input} from 'reactstrap';
import seainputicon from '../../../images/icons/seainputicon.png';
import {decryptMessage} from '../../../utility/encryption';
import * as routes from '../../../constants/routes';
import verticalThreeDots
  from '../../../images/icons/messenger/verticalThreeDots.png';
import {_handleForwardClick, copyToClipboard} from '../../../utility/forward';
import profileDefault from '../../../images/profile/profileDefault.png';
import whiteFileIcon from '../../../images/icons/vault/whiteFileIcon.svg';

import pptIcon from '../../../images/icons/messenger/pptIcon.svg';
import pdfIcon from '../../../images/icons/messenger/pdfIcon.svg';
import excelIcon from '../../../images/icons/messenger/excelIcon.svg';
import docIcon from '../../../images/icons/messenger/docIcon.svg';
import pacsIcon from '../../../images/icons/vault/pacsIcon.png';
import axios from 'axios';
import store from '../../../store';
import {createNotification} from '../../../constants/notificationtoast';

import {getMessage} from '../../../constants/localMessage/errorMessage';

import {RDEE_VAULT_URL } from '../../../services/servicesConfig';

import {
  ShowNameAccordingToLength,
  capitalfirstLetter,
} from '../../../utility/stringFunctions';

import ReactPlayer from 'react-player';
// import Modal from './components/Modal/Modal';
import {retrieveFileName, retrieveFileType} from '../../../utility/getFileName';

import close_icon from '../../../images/close_icon.png';
import {dateTimeFormatForMessenger} from '../../../utility/convertTimeString';
import profilePlaceholder from '../../../images/icons/placeholderProfile.png';
import singleTick from '../../../images/icons/singleTick.png';
import doubleTick from '../../../images/icons/doubleTick.png';

import productDetails
  from '../../../images/marketPlaceBuyer/productDetails.png';
import parse from 'html-react-parser';
import {priceWithDecimal} from '../../../utility/commonFunctions';
import _ from 'lodash';
import {Loading} from '../../../actions/LoadingAction';


import './Message.css';

class Message extends Component {
  constructor (props) {
    super (props);
    this.state = {
      modal: false,
      imagurl: '',
      isPaused: true,
      showFile: false,
      showOptions: false,

      //Message info
      usersToShow: [],
      showMessageInfoModal: false,

      // forward options
      showOptionsModal: false,
      showContactsModal: false,
      allGroups: [],
      contactsToShow: [],
      groupsToShow: [],
      searchText: '',
      messageMenuOptionIndex:null,
      showDicomConfirmBox:false,
      dicominfo:'',
      businessId:'',
      folderString:''
    };
    this.closeModal = this.closeModal.bind (this);

    
    this.messageOptionMenu = React.createRef ();

  }

  componentWillUnmount () {
    document.removeEventListener ('mousedown', this.handleClickOutside);
  }

  componentDidMount () {
    document.addEventListener ('mousedown', this.handleClickOutside);
    // console.log("messengerList>>>>>>>", this.props.messengerList.messenger)
    // console.log( this.props);
    // console.log( this.props.userinfo);

    if (this.props && this.props.userinfo) {
      // console.log (this.props.userinfo);
      if (
        this.props.userinfo &&
        this.props.userinfo.isbusinessProfile
      ) {
        if (this.props.userinfo.isbusinessProfile) {
          if (this.props.userinfo.isbusinessProfile.docID) {
            // console.log (this.props.userinfo);
            let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
            this.setState ({
                businessId: this.props.userinfo.isbusinessProfile
                  .docID,
            },()=>{
              this.setState({
                folderString:`${this.state.businessId}/${loginUser.docID}`
              },()=>{
                // console.log(this.state.folderString);
              })
            })
            }
          }}
        }
  }

  handleClickOutside = event => {
    if (
      this.messageOptionMenu.current &&
      !this.messageOptionMenu.current.contains (event.target)
    ) {

      this.props.openMessage('');
    }

  }

  searchText (val) {
    // console.log("val>>>>>> search", val.target)
    let searchText = val.target.value;
    this.setState ({searchText: searchText});
    let text = searchText.toLowerCase ();
    let chatList = [...this.props.contactsList.contacts];
    let groupList = [...this.state.allGroups];
    let filteredNameContacts = chatList.filter (item => {
      if (_.get (item, 'contact.contactDetail.firstName')) {
        return _.get (item, 'contact.contactDetail.firstName')
          .toLowerCase ()
          .match (text);
      } else if (_.get (item, 'contact.contactDetail.firstName')) {
        return _.get (item, 'contact.contactDetail.firstName')
          .toLowerCase ()
          .match (text);
      }
    });

    let filteredNameGroup = groupList.filter (group => {
      if (_.get (group, 'displayName')) {
        return _.get (group, 'displayName').toLowerCase ().match (text);
      } else if (_.get (group, 'displayName')) {
        return _.get (group, 'displayName').toLowerCase ().match (text);
      }
    });

    if (!text || text === '') {
      this.setState ({
        contactsToShow: this.props.contactsList.contacts,
        groupsToShow: this.state.allGroups,
      });
    } else {
      this.setState ({
        contactsToShow: filteredNameContacts,
        groupsToShow: filteredNameGroup,
      });
    }
  }

  _handleForwardMessage () {
    this.setState ({
      showOptionsModal: false,
      showContactsModal: true,
      forwardMessageArray: [],
      allGroups: _.get (this.props, 'messengerList.messenger', []).filter (
        val => _.get (val, 'chatType', '') === 'groupChat'
      ),
      groupsToShow: _.get (this.props, 'messengerList.messenger', []).filter (
        val => _.get (val, 'chatType', '') === 'groupChat'
      ),
      contactsToShow: this.props.contactsList.contacts,
    });
  }

  _handleMessageInfo () {
    this.setState ({
      showOptionsModal: false,
      showMessageInfoModal: true,
      usersToShow: _.toArray (_.get (this.props.data, 'readBy', {})),
    });
  }

  _handleCopyMessage () {
    const {secretKey} = this.props;
    if (secretKey !== '' && secretKey !== null && secretKey !== undefined) {
      copyToClipboard (this.props.data.text, this.props.secretKey);
    }
    this.setState ({
      showOptionsModal: false,
    },()=>{
      this.props.openMessage('');
    });
  }

  _handleClickOnContact (contact) {
    let reqObj = {
      selectedcontacts: [contact],
      selectedGroups: [],
      messengerList: _.get (this.props, 'messengerList.messenger', []),
      selectedMessages: [this.props.data],
      decryptSecretKey: this.props.secretKey,
      // lastSenderName: _.get(this.props.navigation, 'state.params.lastSenderName', ''),
    };
    _handleForwardClick (reqObj, this.props);

    this.setState ({
      showContactsModal: false,
      forwardMessageArray: [],
    });
  }

  _handleClickOnGroup (group) {
    let reqObj = {
      selectedcontacts: [],
      selectedGroups: [group],
      // messengerList: _.get(group, 'groupId', []),
      selectedMessages: [this.props.data],
      decryptSecretKey: this.props.secretKey,
    };
    _handleForwardClick (reqObj, this.props);

    this.setState ({
      showContactsModal: false,
      forwardMessageArray: [],
    });
  }

  closeModal () {
    this.setState ({
      modal: false,
    });
  }

  closeFileModal () {
    this.setState ({
      showFile: false,
    });
  }
  urlify (text) {
    // var urlRegex = /(https?:\/\/[^\s]+)/g;
    // return text.replace(urlRegex, function(url) {
    //   return '<a href="' + url + '">' + url + '</a>';
    // })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    //var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace (urlRegex, function (url, b, c) {
      var url2 = c == 'www.' ? 'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
    });
  }

  checkNullItems (item) {
    let newitem = [];

    for (let newData of item) {
      if (newData) {
        newitem.push (newData);
      }
    }

    return newitem;
  }

  handleUploadingFiles () {
    const { folderString} = this.state;


    const currentState = store.getState();
    const { Authreducer } = currentState;

    if( this.state.fileListing &&  this.state.fileListing.length>0){

      this.props.Loading (true);
      this.state.fileListing.map ((file, index) => {
      // if (file.progress === 0) {
      if (file) {

    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('fileType', file.type);
    bodyFormData.append('userFileType', 'file');


    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:"Bearer " + Authreducer.authToken,
      },
      // data: JSON.stringify (sendRquest),
      // url,
      data: bodyFormData,
      url: `/rdee-vault/`, // route name
      baseURL:RDEE_VAULT_URL, //local url
      onUploadProgress: progress => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
       
      },
      encType: "multipart/form-data",
    };

    axios (options)
    .then (res => {
      // console.log (res);
      this.props.Loading (false);

      if(res && res.data && res.data.result && res.data.result.status){
            this.props.Loading (false);
            this.setState({
              showDicomConfirmBox:false,
              dicominfo:'',
            })
    
            if(res.data.result.data){
              // this.props.addNewFile (res.data.result.data);
              // console.log(res.data.result.data);
              if( res.data.result.responseCode&& getMessage(res.data.result.responseCode)){
                createNotification ('success',getMessage(res.data.result.responseCode));

              }else{
                if(res.data.result.responseMessage){
                  createNotification ('success',res.data.result.responseMessage);
                }

              }
            }
            }else{
              if( res.data.result.responseCode&& getMessage(res.data.result.responseCode)){
                createNotification ('error',getMessage(res.data.result.responseCode));

              }else{
                if(res.data.result.responseMessage){
                  createNotification ('error',res.data.result.responseMessage);
                }

              }
            }
          })
          .catch (err => {
            console.log(err);
        
            this.props.Loading (false);
            if (err && err.message) {
              createNotification ('error', err.message);
            }
            if(err.response && err.response.status === 401){
              if(err.response.data && err.response.data){
                  createNotification('error', err.response.data);
                  localStorage.clear();
                  setTimeout(()=>{
                   window.location.reload();
                  },1000)
              }
          }
          });
        }
      })
    }else{
      createNotification ('error',"something is wrong.Please try again.");
    }

  }

  render () {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
      secretKey,
      chatType,
      key,
      isOpenMessage
    } = this.props;

    
    // console.log("data>>>>>message", data);
    // console.log("this.state.usersToshow>>>>>usersss", this.state.usersToShow);
    let decryptedText = data && data.text
      ? decryptMessage (data.text.toString (), secretKey)
      : '';

    let decryptedFile = data && data.type === 'attach' && data.attachSource
      ? decryptMessage (data.attachSource.toString (), secretKey)
      : '';

    // const friendlyTimestamp = moment (data.timeStamp).format ('LLLL');
    // const messageTimestamp = moment (data.timeStamp).format ('mm:ss a');

    // const fileType = data.mediaType == 'pdf' ? retrieveFileName(decryptedFile): '';
    // console.log("decryptedFile>>>>>>>>>", decryptedFile)
    const senderName = data && data.senderName ? data.senderName : '';
    const senderPicture = data && data.senderProfilePic
      ? data.senderProfilePic
      : profilePlaceholder;

    const {messageMenuOptionIndex}= this.state;

  

    return (
      <div
        id={data && data.key? data.key:''}
        key={data && data.key? data.key:''}
        // key={key}
        onClick={() => {
          if(data && data.mediaType &&data.mediaType === "dicom"){
            this.props.openMessage('');
            this.setState({
              showDicomConfirmBox:true,
              dicominfo:data
            })
          }else{
            this.props.openMessage(data);
          }
          this.setState ({showOptions: true, messageMenuOptionIndex:data.key})
        }}
        // className='alignInRow'
        // onMouseOver={() => this.setState({ showOptions: true })}
        onMouseLeave={() => this.setState({ showOptions: false })}
        // style={{ backgroundColor: 'rgba(255,255,255,.3)', marginBottom: "0.5%" }}
      >
        {/* system message to show, group info changed STARTS*/}
        {_.get (data, 'system', false) &&
          <div>
            <div
              style={{
                textAlign: 'center',
                color: 'white',
                paddingTop: '10px',
                paddingBottom: '5px',
              }}
            >
              {dateTimeFormatForMessenger (_.get (data, 'timeStamp', null))}
            </div>
            <div
              style={{
                textAlign: 'center',
                color: 'white',
                paddingBottom: '10px',
              }}
            >
              {decryptedText}
            </div>
          </div>}
        {/* system message to show, group info changed ENDS*/}

        {/* normal messages*/}
        {!_.get (data, 'system', false) &&
          <div         
            className={[
              'mainMessageDiv ',
              'message ',
              // 'alignInRow',
              `${isMine ? 'mine ' : 'other '}`,
              `${startsSequence ? 'start ' : ''}`,
              `${endsSequence ? 'end ' : ''}`,
              `${!startsSequence && !endsSequence ? 'middle ' : ''}`
            ]}
            style={{ backgroundColor: this.state.showOptions ? 'rgba(255,255,255,0.2)' : 'transparent'}}
          >

            <div
              className={[
                'message ',
                `${isMine ? 'mine ' : 'other '}`,
                `${startsSequence ? 'start ' : ''}`,
                `${endsSequence ? 'end ' : ''}`,
              `${!startsSequence && !endsSequence ? 'middle ' : ''}`
              ].join (' ')}
              style={{ backgroundColor: this.state.showOptions ? 'rgba(255,255,255,0.2)' : 'transparent'}}
            >
              {showTimestamp &&
                <div className="timestamp">
                  {dateTimeFormatForMessenger (data.timeStamp)}
                </div>}

              {/* triangle with messages*/}
              {<div 
                style={{position:'relative'}}
                >

                  {!isMine &&
                    <div
                      className="receivertrigle"
                      style={{marginTop: startsSequence ? 5 : 0}}
                    />}

                {isMine &&
                  <div
                    className="sendertrigle"
                    style={{marginTop: startsSequence ? 2 : 0}}
                  />}

                  {chatType === 'groupChat' && !isMine&&  <div style={{position:'absolute',top:18}}>
                  <img
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = profilePlaceholder;
                    }}
                    src={senderPicture}
                    alt="profile"
                    className="senderuserImage"
                  />
          </div>}
              </div>}
              {/* triangle with messages ends*/}

              <div
                className="bubble-container"
                style={{marginLeft: chatType === 'groupChat' ? 15 : 5}}
              >

                <div
                  id={data && data.key? `paddingBubbleContent${data.key}`:''}
                  className={[
                    // message backgroud style
                    'bubble ',
                    `${data && data.type === 'productLink' ? ' productBubbleContent ': ' paddingBubbleContent '}` ,

                    `${isMine ? (data.type === 'productLink' ? ' bubblesend ' : ' bubblereceive  ' ): ' bubblesend '}`
                    // `${isMine ? ' bubblereceive ' + (data.type === 'productLink' ? ' bubblesend ' : '') : ' bubblesend '}`,
                  ].join (' ')}
                  title={dateTimeFormatForMessenger (data.timeStamp)}
                >
                  {_.get (data, 'sendType', 'normal') == 'forward' &&
                    <div className={'forwardedText'}>
                      <div>Forwarded Message</div>
                    </div>}

                  {!isMine &&
                    <div className="showUserNameBox">
                      {/*<img  onError={e => {
          e.target.onerror = null;
          e.target.src = profilePlaceholder;
        }} src={senderPicture} alt="profile" className="senderuserImage"/>*/}
                      {capitalfirstLetter (senderName)}
                    </div>}

                  {/*Text and video text STARTS*/}
                  {(data.type === 'text' || data.type === 'videoCall') &&
                    <div style={{paddingLeft: 8, paddingRight: 8}}>
                      {decryptedText.split ('\n').map ((i, key) => {
                        return <div key={key}>{parse (this.urlify (i))}</div>;
                      })}
                    </div>}
                  {/*Text and video text ENDS*/}

                  {/*data.type === 'text' && decryptedText*/}
                  {data.type === 'attach' &&
                    data.mediaType === 'image' &&
                    !this.state.showFile &&
                    <div id={data && data.key? `attachimage${data.key}`:''}>
                      <img
                        alt="decryptedFile"
                        src={decryptedFile}
                        style={{maxWidth: 200, cursor: 'pointer', padding: 10}}
                        // onClick={() =>
                        //   this.setState({
                        //     modal: true,
                        //     imagurl: decryptedFile,
                        //   })}
                        onClick={() =>
                          this.setState ({showFile: !this.state.showFile})}
                      />
                      {decryptedText &&
                        <div style={{paddingLeft: 10, margin: 0}}>
                          {parse (this.urlify (decryptedText))}
                        </div>}

                    </div>}

                  {this.state.showFile &&
                    <div className="modal-wrapper" style={{padding: 10}}>
                      <img
                        alt="decryptedFile"
                        src={decryptedFile}
                        width="540"
                        height="450"
                        onClick={() => this.setState ({showFile: false})}
                      />
                    </div>}

                    
                    
                    {/* {data.type == 'attach' &&
                    data.mediaType == 'other' && */}
                    {data.type === 'attach' &&
                    (data.mediaType !== 'pdf' && data.mediaType !== 'audio' && data.mediaType !== 'video' && data.mediaType !== 'image' ) &&
                    <div
                      // onClick={() =>
                      //   this.setState ({isPaused: !this.state.isPaused})}
                      onClick={() => {
                        if(data && data.mediaType &&data.mediaType === "dicom"){
                          // alert("dicom file");
                          this.props.openMessage('');
                        }else{
                          var win = window.open (decryptedFile, '_blank');
                          win.focus ();
                        }
                        
                      }}

                      style={{padding: 10}}
                    >
                    {
                      <img
                        // src={whiteFileIcon}
                        alt="icon"

                         src={data.mediaType === 'ppt'
                    ? pptIcon
                    : data.mediaType === "csv"
                      ? excelIcon
                      : data.mediaType === "doc"
                        ? docIcon:
                        data.mediaType === "dicom"
                        ? pacsIcon
                        :whiteFileIcon}
                        style={{
                          width: 200,
                          cursor: 'pointer',
                          padding: 10,
                        }}
                      />}
                      {decryptedText &&
                        <p style={{paddingLeft: 10, margin: 0}}>
                          {parse (this.urlify (decryptedText))}
                        </p>}

                    </div>}


                  {data.type === 'attach' &&
                    data.mediaType === 'pdf' &&
                    !this.state.showFile &&
                    <div
                      id={data && data.key? `attachpdf${data.key}`:''}
                      // onMouseOver={() => alert('on PDF')}
                      // onMouseOver={() => this.setState({showOptions: true})}
                      // onMouseLeave={() => this.setState({showOptions: false})}
                      onClick={() => {
                        var win = window.open (decryptedFile, '_blank');
                        win.focus ();
                      }

                      // this.setState({ showFile: !this.state.showFile })
                      }
                    >
                      {!this.state.showFile &&
                        <img
                          // src={require ('../../../images/pdf.png')}
                          alt="pdfIcon"
                          src={pdfIcon}
                          style={{
                            maxWidth: 400,
                            cursor: 'pointer',
                            padding: 10,
                          }}
                        />}
                      {decryptedText &&
                        <p style={{paddingLeft: 10, margin: 0}}>
                          {parse (this.urlify (decryptedText))}
                        </p>}

                      {/* {this.state.showFile && <div><FileViewer
                fileType={retrieveFileType(decryptedFile)}
                filePath={decryptedFile}
                // errorComponent={CustomErrorComponent}
                // onError={this.onError}
                />
                </div>} */}

                      {this.state.showFile &&
                        <div className="modal-wrapper" style={{padding: 10}}>
                          <iframe
                            id={data && data.key? `attachfile${data.key}`:''}
                            src={decryptedFile}
                            width="540"
                            height="450"
                            onClick={() => this.setState ({showFile: false})}
                          />
                        </div>}
                    </div>}

                  {data.type === 'attach' &&
                    data.mediaType === 'audio' &&
                    <div
                      onClick={() =>
                        this.setState ({isPaused: !this.state.isPaused})}
                      style={{padding: 10}}
                    >
                      <ReactPlayer
                        id={data && data.key? `attachaudio${data.key}`:''}
                        controls={true}
                        width={300}
                        height={80}
                        url={decryptedFile}
                        playing={!this.state.isPaused}
                      />

                      {decryptedText &&
                        <p style={{paddingLeft: 10, margin: 0}}>
                          {parse (this.urlify (decryptedText))}
                        </p>}

                    </div>}

                  {data.type === 'attach' &&
                    data.mediaType === 'video' &&
                    <div
                      // onMouseOver={() => alert('onVideo')}
                      // onMouseOver={() => this.setState({showOptions: true})}
                      // onMouseLeave={() => this.setState({showOptions: false})}
                      onClick={() =>
                        this.setState ({isPaused: !this.state.isPaused})}
                    >
                      <ReactPlayer
                        id={data && data.key? `attachvideo${data.key}`:''}
                        controls={true}
                        width={200}
                        height={200}
                        url={decryptedFile}
                        playing={!this.state.isPaused}
                      />

                      {decryptedText &&
                        <p style={{paddingLeft: 10, margin: 0}}>
                          {parse (this.urlify (decryptedText))}
                        </p>}

                    </div>}

                  {data.type === 'productLink' &&
                    data.productDetail &&
                    <div>

                      {data.text &&
                        <p
                          className={[
                            `${isMine ? ' bubblereceive ' : ' bubblesend '}`,
                          ].join (' ')}
                          style={{padding: 10, margin: 0}}
                        >
                          {parse (this.urlify (decryptedText))}
                        </p>}
                      <div
                        className="chatMarketplaceProduct"
                        // onMouseOver={() => alert('chatMarketplaceProduct')}
                        // onMouseOver={() => this.setState({showOptions: true})}
                        // onMouseLeave={() => this.setState({showOptions: false})}
                        onClick={() => {
                          // this.props.history.push()
                          window.location.href =
                            routes.PRODUCT_DETAIL +
                            `/${data.productDetail.docID}`;
                        }}
                      >

                        <div>
                          <img
                            alt="img"
                            src={
                              data.productDetail &&
                                data.productDetail.productImageList &&
                                data.productDetail.productImageList[0]
                                ? data.productDetail.productImageList[0]
                                : productDetails
                            }
                            className="chatProductImg"
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = productDetails;
                            }}
                          />
                        </div>
                        <div // onMouseOver={() => alert('productTitle')} // this is for text and video
                        // onMouseOver={() => this.setState({showOptions: true})}
                        // onMouseLeave={() => this.setState({showOptions: false})}
                        className="chatproduct">
                          <p className="productTitle">

                            {data.productDetail &&
                              data.productDetail.productCategoryType ===
                                'Radiology'
                              ? data.productDetail.deviceSaleName
                              : (data.productDetail.productName
                                  ? data.productDetail.productName
                                  : '')}

                          </p>
                          <p className="productPrice">
                            USD$
                            {' '}
                            {data.productDetail && data.productDetail.priceUSD
                              ? priceWithDecimal (data.productDetail.priceUSD)
                              : 0}
                          </p>
                          {data.productDetail.productCategoryType === 'Radiology'
                            ?  <div>
                                <p className="productlocation">
                                  Device Type: {data.productDetail.deviceType}
                                </p>
                                <p className="productlocation">
                                  Location: {data.productDetail.location}
                                </p>
                              </div>:<div>
                                <p className="productlocation">
                                  Product Type: {data.productDetail.productCategoryType?data.productDetail.productCategoryType:'N/A'}
                                </p>
                                <p className="productlocation">
                                  Category: {data.productDetail.category}
                                </p>
                              </div>
                            }
                        </div>

                      </div>
                    </div>}
                  <div
                    style={{
                      // color: isMine ? '#FFFFFF' : '#333333',
                      marginRight: data.type === 'productLink' ? 10 : 0 ,
                      color: `${isMine ? (data.type === 'productLink' ? ' #333333' : ' #FFFFFF' ):'#333333' }`,
                      marginTop: 5,
                    }}
                    className={
                      'messageTimingText '
                    }
                  >
                    {_.get (data, 'sendType', 'normal') == 'forward' &&
                      <div>
                        {/* <div>Forwarded</div> */}
                        <p
                          className={['forwardedText', 'textStyleNormal']}
                        >{`${capitalfirstLetter (_.get (data, 'lastSenderName', ''))}, ${dateTimeFormatForMessenger (_.get (data, 'lastTimeStamp', null))}`}</p>
                      </div>}

                    {/*time stamp, message status, sent , seen, sending  */}
                    <div className={'timeStampMainDiv'}>
                      <span style={{opacity:0.5}}>{dateTimeFormatForMessenger (data.timeStamp)}</span>
                      {isMine && // for single chat
                      _.get (data, 'chatType', '') == 'singleChat' && // to check the seen status
                        // && _.get(data, 'sender') == this.props.user.uid
                        _.get (data, 'sent', false) &&
                        <img
                          alt="tickImage"
                          src={
                            _.get (
                              data,
                              `readBy.${_.get (this.props, 'oppositeUserUid')}.read`,
                              false
                            )
                              ? doubleTick
                              : singleTick
                          }
                          className={'tickImage'}
                        />}

                      {isMine && // for group chat
                      _.get (data, 'chatType', '') == 'groupChat' && // to check the seen status
                        // && _.get(data, 'sender') == this.props.user.uid
                        _.get (data, 'sent', false) &&
                        <img alt="tickImage" src={singleTick} className={'tickImage'} />}
                    </div>
                    {/*time stamp, message status, sent , seen, sending  */}

                  </div>

                </div>

              </div>


               { ( data && data.key===isOpenMessage) && 
                <div
                ref={this.messageOptionMenu}
                  style={{
                    position: 'relative',
                    // display: messageMenuOptionIndex===data.key? 'block' :'none'
                  }}
                >

                  <div
                    className="messageMenuOption"
                  >

                    <div
                      className="messageMenuOptionlistText"
                      onClick={() => this._handleCopyMessage ()}
                    >
                      Copy Text

                    </div>
                    <div
                      style={{borderBottom :   (!isMine ? 0 :'1px dotted #A2A2A5')}}
                      className="messageMenuOptionlistText"
                      onClick={() => this._handleForwardMessage ()}
                    >
                      Forward Message

                    </div>

                    {isMine &&
                      <div
                        style={{border:0}}
                        className="messageMenuOptionlistText"
                        onClick={() => this._handleMessageInfo ()}
                      >
                        Message Info

                      </div>}

                  </div>

                </div>}


              {/*vertical three dots starts*/}
              {/* {this.state.showOptions && <div
            className="messageThreeDotOptions"
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ showOptionsModal: true })}
          // style={{ marginTop: startsSequence ? 10 : 0 }}
          >
            <img
              src={verticalThreeDots}
              style={{ width: ".5%", height: "2.5%" }}
            />
          </div>} */}
              {/*vertical three dots ends*/}

              <div />
              <Modal
                size="md"
                isOpen={this.state.modal}
                centered={true}
                toggle={() => this.closeModal ()}
              >
                <ModalBody className="placeOrderModal">
                  <div>
                    <img
                      style={{cursor: 'pointer', width: '1000', height: '800'}}
                      src={decryptedFile}
                      className="closeimg"
                      onClick={() => this.closeModal ()}
                      alt="closeimg"
                    />
                  </div>
                  {/* <div>
              {this.state.imagurl &&
              <iframe src={decryptedFile}>

              </iframe>
                // <img style={{ width: '100%' }} src={this.state.imagurl} />
                }

            </div> */}
                </ModalBody>
              </Modal>

              <Modal
                size="md"
                isOpen={this.state.showOptionsModal}
                centered={true}
                toggle={() => this.setState ({showOptionsModal: false})}
              >
                <ModalBody className="messageOptionsBody">
                  <div>
                    <div
                      className={'forwardOptionText'}
                      onClick={() => this._handleCopyMessage ()}
                      style={{textAlign: 'center', cursor: 'pointer'}}
                    >
                      Copy
                    </div>
                    <div
                      className={'forwardOptionText'}
                      onClick={() => this._handleForwardMessage ()}
                      style={{textAlign: 'center', cursor: 'pointer'}}
                    >
                      Forward
                    </div>
                    {isMine &&
                      <div
                        className={'forwardOptionText'}
                        onClick={() => this._handleMessageInfo ()}
                        style={{textAlign: 'center', cursor: 'pointer'}}
                      >
                        MessageInfo
                      </div>}
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                size="sm"
                isOpen={this.state.showContactsModal}
                centered={true}
                toggle={() => this.setState ({showContactsModal: false})}
              >
                <ModalBody className="forwardMessageModel">
                  <div >
                  <div className="shareWithText">Share with...</div>

                    <div>
                      <p className="searchFormemberGroups">Search for members or groups:</p>
                      <div className="searchMembersInput">
                        <img
                          alt="seainputicon"
                          src={seainputicon}
                          style={{width: 34, height: 22, marginLeft: 10}}
                        />
                        <Input
                          className="searchmembersInputs"
                          onChange={val => this.searchText (val)}
                          placeholder="Search contact or group"
                        />
                      </div>
                    </div>

                    <div style={{marginTop:10}}>
                    <div className="searchFormemberGroups">Contacts:</div>
                    <div className="userListingScroller">
                      {this.state.contactsToShow.map ((contact, index) => {
                        const {
                          firstName,
                          profilePictureURL,
                          lastName,
                          professionalTitle,
                        } = contact.contact.contactDetail;
                        return (
                          <div
                            key={index}
                            onClick={() => this._handleClickOnContact (contact)}
                            className="inlineForwardedList"
                          >
                            <div>
                              <div>
                                <img
                                  alt="imgProfile"
                                  src={
                                    profilePictureURL
                                      ? profilePictureURL
                                      : profileDefault
                                  }
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 86,
                                    border: '2px solid #4453D6',
                                  }}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="messengerForwardName">
                                {ShowNameAccordingToLength (
                                  firstName + ' ' + lastName
                                )}
                              </div>
                              <div className="messengerForwardLastseen">{professionalTitle}</div>
                            </div>

                          </div>
                        );
                      })}
                      {this.state.groupsToShow.map ((group, index) => {
                        // console.log("group>>>>   group>>>>>>", group)
                        const {displayName, groupIcon} = group;
                        return (
                          <div
                            key={index}
                            onClick={() => this._handleClickOnGroup (group)}
                            className="inlineForwardedList"
                          >
                            <div>
                              <div>
                                <img
                                  alt="imgProfile"
                                  src={groupIcon ? groupIcon : profileDefault}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 86,
                                    border: '2px solid #4453D6',
                                  }}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="messengerForwardName">
                                {ShowNameAccordingToLength (
                                  displayName + ' ' + ''
                                )}
                              </div>
                              {/* <p className="lastwswef">{professionalTitle}</p> */}
                            </div>

                          </div>
                        );
                      })}
                  </div>
                  </div>

                  </div>
                </ModalBody>
              </Modal>

              {/* Message Info Modal*/}
              <Modal
                size="md"
                isOpen={this.state.showMessageInfoModal}
                centered={true}
                toggle={() => this.setState ({showMessageInfoModal: false})}
              >
                <ModalBody className="showContactList">
                  <div>
                    <div>
                      <p className="labejuegd">{`Read by: ${this.state.usersToShow.length} Users`}</p>
                    </div>
                    <div className="clicktoaddckdjd">
                      {this.state.usersToShow.map ((user, index) => {
                        const {userName, userProfile, readOn} = user;
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              marginTop: 20,
                              cursor: 'pointer',
                            }}
                          >
                            <div>
                              <div>
                                <img
                                  alt="imgProfile"
                                  src={
                                    userProfile ? userProfile : profileDefault
                                  }
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 25,
                                    border: '2px solid #FFFFFF',
                                  }}
                                />
                              </div>
                            </div>

                            <div>
                              <p className="MarcusBar">
                                {ShowNameAccordingToLength (
                                  userName + ' ' + ''
                                )}
                              </p>
                              <p className="lastwswef">{`${moment (readOn).calendar ()}`}</p>
                            </div>

                          </div>
                        );
                      })}
                    </div>

                  </div>
                </ModalBody>
              </Modal>

             
              {/*vertical three dots starts*/}
              {/*this.state.showOptions && <div
                className="messageThreeDotOptions"
                style={{ cursor: 'pointer', borderWidth: 2 }}
                onClick={() => this.setState({ showOptionsModal: true })}
              >
                <img
                  src={verticalThreeDots}
                  className={['objectFit']}
                />
              </div>*/}
              {/*vertical three dots ends*/}

               <Modal
                size="sm"
                isOpen={this.state.showDicomConfirmBox}
                centered={true}
                toggle={() => this.setState ({showDicomConfirmBox: false})}
              >
                <ModalBody className="showContactList">
                  <div style={{textAlign:"center"}}>
                    <div>
                      <p className="labejuegd" style={{fontSize:18}}>Download Option</p>
                    </div>
                    <div style={{display:'inline-grid'}}>
                      <Button className="messengerFiledownloadButton" onClick={()=>{
                        let decryptedDownloadFile = this.state.dicominfo && this.state.dicominfo.type === 'attach' && this.state.dicominfo.attachSource
                          ? decryptMessage (this.state.dicominfo.attachSource.toString (), secretKey)
                          : '';
                          var win = window.open (decryptedDownloadFile, '_blank');
                          win.focus ();
                          this.setState({
                            showDicomConfirmBox:false
                          })
                      }}>Download</Button>
                      <Button className="messengerFiledownloadButton" style={{marginTop:20}}
                      
                      onClick={ async ()=>{ 
                        // console.log(this.state.dicominfo);
                        let decryptedDownloadFile1 = this.state.dicominfo && this.state.dicominfo.type === 'attach' && this.state.dicominfo.attachSource
                          ? decryptMessage (this.state.dicominfo.attachSource.toString (), secretKey)
                          : '';
                          let decryptedDownloadFilename = this.state.dicominfo && this.state.dicominfo.type === 'attach' && this.state.dicominfo.fileName
                          ? decryptMessage (this.state.dicominfo.fileName.toString (), secretKey)
                          : '';
                          // console.log(decryptedDownloadFilename);
                          // console.log(decryptedDownloadFile1);
                          // let  url = new URL(decryptedDownloadFile1);
                          // let  f = new File(decryptedDownloadFile1);
                          // console.log(f);
                          let response = await fetch(decryptedDownloadFile1);
                            let data = await response.blob();
                            let metadata = {
                              type: 'application/dicom',
                            };
                            let url = new File([data], decryptedDownloadFilename,metadata);
                          // let  url = new File([decryptedDownloadFile1], decryptedDownloadFilename,metadata);
                            // console.log(url);
                            // fileListing[0].progress
                            // let fileListing=[];
                            // url.progress=0;
                            // fileListing.push(url)
                            // console.log(url);
                            this.setState({
                              fileListing:[url]
                            },()=>{
                                //  console.log(this.state.fileListing);
                                 this.handleUploadingFiles();
                            })
                      }}>Save To Vault</Button>
                      
                    </div>

                  </div>
                </ModalBody>
              </Modal>

             
            </div>

          </div>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    contactsList: state.ContactsReducer,
    messengerList: state.MessengerReducer,
    chatreducer: state.MessengerReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      Loading,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (Message);
