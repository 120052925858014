import React, {Component} from 'react';
// import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Input, Modal, ModalBody} from 'reactstrap';
import moment from 'moment';
import UserContactListPage from './../../contacts/usercontacts/userList';
import {Dropdown} from 'primereact/dropdown';
import * as routes from '../../../constants/routes';
import profilePlaceholder from '../../../images/profile_placeholder.png';


import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import Search_ from '../../../images/icons/Search_.png';
import {createNotification} from '../../../constants/notificationtoast';
import {Loading} from '../../../actions/LoadingAction';
import {
  getUserDetail,
  uploadUserProfile,
  updateUser,
} from '../../../actions/contacts';
import {
  AddEducationalDetail,
  DeleteEducationalDetail,
  AddProfessionalDetail,
  DeleteProfessionalDetail,
  removeBusiness,
  getProfileDashboard,
  getFindBusiness,
  ModifyPersonalInfo,
} from '../../../actions/Authaction';
// import union from '../../../images/profile/union.png';
import './editUser.css';
import pronew from '../../../images/profile/pronew.png';
import editicon2 from '../../../images/profile/editicon2.png';
import deleteicon2 from '../../../images/profile/deleteicon2.png';
import InputCustom from '../../InputCustom/InputCustom';
import  RightSideContainer from '../../../components/UI/rightSideContainer/rightSideContainer';


import {authErrorMessage} from '../../../constants/localMessage/auth/authHelper';
import * as authValidation from '../../../constants/localMessage/auth/authHelper';

import Resizer from 'react-image-file-resizer';
import ImageCrop from '../../UI/ImageCrop';
import {CapitalizeFirstLetter } from '../../../constants/helper';
import degreeTypeList from '../../../constants/localJSON/degreeType.json';

import Calendar from '../../../components/UI/calendar/calendar';
import { IMAGE_TYPE_ERROR, } from '../../../constants/errorMessages';


//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class editUserDetail extends Component {
  constructor (props) {
    super (props);
    this.state = {
      profilePicture: '',
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      passwordOne: '',
      phoneNumber: '',
      displayName: '',
      professionalTitle: '',
      location: '',
      searchHandle: '',
      AboutMe: '',
      showingAlert: false,
      Loading: true,
      userid: '',
      base64Image: '',
      isSubmit: false,
      invalidEmailFormat: false,
      educationDetail: [],
      proffessionDetail: [],
      addEduModal: false,
      ediEduModal: false,
      addProfModal: false,
      ediProfModal: false,
      addChangeModal: false,
      addNextModal: false,
      addChangelastModal: false,
      businessProfile: [],
      yearCompleted: '',
      university: '',
      degreeType: '',
      courseMajor: '',
      editEducationID: '',
      editWorkExperienceID: '',
      isSubmitProfile: false,
      position: '',
      nameofFirm: '',
      startDate: '',
      endDate: '',
      currentlyEmployed: '',
      experienceType: '',
      businessData: '',
      isSearch: false,
      selectedBusiness: '',
      nameSearchString: '',
      idSearchString: false,
      searchBusinessStep: 1,
      isBusinessSubmit: false,
      searchByName: false,
      experienceTypeoptions:[ {
        label: 'Self Employed',
        value: 'Self Employed',
      },
      {
        label: 'Employed',
        value: 'Employed',
      },
      {
        label: 'Govt Service',
        value: 'Govt Service',
      },],
      loadedImage:'',
      tagline:'',
      isOpenStartPicker: false,
      isOpenEndPicker: false,
      isDisableButton: false

      
    };
    this.onSubmit = this.onSubmit.bind (this);
    this.openStartDatepicker = React.createRef ();
    this.openEndDatepicker = React.createRef ();

  }

  handleClickOutside = event => {

    if (
      this.openStartDatepicker.current &&
      !this.openStartDatepicker.current.contains (event.target)
    ) {
      this.setState ({
        isOpenStartPicker: false,
      });
    }

    if (
      this.openEndDatepicker.current &&
      !this.openEndDatepicker.current.contains (event.target)
    ) {
      this.setState ({
        isOpenEndPicker: false,
      });
    }


    
  }

  componentWillUnmount () {
    document.removeEventListener ('mousedown', this.handleClickOutside);
  }
  componentDidMount () {
    // console.log(new Date(moment(new Date()).format('MM/DD/YYYY')));
    let geturl = this.props.match.params;
    document.addEventListener ('mousedown', this.handleClickOutside);

    if (geturl && geturl.userId) {
      this.setState (
        {
          userid: geturl.userId,
        },
        () => {
          let sendRequest = {
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile: true,
              docID: this.state.userid,
            },
          };
          this.props.getProfileDashboard (sendRequest);
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
 
    if (nextProps) {

      const {isSubmitProfile} = this.state;


      if(isSubmitProfile){
        if( nextProps.userInfo &&
          nextProps.userInfo.dashboardprofile &&
          nextProps.userInfo.dashboardprofile){
            this.props.history.push(routes.PROFILE);
          }

      }



      if (
        nextProps.contacts.uploadImage &&
        nextProps.contacts.uploadImage.profilePictureURL
      ) {
        this.setState (
          {
            // profilePicture:   nextProps.contacts.uploadImage.profilePictureURL
            profilePicture: this.state.base64Image,
          },
          () => {
            // console.log (this.state.profilePicture);
          }
        );
      }

      if (
        nextProps.userInfo &&
        nextProps.userInfo.dashboardprofile &&
        nextProps.userInfo.dashboardprofile
      ) {
        let getData = nextProps.userInfo.dashboardprofile;
        this.setState (
          {
            personalDetail: getData.personalDetails,
            educationDetail: getData.educationDetails,
            proffessionDetail: getData.professionalDetails,
            businessProfile: getData.businessProfile,
            addEduModal: false,
            addProfModal: false,
          },
          () => {
            if (!this.state.isSubmitProfile) {
              this.setState ({
                profilePicture: this.state.personalDetail.profilePictureURL? this.state.personalDetail.profilePictureURL:'',
                title: this.state.personalDetail.title?this.state.personalDetail.title:'',
                firstName: this.state.personalDetail.firstName?this.state.personalDetail.firstName:'',
                lastName: this.state.personalDetail.lastName?this.state.personalDetail.lastName:'',
                emailAddress: this.state.personalDetail.emailAddress?this.state.personalDetail.emailAddress:'',
                phoneNumber: this.state.personalDetail.phoneNumber? this.state.personalDetail.phoneNumber:'',
                displayName: this.state.personalDetail.displayName?this.state.personalDetail.displayName:'',
                searchHandle: this.state.personalDetail.searchHandle?this.state.personalDetail.searchHandle:'',
                AboutMe: this.state.personalDetail.aboutMe?this.state.personalDetail.aboutMe:'',
                location: this.state.personalDetail.location?this.state.personalDetail.location:'',
                professionalTitle: this.state.personalDetail.professionalTitle?this.state.personalDetail.professionalTitle:'',
                tagline: this.state.personalDetail.taglineMessage? this.state.personalDetail.taglineMessage:''
              });
              if (
                this.state.searchBusinessStep === 2 &&
                this.state.isBusinessSubmit
              ) {
                this.setState ({
                  addChangeModal: false,
                  searchBusinessStep: 1,
                  selectedBusiness: '',
                  isBusinessSubmit: false,
                });
              }
            }
          }
        );
      }
      if (
        nextProps.userInfo.findBusiness &&
        nextProps.userInfo.findBusiness.result &&
        nextProps.userInfo.findBusiness.result.status
      ) {
        this.setState ({
          businessData: nextProps.userInfo.findBusiness.result.Data,
        });
      }
    }
  }

  onChange = e => {
    console.warn ('warning function call details');
    let files = e.target.files;
    let response = files[0].size / 1000000;
    if (response && response > 5) {
      createNotification (
        'warning',
        'The uploaded file is too large. Profile can only be a maximum of 5 MB.'
      );
      return ;
    }

    const file = e.target.files[0];
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    // Check If file is not an image
    if (!validImageTypes.includes(fileType)) {
      // console.log("rfreg")
        createNotification('warning', IMAGE_TYPE_ERROR);
    } else {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({
            loadedImage:reader.result,
            filename: files[0].name
          })});
        reader.readAsDataURL(e.target.files[0]);
    }
  
  };

  uploadUserImage(croppedImage){
    Resizer.imageFileResizer(
      croppedImage,
      300,
      300,
      'JPEG',
      400,
      0,
      uri => {
      
        const {filename}= this.state;
        this.setState({
          base64Image:uri
        },()=>{
          let sendRequest = {
            data: {
              profilePicture: uri,
              filename: filename,
              contentType: 'image/jpeg',
            },
          };
          this.props.uploadUserProfile (sendRequest);
        })

        


      },
      'base64'
      )
  }

  onSubmit = event => {
    event.preventDefault ();
    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          profilePicture,
          title,
          firstName,
          lastName,
          emailAddress,
          passwordOne,
          phoneNumber,
          displayName,
          professionalTitle,
          location,
          searchHandle,
          AboutMe,
          tagline
        } = this.state;
        const {history, register} = this.props;
        // console.log ('submitting form', emailAddress);

        const isInvalid =
          !title ||
          !firstName ||
          !lastName ||
          // !professionalTitle  ||
          // !location  ||
          // !AboutMe ||
          // !searchHandle || 
          !tagline;

        // if(emailAddress){
        //   const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        //   const result = pattern.test(emailAddress);

        //   if(!result){
        //     this.setState({
        //       invalidEmailFormat: true
        //     })

        //   }else{
        //     this.setState({
        //       invalidEmailFormat: false
        //     })
        //   }
        // }

        if (isInvalid) {
          return false;
        }
        this.setState ({
          isSubmitProfile: true,
        });

        this.props.Loading (true);

        let sendRequest = {
          data: {
            title: title?title:'',
            firstName: firstName,
            lastName: lastName,
            // displayName: displayName,
            searchHandle: searchHandle?searchHandle:'',
            phoneNumber: phoneNumber,
            aboutMe: this.state.AboutMe?this.state.AboutMe:'',
            location: this.state.location?this.state.location:'',
            professionalTitle: professionalTitle?professionalTitle:'',
            taglineMessage: tagline?tagline:''
          },
        };

        this.props.updateUser (sendRequest);
      }
    );
  };
  closeModal = () => {
    this.setState ({
      addEduModal: false,
      ediEduModal: false,
      addProfModal: false,
      ediProfModal: false,
      addChangeModal: false,
      addNextModal: false,
      addChangelastModal: false,
    });
  };
  openAddEduModal = (item, status) => {
   
    this.setState ({
      addEduModal: true,
      isSubmit: false,
      editEducationID: status ? item.docID : '',
      yearCompleted: status ? item.yearCompleted : '',
      university: status ? item.university : '',
      degreeType: status ? item.degreeType : '',
      courseMajor: status ? item.courseMajor : '',
      isDisableButton: false
    });
  };

  openAddProfModal = (item, status) => {

    this.setState (
      {
        addProfModal: true,
        isSubmit: false,
        editWorkExperienceID: status ? item.docID : '',
        position: status ? item.position : '',
        nameofFirm: status ? item.firmName : '',
        // experienceType:status? item.experienceType:"",
        experienceType: item.experienceType,

        currentlyEmployed: status ? item.currentlyWorking : '',
        startDate: status ? new Date (item.startDate) : '',
        endDate: status ? new Date (item.endDate) : '',
        isDisableButton: false
      },
      () => {
        // console.log (this.state.experienceType);
      }
    );
  };
  openEdiProfModal = () => {
    this.setState ({ediProfModal: true});
  };
  openAddChangeModal = () => {
    this.setState ({addChangeModal: true,selectedBusiness:'',searchBusinessStep:1,searchString:''});
  };
  openAddNextModal = () => {
    this.setState ({addChangeModal: false});
    this.setState ({addNextModal: true});
  };
  openAddChangelastModal = () => {
    this.setState ({addNextModal: false});
    this.setState ({addChangelastModal: true});
  };

  AddProffessionalDetail () {
    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          position,
          nameofFirm,
          experienceType,
          startDate,
          editWorkExperienceID,
          endDate,
          currentlyEmployed,
        } = this.state;
        //a boolen to perform validation
        const isInvalid =
          position === '' ||
          nameofFirm === '' ||
          experienceType === '' ||
          startDate === '' ||
          (endDate === '' && currentlyEmployed === false);

        if (isInvalid) {
          return false;
        }
        this.setState({
          isDisableButton: true
        })

        // const sendRequest = {
        //   data: {
        //     yearCompleted: this.state.yearCompleted,
        //     university: this.state.university,
        //     degreeType: this.state.degreeType,
        //     courseMajor: this.state.courseMajor,
        //     docID: editWorkExperienceID ? editWorkExperienceID:'',
        //   },
        // };
        // console.log(sendRequest);

        let sendRequest = {
          data: {
            experienceType: this.state.experienceType,
            firmName: this.state.nameofFirm,
            position: this.state.position,
            startDate:this.state.startDate,
            endDate: currentlyEmployed ? '' : this.state.endDate,
            currentlyWorking: this.state.currentlyEmployed,
            docID: editWorkExperienceID ? editWorkExperienceID : '',
          },
        };
        this.props.AddProfessionalDetail (sendRequest);
      }
    );
  }

  AddEducationalDetail () {
    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          yearCompleted,
          university,
          degreeType,
          courseMajor,
          editEducationID,
        } = this.state;
        //a boolen to perform validation
        const isInvalid =
          yearCompleted === '' ||
          university === '' ||
          degreeType === '' ||
          courseMajor === '' ||
          university.length > 100 ||
          courseMajor.length > 100;

        if (isInvalid) {
          return false;
        }

        this.setState({
          isDisableButton: true
        })

        const sendRequest = {
          data: {
            yearCompleted: this.state.yearCompleted,
            university: this.state.university,
            degreeType: this.state.degreeType,
            courseMajor: this.state.courseMajor,
            docID: editEducationID ? editEducationID : '',
          },
        };
        this.props.AddEducationalDetail (sendRequest);
      }
    );
  }
  deleteEducationDetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteEducationalDetail (data);
  };

  deleteProfessionalDetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteProfessionalDetail (data);
  };

  removeBusiness () {
    let sendRequest = {
      data: {},
    };
    this.props.removeBusiness (sendRequest, this.state.userid);
  }

  handleChangeSearch () {
    const {searchString} = this.state;
    if (this.state.searchString && this.state.searchString.length > 2) {
   
      let sendRequest = {
        data: {
          nameSearchString: this.state.searchByName ? searchString : false,
          idSearchString: !this.state.searchByName ? searchString : false,
          limit: 50,
        },
      };

      this.setState ({
        isSearch: true,
      });
      this.props.getFindBusiness (sendRequest);
    }
  }

  onSelect = item => {
    this.setState ({
      isSearch: false,
      selectedBusiness: item,
    });
  };

  doSubmitAddBusiness () {
    this.setState (
      {
        isBusinessSubmit: true,
      },
      () => {
        const {selectedBusiness} = this.state;
        let sendRequest = {
          data: {
            associatedBusiness: selectedBusiness.docID,
          },
        };
        this.props.ModifyPersonalInfo (sendRequest);
      }
    );
  }

  render () {
    const {
      profilePicture,
      title,
      firstName,
      lastName,
      emailAddress,
      invalidEmailFormat,
      phoneNumber,
      isSubmit,
      displayName,
      professionalTitle,
      location,
      searchHandle,
      AboutMe,
      base64Image,
      businessProfile,
      educationData,
      yearCompleted,
      university,
      degreeType,
      courseMajor,
      editEducationID,
      editWorkExperienceID,
      nameofFirm,
      position,
      startDate,
      endDate,
      currentlyEmployed,
      experienceType,
      businessData,
      isSearch,
      searchBusinessStep,
      selectedBusiness,
      experienceTypeoptions,
      loadedImage,
      tagline,
      isOpenStartPicker,
      isOpenEndPicker,
      isDisableButton
    } = this.state;

    return (
      <div>
        <div style={{display: 'flex', background: '#FFFFFF'}}>
          <div className="editprofilemainContainer">
            <div style={{textAlign: 'right'}}>
              <Button
                className="editProfilebackbutton"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
              >
                <div>
                  <img src={leftbackarrow} alt="editbackIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>
            <div
              style={{
                display: 'flex'
              }}
            >
              <div style={{marginTop:20}}>
                <div className="GENERALDETAIL">
                  <span> GENERAL</span> DETAILS
                </div>

                <div style={{display: 'flex', marginTop: 25}}>
                  <div>
                    <p className="edititle">Title</p>
                    <div style={{width: '88px', height: '49px'}}>
                      {!this.state.addEduModal && <InputCustom
                        value={title}
                        onSelect={value => {
                          this.setState (byPropKey ('title', value));
                        }}
                        options={[
                          // { name: "Title", value: "" },
                          {name: 'Mr.', value: 'Mr.'},
                          {name: 'Dr.', value: 'Dr.'},
                          {name: 'Ms.', value: 'Ms.'},
                          {name: 'Mrs.', value: 'Mrs.'},
                        ]}
                      />}

                    </div>

                    {!title &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 20}}
                      >
                      
                        {' '} {authErrorMessage(authValidation.PROFILE_TITLE)}{' '}
                      </p>}
                  </div>
                  <div>
                    <p className="edifirstname">First Name</p>
                    <Input
                      className="inputfirstn"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="Legal First Name*"
                      value={firstName}
                      readOnly={true}
                      onChange={e =>
                        this.setState (byPropKey ('firstName', e.target.value))}
                    />
                    {!firstName &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 30}}
                      >
                        {' '}Firstname is required *{' '}
                      </p>}
                  </div>

                  <div>
                    <p className="edilastname">Last Name</p>
                    <Input
                      className="inputlastname"
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Legal Last Name*"
                      value={lastName}
                      readOnly={true}
                      onChange={e =>
                        this.setState (byPropKey ('lastName', e.target.value))}
                    />
                    {!lastName &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 30}}
                      >
                        {' '}Lastname is required *{' '}
                      </p>}
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: 20}}>
                <div>
                    <p className="edilocation">Location</p>
                    <Input
                      className="inputlocationj"
                      type="text"
                      name="Location"
                      id="Location"
                      placeholder="Location"
                      value={CapitalizeFirstLetter(location)}
                      onChange={e =>
                        this.setState (byPropKey ('location', e.target.value))}
                    />
                    {/* {!location &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 20}}
                      >
                      {authErrorMessage(authValidation.PROFILE_LOCATION)}
                      </p>} */}
                  </div>
                  {/*<div>
                    <p className="edidisplayname">Preferred Display Name</p>
                    <Input
                      className="inputdisplayname"
                      type="text"
                      name="displayName"
                      id="displayName"
                      placeholder="Preferred Display Name*"
                      value={displayName}
                      onChange={e =>
                        this.setState (
                          byPropKey ('displayName', e.target.value)
                        )}
                    />
                    {!displayName &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 20}}
                      >
                        {' '}
                        {authErrorMessage(authValidation.PROFILE_DISPLAYNAME)}
                        {' '}
                      </p>}
                    </div>*/}
                  <div>
                    <p className="ediprofesinaltit">Professional Title</p>
                    <Input
                      className="inputprofessionaltitle"
                      type="text"
                      name="professionalTitle"
                      id="professionalTitle"
                      placeholder="Professional Title"
                      value={CapitalizeFirstLetter(professionalTitle)}
                      onChange={e =>
                        this.setState (
                          byPropKey ('professionalTitle', e.target.value)
                        )}
                    />
                    {/* {!professionalTitle &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 30}}
                      >
                      {authErrorMessage(authValidation.PROFILE_PROFESSIONAL_TITLE)}
                      </p>} */}
                  </div>
                </div>
              
                <div style={{display: 'flex', marginTop: 20}}>
                <div>
                    <p className="edilocation">Tagline</p>
                    <Input
                      className="inputlocationj"
                      type="text"
                      placeholder="Tagline"
                      value={CapitalizeFirstLetter(tagline)}
                      onChange={e =>
                        this.setState (byPropKey ('tagline', e.target.value))}
                    />
                    {!tagline &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 20}}
                      >
                      {authErrorMessage(authValidation.PROFILE_TAGLINE)}
                      </p>}
                  </div>
                </div>
              
              </div>
              <div style={{marginLeft: 35}}>
                <div className="EditMyPr"  data-rh="Edit profile">
                  Edit My Profile
                </div>
                <div>
                  <p className="DisplayProfile">Display Profile Picture</p>
                  <p className="Pleaseuploada">
                    {' '}Please upload a photo of type JPEG or PNG
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {!profilePicture &&
                      <div>
                        <img alt="pronew" src={pronew} className="userimgcirclsd" />

                      </div>}
                    {profilePicture &&
                      <div>
                        <img
                          alt="userimgcirclsd"
                          src={base64Image ? base64Image : profilePicture}
                          className="userimgcirclsd"
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = profilePlaceholder;
                          }}
                        />
                      </div>}

                    <div />
                    <div />
                  </div>
                  <div>
                    <label className="imguseruplo" data-rh="Select File">
                      <input
                        type="file"
                        name="profilePicture"
                        onChange={e => this.onChange (e)}
                        accept="image/*"
                      />
                      <i className="fa fa-cloud-upload" />Upload
                    </label>

                    {loadedImage && <ImageCrop image={loadedImage} onSubmitClick={croppedImage =>{
                      console.log(croppedImage);
                      this.setState({
                        loadedImage:''
                      })

                      this.uploadUserImage(croppedImage);
                  }}/>}
                  </div>
                </div>
                <div>
                <div style={{paddingLeft:15,position:'relative',width:30,top:69,color:'#FFFFFF',zIndex:10}}>  <span>@</span></div>

                  <p className="edisearchhandfggj"> Preferred Search Handle</p>

                  <input
                    className="inputsearchhandlskndej"
                    type="text"
                    // name="searchHandle"
                    // id="searchHandle"
                    placeholder="Preferred Search Handle*"
                    value={CapitalizeFirstLetter(searchHandle)}
                    pattern="[a-zA-Z0-9]*"

                      onChange={evt => {
                        const searchHandle = evt.target.validity.valid
                          ? evt.target.value
                          : this.state.searchHandle;
                        this.setState ({
                          searchHandle,
                        });
                      }}
                  />
                 

                  {/* {!searchHandle &&
                    isSubmit &&
                    <p
                      className="validationerrorMessage"
                      style={{paddingLeft: 20}}
                    >
                      {authErrorMessage(authValidation.PROFILE_SEARCHHANDLE)}
                    </p>} */}
                </div>
              </div>
            </div>
            <div>
              <p className="ediaboutmeg">About Me</p>
              <textarea
                className="inputaboutmejdsgc"
                type="text"
                name="AboutMe"
                id="searchHandle"
                placeholder="AboutMe*"
                value={CapitalizeFirstLetter(AboutMe)}
                onChange={e =>
                  this.setState (byPropKey ('AboutMe', e.target.value))}
              />
              {/* {!AboutMe &&
                isSubmit &&
                <p className="validationerrorMessage" style={{paddingLeft: 20}}>
                {authErrorMessage(authValidation.PROFILE_ABOUTME)}
           
                </p>} */}
            </div>
            <div className="EDUCATIONALvfb">
              <span> EDUCATIONAL</span> DETAILS
            </div>
            <div className="Editorad">
              Edit or add to your educational history.
            </div>
            <div style={{marginTop: 20}}>
              {this.state.educationDetail &&
                this.state.educationDetail.length > 0 &&
                this.state.educationDetail.map ((item, index) => (
                  <div className="edudivinfodcs" key={index}>
                    <div className="infokcdni998">
                      {item.yearCompleted}
                    </div>
                    <div className="Bachelorof">
                      {item.degreeType}
                    </div>
                    <div className="Universescdv">
                      {item.university}
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Button
                        className="buttonclas"
                        onClick={() => this.openAddEduModal (item, true)}
                        data-rh="Edit"

                      >
                        <img alt="" src={editicon2} />
                      </Button>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Button
                        className="buttonclas"
                        onClick={() => this.deleteEducationDetail (item)}
                        data-rh="Delete"

                      >
                        <img alt="" src={deleteicon2} />
                      </Button>
                    </div>
                  </div>
                ))}
              <div style={{width: 1039,textAlign:'right'}}>
                <Button
                  className="addnewbutton"
                  onClick={() => this.openAddEduModal ('', false)}
                  data-rh="Add new"
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="EDUCATIONALvfb">
              <span> WORK</span> EXPERIENCE
            </div>
            <div className="Editorad">
              Edit or add to your employment history.
            </div>
            <div style={{marginTop: 20}}>
              {this.state.proffessionDetail &&
                this.state.proffessionDetail.length > 0 &&
                this.state.proffessionDetail.map ((item, index) => (
                  <div className="edudivinfodcs" key={index}>
                    <div className="infokcdni998">

                      {item && item.startDate
                        ? moment (new Date (item.startDate)).format ('MM/YY')
                        : 'N/A'}
                      {' - '}

                      {item && item.currentlyWorking === true
                        ? 'Current'
                        : item && item.endDate
                            ? moment (new Date (item.endDate)).format ('MM/YY')
                            : 'N/A'}

                    </div>
                    <div className="Bachelorof">
                      {item.position}
                    </div>
                    <div className="Universescdv">
                      {item.firmName}
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Button
                        className="buttonclas"
                        onClick={() => this.openAddProfModal (item, true)}
                        data-rh="Edit"
                      >
                        {' '}
                        <img
                          alt=""
                          src={editicon2}
                       
                        />
                      </Button>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Button className="buttonclas"  data-rh="Delete">
                        <img
                          alt=""
                          src={deleteicon2}
              
                          onClick={() => this.deleteProfessionalDetail (item)}
                        />
                      </Button>
                    </div>
                  </div>
                ))}
              <div style={{width: 1039,textAlign:'right'}}>
                <Button
                  className="addnewbutton"
                  onClick={() => this.openAddProfModal ('', false)}
                  data-rh="Add new"

                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="EDUCATIONALvfb">
              <span> BUSINESS</span> PROFILE
            </div>
            {businessProfile &&
              businessProfile.registeredBusinessName &&
              <div>
                <div className="Editorad">
                  Edit your current business affiliation.
                </div>
                <div className="registeredbusinesname">
                  Registered Business Name
                </div>
                <div className="businessValueContent">
                  {businessProfile && businessProfile.registeredBusinessName
                    ? businessProfile.registeredBusinessName
                    : 'N/A'}
                </div>
                <div className="registeredbusinesname">
                  Business ID
                </div>
                <div>
                  {businessProfile && businessProfile.businessID
                    ? businessProfile.businessID
                  : 'N/A'}
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: 146}}>
                    <div className="registeredbusinesname">
                      Country
                    </div>
                    <div className="registeredBusinessLocation">
                      {businessProfile && businessProfile.businessCountry
                        ? businessProfile.businessCountry
                        : 'N/A'}

                    </div>
                  </div>
                  <div>
                    <div className="registeredbusinesname">
                      City
                    </div>
                    <div className="registeredBusinessLocation">
                      {businessProfile && businessProfile.businessCity
                        ? businessProfile.businessCity
                        : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>}
            {businessProfile && businessProfile.length === 0 &&
              <div className="Editorad">
                No business added yet.
              </div>}
            <div
              className="editBussinessBoxProfile"
            >
              {businessProfile && businessProfile.length === 0
                ? <div>
                    {' '}<Button
                      className="removefdsvbutton"
                      style={{float: 'left', width: 173,marginTop:10}}
                      onClick={this.openAddChangeModal}
                      data-rh="Add Business"

                    >
                      Add Business
                    </Button>
                  </div>
                : <div style={{display: 'flex',width:'100%',marginTop:10}}>
                    <div>
                      <Button
                        className="removefdsvbutton"
                        onClick={() => this.removeBusiness ()}
                        data-rh="Remove"

                      >
                        Remove
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="changefdsvbutton"
                        onClick={this.openAddChangeModal}
                        data-rh="Change"
                      >
                        Change
                      </Button>
                    </div>
                  </div>}

              <div>
                <Button className="saveallfdsvbutton" onClick={this.onSubmit} data-rh="Submit">
                  Save All Changes
                </Button>
              </div>
            </div>
          </div>
          <RightSideContainer>
          <UserContactListPage history={this.props.history} />
          </RightSideContainer>
         {/*} <div className="contact-container-parent blueBg">
            <UserContactListPage history={this.props.history} />
              </div>*/}
          <Modal
            size="sm"
            isOpen={this.state.addEduModal}
            centered={true}
            toggle={this.closeModal}
            backdropClassName="purpleModalBg"
          >
            <ModalBody className="adddeducatimodal">
            <div style={{display:"inline-flex",justifyContent:'space-between',width:'100%'}}>


             
              <div className="EDUCATIONALslcjdl">
                <span> {editEducationID ? 'EDIT ' : 'ADD '}</span>
                {' '}
                EDUCATIONAL DETAIL
              </div>
              <div>
              <Button
                className="editProfilebackbutton"
                onClick={this.closeModal}
                data-rh="Go back"
              >
                <div>
                  <img src={leftbackarrow} alt="editbackIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>
              </div>
              <div style={{margin:10}}>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="unoversitylabel">University Name</p>
                  <Input
                    className="unoversitylabelinput"
                    type="text"
                    // name="UniversityName"
                    // id="UniversityName"
                    placeholder=""
                    value={CapitalizeFirstLetter(university)}
                    // value={this.state.university}
                    onChange={e => {
                      this.setState ({university: e.target.value});
                    }}
                  />
                  {!university &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                    {authErrorMessage(authValidation.EDUCATION_UNIVERSITY)}
                    </p>}
                  {university &&
                    university.length > 100 &&
                    <p className="validationerrorMessage">
                      {' '}
                      {authErrorMessage(authValidation.MAX_100_CHAR)}
                      
                    </p>}
                </div>
                <div >
                  <p className="yearhdmlabel">Year Completed</p>
                  {/*<Input
                    className="yearshjscinput"
                    type="text"
                    name="YearCompleted"
                    id="YearCompleted"
                    placeholder=""
                    value={yearCompleted}
                    onChange={e =>
                      this.setState ({yearCompleted: e.target.value})}
                    />*/}
                    <div style={{height:50,paddingLeft:5}} className="mainYearSelectDiv">

                    <Dropdown
                    className="yearshjscinput"
                    value={yearCompleted}
                    options={[
                      {label: '2020', value: '2020'},
                      {label: '2019', value: '2019'},
                      {label: '2018', value: '2018'},
                      {label: '2017', value: '2017'},
                      {label: '2016', value: '2016'},
                      {label: '2015', value: '2015'},
                      {label: '2014', value: '2014'},
                      {label: '2013', value: '2013'},
                      {label: '2012', value: '2012'},
                      {label: '2011', value: '2011'},
                      {label: '2010', value: '2010'},
                      {label: '2009', value: '2009'},
                      {label: '2008', value: '2008'},
                      {label: '2007', value: '2007'},
                      {label: '2006', value: '2006'},
                      {label: '2005', value: '2005'},
                      {label: '2004', value: '2004'},
                      {label: '2003', value: '2003'},
                      {label: '2002', value: '2002'},
                      {label: '2001', value: '2001'},
                    ]}
                    onChange={e => this.setState ({yearCompleted: e.value})}
                  />

                   
                  </div>
                  {!yearCompleted &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                    {authErrorMessage(authValidation.EDUCATION_YEAR)}
                    </p>}

                </div>
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="unoversitylabel">Degree Type</p>

                  <div className="mainYearSelectDiv">
                  
                  <Dropdown
                    style={{width:310,marginLeft:0}}
                    className="yearshjscinput"
                    value={degreeType}
                    options={degreeTypeList}
                    onChange={e => this.setState ({degreeType: e.value})}
                  />
                  </div>
                  {/*<Input
                    className="degretypeinput"
                    type="text"
                    id="UniversityName"
                    placeholder=""
                    value={CapitalizeFirstLetter(degreeType)}
                    onChange={e => this.setState ({degreeType: e.target.value})}
                  />*/}
                  {!degreeType &&
                    isSubmit &&
                    <p className="validationerrorMessage">

                    {authErrorMessage(authValidation.EDUCATION_DEGREE)}
                     
                    </p>}

                </div>
                <div>
                  <p className="yearhdmlabel">Major Course</p>
                  <Input
                    className="majorcourseinput"
                    type="text"
                    name="MajorCourse"
                    id="MajorCourse"
                    placeholder=""
                    value={CapitalizeFirstLetter(courseMajor)}
                    onChange={e =>
                      this.setState ({courseMajor: e.target.value})}
                  />
                  {!courseMajor &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                    {authErrorMessage(authValidation.EDUCATION_COURSE)}
                 
                    </p>}
                  {courseMajor &&
                    courseMajor.length > 100 &&
                    <p className="validationerrorMessage">
                    {authErrorMessage(authValidation.MAX_100_CHAR)}
                    </p>}

                </div>
              </div>
              </div>
              <div style={{textAlign: 'center'}}>
                <Button
                  className="addtoprofiole"
                  onClick={() => this.AddEducationalDetail ()}
                  data-rh="Submit"

                  disabled={isDisableButton}

                >

                  {editEducationID ? 'Save Changes ' : 'Add to Profile'}

                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={this.state.addProfModal}
            centered={true}
            toggle={this.closeModal}
            backdropClassName="purpleModalBg"
          >
            <ModalBody className="adddeducatimodal">
              <div style={{display:"inline-flex",justifyContent:'space-between',width:'100%'}}>
                 
              <div className="profesinmksslcjdl">
                <span> {editWorkExperienceID ? 'EDIT ' : 'ADD '}</span>
                {' '}
                PROFESSIONAL EXPERIENCE
              </div>
              <div>
              <Button
                className="editProfilebackbutton"
                onClick={this.closeModal}
                data-rh="Go back"

              >
                <div>
                  <img src={leftbackarrow} alt="editbackIcon" />
                </div>

                Go <span> Back</span>
              </Button>
              </div>
            </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="nameoffirm">Name of Firm</p>
                  <Input
                    className="nameoffirminput"
                    type="text"
                    // name="nameofFirm"
                    // id="nameofFirm"
                    // placeholder=""
                    value={CapitalizeFirstLetter(nameofFirm)}
                    onChange={e => {
                      // console.log (e.target.value);
                      this.setState ({
                        nameofFirm: e.target.value,
                      });
                    }
                    // console.log(e.target.value)
                    // this.setState (byPropKey ('nameofFirm', e.target.value))
                    }
                  />
                  {!nameofFirm &&
          isSubmit &&
          <p
            className="validationerrorMessage"
            style={{paddingLeft: 20}}
          >
          {authErrorMessage(authValidation.PROFESSIONAL_FIRMNAME)}
        </p>}
                </div>
                <div>
                  <p className="experiencety">Experience Type</p>

                  <Dropdown
                  className="experiencetyinput"
                  value={experienceType}
                  options={experienceTypeoptions}
                  onChange={e => this.setState ({experienceType: e.value})}
                />

                 {/*} <Input
                    type="select"
                    name="experienceType"
                    id="select"
                    className="experiencetyinput"
                    value={experienceType}
                    onChange={e =>
                      this.setState (
                        byPropKey ('experienceType', e.target.value)
                      )}
                  >
                    <option xs="12" lg="4" value="" />
                    <option xs="12" lg="4" value="SelfEmployed.">
                      Self Employed
                    </option>
                    <option xs="12" lg="4" value="Employed">
                      Employed
                    </option>
                    <option xs="12" lg="4" value="Employed">
                      Govt Service
                    </option>

                      </Input>*/}

                  {!experienceType &&
                      isSubmit &&
                      <p
                        className="validationerrorMessage"
                        style={{paddingLeft: 20}}
                      >
          {authErrorMessage(authValidation.PROFESSIONAL_EXPERIENCETYPE)}
   
                    </p>}
                </div>
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="positojkcd">Position</p>
                  <Input
                    className="positioninput"
                    type="text"
                    name="position"
                    id="Position"
                    placeholder=""
                    value={CapitalizeFirstLetter(position)}
                    onChange={e =>
                      this.setState (byPropKey ('position', e.target.value))}
                  />
                  {!position &&
          isSubmit &&
          <p
            className="validationerrorMessage"
            style={{paddingLeft: 20}}
          >
          {authErrorMessage(authValidation.PROFESSIONAL_POSITION)}
            
        </p>}
                </div>

                <div style={{position:'relative'}}  ref={this.openStartDatepicker}>
                  <p className="startdate">Start Date</p>
                    <div  className="startdateinput"  onClick={() =>
                      this.setState ({
                        isOpenStartPicker: !isOpenStartPicker,
                      })}
                      >{this.state.startDate?moment(this.state.startDate).format('MM/YY'):'MM/YY'}</div>
                    {isOpenStartPicker && <div style={{position:'absolute'}}>
                        <Calendar 
                          // minDate={sendDate} 
                          // minDate={this.state.startDate?new Date(moment(this.state.startDate).format('MM/DD/YYYY')):new Date('01/01/1900')}
                          minDate={new Date('01/01/1900')}
                          maxDate={new Date (moment(new Date()).format('MM/DD/YYYY'))}

                          // maxDate={currentlyEmployed ?new Date (moment(new Date()).format('MM/DD/YYYY')) : (endDate? new Date (moment(new Date(endDate)).format('MM/DD/YYYY')): new Date (moment(new Date()).format('MM/DD/YYYY')))}
                         selectedDate={this.state.startDate} 
                        onSubmit={(value)=>{
                          this.setState({
                            isOpenStartPicker: false
                          })
                          if(value){
                            console.log(value);
                            this.setState ({
                              startDate: value,
                              endDate: '',
                            });
                          }
                          }} 
                        />
                  </div>}
                  
                  {!startDate &&
                    isSubmit &&
                    <p className="validationerrorMessage">
          {authErrorMessage(authValidation.PROFESSIONAL_STARTDATE)}
                    </p>}
                </div>
                <div style={{position:'relative'}}  ref={this.openEndDatepicker}>
                  <p className="startdate">End Date</p>

                  <div  className="startdateinput"  onClick={() =>
                    this.setState ({
                      isOpenEndPicker: !isOpenEndPicker,
                    })}
                    >{currentlyEmployed ? '' : this.state.endDate?moment(this.state.endDate).format('MM/YY'):'MM/YY'}</div>


                    {isOpenEndPicker && <div style={{position:'absolute'}}>
                        <Calendar 
                          // minDate={this.state.startDate}
                          minDate={this.state.startDate?new Date(moment(this.state.startDate).format('MM/DD/YYYY')):new Date('01/01/1900')}
                          maxDate={new Date (moment(new Date()).format('MM/DD/YYYY'))}
                         selectedDate={this.state.endDate} 
                        onSubmit={(value)=>{
                          this.setState({
                            isOpenEndPicker: false
                          })
                          if(value){
                            this.setState ({
                              endDate: value,
                              currentlyEmployed: false,
                              // endDate: '',
                            });
                          }
                          }} 
                        />
                  </div>}


                 
                  {!endDate &&
                    isSubmit &&
                    !currentlyEmployed &&
                    <p className="validationerrorMessage">
          {authErrorMessage(authValidation.PROFESSIONAL_ENDDATE)}
         
                    </p>}
                </div>
              </div>
              {/*<label
                style={{display: 'flex', alignItems: 'end', paddingLeft: 453}}
              >

                <Input
                  className="radioclasscd"
                  type="radio"
                  name="currentlyEmployed"
                  id="currentlyEmployed"
                  placeholder=""
                  value={currentlyEmployed}
                  checked={currentlyEmployed}
                  onChange={e => {
                    this.setState ({
                      currentlyEmployed: true,
                      endDate: '',
                    });
                  }}
                />
                <p className="Imcurren"> I’m currently employed here</p>
                </label>*/}
                <div className="currentlyWorkinghere">
                    <input type="radio"
                    value={currentlyEmployed}
                    checked={currentlyEmployed}
                    name="currentlyEmployed"
                    id="currentlyEmployed"  name="radio-group" onClick={()=> {
                      this.setState ({
                        currentlyEmployed: !currentlyEmployed,
                        endDate: '',
                      });
                    }}/>
                    <label for="currentlyEmployed" > <span className="Imcurren">I’m currently employed here </span></label>
                  </div>
              <div style={{textAlign: 'center'}}>
                <Button
                  className="addprofchangebutton"
                  onClick={() => this.AddProffessionalDetail ()}
                  data-rh="Submit"
                  disabled={isDisableButton}

                >
                  {editWorkExperienceID ? 'Save Changes ' : 'Add to Profile'}
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={this.state.addChangeModal} centered={true}  backdropClassName="purpleModalBg">
            <ModalBody className="addchangemodal">
            <div style={{display:"inline-flex",justifyContent:'space-between',width:'100%'}}>

             
              <div className="addchngbusimne">
                <span> ADD/CHANGE</span> BUSINESS
              </div>

              <div >
              <Button
                className="editProfilebackbutton"
                onClick={this.closeModal}
                data-rh="Go back"

              >
                <div>
                  <img src={leftbackarrow} alt="editbackIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>
            </div>
              {searchBusinessStep === 1 &&
                <div>
                  <div>
                    <p className="addchangebulB">
                      Search for your business in our database
                    </p>
                    <div className="searchbaradchange">
                      <img
                        alt=""
                        src={Search_}
                        style={{height: 31, width: 31, marginLeft: 15}}
                      />
                      <Input
                        className="searchinputaddchanb"
                        type="text"
                        name="UniversityName"
                        id="UniversityName"
                        placeholder="Enter Search Text"
                        value={this.state.searchString}
                        onChange={e =>
                          this.setState ({searchString: e.target.value}, () => {
                            this.handleChangeSearch ();
                          })}
                      />
                    </div>
                    <div className="filterByTextBusiness">
                    Business lookup by 

                      <label onClick={()=>{
                        this.setState({
                          searchByName: false
                        })
                      }}>
                        <input type="radio"  checked={!this.state.searchByName} style={{marginRight:10}} />
                        {' '}
                          Business ID

                      </label>
                      <label onClick={()=>{
                        this.setState({
                          searchByName: true
                        })
                      }}>
                        <input type="radio"  checked={this.state.searchByName}  style={{marginRight:10}}/>
                        {' '}
                        Business Name

                      </label>
                    </div>

                      {businessData &&
                        businessData.length > 0 &&
                        isSearch &&
                        <div>
                        <p className="foundResultsEdit"><span>{businessData.length} </span> results found:</p>
                        <div  className="searchBusinessListEdit">
                        {businessData.map ((item, index) => (
                          <div
                            key={index}
                            onClick={() => this.onSelect (item)}
                            style={{paddingLeft: 10}}
                            className="searchBusiness"
                          >
                            {item && item.registeredBusinessName
                              ? item.registeredBusinessName
                              : ''}
                          </div>
                        ))}
                        </div>
                        </div>}


                    {isSearch &&
                      businessData &&
                      businessData.length == 0 &&
                      <div className="searcherroraddchange">
                        No match found.
                      </div>}

                  </div>

                  <div
                    style={{textAlign: 'center'}}
                    className="businessButtonBar"
                  >
                    {isSearch &&
                      businessData &&
                      businessData.length == 0 &&
                      <Button
                        className="nextasearcagaibutton"
                        onClick={this.openAddChangelastModal}
                      >
                        Search Again
                      </Button>}

                    {isSearch &&
                      businessData &&
                      businessData.length == 0 &&
                      <Button className="nextregisternewbbutton" onClick={()=>this.props.history.push(routes.BUSINESS_REGISTER)}>
                        Register New Business
                      </Button>}

                    <Button
                      disabled={!selectedBusiness}
                      className="nextadchangebutton"
                      onClick={() =>
                        this.setState ({
                          searchBusinessStep: 2,
                        })}
                      data-rh="Next"

                    >
                      Next
                    </Button>

                  </div>
                </div>}
              {searchBusinessStep === 2 &&
                <div>
                  <div className="oneresultfou">
                    One result found:
                  </div>
                  <div className="onemoreresuktj">
                    {selectedBusiness && selectedBusiness.registeredBusinessName
                      ? selectedBusiness.registeredBusinessName
                      : ''}
                  </div>
                  <div>
                    <div style={{width: 500, margin: 'auto', paddingLeft: 30}}>
                      <div className="Registeredmodaltexc">
                        Registered Business Name
                      </div>
                      <div className="noneferftext2">
                        {selectedBusiness &&
                          selectedBusiness.registeredBusinessName
                          ? selectedBusiness.registeredBusinessName
                          : ''}
                      </div>
                      <div className="Registeredmodaltexc">
                        Business ID
                      </div>
                      <div className="noneferftext2">
                        {(selectedBusiness && selectedBusiness.businessID)
                    ? selectedBusiness.businessID
                       : 'N/A'}

                        

                      </div>
                      <div className="citycountryrow">
                        <div>
                          <div className="Countrylastmod">Country</div>
                          <div className="noneferftext2">
                            {selectedBusiness &&
                              selectedBusiness.businessCountry
                              ? selectedBusiness.businessCountry
                              : 'N/A'}
                          </div>
                        </div>
                        <div>
                          <div className="Citylastmodal">City</div>
                          <div className="noneferftext2">
                            {selectedBusiness && selectedBusiness.businessCity
                              ? selectedBusiness.businessCity
                              : 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{textAlign: 'center'}}
                      className="businessButtonBar"
                    >
                      <Button
                        className="submitbuttonlastmodal"
                        onClick={() => this.doSubmitAddBusiness ()}
                         data-rh="Submit"

                      >
                        Submit
                      </Button>

                    </div>
                  </div>

                </div>}
            </ModalBody>
          </Modal>
        </div>

        <div />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserDetail,
      Loading,
      uploadUserProfile,
      updateUser,
      AddEducationalDetail,
      DeleteEducationalDetail,
      removeBusiness,
      AddProfessionalDetail,
      DeleteProfessionalDetail,
      getProfileDashboard,
      getFindBusiness,
      ModifyPersonalInfo,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (editUserDetail);
