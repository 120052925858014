import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Input } from 'reactstrap';
import StarRatingComponent from 'react-star-rating-component';
import './marketplaceSidemenu.css';
import * as routes from '../../../../constants/routes';
import productDetails from '../../../../images/marketPlaceBuyer/productDetails.png';
import Searchamrke from '../../../../images/marketPlaceBuyer/Searchamrke.png';
import markefilter from '../../../../images/marketPlaceBuyer/markefilter.png';
import marketfiltercloseimg from '../../../../images/icons/marketfiltercloseimg.png';
import {
  getrecentViewHistory,
  getMarketPlaceActionOrder,
  marketPlaceReviews,
  getProductListing,
  fetchProductManufacturer,
} from '../../../../actions/marketplaceaction';
import { resetEducationDetail } from '../../../../actions/Authaction';
import { ShowNameAccordingToLength } from '../../../../utility/stringFunctions';
import { priceWithDecimal } from '../../../../utility/commonFunctions';

import moment from 'moment';

import { dataTimeFormat } from '../../../../utility/convertTimeString';
import { Dropdown } from 'primereact/dropdown';

const deviceType = [
  { label: 'MRI', value: 'MRI' },
  { label: 'CT', value: 'CT' },
];
const manufacture = [
  { label: 'Siemens Servicing', value: 'Siemens Servicing' },
  { label: 'Shimadzu Servicing', value: 'Shimadzu Servicing' },
  { label: 'GE Servicing', value: 'GE Servicing' },
];
const location = [
  { label: 'California', value: 'California' },
  { label: 'New york', value: 'New york' },
];
const price = [
  { label: '10', value: '10' },
  { label: '100', value: '100' },
  { label: '100,000', value: '100,000' },
  { label: '200,000', value: '200,000' },
];
const radiologyTypes = [
  { label: 'MRI Scanners', value: 'MRI Scanners' },
  { label: 'CT Systems', value: 'CT Systems' },
  { label: 'Ultrasounds', value: 'Ultrasounds' },
  { label: 'X-Ray Equipment', value: 'X-Ray Equipment' },
  { label: 'Refurbished Systems', value: 'Refurbished Systems' },
  {
    label: 'Medical Imaging Spare Parts',
    value: 'Medical Imaging Spare Parts',
  },
  { label: 'Ultrasound Probes', value: 'Ultrasound Probes' },
  { label: 'Everything else', value: 'Everything else' },
];
const nonradiologyTypes = [
  { label: 'Hand Sanitizers', value: 'Hand Sanitizers' },
  { label: 'Health and Personal Care', value: 'Health and Personal Care' },
  {
    label: 'Cleaning and Hygiene Products',
    value: 'Cleaning and Hygiene Products',
  },
  {
    label: 'Disinfectant Wipes and Sprays',
    value: 'Disinfectant Wipes and Sprays',
  },
  { label: 'Face Masks (N95, KN95)', value: 'Face Masks (N95, KN95)' },
  { label: 'Face Masks (Others)', value: 'Face Masks (Others)' },
  { label: 'Everything else', value: 'Everything else' },
];
class MarketPlaceSidemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentViewsList: [],
      reviewList: '',
      latestActionList: [],
      marketFilterBox: false,
      browsedeviceType: '',
      browselocation: '',
      browsemanufacture: '',
      browseprice: '',
      productCategoryType: '',
      priceItemCollection: [
        { label: '$0', value: '0' },
        { label: '$100', value: '100' },
        { label: '$1000', value: '1000' },
        { label: '$10000', value: '10000' },
        { label: '$50000', value: '50000' },
        { label: '$100000', value: '100000' },
        { label: '$200000', value: '200000' },
        { label: '$300000', value: '300000' },
        { label: '$400000', value: '400000' },
        { label: '$500000', value: '500000' },
        { label: '$600000', value: '600000' },
        { label: '$600000+', value: '600000+' },
      ],
      selectedMinIndex: 1,
      productCategoryTypeOptions: [],
      manufactureCollection: [],
      manufactureItemInfo: [],
      maxpriceValue: '',
      browseCategoryType: '',
      browseManufactureItemInfo: [],
      selectedBrowseMinIndex: 1,
      browseMaxpriceValue: '',
    };
  }

  componentDidMount() {
    let sendRequest = {
      data: {},
    };
    this.props.resetEducationDetail();
    this.props.fetchProductManufacturer();

    this.props.getrecentViewHistory(sendRequest);

    let getReviewPayload = {
      data: {
        offset: 0,
        limit: 30,
      },
    };
    this.props.marketPlaceReviews(getReviewPayload);

    let sendActionRequest = {
      data: {
        limit: 10,
        offset: 0,
        orderType: 'actionRequired',
      },
    };
    this.props.getMarketPlaceActionOrder(sendActionRequest);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.product && nextProps.product.manufactureItemValues) {
        const obj = nextProps.product.manufactureItemValues;
        let productCategoryTypeOptions = [];
        Object.keys(obj).map((key) => {
          productCategoryTypeOptions.push({ label: key, value: key });
        });
        this.setState({
          productCategoryTypeOptions: productCategoryTypeOptions,
          manufactureCollection: obj,
        });
      }
      if (nextProps.product && nextProps.product.recentViews) {
        this.setState({
          recentViewsList: nextProps.product.recentViews,
        });
      }
      if (nextProps.product && nextProps.product.usersReview) {
        this.setState(
          {
            reviewList: nextProps.product.usersReview,
          },
          () => {}
        );
      }
      if (nextProps.product && nextProps.product.placeActionOrderList) {
        this.setState({
          latestActionList: nextProps.product.placeActionOrderList,
        });
      }
    }
  }
  // placeActionOrderList

  openFilter = () => {
    this.setState({
      marketFilterBox: true,
    });
  };
  closeFilter = () => {
    this.setState({
      marketFilterBox: false,
    });
  };
  doBrowseSearch() {
    const {
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      browseCategoryType,
      browseMaxpriceValue,
    } = this.state;
    const sendRequest = {
      data: {
        productCategoryType: browseCategoryType,
        productType: browsedeviceType,
        manufacturer: browsemanufacture,
        location: browselocation,
        minPrice: browseprice,
        maxPrice: browseMaxpriceValue,
      },
    };

    // this.props.getProductListing (sendRequest);
    this.props.history.push(routes.BROWSE_PRODUCT, {
      searchObject: sendRequest.data,
    });
  }
  render() {
    const {
      recentViewsList,
      reviewList,
      latestActionList,
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      selectedMinIndex,
      browseCategoryType,
    } = this.state;

    return (
      <div>
        <div className="sidemenumaindiv" id="marketplaceSearchsidemenumaindiv1">
          {latestActionList && latestActionList.length > 0 && (
            <div
              className="quiringActionsedemn"
              id="marketplaceSearchsidemenumaindiv2"
            >
              Orders Requiring Action
            </div>
          )}
          {latestActionList &&
            latestActionList.length > 0 &&
            latestActionList.map((item, i) => (
              <div
                key={i}
                className="ordersrequiresedmen"
                onClick={() => {
                  // this.props.history.push (routes.PRODUCT_DETAIL + `/${item.docID}`)

                  window.location.href =
                    routes.PLACED_ORDERS_DETAILS + `/${item.docID}`;
                }}
                id={`marketplaceSearchsidemenumaindiv1${i}`}
              >
                <div>
                  <img
                    alt="productDetails"
                    src={
                      item &&
                      item.productDetails &&
                      item.productDetails[0] &&
                      item.productDetails[0].productImageList &&
                      item.productDetails[0].productImageList[0]
                        ? item.productDetails[0].productImageList[0]
                        : productDetails
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = productDetails;
                    }}
                    style={{ height: 90, width: 90, borderRadius: 10 }}
                    id={`marketplaceSearchsidemenumaindivimg${i}`}
                  />
                </div>
                <div
                  style={{ width: '100%' }}
                  id={`marketplaceSearchsidemenumaindivBox${i}`}
                >
                  <div>
                    <p
                      className="Siemenssedmekjpr"
                      id={`marketplaceSearchsidemenumaSiemenssedmekjpr${i}`}
                    >
                      {item &&
                      item.productDetails &&
                      item.productDetails[0] &&
                      item.productDetails[0]
                        ? item &&
                          item.productDetails[0].productCategoryType &&
                          item.productDetails[0].productCategoryType ===
                            'Radiology'
                          ? item.productDetails[0].deviceSaleName
                          : item.productDetails[0] &&
                            item.productDetails[0].productName
                          ? item.productDetails[0].productName
                          : 'N/A'
                        : 'N/A'}
                    </p>
                    <p
                      className="OrderID10sefv"
                      id={`marketplaceSearchsidemenuOrderID10sefv${i}`}
                    >
                      Order ID: {item && item.orderID ? item.orderID : 'N/A'}
                    </p>
                    <p
                      className="OrderPlacecdcdfvfd"
                      id={`marketplaceSearchsidemenuOrderPlace${i}`}
                    >
                      Quantity:
                      {item && item.productQuantity ? item.productQuantity : 0}
                    </p>
                    <p
                      className="OrderPlacecdcdfvfd"
                      id={`marketplaceSearchsidemenuOrderPlacecd${i}`}
                    >
                      Order Placed:
                      {item && item.orderDate
                        ? dataTimeFormat(item.orderDate)
                        : 'N/A'}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className="Awaitinsefkrnf"
                      id={`marketplaceSearchsidemenuAwaitinsefkrnf${i}`}
                    >
                      <p className="greenlightsedemnei" />
                      {item && item.orderStatus === 'seller confirmed'
                        ? 'Awaiting Payment'
                        : ''}
                      {item && item.orderStatus === 'shipped'
                        ? 'Confirm Delivery'
                        : ''}
                      {item &&
                      (item.orderStatus === 'completed' ||
                        item.orderStatus === 'delivered')
                        ? 'Leave Feedback'
                        : ''}
                    </div>
                    <div
                      className="US7scsdvf500"
                      id={`marketplaceSearchsidemenuUSDPrice${i}`}
                    >
                      USD${' '}
                      {item && item.totalOrderPrice
                        ? priceWithDecimal(item.totalOrderPrice)
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {this.props.isSearch && (
            <div>
              <div
                className="SearchMarksidemeni"
                id="marketplaceSearchsidemenusearch"
              >
                Search Marketplace
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 19,
                }}
                id="marketplaceSearchsidemenusearch1"
              >
                <div
                  className="searchmenuinptsidemn"
                  id="marketplaceSearchsidemenusearch2"
                >
                  <img
                    alt="Searchamrke"
                    src={Searchamrke}
                    style={{ height: 28, width: 28, marginLeft: 10 }}
                    id="marketplaceSearchsidemenusearch3"
                  />
                  <Input
                    className="tersearchsidemneu"
                    type="text"
                    placeholder="Enter search term"
                    id="marketplaceSearchsidemenusearch4"
                  />
                </div>
                {!this.state.marketFilterBox && (
                  <div className="filtclesedemni" onClick={this.openFilter}>
                    <img alt="markefilter" src={markefilter} />
                  </div>
                )}
              </div>

              <div
                className="marketFilterWrapper"
                id="marketplaceSearchsidemenusearch5"
              >
                {this.state.marketFilterBox && (
                  <div
                    className="marketficlosecircleMenu"
                    onClick={this.closeFilter}
                    style={{ margin: 'auto' }}
                    id="marketplaceSearchsidemenusearch6"
                  >
                    <img
                      alt="marketfiltercloseimg"
                      src={marketfiltercloseimg}
                    />
                  </div>
                )}
                {this.state.marketFilterBox && (
                  <div
                    className="marketfilterbox"
                    id="marketplaceSearchsidemenusearch7"
                  >
                    <p
                      className="dropdownlablemarket"
                      id="marketplaceSearchsidemenusearchcat8"
                    >
                      {' '}
                      Product Category
                    </p>
                    <Dropdown
                      className="marketsearcyinput"
                      placeholder="select"
                      value={this.state.productCategoryType}
                      options={this.state.productCategoryTypeOptions}
                      onChange={(e) =>
                        this.setState({ productCategoryType: e.value }, () => {
                          let manufactureItemInfo = [];
                          if (
                            this.state.manufactureCollection &&
                            this.state.manufactureCollection[
                              this.state.productCategoryType
                            ]
                          ) {
                            let res = Object.values(
                              this.state.manufactureCollection[
                                this.state.productCategoryType
                              ]
                            );
                            if (res && res.length > 0) {
                              for (let items of res) {
                                manufactureItemInfo.push({
                                  label: items,
                                  value: items,
                                });
                              }
                              this.setState({
                                manufactureItemInfo: manufactureItemInfo,
                              });
                            }
                          }
                        })
                      }
                      id="marketplaceSearchsidemenusearchcat9"
                    />
                    <p
                      className="dropdownlablemarket"
                      id="marketplaceSearchsidemenusearch8"
                    >
                      {' '}
                      Product Type
                    </p>
                    <Dropdown
                      className="marketsearcyinput"
                      placeholder="select"
                      value={this.state.deviceTypeValue}
                      // options={deviceType}
                      options={
                        this.state.productCategoryType
                          ? this.state.productCategoryType ===
                            'Radiology Products'
                            ? radiologyTypes
                            : nonradiologyTypes
                          : []
                      }
                      onChange={(e) =>
                        this.setState({ deviceTypeValue: e.value })
                      }
                      id="marketplaceSearchsidemenusearch9"
                    />

                    <p
                      className="dropdownlablemarket"
                      id="marketplaceSearchsidemenusearch10"
                    >
                      {' '}
                      Manufacturer
                    </p>
                    <Dropdown
                      className="marketsearcyinput"
                      placeholder="select"
                      value={this.state.manufactureValue}
                      // options={manufacture}
                      options={this.state.manufactureItemInfo}
                      onChange={(e) =>
                        this.setState({ manufactureValue: e.value })
                      }
                      id="marketplaceSearchsidemenusearch11"
                    />
                    <p
                      className="dropdownlablemarket"
                      id="marketplaceSearchsidemenusearch14"
                    >
                      Location
                    </p>
                    <Dropdown
                      className="marketsearcyinput"
                      placeholder="select"
                      value={this.state.locationValue}
                      options={location}
                      onChange={(e) =>
                        this.setState({ locationValue: e.value })
                      }
                      id="marketplaceSearchsidemenusearch15"
                    />

                    <p
                      className="dropdownlablemarket"
                      id="marketplaceSearchsidemenusearch12"
                    >
                      {' '}
                      Price Range (Min)
                    </p>
                    <Dropdown
                      className="marketsearcyinput"
                      placeholder="select"
                      value={this.state.priceValue}
                      options={this.state.priceItemCollection.slice(0, 10)}
                      onChange={(e) =>
                        this.setState({ priceValue: e.value }, () => {
                          var findItemindex = this.state.priceItemCollection
                            .map((img) => {
                              return img.value;
                            })
                            .indexOf(this.state.priceValue);
                          this.setState({
                            selectedMinIndex: findItemindex + 1,
                          });
                        })
                      }
                      id="marketplaceSearchsidemenusearch13"
                    />

                    <p
                      className="dropdownlablemarket"
                      id="marketplaceSearchsidemenusearch12"
                    >
                      {' '}
                      Price Range (Max)
                    </p>
                    <Dropdown
                      className="marketsearcyinput"
                      placeholder="select"
                      value={this.state.maxpriceValue}
                      options={this.state.priceItemCollection.slice(
                        selectedMinIndex,
                        20
                      )}
                      onChange={(e) =>
                        this.setState({ maxpriceValue: e.value })
                      }
                      id="marketplaceSearchsidemenusearchmax13"
                    />
                  </div>
                )}
              </div>
              <div className="diveidersiddemenu" />
              <div
                className="browsemarkwsedmenu"
                id="marketplaceSearchsidemenusearch16"
              >
                Browse Marketplace
              </div>
              <div
                className="browseMarketplaceWrapper"
                id="marketplaceSearchsidemenusearch17"
              >
                <Dropdown
                  className="marketBrowseinput"
                  placeholder="Category"
                  // value={browseCategoryType}
                  // options={deviceType}
                  // onChange={e => this.setState ({browseCategoryType: e.value})}
                  id="marketplaceSearchsidemenusearchCategory18"
                  value={browseCategoryType}
                  options={this.state.productCategoryTypeOptions}
                  onChange={(e) =>
                    this.setState({ browseCategoryType: e.value }, () => {
                      let browseManufactureItemInfo = [];
                      if (
                        this.state.manufactureCollection &&
                        this.state.manufactureCollection[
                          this.state.browseCategoryType
                        ]
                      ) {
                        let res = Object.values(
                          this.state.manufactureCollection[
                            this.state.browseCategoryType
                          ]
                        );
                        if (res && res.length > 0) {
                          for (let items of res) {
                            browseManufactureItemInfo.push({
                              label: items,
                              value: items,
                            });
                          }
                          this.setState({
                            browseManufactureItemInfo: browseManufactureItemInfo,
                          });
                        }
                      }
                    })
                  }
                />
                <Dropdown
                  className="marketBrowseinput"
                  placeholder="Device Type"
                  value={browsedeviceType}
                  // options={this.state.browseManufactureItemInfo}
                  options={
                    this.state.browseCategoryType
                      ? this.state.browseCategoryType === 'Radiology Products'
                        ? radiologyTypes
                        : nonradiologyTypes
                      : []
                  }
                  onChange={(e) => this.setState({ browsedeviceType: e.value })}
                  id="marketplaceSearchsidemenusearch18"
                />
              </div>
              <div
                className="browseMarketplaceWrapper"
                id="marketplaceSearchsidemenusearchss17"
              >
                <Dropdown
                  className="marketBrowseinput"
                  placeholder="Manufacturer"
                  value={browsemanufacture}
                  options={this.state.browseManufactureItemInfo}
                  onChange={(e) =>
                    this.setState({ browsemanufacture: e.value })
                  }
                  id="marketplaceSearchsidemenusearch21"
                />
                <Dropdown
                  className="marketBrowseinput"
                  placeholder="Location"
                  value={browselocation}
                  options={location}
                  onChange={(e) => this.setState({ browselocation: e.value })}
                  id="marketplaceSearchsidemenusearch19"
                />
              </div>
              <div
                className="browseMarketplaceWrapper"
                id="marketplaceSearchsidemenusearch20"
              >
                <Dropdown
                  className="marketBrowseinput"
                  placeholder="Price Min"
                  // value={browseprice}
                  // options={price}
                  // onChange={e => this.setState ({browseprice: e.value})}
                  value={browseprice}
                  options={this.state.priceItemCollection.slice(0, 10)}
                  onChange={(e) =>
                    this.setState({ browseprice: e.value }, () => {
                      var findItemindex = this.state.priceItemCollection
                        .map((img) => {
                          return img.value;
                        })
                        .indexOf(this.state.browseprice);
                      this.setState({
                        selectedBrowseMinIndex: findItemindex + 1,
                      });
                    })
                  }
                  id="marketplaceSearchsidemenusearch22"
                />

                <Dropdown
                  className="marketBrowseinput"
                  placeholder="Price Max"
                  value={this.state.browseMaxpriceValue}
                  options={this.state.priceItemCollection.slice(
                    this.state.selectedBrowseMinIndex,
                    20
                  )}
                  onChange={(e) =>
                    this.setState({ browseMaxpriceValue: e.value })
                  }
                  id="marketplaceSearchsidemenusearchbrowsemax13"
                />
              </div>
              <div
                className="browsebuttondisdemWrapper"
                id="marketplaceSearchsidemenusearch23"
              >
                <Button
                  id="marketplaceSearchsidemenusearch24"
                  data-rh="Browse"
                  className="browsebuttondisdem"
                  onClick={() => this.doBrowseSearch()}
                >
                  Browse{' '}
                </Button>
              </div>
            </div>
          )}

          {this.props.isSearch && <div className="diveidersiddemenu" />}
          {recentViewsList && recentViewsList.length > 0 && (
            <div id="marketplaceSearchsidemenusearch25">
              <div
                className="RecentlyViewed"
                id="marketplaceSearchsidemenusearch26"
              >
                Recently Viewed
              </div>
              {recentViewsList &&
                recentViewsList.map((item, i) => (
                  <div
                    key={i}
                    className="detailsdivforside"
                    onClick={() => {
                      // this.props.history.push (routes.PRODUCT_DETAIL + `/${item.docID}`)

                      window.location.href =
                        routes.PRODUCT_DETAIL + `/${item.docID}`;
                    }}
                    id={`marketplaceSearchsidemenusearchReview${i}`}
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>
                        <img
                          alt=""
                          src={
                            item &&
                            item.productImageList &&
                            item.productImageList[0]
                              ? item.productImageList[0]
                              : productDetails
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = productDetails;
                          }}
                          style={{
                            height: 90,
                            width: 90,
                            borderRadius: 10,
                            marginRight: 38,
                          }}
                          id={`marketplaceSearchsidemenusearchReviewimg${i}`}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p
                          className="Siemensside"
                          id={`marketplaceSearchsidemenusearchReviewProductname${i}`}
                        >
                          {item && item.productCategoryType !== 'Radiology'
                            ? item.productName
                            : item && item.deviceSaleName
                            ? item.deviceSaleName
                            : ''}
                        </p>
                        {item && item.productCategoryType === 'Radiology' && (
                          <p
                            className="DateoMaside"
                            id={`marketplaceSearchsidemenusearchReviewProType${i}`}
                          >
                            {' '}
                            Date of Manufacture :{' '}
                            {item && item.dateOfManufacture
                              ? moment(new Date(item.dateOfManufacture)).format(
                                  'MM/DD/YYYY'
                                )
                              : 'N/A'}
                          </p>
                        )}
                        {item && item.productCategoryType !== 'Radiology' && (
                          <p
                            className="DateoMaside"
                            id={`marketplaceSearchsidemenusearchReviewProductType${i}`}
                          >
                            {' '}
                            Product Type : {item && item.productCategoryType}
                          </p>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          id={`marketplaceSearchsidemenusearchReviewProductBox${i}`}
                        >
                          {item && item.productCategoryType === 'Radiology' && (
                            <p
                              className="locationSide"
                              id={`marketplaceSearchsidemenusearchReviewProductBoxlocal${i}`}
                            >
                              {item && item.location ? item.location : ''}
                            </p>
                          )}
                          {item && item.productCategoryType !== 'Radiology' && (
                            <p
                              className="locationSide"
                              id={`marketplaceSearchsidemenusearchReviewProductBoxloca${i}`}
                            >
                              {item && item.category ? item.category : ''}
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={{ alignSelf: 'center' }}>
                        <p
                          className="priceValueText"
                          id={`marketplaceSearchsidemenusearchReviewProductBoxUsd${i}`}
                        >
                          USD${' '}
                          {item && item.priceUSD
                            ? priceWithDecimal(item.priceUSD)
                            : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="diveidersiddemenu" />
            </div>
          )}
          <div className="MyLatessidem">My Latest Feedback</div>

          {reviewList &&
          reviewList.buyerReviews &&
          reviewList.buyerReviews.length > 0 ? (
            reviewList.buyerReviews.map((item, i) => (
              <div
                key={i}
                className="feedbacksidemenudiv"
                style={{ marginTop: 10 }}
                id={`marketplaceSearchsidemenusearchReviewListBox${i}`}
              >
                <div>
                  <img
                    alt=""
                    src={
                      item && item.reviewerProfilePictureURL
                        ? item.reviewerProfilePictureURL
                        : productDetails
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = productDetails;
                    }}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 50,
                      marginLeft: 10,
                    }}
                    id={`marketplaceSearchsidemenusearchReviewListBoximg${i}`}
                  />
                </div>
                <div
                  className="descriptionFeedback"
                  id={`marketplaceSearchsidemenusearchReviewListBoxFeed${i}`}
                >
                  <div
                    className="Beverlysidemen"
                    id={`marketplaceSearchsidemenusearchReviewListBoxReviewername${i}`}
                  >
                    {item && item.reviewerFirstName
                      ? ShowNameAccordingToLength(
                          item.reviewerFirstName + ' ' + item.reviewerLastName
                        )
                      : ''}

                    {/*item && item.reviewerLastName
                        ? item.reviewerLastName
                      : ''*/}
                  </div>
                  <div
                    className="Ordersedeminr"
                    id={`marketplaceSearchsidemenusearchReviewListBoxOrderid${i}`}
                  >
                    Order #{item && item.orderID ? item.orderID : 'N/A'}
                  </div>
                </div>
                <div
                  className="starRating"
                  id={`marketplaceSearchsidemenusearchReviewListBoxorderRating${i}`}
                >
                  <StarRatingComponent
                    id={`marketplaceSearchsidemenusearchReviewListBoxorderRatingContent${i}`}
                    name={'rating'}
                    starCount={5}
                    value={item && item.reviewScore ? item.reviewScore : 0}
                    emptyStarColor={'#E4E4E4'}
                    starColor={'#F5D028'}
                    style={{ fontSize: '80px', margin: 0 }}
                    //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="nosellerFeedback">No Feedback</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getrecentViewHistory,
      getMarketPlaceActionOrder,
      marketPlaceReviews,
      getProductListing,
      resetEducationDetail,
      fetchProductManufacturer,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketPlaceSidemenu);
