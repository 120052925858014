
import { ShowNameAccordingToLength } from './stringFunctions';
import { generateMessengerKey } from './commonFunctions';
import firebase from 'firebase';
import { sendP2PMessageForward, sendGroupChatMessageForward, shareButtonClickP2P, shareButtonClickGroupChat } from './forwardMessage';
import { userSharedKey, CalculatedSharedSecret, encryptMessage, decryptMessage } from './encryption'
import _ from 'lodash';

export const _handleForwardClick = (obj, props) => {

    let selectedContacts = obj.selectedcontacts,
        messagesArray = obj.selectedMessages,
        selectedGroups = obj.selectedGroups,
        // messengerList = obj.messengerList,
        decryptSecretKey = obj.decryptSecretKey;

    // console.log("obj>>>>>>>>_handleForwardClick", obj)

    // CONTACTS /// ITERATIONS
    selectedContacts.map(async (contact) => {
        let secretKey = await _handleP2PSecretKey(contact.contact.contactDetail.docID, props)
        //   console.log("secretKey>>>>>>>", secretKey)
        var oppositeUserName = ShowNameAccordingToLength(contact.contact.contactDetail.firstName + " " + contact.contact.contactDetail.lastName)
        let reqObj = {
            messengerKey: generateMessengerKey(props.userinfo.user.Data.userDetails[0].personalDetails.docID, contact.contact.contactDetail.docID),
            senderId: props.userinfo.user.Data.userDetails[0].personalDetails.docID,
            oppositeUserUid: contact.contact.contactDetail.docID,
            oppositeProfilePictureURL: contact.contact.contactDetail.profilePictureURL !== null
                ? contact.contact.contactDetail.profilePictureURL
                : '',
            oppositeUserDisplayName: oppositeUserName,
            secretKey: secretKey.toString(),
            decryptSecretKey: decryptSecretKey.toString()
        }
        //   console.log("reqObj>>>>>>>>>>>", reqObj);
        messagesArray.map(async (messageInfo) => {
            _.get(messageInfo, 'type', "") == "productLink"
                ? await _handleProductMessage(secretKey, messageInfo, contact.contact.contactDetail, props)
                : _.get(messageInfo, 'type', "") === "productsCatalgue"
                    ? _handleProductCatalogueForward()//forwarding PRODUCT CATALOGUE for P2P//
                    : await sendP2PMessageForward(reqObj, messageInfo, props)
        });
    })

    // GROUP ARRAY /// ITERATIONS
    selectedGroups.map(async (group) => {
        const conversationDetail = await fetchGroupDataFromFirebase(group.groupId);
        const { groupPublicKey, groupName, groupAdminUid, groupIcon, users, groupId, groupCreatedAt, groupCreaterName, groupCreaterId } = conversationDetail;
        console.log("conversationDetail>>>>>>", conversationDetail)
        let groupSecretKey = await _handleGroupSecretKey(groupPublicKey)
        console.log("groupSecretKey>>>>>>", groupSecretKey)

        let reqObj = {
            messengerKey: groupId,
            addedContactsArray: _.toArray(users),
            groupName: groupName,
            groupIcon: groupIcon,
            secretKey: groupSecretKey,
            decryptSecretKey: decryptSecretKey.toString()
        }

        messagesArray.map(async (messageInfo) => {
            _.get(messageInfo, 'type', "") === "productLink"
                ? await _handleProductMessageForGroup(groupSecretKey, messageInfo, conversationDetail, props)
                : _.get(messageInfo, 'type', "") === "productsCatalgue"
                    ? _handleProductCatalogueForwardForGroup()//forwarding PRODUCT CATALOGUE for GROUP//
                    : await sendGroupChatMessageForward(reqObj, messageInfo, props)
        });
    })

    if (selectedContacts.length + selectedGroups.length > 0) {
        //   props.navigation.goBack();
    } else {
        //   props.dispatch(ToastActionsCreators.displayInfo("Please select any contact or group"))
    }

}

const _handleP2PSecretKey = (oppositeUserUid, props) => {
    const database = firebase.database();
    let oppoUserID = oppositeUserUid;
    let UserOwnDocID = _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
    return new Promise(async (resolve) => {

        await database.ref(`Users/${UserOwnDocID}/onlineStatus`).on("value", async snapShot => {
            // console.log("snapShot>>>>> user own PUBLIC KEY", snapShot.child('publicKey').val())
            let userOwnPublicKey = snapShot.child('publicKey').val();
            // console.log("userOwnPublicKey>>>>>>>>>>", userOwnPublicKey)

            await database.ref(`Users/${oppoUserID}/onlineStatus`).on("value", snapShot => {
                let oppositeUserPublicKey = snapShot.child('publicKey').val();
                let oppositeSharedKey = userSharedKey(oppositeUserPublicKey)
                // console.log("oppositeSharedKey>>>>>>", oppositeSharedKey)
                let finalSecretKey = CalculatedSharedSecret(oppositeSharedKey, userOwnPublicKey)
                resolve(finalSecretKey);
                // console.log("oppositeSharedKey>>>>", oppositeSharedKey, "finalSecretKey>>>", finalSecretKey)
            })
        })

    })
}

// method to fetcg group details from firebase everytime when come in groupchat//
const fetchGroupDataFromFirebase = async (groupId) => {
    const database = firebase.database();
    const conversationRef = database.ref(`GroupChatDetail/${groupId}`);
    return new Promise((resolve) => {
        // Get details for this conversation id
        conversationRef.on("value", snapshot => {
            resolve(snapshot.val());
            // console.log("snapshot.val()>>>>>fetchGroupDataFromFirebase", snapshot.val())
        })
    });
}

const _handleGroupSecretKey = async (groupPublicKey) => {

    return new Promise((resolve) => {
        let groupSharedKey = userSharedKey(groupPublicKey)
        let finalSecretKey = CalculatedSharedSecret(groupSharedKey, groupPublicKey)
        resolve(finalSecretKey);
    });

}

const _handleProductMessage = async (secretKey, messageInfo, contactDetail, props) => {

    let productReqObj = {
        key: generateMessengerKey(props.userinfo.user.Data.userDetails[0].personalDetails.docID, contactDetail.docID),
        userOwnDetail: props.userinfo.user.Data.userDetails[0],
        oppositeUserUid: contactDetail.docID,
        oppositeProfilePictureURL: contactDetail.profilePictureURL !== null
            ? contactDetail.profilePictureURL
            : '',
        oppositeUserDisplayName: contactDetail.firstName + ' ' + contactDetail.lastName,
        secretKey: secretKey.toString(),
        productDetail: _.get(messageInfo, 'productDetail', {}),
        messageInfo: messageInfo

        //   props: props
    };
    // console.log("productReqObj>>>>>>", productReqObj)
    await shareButtonClickP2P(productReqObj)
}

const _handleProductMessageForGroup = async (secretKey, messageInfo, groupDetail, props) => {
    // console.log("messageInfo.key>>>>>", messageInfo.key, groupDetail.groupId)
    let productReqObj = {
        addedContactsArray: _.toArray(groupDetail.users),
        messengerKey: groupDetail.groupId,
        userOwnDetail: props.userinfo.user.Data.userDetails[0],
        groupName: groupDetail.groupName,
        groupIcon: groupDetail.groupIcon,
        groupDescription: "groupDescription",
        secretKey: secretKey.toString(),
        productDetail: _.get(messageInfo, 'productDetail', {}),
        messageInfo: messageInfo
    }
    // console.log("productReqObj>>>>>", productReqObj)
    await shareButtonClickGroupChat(productReqObj)

}

const _handleProductCatalogueForwardForGroup = async () => {

}

const _handleProductCatalogueForward = async () => {

}

export const copyToClipboard = (text, secretKey) => {
    let decryptedText = text !== null &&  text !== '' && text !== undefined ? decryptMessage(text.toString(), secretKey): ''
    // console.log('text>>>>>>>>copyToClipboard', decryptedText)
    var textField = document.createElement('textarea')
    textField.innerText = decryptedText
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  export const _handlelastSenderName = (messageInfo) => {
    let lastSenderName  = _.get(messageInfo, 'chatType', 'singleChat') === "groupChat"
     ? _.get(messageInfo, 'sendType') === 'forward'
       ? _.get(messageInfo, 'lastSenderName', '')
       : _.get(messageInfo, 'senderName', '')
     : _.get(messageInfo, 'sendType') === 'forward'
       ? _.get(messageInfo, 'lastSenderName', '')
       : _.get(messageInfo, 'senderName', '');
       return lastSenderName;
   }


  

