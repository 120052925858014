import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import moment from 'moment';
import {Dropdown} from 'primereact/dropdown';
import Resizer from 'react-image-file-resizer';

import {Input, Button, Progress, Modal, ModalBody} from 'reactstrap';

import profilePlaceholder from '../../images/icons/placeholderProfile.png';
import editWhite from '../../images/icons/editWhite.png';
import deleteWhite from '../../images/icons/deleteWhite.png';
import downWhiteArrow from '../../images/icons/downwhiteArrow.png';
import accountsuccess from '../../images/icons/accountsuccess.png';
import leftbackarrow from '../../images/icons/leftbackarrow.png';

import {
  getProfileDashboard,
  GetEducationalDetail,
  AddEducationalDetail,
  DeleteEducationalDetail,
  getProfessionalDetail,
  AddProfessionalDetail,
  DeleteProfessionalDetail,
} from '../../actions/Authaction';
import {
  getUserDetail,
  uploadUserProfile,
  updateUser,
} from '../../actions/contacts';
import {createNotification} from '../../constants/notificationtoast';
import InputCustom from '../InputCustom/InputCustom';

import './addUserProfile.css';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as routes from '../../constants/routes';
import ImageCrop from '../UI/ImageCrop';
import {IMAGE_TYPE_ERROR} from '../../constants/errorMessages';
import {CapitalizeFirstLetter } from '../../constants/helper';
import degreeTypeList from '../../constants/localJSON/degreeType.json';
import Calendar from '../../components/UI/calendar/calendar';


class AddUserProfileDetail extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      step: 1,
      modal: false,
      professionalmodal: false,
      ExperienceTypeList: [
        {label: 'Employed', value: 'Employed'},
        {label: 'Business', value: 'Business'},
        {label: 'Govt Service', value: 'Govt Service'},
        {label: 'Private', value: 'Private'},
      ],
      personalProfile: '',
      profileCompletion: '',
      address1: '',
      aboutMe: '',
      profilePicture: '',
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      displayName: '',
      searchHandle: '',
      position: '',
      base64Image: '',
      educationData: [],
      isSubmit: false,
      editEducationID: '',
      experienceType: '',
      firmName: '',
      //position:'',
      professionData: [],
      professionalposition: '',
      firmName: '',
      startDate: '',
      endDate: '',
      currentlyWorking: false,
      editProfessionalId: '',
      professionalTitle: '',
      isOpenStartPicker: false,
      isOpenEndPicker: false
    };
    this.openModal = this.openModal.bind (this);
    this.openProfessionalModal = this.openProfessionalModal.bind (this);
    this.goBack = this.goBack.bind (this);
    this.openStartDatepicker = React.createRef ();
    this.openEndDatepicker = React.createRef ();
  }
  openModal () {
    this.setState ({
      modal: !this.state.modal,
      editEducationID: '',
    });
  }
  openProfessionalModal () {
    this.setState ({
      professionalmodal: !this.state.professionalmodal,
      editProfessionalId: '',
      experienceType: '',
      firmName: '',
      professionalposition: '',
      startDate:  '',
      endDate: '',
      currentlyWorking: false,
    });
  }
  moveNext = () => {
    this.setState ({step: this.state.step + 1}, () => {
      this.setState({
        isSubmit: false
      })
      if (this.state.step === 2) {
        const sendRequest = {
          data: {},
        };
        this.props.GetEducationalDetail (sendRequest);
      }
      // if(this.state.step ===3){
      // const sendRequest = {
      //   data: {},
      // };
      // this.props.getProfessionalDetail (sendRequest);
      // }
    });
  };
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps.userInfo &&
        nextProps.userInfo.dashboardprofile &&
        nextProps.userInfo.dashboardprofile.educationDetails &&
        nextProps.userInfo.dashboardprofile.educationDetails
      ) {
        this.setState (
          {
            educationData: nextProps.userInfo.dashboardprofile.educationDetails,
            modal: false,
            yearCompleted: '',
            university: '',
            degreeType: '',
            courseMajor: '',
            isSubmit: false,
          },
          () => {
          
          }
        );
      }

      if (
        nextProps.contacts.uploadImage &&
        nextProps.contacts.uploadImage.profilePictureURL
      ) {
        this.setState (
          {
            // profilePicture:   nextProps.contacts.uploadImage.profilePictureURL
            profilePicture: this.state.base64Image,
          },
          () => {
          
          }
        );
      }

      if (nextProps.userInfo && nextProps.userInfo.dashboardprofile) {
        this.setState (
          {
            personalProfile: nextProps.userInfo.dashboardprofile
              .personalDetails,
            profileCompletion: nextProps.userInfo.dashboardprofile
              .profileCompletion,
          },
          () => {
            const {personalProfile} = this.state;
            this.setState ({
              title: personalProfile.title?personalProfile.title:'',
              firstName: personalProfile.firstName?personalProfile.firstName:'',
              lastName: personalProfile.lastName?personalProfile.lastName:'',
              emailAddress: personalProfile.emailAddress,
              phoneNumber: personalProfile.phoneNumber,
              displayName: personalProfile.displayName?personalProfile.displayName:'',
              searchHandle: personalProfile.searchHandle?personalProfile.searchHandle:'',
              address1: personalProfile.location?personalProfile.location:'',
              aboutMe: personalProfile.aboutMe?personalProfile.aboutMe:'',
              position: personalProfile.position?personalProfile.position:'',
              professionalTitle: personalProfile.professionalTitle?personalProfile.professionalTitle:'',
            });
            if (!this.state.base64Image) {
              this.setState ({
                profilePicture: personalProfile.profilePictureURL,
              });
            }
          }
        );
      }
      if (
        nextProps.userInfo &&
        nextProps.userInfo.dashboardprofile &&
        nextProps.userInfo.dashboardprofile.professionalDetails &&
        nextProps.userInfo.dashboardprofile.professionalDetails
      ) {
      
        this.setState ({
          professionData: nextProps.userInfo.dashboardprofile
            .professionalDetails,
          professionalmodal: false,
          experienceType: '',
          firmName: '',
          position: '',
          startDate: '',
          endDate: '',
          isSubmit: false,
        });
      }
    }
  }

  handleClickOutside = event => {

    if (
      this.openStartDatepicker.current &&
      !this.openStartDatepicker.current.contains (event.target)
    ) {
      this.setState ({
        isOpenStartPicker: false,
      });
    }

    if (
      this.openEndDatepicker.current &&
      !this.openEndDatepicker.current.contains (event.target)
    ) {
      this.setState ({
        isOpenEndPicker: false,
      });
    }


    
  }
  componentWillUnmount () {
    document.removeEventListener ('mousedown', this.handleClickOutside);
  }

  componentDidMount () {
    document.addEventListener ('mousedown', this.handleClickOutside);
    // if (
    //   this.props &&
    //   this.props.location &&
    //   this.props.location.state &&
    //   this.props.location.state &&
    //   this.props.location.state.activeWindow
    // ) {
    //   this.setState ({
    //     step: this.props.location.state.activeWindow,
    //   });
    // }
    let sendRequestUserProfile = {
      data: {},
    };
    this.props.getProfileDashboard (sendRequestUserProfile);

    // this.moveNext ();
  }

  updateProfile () {

    const {
      profileCompletion,
      profilePicture,
      title,
      firstName,
      lastName,
      emailAddress,
      passwordOne,
      phoneNumber,
      displayName,
      address1,
      position,
      searchHandle,
      aboutMe,
      professionalTitle,
    } = this.state;

    
    let checkValidation= !(firstName &&lastName);
    if(checkValidation){
      this.setState({
        isSubmit: true
      })
      return;
    }
    this.setState({
      isSubmit: false
    })

   

    let sendRequest = {
      data: {
        title: title?title:'',
        firstName: firstName?firstName:'',
        lastName: lastName?lastName:'',
        // displayName: displayName,
        searchHandle: searchHandle?searchHandle:'',
        aboutMe: aboutMe?aboutMe:'',
        location: address1?address1:'',
        position: position?position:'',
        professionalTitle: professionalTitle?professionalTitle:'',
      },
    };
    this.moveNext ();
    this.props.updateUser (sendRequest);
  }

  onChangeUploadPhoto = e => {
    // console.warn ('warning function call details');
    let files = e.target.files;
    let response = files[0].size / 1000000;
    // console.log(Math.floor(response));

    if (response && response > 5) {
      createNotification (
        'error',
        'The uploaded file is too large. Profile can only be a maximum of 5 MB.'
      );
      return false;
    }

    const reader = new FileReader ();
    reader.addEventListener ('load', () => {
      this.setState ({
        loadedImage: reader.result,
        filename: files[0].name,
      });
    });
    reader.readAsDataURL (e.target.files[0]);

    // console.warn ('warning function call details');
    // // let files = e.target.files;
    // // console.log(files);
    // let reader = new FileReader ();
    // reader.readAsDataURL (files[0]);
    // reader.onload = e => {
    //   //   this.setState (byPropKey ('profilePicture', e.target.result));
    //   this.setState ({
    //     base64Image: e.target.result,
    //   });
    //   let sendRequest = {
    //     data: {
    //       profilePicture: e.target.result,
    //       filename: files[0].name,
    //       contentType: 'image/jpeg',
    //     },
    //   };
    //   this.props.uploadUserProfile (sendRequest);
    // };
  };

  uploadUserImage (croppedImage) {
    Resizer.imageFileResizer (
      croppedImage,
      300,
      300,
      'JPEG',
      400,
      0,
      uri => {
        console.log (uri);
        const {filename} = this.state;
        this.setState (
          {
            base64Image: uri,
          },
          () => {
            let sendRequest = {
              data: {
                profilePicture: uri,
                filename: filename,
                contentType: 'image/jpeg',
              },
            };
            this.props.uploadUserProfile (sendRequest);
          }
        );
      },
      'base64'
    );
  }

  AddEducationalDetail () {
    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          yearCompleted,
          university,
          degreeType,
          courseMajor,
          editEducationID,
        } = this.state;
        //a boolen to perform validation
        const isInvalid =
          yearCompleted === '' ||
          university === '' ||
          degreeType === '' ||
          courseMajor === '' ||
          (university && university.length > 100) ||
          (courseMajor && courseMajor.length > 100);

        if (isInvalid) {
          return false;
        }

        const {history} = this.props;

        const sendRequest = {
          data: {
            yearCompleted: this.state.yearCompleted,
            university: this.state.university,
            degreeType: this.state.degreeType,
            courseMajor: this.state.courseMajor,
            docID: editEducationID ? editEducationID : '',
          },
        };
        this.props.AddEducationalDetail (sendRequest);
      }
    );
  }

  deleteEducationDetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteEducationalDetail (data);
  };
  openEdit (item) {
    this.setState ({
      editEducationID: item.docID,
      yearCompleted: item.yearCompleted,
      university: item.university,
      degreeType: item.degreeType,
      courseMajor: item.courseMajor,
      modal: true,
    });
  }

  AddProfessionalDetail () {
    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          experienceType,
          firmName,
          professionalposition,
          startDate,
          endDate,
          editProfessionalId,
          currentlyWorking,
        } = this.state;

        const isInvalid =
          experienceType === '' ||
          firmName === '' ||
          professionalposition === '' ||
          professionalposition.length > 100 ||
          firmName.length > 100;

        if (!currentlyWorking) {
          if (startDate === '' || endDate === '') {
            return false;
          }
        }

        if (isInvalid) {
          return false;
        }

        let sendRequest = {
          data: {
            experienceType: this.state.experienceType,
            firmName: this.state.firmName,
            position: this.state.professionalposition,
            startDate: this.state.startDate,
            endDate: currentlyWorking ? '' : this.state.endDate,
            currentlyWorking: this.state.currentlyWorking,
            docID: editProfessionalId ? editProfessionalId : '',
          },
        };
        // console.log (sendRequest);
        this.props.AddProfessionalDetail (sendRequest);
      }
    );
  }

  deleteProfessionaldetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteProfessionalDetail (data);
  };
  editProfessional (item) {
    this.setState ({
      professionalmodal: true,
      experienceType: item.experienceType,
      firmName: item.firmName,
      professionalposition: item.position,
      startDate: item.startDate ? new Date (item.startDate) : '',
      endDate: item.endDate ? new Date (item.endDate) : '',
      currentlyWorking: item.currentlyWorking,
      editProfessionalId: item.docID,
    });
  }
  goBack () {
    if (this.state.step === 1) {
      this.props.history.goBack ();
      return;
    }
    this.setState ({
      step: this.state.step - 1,
    });
  }

  render () {
    const {
      profileCompletion,
      profilePicture,
      title,
      firstName,
      lastName,
      emailAddress,
      passwordOne,
      phoneNumber,
      displayName,
      address1,
      position,
      searchHandle,
      aboutMe,
      educationData,
      yearCompleted,
      university,
      degreeType,
      courseMajor,
      isSubmit,
      experienceType,
      firmName,
      // position,
      startDate,
      endDate,
      professionData,
      professionalposition,
      currentlyWorking,
      editProfessionalId,
      editEducationID,
      professionalTitle,
      loadedImage,
      isOpenStartPicker,
      isOpenEndPicker
    } = this.state;

    const completeProfileProgress=<div>{this.state.step < 4 &&
      <div className="CompleteUse">
        Complete User Profile
        <Progress
          value={
            profileCompletion && profileCompletion.percentageComplete
              ? profileCompletion.percentageComplete
              : 0
          }
          className="completeProfileProgressbar"
        />
      </div>}
      </div>

    return (
      <div className="rootaddUserContainer">
        <div style={{textAlign: 'right'}}>
          <Button
            className="stepGoBackIcon"
            onClick={() => this.goBack ()}
            data-rh="Go back"
          >
            <div>
              <img src={leftbackarrow} alt="backIcon" />
            </div>

            Go <span> Back</span>
          </Button>
        </div>

        <div className="addUSerContainer">

          

          {this.state.step === 1 &&
            <div className="blackBoxProfile" style={{padding: 30, margin: 'auto'}}>
              {completeProfileProgress}
              <div className="GENERALDETA">
                GENERAL DETAILS
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                      <p className="addtphetit">Title</p>
                      {/*<Input
                        type="select"
                        name="title"
                        id="select"
                        className="inpuadduse"
                        value={title}
                        onChange={e => this.setState ({title: e.target.value})}
                      >
                        <option xs="12" lg="4" value="">Dr.</option>
                        <option xs="12" lg="4" value="Mr.">Mr.</option>
                        <option xs="12" lg="4" value="Dr.">Dr.</option>
                        <option xs="12" lg="4" value="Ms.">Ms.</option>
                        <option xs="12" lg="4" value="Mrs.">Mrs.</option>
                      </Input>*/}

                      <div
                        style={{width: '88px', height: '49px', marginRight: 15}}
                      >
                        <div className="profileUserTitle">{title?title:''}</div>
                        {/* <InputCustom
                          value={title}
                          onSelect={value => {
                            this.setState ({title: value});
                          }}
                          options={[
                            {name: 'Mr.', value: 'Mr.'},
                            {name: 'Dr.', value: 'Dr.'},
                            {name: 'Ms.', value: 'Ms.'},
                            {name: 'Mrs.', value: 'Mrs.'},
                          ]}
                        /> */}

                      </div>
                      {/*!title &&
                        isSubmit &&
                        <p className="validationerrorMessage">
                        {' '}Title is required *{' '}
                    </p>*/}
                    </div>
                    <div>
                      <p className="addtphetit">First Name</p>
                      <input
                        type="text"
                        name=""
                        id=""
                        className="inpuadduse2"
                        placeholder="Enter First Name"
                        value={CapitalizeFirstLetter(firstName)}
                        // onChange={e =>
                        //   this.setState ({firstName: e.target.value})}
                        readOnly={true}
                      />

                      {!firstName &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                        {' '}First name is required *{' '}
                    </p>}
                    </div>
                    <div>
                      <p className="addtphetit">Last Name</p>
                      <input
                        type="text"
                        name=""
                        // id="select"
                        className="inpuadduse2"
                        placeholder="Enter Last Name"
                        value={CapitalizeFirstLetter(lastName)}
                        // onChange={e =>
                        //   this.setState ({lastName: e.target.value})}
                          readOnly={true}
                      />

                       {!lastName &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                        {' '}Last name is required *{' '}
                    </p>}
                    </div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                      <p className="addtphetit">Location</p>
                      <input
                        type="text"
                        name=""
                        // id="select"
                        className="inpuadduse3"
                        placeholder="Enter Location"
                        value={CapitalizeFirstLetter(address1)}
                        onChange={e =>
                          this.setState ({address1: e.target.value})}
                      />

                      {/* {!address1 &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                        {' '}Location is required *{' '}
                    </p>} */}
                    </div>
                    <div>
                      <p className="addtphetit">Professional Title</p>
                      <input
                        type="text"
                        name=""
                        // id="select"
                        className="inpuadduse3"
                        placeholder="Professional Title"
                        style={{fontStyle: 'italic'}}
                        value={CapitalizeFirstLetter(professionalTitle)}
                        onChange={e =>
                          this.setState ({professionalTitle: e.target.value})}
                      />

                      {/* {!professionalTitle &&
                        isSubmit &&
                        <p className="validationerrorMessage">
                          Professional title is required *
                        </p>} */}
                    </div>
                  </div>
                  <div>

                    {/*!title &&
                  isSubmit &&
               <p className="validationerrorMessage">
                {' '}Title is required *{' '}
               </p>*/}
                  </div>
                </div>
                <div style={{marginLeft: 20}}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 27,
                    }}
                  >
                    <div>
                      <img
                        alt="profilePicture"
                        src={
                          profilePicture ? profilePicture : profilePlaceholder
                        }
                        className="placeholder"
                      />
                    </div>
                    <div>
                      <label
                        className="uploasscxd"
                        style={{marginLeft: 120}}
                        data-rh="Select File"
                      >
                        <input
                          type="file"
                          name="profilePicture"
                          onChange={e => this.onChangeUploadPhoto (e)}
                        />
                        <i className="fa fa-cloud-upload" />Upload
                      </label>

                      {loadedImage &&
                        <ImageCrop
                          image={loadedImage}
                          onSubmitClick={croppedImage => {
                       
                            this.setState ({
                              loadedImage: '',
                            });

                            this.uploadUserImage (croppedImage);
                          }}
                        />}

                    </div>
                  </div>
                  <div style={{marginTop: 32}}>
                    <div className="placetexj">
                      Display Profle Picture
                    </div>
                    <div className="placetexj2">
                      Please upload a photo of type JPEG or PNG
                    </div>
                  </div>
                  <div>
                   

                    <p className="addtphetit" style={{display:'inline-flex',paddingLeft:0}}> <div className="searchHandleProfile">@</div> Preferred Search Handle</p>
                    <input
                      type="text"
                      className="inpuadduse4"
                      value={CapitalizeFirstLetter(searchHandle)}
                      // onChange={e =>
                      //   this.setState ({searchHandle: e.target.value})}

                      pattern="[a-zA-Z0-9]*"

                      onChange={evt => {
                        const searchHandle = evt.target.validity.valid
                          ? evt.target.value
                          : this.state.searchHandle;
                        this.setState ({
                          searchHandle,
                        });
                      }}


                    />

                    {/*!title &&
       isSubmit &&
       <p className="validationerrorMessage">
           {' '}Title is required *{' '}
       </p>*/}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <p className="addtphetit">About Me</p>
                  <textarea
                    placeholder="Tell us about yourself"
                    name=""
                    // id="select"
                    className="inpuadduse5"
                    value={CapitalizeFirstLetter(aboutMe)}
                    onChange={e => this.setState ({aboutMe: e.target.value})}
                  />

                  {/*!title &&
            isSubmit &&
         <p className="validationerrorMessage">
          {' '}Title is required *{' '}
         </p>*/}
                </div>
                <div style={{textAlign: 'center'}}>
                  <Button
                    className="dzcdxloadon"
                    onClick={() => {
                      this.updateProfile ();
                     
                    }}
                    data-rh="Next"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>}
          {this.state.step === 2 &&<div  className="blackBoxProfile" >
            <div style={{padding: 30}} className="innerContentCompleteProfile">
            {completeProfileProgress}
              <div   style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop:20

              }}>
             
                <div>
                  <div className="EDUCATIONAL">
                    <span>EDUCATIONAL </span> DETAILS
                  </div>
                  <div className="Noeducati">
                    {educationData && educationData.length === 0
                      ? 'No educational details added.'
                      : 'Edit or add to your educational history.'}
                  </div>

                </div>
                <div>
                  <div>
                    {educationData && educationData.length === 0
                      ? <Button
                          className="addedxuhdk"
                          onClick={this.openModal}
                          data-rh="Add new"
                        >
                          Add Education Details
                        </Button>
                      : <Button
                          className="adnewbutton"
                          onClick={this.openModal}
                          data-rh="Add new"
                        >
                          Add New
                        </Button>}

                  </div>
                  {educationData &&
                    educationData.length ===0 && <div
                    className="DDsssLA"
                    onClick={() => this.moveNext ()}
                    data-rh="skip"
                  >
                    ADD LATER{' '}
                  </div>}

                </div>
              </div>
              <div style={{marginTop: 20}}>
                {educationData &&
                  educationData.length > 0 &&
                  educationData.map ((item, index) => (
                    <div className="detailsduicnd" key={index}>
                      <div style={{display: 'flex'}}>
                        <div className="ss2000">
                          {item && item.yearCompleted
                            ? item.yearCompleted
                            : 'N/A'}
                        </div>
                        <div className="ss2000">
                          {' '}
                          {item && item.degreeType ? item.degreeType : 'N/A'}
                        </div>
                      </div>
                      <div className="Universitzxcd">
                        {item && item.university ? item.university : 'N/A'}
                      </div>
                      <div>
                        <Button
                          className="actionButtonIcon"
                          onClick={() => this.openEdit (item)}
                          data-rh="Edit"
                        >
                          <img style={{width: 22}} src={editWhite} alt="edit" />
                        </Button>
                        <Button
                          className="actionButtonIcon"
                          onClick={() => this.deleteEducationDetail (item)}
                          data-rh="Delete"
                        >
                          <img
                            style={{width: 17}}
                            src={deleteWhite}
                            alt="delete"
                          />
                        </Button>

                      </div>
                    </div>
                  ))}
              </div>
              {educationData &&
                educationData.length > 0 &&
                <div style={{textAlign: 'center'}}>
                  <Button
                    className="dzcdxloadon"
                    onClick={() => this.moveNext ()}
                    data-rh="Next"
                  >
                    Next
                  </Button>
                </div>}
            </div></div>}
          {this.state.step === 3 &&<div  className="blackBoxProfile" >
          <div style={{padding: 30}} className="innerContentCompleteProfile">
          {completeProfileProgress}
           
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop:20
                }}
              >
                <div>
                  <div className="EDUCATIONAL">
                    <span>PROFESSIONAL </span> DETAILS
                  </div>
                  <div className="Noeducati">
                    {professionData && professionData.length === 0
                      ? ' No experience added.'
                      : 'Edit or add to your professional history.'}
                  </div>

                </div>
                <div>
                  <div>
                    {professionData && professionData.length === 0
                      ? <Button
                          className="addedxuhdk"
                          onClick={this.openProfessionalModal}
                          data-rh="Add new"
                        >
                          Add Work Experience
                        </Button>
                      : <div>
                          <Button
                            className="adnewbutton"
                            onClick={this.openProfessionalModal}
                            data-rh="Add new"
                          >
                            Add New
                          </Button>
                        </div>}
                  </div>
                  {professionData &&
                    professionData.length ===0 &&<div
                    className="DDsssLA"
                    onClick={() => this.moveNext ()}
                    data-rh="Skip"
                  >
                    ADD LATER{' '}
                  </div>}

                </div>
              </div>
              <div>
                {professionData &&
                  professionData.length > 0 &&
                  professionData.map ((item, index) => (
                    <div key={index} className="detailsduicnd">
                      <div style={{display: 'flex'}}>
                        <div className="ss2000">

                          {item && item.startDate
                            ? moment (new Date (item.startDate)).format (
                                'MM/YY'
                              )
                            : ''}
                          {' - '}
                          {item &&
                            item.currentlyWorking  &&
                            <span>Current</span>}

                          {item &&
                            item.endDate &&
                            !item.currentlyWorking 
                            ? moment (new Date (item.endDate)).format ('MM/YY')
                            : ''}
                        </div>
                        <div className="ss2000">
                          {' '}  {item && item.position ? item.position : 'N/A'}
                        </div>
                      </div>
                      <div className="Universitzxcd">
                        {item && item.firmName ? item.firmName : 'N/A'}
                      </div>
                      <div>
                        <Button
                          className="actionButtonIcon"
                          onClick={() => this.editProfessional (item)}
                          data-rh="Edit"
                        >
                          <img style={{width: 22}} src={editWhite} alt="edit" />
                        </Button>
                        <Button
                          className="actionButtonIcon"
                          onClick={() => this.deleteProfessionaldetail (item)}
                          data-rh="Delete"
                        >
                          <img
                            style={{width: 17}}
                            src={deleteWhite}
                            alt="delete"
                          />
                        </Button>

                      </div>
                    </div>
                  ))}
              </div>

              {professionData &&
                professionData.length > 0 &&
                <div style={{textAlign: 'center'}}>
                  <Button
                    className="dzcdxloadon"
                    onClick={() =>
                      this.setState ({
                        step: 4,
                      })}
                    data-rh="Next"
                  >
                    Next
                  </Button>
                </div>}
            </div>
            </div>
          }
          {this.state.step === 4 &&
            <div style={{padding: 30, paddingTop: 100}}>

              <div style={{textAlign: 'center'}}>
                <img
                  style={{width: 100}}
                  src={accountsuccess}
                  alt="submitIcon"
                />
              </div>
              <div className="infosubmt">Information Submitted!</div>
              <div className="Thankyou">
                {' '}Thank you! Your details have been recorded.
              </div>
              <div style={{textAlign: 'center'}}>
                <Button
                  className="dzcdxloadon"
                  onClick={() =>
                    this.props.history.push (routes.PROFILE_DASHBOARD)}
                  data-rh="Return to Dashboard"
                >
                  Return to Dashboard
                </Button>
              </div>
            </div>}

        </div>

        <Modal
          isOpen={this.state.modal}
          centered={true}
          toggle={this.openModal}
          size="lg"
        >
          <ModalBody className="eduaddmo">
            <div>
              <div className="ADDEDUCATscd">

                <span>{editEducationID ? 'EDIT ' : 'ADD '} </span>

                EDUCATIONAL DETAIL
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="addtphetitmo">University Name</p>
                  <input
                    //   type="text"
                    name=""
                    // id="select"
                    className="inpuaddusemo"
                    value={CapitalizeFirstLetter(this.state.university)}
                    onChange={e => {
                      this.setState ({university: e.target.value});
                    }}
                  />

                  {!university &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      University name is required *
                    </p>}
                  {university &&
                    university.length > 100 &&
                    <p className="validationerrorMessage">
                      {' '}Maximum limit 100 characters
                    </p>}
                </div>
                <div>
                  <p style={{paddingLeft: 25}} className="addtphetitmo">
                    Year Completed
                  </p>
                  <div style={{height: 50}} className="mainYearSelectDiv">

                    <Dropdown
                      className="yearshjscinput"
                      value={yearCompleted}
                      options={[
                        {label: '2020', value: '2020'},
                        {label: '2019', value: '2019'},
                        {label: '2018', value: '2018'},
                        {label: '2017', value: '2017'},
                        {label: '2016', value: '2016'},
                        {label: '2015', value: '2015'},
                        {label: '2014', value: '2014'},
                        {label: '2013', value: '2013'},
                        {label: '2012', value: '2012'},
                        {label: '2011', value: '2011'},
                        {label: '2010', value: '2010'},
                        {label: '2009', value: '2009'},
                        {label: '2008', value: '2008'},
                        {label: '2007', value: '2007'},
                        {label: '2006', value: '2006'},
                        {label: '2005', value: '2005'},
                        {label: '2004', value: '2004'},
                        {label: '2003', value: '2003'},
                        {label: '2002', value: '2002'},
                        {label: '2001', value: '2001'},
                      ]}
                      onChange={e => this.setState ({yearCompleted: e.value})}
                    />
                  </div>
                  {/*<div style={{height:50}}>
                  <InputCustom
                  value={yearCompleted}
                  onSelect={value => {
                    console.log (value);
                    this.setState ({yearCompleted:value})
                  }}
                  options={[
                    {name: '2020', value: '2020'},
                    {name: '2019', value: '2019'},
                    {name: '2018', value: '2018'},
                    {name: '2017', value: '2017'},
                    {name: '2016', value: '2016'},
                    {name: '2015', value: '2015'},
                    {name: '2014', value: '2014'},
                    {name: '2013', value: '2013'},
                    {name: '2012', value: '2012'},
                    {name: '2011', value: '2011'},
                    {name: '2010', value: '2010'},
                    {name: '2009', value: '2009'},
                    {name: '2008', value: '2008'},
                    {name: '2007', value: '2007'},
                    {name: '2006', value: '2006'},
                    {name: '2005', value: '2005'},
                    {name: '2004', value: '2004'},
                    {name: '2003', value: '2003'},
                    {name: '2002', value: '2002'},
                    {name: '2001', value: '2001'},
                  ]}
                />
                </div>*/}
                  {/*<input
                    type="text"
                    name=""
                    // id="select"
                    className="inpuaddusemo2"
                    value={yearCompleted}
                    maxLength="4"
                    onChange={e =>
                      this.setState (
                        {yearCompleted:  e.target.value}
                      )}
                      />*/}
                  {!yearCompleted &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Completion Year  is required *
                    </p>}

                </div>
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="addtphetitmo">Degree Type</p>
                  <div className="mainYearSelectDiv">
                  <Dropdown
                      style={{width:300,marginLeft:5}}
                      className="yearshjscinput"
                      value={this.state.degreeType}
                      options={degreeTypeList}
                      onChange={e => this.setState ({degreeType: e.value})}
                    />
                    </div>
                  {/*<input
                    type="text"
                    name=""
                    className="inpuaddusemo3"
                    value={this.state.degreeType}
                    onChange={e => this.setState ({degreeType: e.target.value})}
                  />*/}
                  {!degreeType &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      {' '}Degree type is required *
                    </p>}

                </div>
                <div>
                  <p className="addtphetitmo">Major Course</p>
                  <input
                    style={{marginLeft: 14}}
                    //   type="text"
                    name=""
                    className="inpuaddusemo3"
                    value={CapitalizeFirstLetter(this.state.courseMajor)}
                    onChange={e =>
                      this.setState ({courseMajor: e.target.value})}
                  />
                  {!courseMajor &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Major courses is required *
                    </p>}
                  {courseMajor &&
                    courseMajor.length > 100 &&
                    <p className="validationerrorMessage">
                      {' '}Maximum limit 100 characters
                    </p>}
                </div>
              </div>
              <div
                style={{textAlign: 'center'}}
                
              >
                <Button className="buttobvh" onClick={() => this.AddEducationalDetail ()}>
                  {editEducationID ? 'Save Changes' : 'Add to Profile'}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.professionalmodal}
          centered={true}
          toggle={this.openProfessionalModal}
          size="lg"
        >
          <ModalBody className="eduaddmo">
            <div>
              <div className="ADDEDUCATscd">
                <span> {editProfessionalId ? 'EDIT ' : 'ADD '} </span> PROFESSIONAL DETAIL
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="addtphetitmo">Name of Firm</p>
                  <input
                    type="text"
                    // id="select"
                    className="inpuaddusemo3"
                    value={CapitalizeFirstLetter(firmName)}
                    onChange={e => this.setState ({firmName: e.target.value})}
                  />
                  {!firmName &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Firm name is required *
                    </p>}
                  {firmName &&
                    firmName.length > 100 &&
                    <p className="validationerrorMessage">
                      {' '}Maximum limit 100 characters
                    </p>}
                </div>
                <div>
                  <p className="addtphetitmo">Experience Type</p>
                  {/*} <div className="dropdownborder">
                    <select className="selectDropdown"  value={this.state.experienceType}
                    onChange={e =>
                      this.setState (
                        {experienceType: e.target.value
                        }
                      )}>
                      {this.state.ExperienceTypeList &&
                        this.state.ExperienceTypeList.map ((item, index) => (
                          <option key={index} xs="12" lg="4" value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                    <img src={downWhiteArrow} alt="downarrow" />
                        </div>*/}

                  <Dropdown
                    className="selectDropdown"
                    value={experienceType}
                    options={this.state.ExperienceTypeList}
                    onChange={e => this.setState ({experienceType: e.value})}
                  />
                  {!experienceType &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Experience type is  required *
                    </p>}

                  {/*!title &&
                isSubmit &&
            <p className="validationerrorMessage">
            {' '}Title is required *{' '}
            </p>*/}
                </div>
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <p className="addtphetitmo">Position</p>
                  <input
                    type="text"
                    name=""
                    className="positionInput"
                    value={CapitalizeFirstLetter(professionalposition)}
                    onChange={e =>
                      this.setState ({professionalposition: e.target.value})}
                  />
                  {!professionalposition &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Position is required *
                    </p>}
                  {professionalposition &&
                    professionalposition.length > 100 &&
                    <p className="validationerrorMessage">
                      {' '}Maximum limit 100 characters
                    </p>}
                </div>
                <div style={{display: 'inline-flex'}}>
                  <div className="dateDiv" style={{position:'relative'}}  ref={this.openStartDatepicker}>
                    <p className="addtphetitmo">Start Date</p>
                    
                    <div  className="professionalDateinput"  onClick={() =>
                      this.setState ({
                        isOpenStartPicker: !isOpenStartPicker,
                      })}
                      >{this.state.startDate?moment(this.state.startDate).format('MM/YY'):'MM/YY'}</div>
                    {isOpenStartPicker && <div style={{position:'absolute'}}>
                        <Calendar 
                          // minDate={sendDate} 
                          maxDate={currentlyWorking ? new Date() : (endDate?endDate: new Date())}
                         selectedDate={this.state.startDate} 
                        onSubmit={(value)=>{
                          this.setState({
                            isOpenStartPicker: false
                          })
                          if(value){
                            this.setState ({
                              startDate: value,
                              endDate: '',
                            });
                          }
                          }} 
                        />
                  </div>}
                    {!startDate &&
                      isSubmit &&
                      !currentlyWorking &&
                      <p className="validationerrorMessage">
                        Start Date is required *
                      </p>}
                  </div>
                  <div className="dateDiv" style={{position:'relative'}}  ref={this.openEndDatepicker}>
                    <p className="addtphetitmo">End Date</p>
                    
                    <div  className="professionalDateinput"  onClick={() =>
                      this.setState ({
                        isOpenEndPicker: !isOpenEndPicker,
                      })}
                      >{currentlyWorking ? '' : this.state.endDate?moment(this.state.endDate).format('MM/YY'):'MM/YY'}</div>
  
  
                      {isOpenEndPicker && <div style={{position:'absolute'}}>
                          <Calendar 
                            minDate={this.state.startDate}
                            maxDate={new Date ()}
                           selectedDate={this.state.endDate} 
                          onSubmit={(value)=>{
                            this.setState({
                              isOpenEndPicker: false
                            })
                            if(value){
                              this.setState ({
                                endDate: value,
                                currentlyWorking: false,
                                // endDate: '',
                              });
                            }
                            }} 
                          />
                    </div>}
                    {!endDate &&
                      isSubmit &&
                      !currentlyWorking &&
                      <p className="validationerrorMessage">
                        End Date is required *
                      </p>}
                  </div>
                </div>
              </div>
              {/*<label className="currentlyworking">
                <input
                  type="radio"
                  style={{marginRight: 15}}
                  checked={currentlyWorking}
                  value={this.state.currentlyWorking}
                  onClick={e => {
                    this.setState (
                      {
                        currentlyWorking: !this.state.currentlyWorking,
                      },
                      () => {
                        if (this.state.currentlyWorking) {
                          this.setState ({
                            endDate: '',
                          });
                        }
                      }
                    );
                  }}
                />
                {' '}
                I’m currently employed here

                </label>*/}
              <div className="currentlyWorkinghere" style={{marginTop:10}}>
                    <input type="radio"
                    value={currentlyWorking}
                    checked={currentlyWorking}
                    name="currentlyEmployed"
                    id="currentlyWorkingprof"  name="radio-group" onClick={()=> {
                      this.setState ({
                        currentlyWorking: !currentlyWorking,
                        endDate: '',
                      });
                    }}/>
                    <label for="currentlyWorkingprof" > <span className="Imcurren">I’m currently employed here </span></label>
                  </div>
              <div style={{textAlign: 'center'}}>
                <Button
                  className="buttobvh"
                  onClick={() => this.AddProfessionalDetail ()}
                >

                  {editProfessionalId ? 'Save Changes ' : ' Add to Profile '}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getProfileDashboard,
      updateUser,
      uploadUserProfile,
      GetEducationalDetail,
      AddEducationalDetail,
      DeleteEducationalDetail,
      getProfessionalDetail,
      AddProfessionalDetail,
      DeleteProfessionalDetail,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  AddUserProfileDetail
);
