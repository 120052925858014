import firebase from 'firebase';
import _ from 'lodash'
// import { sendUserNotification } from 'action/notification'
import {
  addNotificationHistory,
  sendnotification,
  fetchMessengerList
} from '../../actions/Messenger';
import { ShowNameAccordingToLength, capitalfirstLetter } from "../../utility/stringFunctions";
import { userSharedKey, CalculatedSharedSecret, encryptMessage } from '../../utility/encryption';

// HANDLED DELETED MESSAGES FOR P2P AND GROUP CHAT
export const getUserChatDeleteDetail = async (obj, callback) => {
  let messengerKey = obj.messengerKey,
    userUid = obj.userUid,
    groupChat = obj.groupChat;

  let chatThread = groupChat ? 'GroupMessages' : "Messages"

  const database = firebase.database();

  let ObjectOfDeleteDetail = {};
  return new Promise(async (resolve) => {
    await database
      .ref(`${chatThread}/${messengerKey}/usersDetail`)
      .on('value', async val => {
        // console.log("val>>>>>>>onceonfocussss>>>> userDetail", val.val())
        let usersWhoDeletedChatArray = _.get(val.val(), 'deletedBy', []);
        callback(usersWhoDeletedChatArray)// returning users array who deleted chat
        let objectTobeFound = usersWhoDeletedChatArray.find((o) => o.uid === userUid);
        if (objectTobeFound !== undefined) {
          ObjectOfDeleteDetail = objectTobeFound
        }
        resolve(ObjectOfDeleteDetail);

      })/// delete chat ends here

  })
}

// HANDLE ANY USER IS TYPING FOR P2P AND GROUP CHAT
export const handleUserTypingInfo = async (obj, callback) => {
  const database = firebase.database();

  let messengerKey = obj.messengerKey,
    groupChat = obj.groupChat;

  let chatThread = groupChat ? 'GroupMessages' : "Messages";

  //   let userTypingInfo = {
  //     userTypingId: null,
  //     isUserTyping: false,
  //     userName: "noUser"
  //   };

  await database
    .ref(chatThread)
    .child(messengerKey)
    .child('isTyping').on('value', async (snapshot) => {
      callback(snapshot.val())
    })
}

// this function is used to delete the group conversation for current user,
//but chat will be availble for all other user and in the firebase for lifetime
export const _handleDeleteConversation = (obj, callback) => {

  const database = firebase.database();

  let messengerKey = obj.messengerKey,
    userIdWhoDelete = obj.userIdWhoDelete,
    chatDeletedUsersArray = _.uniqBy([...obj.chatDeletedUsersArray], e => e.uid),
    isUserInDeleteChatList = obj.isUserInDeleteChatList,
    groupChat = obj.groupChat;

  let chatThread = groupChat ? 'GroupMessages' : "Messages"
  let newArrayOfUsersDeletedChat = [];
  if (chatDeletedUsersArray.length > 0 && isUserInDeleteChatList) {
    newArrayOfUsersDeletedChat = chatDeletedUsersArray.map((val) => {
      if (val.uid == userIdWhoDelete) {
        return { ...val, deletionOn: Math.floor(Date.now()) }
      } else {
        return val
      }
    })
  } else {
    chatDeletedUsersArray.push(
      { uid: userIdWhoDelete, deletionOn: Math.floor(Date.now()) }
    )
  }

  let ObjTobeUpdated = {
    usersDetail: {
      deletedBy: newArrayOfUsersDeletedChat.length > 0
        ? newArrayOfUsersDeletedChat
        : chatDeletedUsersArray
    }
  }
  database.ref(`${chatThread}/${messengerKey}`).update(ObjTobeUpdated);
  callback();
}

// FETCH GROUP and P2P CHAT MESSAGES
export const fetchMessages = async (obj, props, messagePushCallback, dontShowLoadMoreCallback) => {

  let threadKey = obj.threadKey,
    deletionOnVal = obj.deletionOnVal,
    limitToFetchMessages = obj.limitToFetchMessages,
    groupChat = obj.groupChat;

  let chatThread = groupChat ? 'GroupMessages' : "Messages"
  // console.log("deletionOnVal>>>>>", deletionOnVal)
  const database = firebase.database();

  await database.ref(chatThread)
    .child(threadKey)
    .orderByChild('timeStamp')
    .limitToLast(limitToFetchMessages)
    .on('child_added', snapshot => {

      // console.log("fetch method in child_add >>>>>>>", snapshot.val())
      var item = snapshot.val();
      item.key = snapshot.key;
      // console.log("fetch method in child_add  item>>>>>>>", item)
      if (deletionOnVal !== null) {
        let timestampOfMessage = _.get(item, 'timeStamp', null);
        if (timestampOfMessage !== null && timestampOfMessage > deletionOnVal) {
          // console.log("after if deletionOnVal>>", snapshot.child(`readBy/${_.get(props.userinfo,'user.Data.userDetails[0].personalDetails.docID', null)}`).exists())

          if (_.get(item, 'sender', null) // to check the Message seen status
            && _.get(item, 'sender') !== _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
            && !(snapshot.child(`readBy/${_.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)}`).exists())
          ) {
            // console.log("inside the firebase read statsus")
            {/* *** updating the message info , means who have seen this with pic and name  ****** */ }
            let objToUpdate = {
              userId: _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null),
              userName: capitalfirstLetter(_.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.firstName', '')) + " " + _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.lastName', ''),
              userProfile: _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', null),
              read: true,
              readOn: Math.floor(Date.now())
            }
            database.ref(`${chatThread}/${threadKey}/${snapshot.key}/readBy/${_.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)}`).update(objToUpdate);
          }

          messagePushCallback(item)
        } else if (timestampOfMessage !== null && timestampOfMessage < deletionOnVal) {
          dontShowLoadMoreCallback()

        }
      } else {

        if (_.get(item, 'sender', null) // to check the Message seen status
          && _.get(item, 'sender') !== _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
          && !(snapshot.child(`readBy/${_.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)}`).exists())
        ) {
          // console.log("inside the firebase read statsus")
          {/* *** updating the message info , means who have seen this with pic and name  ****** */ }
          let objToUpdate = {
            userId: _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null),
            userName: capitalfirstLetter(_.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.firstName', '')) + " " + _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.lastName', ''),
            userProfile: _.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.profilePictureURL', null),
            read: true,
            readOn: Math.floor(Date.now())
          }
          database.ref(`${chatThread}/${threadKey}/${snapshot.key}/readBy/${_.get(props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)}`).update(objToUpdate);
        }

        messagePushCallback(item)
      }

    });

  // child have changed starts here
  await database.ref(chatThread)
    .child(threadKey)
    .on('child_changed', snapshotChangedChild => {
      // console.log("child_changed >>>>>> snapshot>>>>", snapshotChangedChild.val());
      var changedChild = snapshotChangedChild.val();
      changedChild.key = snapshotChangedChild.key;
      // console.log("changedChild>>>>>>>> child", changedChild)
      if (_.get(changedChild, 'key', null) !== 'isTyping' && _.get(changedChild, 'key', null) !== 'usersDetail') {
        if (deletionOnVal !== null) {
          let timestampOfMessage = _.get(changedChild, 'timeStamp', null);
          if (timestampOfMessage !== null && timestampOfMessage > deletionOnVal) {
            messagePushCallback(changedChild, true)
          } else if (timestampOfMessage !== null && timestampOfMessage < deletionOnVal) {
            dontShowLoadMoreCallback()
          }
        } else {
          messagePushCallback(changedChild, true)
        }
      }

    }) // child have changed ends here
}

// HANDLE limit  to select contacts or groups for fowwarding message
export const getLimitContactsorGroupsTobeSelected = async () => {
  const database = firebase.database();

  return new Promise(async (resolve) => {
    await database
      .ref(`Limits/limitToselectContactsOrGroups`)
      .on('value', async snap => {
        // console.log("val>>>>>>>getLimitContactsorGroupsTobeSelected>>>> userDetail", snap.val())

        resolve(snap.val());

      })/// delete chat ends here
    database.ref(`Limits/limitToselectContactsOrGroups`).off("value");

  })
}

// HANDLE limit for no of forward messsages
export const getLimitForForwardMessages = async () => {
  const database = firebase.database();

  return new Promise(async (resolve) => {
    await database
      .ref(`Limits/limitToselectMessagesToForward`)
      .on('value', async snap => {
        // console.log("val>>>>>>>getLimitForForwardMessages>>>> userDetail", snap.val())

        resolve(snap.val());

      })/// delete chat ends here
    database.ref(`Limits/limitToselectMessagesToForward`).off("value");

  })
}

// HANDLE limit  to select contacts or groups for fowwarding message
export const setLimits = async () => {
  const database = firebase.database();

  // await database
  // .ref(`Limits`).update({
  //     "limitToselectContactsOrGroups": 5 // to set limit for select no.of contacts or groups to be selected
  // })

  // await database
  // .ref(`Limits`).update({
  //     "limitToselectMessagesToForward": 10 // to set limit for select no.of contacts or groups to be selected
  // })

}
