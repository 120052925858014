import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Input, Button, Progress, Modal, ModalBody } from 'reactstrap';
import './userKyc.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as routes from '../../constants/routes';
import { Dropdown } from 'primereact/dropdown';
import { Loading } from '../../actions/LoadingAction';
import { createNotification } from '../../constants/notificationtoast';

import {
  getProfileDashboard,
  sendUserInfoKYC,
  onfidoResumeChecksAction,
  onfidoGetSDKTokenForApplicantAction,
  resetApplicantToken,
} from '../../actions/Authaction';
import accountSuccess from '../../images/icons/accountsuccess.png';

import axios from 'axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import leftbackarrow from '../../images/icons/leftbackarrow.png';

import Nationality from '../../constants/localJSON/kyc/Nationality';
import usaStates from '../../constants/localJSON/kyc/USAstates';
import indiaStates from '../../constants/localJSON/kyc/INDIAstates';
import chinaStates from '../../constants/localJSON/kyc/CHINAstates';
import ukStates from '../../constants/localJSON/kyc/UKstates';

// import countryJSON from '../../constants/localJSON/countryList.json';
import countryFlags from '../../constants/localJSON/countryList';

import Calendar from '../../components/UI/calendar/calendar';

import { init } from 'onfido-sdk-ui';
var Onfido = require('onfido-sdk-ui');
const proxyurl = 'https://cors-anywhere.herokuapp.com/';
const onfidoBaseURL = 'https://api.us.onfido.com/v3/';
// const onfidoBaseURL = 'https://api.onfido.com/v3/';

class UserKycPage extends Component {
  // defining state
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      profileCompletion: '',
      genderDropDopwn: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
      ],
      titleDropDopwn: [
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mrs.', value: 'Mrs.' },
      ],
      residenceDropDopwn: [
        { label: 'India', value: 'IND' },
        { label: 'United States', value: 'USA' },
        { label: 'United Kingdom', value: 'GBR' },
        { label: 'China', value: 'CHN' },
      ],
      nationalityDropDopwn: [],
      usaStateDropDopwn: [],
      indianStateDropDopwn: [],
      titleValue: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      nationalityValue: '',
      counteryResidence: '',
      isSubmit: false,
      address1: '',
      address2: '',
      state: '',
      postalCode: '',
      applicantID: '',
      applicantToken: '',
      dateOfBirth: '',
      isCallKYC: false,
      isOpenDobPicker: false,
      ukStateDropDopwn: [],
      chinaStateDropDopwn: [],
      inOnfidoLoaded: false,
      filterCountry: '',
      countryCode: '+1',
      countryFlag:
        countryFlags && countryFlags.length > 0
          ? countryFlags[234].countryFlag
          : '',
      userPhone: '',
    };

    this.openDobDatepicker = React.createRef();
  }
  nextScreen = () => {
    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const {
          titleValue,
          firstName,
          lastName,
          email,
          phone,
          gender,
          nationalityValue,
          counteryResidence,
          dateOfBirth,
          state,
          postalCode,
          address1,
          address2,
          userPhone,
        } = this.state;
        const isInvalid =
          !titleValue ||
          !firstName ||
          !lastName ||
          !email ||
          !userPhone ||
          !gender ||
          !nationalityValue ||
          !counteryResidence ||
          !address1 ||
          // !address2 ||
          !state ||
          !postalCode ||
          !dateOfBirth;

        if (isInvalid) {
          return;
        }

        this.setState(
          {
            step: 2,
          },
          () => {
            this.setState(
              {
                applicantID: this.props.userInfo.user.Data.userDetails[6]
                  .applicantID,
              },
              () => {
                // alert( this.state.applicantID);

                let params = {
                  data: {
                    // applicationID: this.state.applicantID,
                    referrer: `*://*/*`,
                  },
                };

                this.props.onfidoGetSDKTokenForApplicantAction(params);
              }
            );

            // this.fetchApplicationId ();
          }
        );

        // let sendRequest={
        //   data:{
        //     title:titleValue,
        //     firstName:firstName,
        //     lastName:lastName,
        //     emailAddress:email,
        //     phoneNumber:phone,
        //     gender:gender,
        //     nationality:Nationality,
        //     countryOfResidence:counteryResidence,
        //   }
        // }
        // this.props.sendUserInfoKYC(sendRequest);
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userInfo && nextProps.userInfo.successKYC) {
      this.setState({
        step: 3,
      });
    }

    // console.log(nextProps.userInfo);

    if (
      nextProps.userInfo &&
      nextProps.userInfo.user &&
      nextProps.userInfo.user.Data &&
      nextProps.userInfo.user.Data.userDetails
    ) {
      if (
        nextProps.userInfo.user.Data.userDetails.length > 6 &&
        nextProps.userInfo.user.Data.userDetails[6]
      ) {
        //  this.setState({
        //   applicantID:nextProps.userInfo.user.Data.userDetails[6].applicantID
        //   },()=>{
        //     if( this.state.step===2){
        //       let params = {
        //         data: {
        //           applicationID: this.state.applicantID
        //         }
        //       }
        //       this.props.onfidoGetSDKTokenForApplicantAction(params);
        //     }
        //   })
      }
    }

    if (nextProps.userInfo && nextProps.userInfo.applicantKYCToken) {
      this.setState(
        {
          applicantToken: nextProps.userInfo.applicantKYCToken,
        },
        () => {
          if (this.state.step === 2 && this.state.inOnfidoLoaded === false) {
            console.log('logggs');

            this.setState(
              {
                inOnfidoLoaded: true,
              },
              () => {
                this.initiateScan();
              }
            );
            // this.initiateScan ();
          }
        }
      );
    }

    if (nextProps.userInfo && nextProps.userInfo.dashboardprofile) {
      this.setState(
        {
          personalProfile: nextProps.userInfo.dashboardprofile.personalDetails,
          profileCompletion:
            nextProps.userInfo.dashboardprofile.profileCompletion,
        },
        () => {
          const { personalProfile } = this.state;
          if (this.state.step === 1) {
            this.setState(
              {
                titleValue: personalProfile.title,
                firstName: personalProfile.firstName,
                lastName: personalProfile.lastName,
                email: personalProfile.emailAddress,
                phone: personalProfile.phoneNumber
                  ? personalProfile.phoneNumber
                  : '',
                gender: personalProfile.gender ? personalProfile.gender : '',
                nationalityValue: personalProfile.nationality
                  ? personalProfile.nationality
                  : '',
                address1: personalProfile.address1
                  ? personalProfile.address1
                  : '',
                address2: personalProfile.address2
                  ? personalProfile.address2
                  : '',
                counteryResidence: personalProfile.countryOfResidence
                  ? personalProfile.countryOfResidence
                  : '',
                dateOfBirth: personalProfile.dateOfBirth
                  ? new Date(personalProfile.dateOfBirth)
                  : '',
                state: personalProfile.state ? personalProfile.state : '',
                postalCode: personalProfile.postalCode
                  ? personalProfile.postalCode
                  : '',
                filterCountry: personalProfile.state
                  ? personalProfile.state
                  : '',
                countryCode: personalProfile.countryCode
                  ? personalProfile.countryCode
                  : '',
                userPhone: personalProfile.localPhoneNumber
                  ? personalProfile.localPhoneNumber
                  : '',
              },
              () => {
                if (countryFlags && countryFlags.length > 0) {
                  let index = countryFlags.findIndex((items) =>
                    items.dial_code
                      ? items.dial_code === this.state.countryCode
                      : ''
                  );
                  if (index > -1) {
                    this.setState({
                      countryFlag: countryFlags[index].countryFlag,
                    });
                  }
                }
              }
            );
          }
        }
      );
    }
  }

  handleClickOutside = (event) => {
    if (
      this.openDobDatepicker.current &&
      !this.openDobDatepicker.current.contains(event.target)
    ) {
      this.setState({
        isOpenDobPicker: false,
      });
    }
  };
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // sortBy(){
  //   return function (a, b) {
  //     var nameA = a.name.toLowerCase (),
  //       nameB = b.name.toLowerCase ();
  //     if (
  //       nameA < nameB //sort string ascending
  //     )
  //       return -1;
  //     if (nameA > nameB) return 1;
  //     return 0;
  //   };
  // }
  componentDidMount() {
    // let chinaList=[];
    // console.log(ukStates);
    // for(let item of ukStates){
    //   chinaList.push({name: item.name, code: item.name, region: item.region})
    // }
    // console.log(JSON.stringify(chinaList.sort (this.sortBy ())));

    document.addEventListener('mousedown', this.handleClickOutside);
    let nationalityItems = [];
    for (let items of Nationality) {
      nationalityItems.push({ label: items.label, value: items.label });
    }

    let usaStateDropDopwn = [];
    for (let items of usaStates) {
      usaStateDropDopwn.push({ label: items.name, value: items.code });
    }
    let indianStateDropDopwn = [];
    for (let items of indiaStates) {
      indianStateDropDopwn.push({ label: items.name, value: items.code });
    }
    let chinaStateDropDopwn = [];
    for (let items of chinaStates) {
      chinaStateDropDopwn.push({ label: items.name, value: items.code });
    }
    let ukStateDropDopwn = [];
    for (let items of ukStates) {
      ukStateDropDopwn.push({ label: items.name, value: items.code });
    }
    // console.log(indianStateDropDopwn)
    // console.log(usaStateDropDopwn)

    this.setState({
      nationalityDropDopwn: nationalityItems,
      indianStateDropDopwn: indianStateDropDopwn,
      ukStateDropDopwn: ukStateDropDopwn,
      chinaStateDropDopwn: chinaStateDropDopwn,
      usaStateDropDopwn: usaStateDropDopwn,
    });
    this.props.resetApplicantToken();
    let sendRequestUserProfile = {
      data: {},
    };
    this.props.getProfileDashboard(sendRequestUserProfile);

    // this.props.onfidoResumeChecksAction(sendRequestUserProfile)
    // this.fetchApplicationId();
  }

  fetchApplicationId() {
    const { firstName, lastName, email, dateOfBirth } = this.state;
    const url = proxyurl + onfidoBaseURL + 'applicants';
    // const url =  onfidoBaseURL + 'applicants';

    const sendRquest = {
      first_name: firstName,
      last_name: lastName,
      dob: dateOfBirth,
      email: email,
      // email:'shubhamvishwakarma19@gmail.com',
      // address: { }
    };
    // const sendRquest = {
    //   first_name: "shubham",
    //   last_name: "kumar",
    //   dob: "20-01-2015",
    //   // email: email,
    //   email:'shubhamvishwakarma19@gmail.com',
    //   // address: { }
    // };
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization:
          'Token token=api_sandbox_us.czLKtq6MG4M.hS_yzsD0e5VE6SDNiiBRwZwWfMKWltH9',
      },
      data: JSON.stringify(sendRquest),
      url,
    };

    this.props.Loading(true);
    axios(options)
      .then((res) => {
        // console.log (res);
        if (res && res.data) {
          this.setState(
            {
              applicantID: res.data.id,
            },
            () => {
              let params = {
                data: {
                  applicationID: this.state.applicantID,
                },
              };

              this.props.onfidoGetSDKTokenForApplicantAction(params);
              // this.getAuthToken ();
            }
          );
        }
        // do good things
      })
      .catch((err) => {
        this.props.Loading(false);
        if (err && err.message) {
          createNotification('error', err.message);
        }
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  }

  // getAuthToken () {
  //   // alert (window.location.href);
  //   // alert(`https://${window.location.hostname}/home/userkyc`)
  //   const url = proxyurl + onfidoBaseURL + 'sdk_token';
  //   // console.log ('applicantID ' + this.state.applicantID);
  //   const sendRquest = {
  //     applicant_id: this.state.applicantID,
  //     referrer:`*://*/*`
  //     // referrer:`https://*.${window.location.hostname}:3000/home/userkyc*`
  //     // referrer:window.location.href
  //     // referrer: window.location.hostname === 'localhost'
  //     //   ? `http://${window.location.hostname}:3000/home/userkyc`
  //     //   : `https://${window.location.hostname}/home/userkyc`,
  //   };
  //   // "referrer": "http://localhost:3000/home/userkyc"
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       'content-type': 'application/json',
  //       Authorization: 'Token token=api_sandbox_us.czLKtq6MG4M.hS_yzsD0e5VE6SDNiiBRwZwWfMKWltH9',
  //     },
  //     data: JSON.stringify (sendRquest),
  //     url,
  //   };

  //   axios (options)
  //     .then (res => {
  //       // console.log (res);
  //       this.props.Loading (false);

  //       if (res && res.data) {
  //         this.setState (
  //           {
  //             applicantToken: res.data.token,
  //           },
  //           () => {
  //             this.initiateScan ();
  //           }
  //         );
  //       }
  //       // do good things
  //     })
  //     .catch (err => {
  //       this.props.Loading (false);
  //       if (err && err.message) {
  //         createNotification ('error', err.message);
  //       }

  //       if (err.response) {
  //         // client received an error response (5xx, 4xx)
  //       } else if (err.request) {
  //         // client never received a response, or request never left
  //       } else {
  //         // anything else
  //       }
  //     });
  // }

  async initiateScan() {
    // alert(this.state.applicantToken);
    Onfido.init({
      token: this.state.applicantToken,
      // token:'eyJhbGciOiJIUzI1NiJ9.eyJwYXlsb2FkIjoiNVg2cTFtSElPN0hpSWkxb1NPcGZDeFZnUSt2SllmLzZFUnZOZzFld3pMSlg4VFNzVTdEb0VmdkJCMElZXG5RTU1KcDRUTFY3dWJEdVRsTFV3NWtvVFhKYm9UNHpDbVE2R1VCU0pOV0RnZzluaUNCVmIyVkdPeFVNcVNcbnpSVGx3cncwXG4iLCJ1dWlkIjoiY3pMS3RxNk1HNE0iLCJleHAiOjE1OTUwNTg4ODUsInVybHMiOnsib25maWRvX2FwaV91cmwiOiJodHRwczovL2FwaS51cy5vbmZpZG8uY29tIiwidGVsZXBob255X3VybCI6Imh0dHBzOi8vdGVsZXBob255LnVzLm9uZmlkby5jb20iLCJkZXRlY3RfZG9jdW1lbnRfdXJsIjoiaHR0cHM6Ly9zZGsudXMub25maWRvLmNvbSIsInN5bmNfdXJsIjoiaHR0cHM6Ly9zeW5jLm9uZmlkby5jb20iLCJob3N0ZWRfc2RrX3VybCI6Imh0dHBzOi8vaWQub25maWRvLmNvbSJ9fQ.TWbujcoue8N7k9HkMSCXKzlGiFILzfvn83ZtwHQeQbs',
      containerId: 'onfido-mount',
      // steps: [
      //   'welcome',
      //   'document',
      //   'face',
      //   'complete'
      // ],
      steps: [
        {
          type: 'welcome',
          options: {
            title: 'Welcome to R-DEE  \n KYC Verification!',
            descriptions: [
              'Please provide your details in the following steps, and keep your government issued IDs ready.',
            ],
            nextButton: 'Start Verification',
          },
        },
        {
          type: 'document',
          options: {
            documentTypes: {
              passport: true,
              driving_licence: true,
              national_identity_card: false,
            },
          },
        },
        'face',
        'complete',
      ],
      hideOnfidoLogo: {
        options: {
          enterpriseFeatures: {
            hideOnfidoLogo: true,
          },
        },
      },
      onComplete: (data) => {
        // console.log (data);
        console.log('everything is complete');
        const {
          titleValue,
          firstName,
          lastName,
          email,
          phone,
          gender,
          nationalityValue,
          counteryResidence,
          address1,
          address2,
          state,
          postalCode,
          dateOfBirth,
          userPhone,
          countryCode,
        } = this.state;

        let sendRequest = {
          data: {
            title: titleValue,
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            // phoneNumber: phone,
            phoneNumber: countryCode + userPhone,
            nationality: nationalityValue,
            dateOfBirth: moment(dateOfBirth).format('YYYY/MM/DD'),
            countryOfResidence: counteryResidence,
            address1: address1,
            address2: address2 ? address2 : '',
            state: state,
            postalCode: postalCode,
            gender: gender,
          },
        };
        if (!address2) {
          delete sendRequest.data.address2;
        }
        // console.log (sendRequest);

        this.props.sendUserInfoKYC(sendRequest);
        // tell your backend service that it can create the check
        // when creating a facial similarity check, you can specify
        // whether you want to start a `facial_similarity_photo` check
        // or a `facial_similarity_video` check based on the value within `data.face.variant`
      },
      onError: (error) => {
        console.log('Error something going wrong');

        console.log(error);
        if (error && error.message) {
          createNotification('error', error.message);
        }
      },
    });
  }

  render() {
    const {
      profileCompletion,
      genderDropDopwn,
      titleDropDopwn,
      titleValue,
      firstName,
      lastName,
      email,
      phone,
      gender,
      nationalityValue,
      counteryResidence,
      residenceDropDopwn,
      isSubmit,
      address1,
      address2,
      state,
      postalCode,
      dateOfBirth,
      step,
      nationalityDropDopwn,
      usaStateDropDopwn,
      indianStateDropDopwn,
      isOpenDobPicker,
      ukStateDropDopwn,
      chinaStateDropDopwn,
      filterCountry,
      countryCode,
      countryFlag,
      userPhone,
    } = this.state;

    const lowercasedFilter = filterCountry.toLowerCase();
    const filteCountryData = countryFlags.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    const completeKYC = (
      <div className="Completekyc" id="kycProgressBox">
        <div>Complete KYC Verification</div>
        <Progress
          value={step * 33.33}
          className="kyvProgressbar"
          id="kycProgressBar"
        />
      </div>
    );
    return (
      <div>
        <div
          style={{
            textAlign: 'right',
            paddingRight: 5,
            position: 'absolute',
            right: 0,
            top: 129,
          }}
        >
          <Button
            id="kycGoBackIcon"
            className="kycstepGoBackIcon"
            onClick={() => {
              if (step === 1) {
                this.props.history.goBack();
              } else {
                this.setState({
                  step: step - 1,
                });
              }
            }}
            data-rh="Go back"
          >
            <div>
              <img src={leftbackarrow} alt="backIcon" />
            </div>
            Go <span> Back</span>
          </Button>
        </div>

        <div className="stepmaindiv" id="kycVerification1">
          {this.state.step === 1 && (
            <div className="personalInfoBox" id="kycVerification2">
              {completeKYC}
              <div id="kycVerification3">
                <div className="Personaldetky" id="kycVerification4">
                  <span>PERSONAL </span> INFORMATION
                </div>
                <div className="inputFieldBoxes" id="kycVerification5">
                  <div id="kycVerification6">
                    <p className="titlekyc" id="kycVerificationtitle">
                      Title
                    </p>

                    <Dropdown
                      style={{ width: 88 }}
                      className="kycDropdowns"
                      value={titleValue}
                      options={titleDropDopwn}
                      onChange={(e) => this.setState({ titleValue: e.value })}
                      id="kycVerification7"
                    />
                    {!titleValue && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Title is required *
                      </p>
                    )}
                  </div>
                  <div>
                    <p className="firstnamlabky" id="kycVerification8">
                      First Name
                    </p>
                    <input
                      id="kycVerification9"
                      placeholder=""
                      className="firstnamekyc"
                      style={{ marginLeft: 20 }}
                      value={firstName}
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                      disabled
                    />
                    {!firstName && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        First name is required *
                      </p>
                    )}
                  </div>

                  <div id="kycVerification10">
                    <p className="firstnamlabky">Last Name</p>
                    <input
                      placeholder=""
                      className="lastnamekyc"
                      style={{ marginLeft: 20 }}
                      value={lastName}
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                      disabled
                      id="kycVerification11"
                    />
                    {!lastName && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Last name is required *
                      </p>
                    )}
                  </div>
                </div>
                <div className="inputFieldBoxes" id="kycVerification12">
                  <div>
                    <p className="titlekyc">Address1</p>
                    <input
                      className="addressKycinput"
                      value={address1}
                      onChange={(e) =>
                        this.setState({ address1: e.target.value })
                      }
                      id="kycVerification13"
                    />
                    {!address1 && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Address1 is required *
                      </p>
                    )}
                  </div>
                  <div style={{ marginLeft: 20 }} id="kycVerification14">
                    <p className="titlekyc">Address2</p>
                    <input
                      className="addressKycinput"
                      value={address2}
                      onChange={(e) =>
                        this.setState({ address2: e.target.value })
                      }
                      id="kycVerification15"
                    />
                    {/* {!address2 && isSubmit && (
                      <p className='validationerrorMessage errorpaddingleft'>
                        Address2 is required *
                      </p>
                    )} */}
                  </div>
                </div>

                <div className="inputFieldBoxes" id="kycVerification16">
                  <div>
                    <p className="countryresky">Country of Residence</p>

                    <Dropdown
                      style={{ width: 250 }}
                      className="kycDropdowns"
                      value={counteryResidence}
                      options={residenceDropDopwn}
                      onChange={(e) =>
                        this.setState({ counteryResidence: e.value })
                      }
                      id="kycVerification17"
                    />

                    {!counteryResidence && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Country of Residence is required *
                      </p>
                    )}
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <p className="titlekyc">Nationality</p>

                    <Dropdown
                      style={{ width: 250 }}
                      className="kycDropdowns"
                      value={nationalityValue}
                      options={nationalityDropDopwn}
                      onChange={(e) =>
                        this.setState({ nationalityValue: e.value })
                      }
                      id="kycVerification18"
                    />
                    {/* <input
                      placeholder=""
                      className="nationalityKycinput"
                      value={Nationality}
                      onChange={e =>
                        this.setState ({Nationality: e.target.value})}
                      /> */}
                    {!nationalityValue && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Nationality is required *
                      </p>
                    )}
                  </div>

                  <div style={{ marginLeft: 20 }} id="kycVerification19">
                    <p className="titlekyc">State</p>

                    <Dropdown
                      style={{ width: 181 }}
                      className="kycDropdowns"
                      value={state}
                      options={
                        counteryResidence == 'USA'
                          ? usaStateDropDopwn
                          : counteryResidence == 'IND'
                          ? indianStateDropDopwn
                          : counteryResidence == 'GBR'
                          ? ukStateDropDopwn
                          : counteryResidence == 'CHN'
                          ? chinaStateDropDopwn
                          : []
                      }
                      // options={counteryResidence ? (counteryResidence==='IND' ?indianStateDropDopwn:  usaStateDropDopwn):[]}
                      // options={usaStateDropDopwn}
                      onChange={(e) => this.setState({ state: e.value })}
                      id="kycVerification20"
                    />

                    {/* } <input
                      className="kycStateInput"
                      value={state}
                      onChange={e => this.setState ({state: e.target.value})}
                    /> */}
                    {!state && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        State is required *
                      </p>
                    )}
                  </div>

                  <div style={{ marginLeft: 20 }} id="kycVerification21">
                    <p className="titlekyc">Postal code</p>
                    <input
                      className="postalCodekycInput"
                      value={postalCode}
                      onChange={(e) =>
                        this.setState({ postalCode: e.target.value })
                      }
                      id="kycVerification22"
                    />
                    {!postalCode && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Postal code is required *
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 11,
                    // justifyContent: 'space-between',
                  }}
                  id="kycVerification23"
                >
                  <div id="kycVerification24">
                    <p className="kycphoneLabel">Phone</p>

                    <div className="kycPhoneInputBox" id="kycVerification25">
                      <div>
                        {countryFlag && (
                          <img
                            id="kycVerification26"
                            alt="kycVerification26"
                            style={{ width: 35, height: 30 }}
                            src={countryFlag}
                          />
                        )}
                      </div>
                      <div>
                        <input
                          placeholder=""
                          className="kycPhoneInputField"
                          pattern="[0-9]*"
                          style={{ marginLeft: 19 }}
                          value={userPhone}
                          onChange={(evt) => {
                            const userPhone = evt.target.validity.valid
                              ? evt.target.value
                              : this.state.userPhone;
                            this.setState({
                              userPhone,
                            });
                          }}
                          disabled
                          id="kycVerification27"
                        />
                      </div>
                    </div>

                    {!userPhone && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Phone is required *
                      </p>
                    )}
                  </div>

                  <div>
                    <p className="kycGenderlabel" id="kycVerification28">
                      Gender
                    </p>

                    <Dropdown
                      style={{ width: 187, marginLeft: 20, opacity: 1 }}
                      className="kycDropdowns"
                      value={gender}
                      options={genderDropDopwn}
                      onChange={(e) => this.setState({ gender: e.value })}
                      disabled
                      id="kycVerification29"
                    />
                    {!gender && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Gender is required *
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    // alignItems: 'center',
                    marginTop: 11,
                    // justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <p className="titlekyc" id="kycVerification30">
                      Email
                    </p>
                    <input
                      placeholder=""
                      className="emailKycinput"
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      disabled
                      id="kycVerification31"
                    />

                    {!email && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Email is required *
                      </p>
                    )}
                  </div>

                  <div
                    id="kycVerification32"
                    style={{ marginLeft: 20, position: 'relative' }}
                    ref={this.openDobDatepicker}
                  >
                    <p className="titlekyc" id="kycVerification33">
                      Date of Birth{' '}
                    </p>

                    <div
                      style={{ cursor: 'initial' }}
                      id="kycVerification34"
                      className="kycDOBpicker"
                      onClick={() =>
                        this.setState({
                          isOpenDobPicker: false,
                        })
                      }
                    >
                      {dateOfBirth
                        ? moment(dateOfBirth).format('DD/MM/YYYY')
                        : 'DD/MM/YYYY'}
                    </div>

                    {isOpenDobPicker && (
                      <div style={{ position: 'absolute' }}>
                        <Calendar
                          id="kycVerification35"
                          minDate={new Date('01/01/1900')}
                          maxDate={new Date()}
                          selectedDate={dateOfBirth}
                          onSubmit={(value) => {
                            this.setState({
                              isOpenDobPicker: false,
                            });
                            if (value) {
                              this.setState({
                                dateOfBirth: value,
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                    <div
                      id="kycVerification36"
                      className="titlekyc"
                      style={{ opacity: 0.5 }}
                    >
                      DD/MM/YYYY
                    </div>
                    {!dateOfBirth && isSubmit && (
                      <p className="validationerrorMessage errorpaddingleft">
                        Date of birth is required *
                      </p>
                    )}
                  </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                  <Button
                    id="kycVerification37"
                    className="KYCregisterButton"
                    onClick={this.nextScreen}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}

          {this.state.step === 2 && (
            <div className="kycBoxesBg">
              {completeKYC}
              <div id="onfido-mount" />
            </div>
          )}

          {this.state.step === 3 && (
            <div className="kycBoxesBg" id="kycVerification38">
              <div
                style={{ display: 'flex', justifyContent: 'center' }}
                id="kycVerification39"
              >
                <div style={{ padding: 2 }} id="kycVerification40">
                  {completeKYC}
                  {/* <div className="IDUPLO">
                  ID UPLOAD
        </div> */}
                  <div className="kycSuccessBox" id="kycVerification41">
                    <img
                      className="succeessImg"
                      src={accountSuccess}
                      alt="success"
                    />
                    <div
                      id="kycVerification42"
                      className="completeSuccessTitle"
                    >
                      Thank you, {firstName + ' ' + lastName}!
                    </div>
                    <div id="kycVerification43" className="kycThankyouTitle">
                      You have completed your ID verification.
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }} id="kycVerification44">
                    <Button
                      className="KYCregisterButton"
                      onClick={() =>
                        this.props.history.push(routes.PROFILE_DASHBOARD)
                      }
                      id="kycVerification45"
                    >
                      Return to Dashboard
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfileDashboard,
      sendUserInfoKYC,
      onfidoResumeChecksAction,
      onfidoGetSDKTokenForApplicantAction,
      resetApplicantToken,
      Loading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserKycPage);
