import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import queryString from 'query-string';

import {
  deleteConversationDetails,
  getChatRecentMessagesDashboard,
} from '../../../actions/Messenger';
import {GROUPDETAILS} from '../../../constants/routes';

import ConversationList from '../ConversationList';
import MessageList from '../MessageList';
import MessageListGroupChat from '../MessageListGroupChat';
import DefaultScreen from '../DefaultScreen';
import {Button} from 'reactstrap';
import _ from 'lodash';
import backIcon from '../../../images/icons/messenger/backwhiteArrow.png';
import './Messenger.css';
import {
  checkAvailableAccess,
} from '../../../constants/localMessage/errorMessage';
import CommonAccessContainer
  from '../../../components/UI/commonAccessContainer/commonAccessContainer';

import connectComing from '../../../images/comingsoon/connectComing.svg';


class Messenger extends Component {
  constructor (props) {
    super (props);
    this.state = {
      userChatInfo: {},
      messengerKey: _.get (this.props.location, 'state.messengerKey', ''),
      chatType: '',
      conversationId: null,
      currentuserid: '',
      isAccess: true,
    };
  }

  static getDerivedStateFromProps (nextProps) {
    return {
      // userChatInfo: nextProps.messengerList.messenger.length > 0 ? nextProps.messengerList.messenger[0] : {}
    };
  }

  async _handleOnPressMessenger (data) {
    // console.log("_handleOnPressMessenger DATA******", data, data.key)
    this.setState ({
      chatType: data.chatType,
      userChatInfo: data,
      conversationId: data.key,
      messengerKey: data.key,
    });

    if (_.get (this.props.userinfo, 'user.Data.userDetails[0]', '')) {
      let myId = _.get (
        this.props.userinfo,
        'user.Data.userDetails[0].personalDetails.docID',
        ''
      );
      this.props.getChatRecentMessagesDashboard (myId);
    }
  }

  _handleMessengerValue = conversationId => {
    if (this.state.chatType === 'groupChat') {
      return conversationId;
    } else if (this.state.chatType === 'singleChat') {
      let keyVal = '';
      let myId = this.props.userinfo.user.Data.userDetails[0].personalDetails
        .docID;
      if (conversationId > myId) {
        keyVal = `${conversationId}${myId}`;
      } else {
        keyVal = `${myId}${conversationId}`;
      }
      return keyVal;
    }
  };

  onDeleteSubmit = async chatID => {
    // alert("de")
    // this.setState({
    //   chatType: '',
    //   conversationId: null,
    // });
    const {deleteConversationDetails, messengerList: {messenger}} = this.props;
    const {conversationId, chatType, messengerKey} = this.state;

    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    // console.log(loginUser);
    let currentuserid = loginUser.docID;
    await deleteConversationDetails ({
      conversationId,
      messengerKey,
      chatType,
      conversationList: messenger,
      currentuserid,
      chatID,
    });
  };

  redirectToGroupInfo = () => {
    const {history} = this.props;
    const {conversationId} = this.state;
    if (conversationId) {
      const redirectRoute = `${GROUPDETAILS}?conversationId=${conversationId}`;
      history.push (redirectRoute);
    }
  };

  _handleOnLeaveGroup () {
    this.setState ({
      userChatInfo: {},
      messengerKey: _.get (this.props.location, 'state.messengerKey', ''),
      chatType: '',
      conversationId: null,
    });
  }

  _creatingNewChat (data) {
    this.setState (
      {
        chatType: 'singleChat',
        userChatInfo: data.userChatInfo,
      },
      () => {
        this.setState ({
          messengerKey: this._handleMessengerValue (
            this.state.userChatInfo.oppositeUserUid
          ),
        });
      },
      () => {}
    );
  }

  render () {
    // console.log("i am on messenger page userChatInfo>>>>>", this.state.userChatInfo)
    // console.log("messengerList>>>>>", this.props.messengerList)
    const {
      currentuserid,
      chatType,
      userChatInfo,
      messengerKey,
      conversationId,
      isAccess
    } = this.state;

    const {messengerList: {messenger}} = this.props;

    let view = null;
    if (chatType === 'singleChat') {
      const [selectedConversation] = messenger.filter (
        obj => obj.oppositeUserUid === conversationId
      );
      view = (
        <MessageList
          userChatInfo={userChatInfo}
          history={this.props.history}
          currentuserid={currentuserid}
          messengerKey={messengerKey}
          conversationTitle={
            selectedConversation && selectedConversation.displayName
              ? selectedConversation.displayName
              : userChatInfo.displayName
          }
          onDeleteConversation={this.onDeleteSubmit}
        />
      );
    } else if (chatType === 'groupChat') {
      const [selectedConversation] = messenger.filter (
        obj => obj.groupId === conversationId
      );
      view = (
        <MessageListGroupChat
          userChatInfo={userChatInfo}
          history={this.props.history}
          currentuserid={currentuserid}
          messengerKey={messengerKey}
          conversationTitle={
            selectedConversation && selectedConversation.displayName
              ? selectedConversation.displayName
              : ''
          }
          onDeleteConversation={this.onDeleteSubmit}
          onGroupInfoClick={this.redirectToGroupInfo}
          onLeaveGroupClick={() => this._handleOnLeaveGroup ()}
        />
      );
    } else {
      view = <DefaultScreen {...this.props} />;
    }

    return (
      <div>
     {isAccess &&  <div className="scrollMessengerView">
       
        <div className="messenger">
          <div className="scrollable sidebar">
            <ConversationList
              onClickConversation={data => this._handleOnPressMessenger (data)}
              history={this.props.history}
              creatingNewChat={data => this._creatingNewChat (data)}
            />
          </div>

          <div className="content">
            {view}
          </div>

          <div style={{textAlign: 'right', marginRight: 20,marginLeft:10,marginTop:20}}>
          <Button
            data-rh="Go back"
            className="messengerBack"
            onClick={() => this.props.history.goBack ()}
          >
            <div><img src={backIcon} alt="back icon" /></div>
            Go <span> Back </span>
          </Button>
        </div>

        </div>

        </div>}
        {!isAccess && 
      <CommonAccessContainer bgImage={connectComing} message={checkAvailableAccess('messenger')} />
    }
      </div>
    );
  }

  componentDidMount = () => {
    if (
      checkAvailableAccess ('messenger') &&
      checkAvailableAccess ('messenger') === 'messenger'
    ) {
      this.setState (
        {
          isAccess: true,
        },
        () => {
          const {messengerList: {messenger}, location: {search}} = this.props;
          // console.log("messengerList>>>> in didmount>>>>", this.props.messengerList)

          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fetchNewGroupChat
          ) {
            console.log (this.props.location.state.fetchNewGroupChat);
            this._handleOnPressMessenger (
              this.props.location.state.fetchNewGroupChat
            );
          }

          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.userChatInfo
          ) {
            if (
              this.props.location.state.userChatInfo &&
              this.props.location.state.userChatInfo.chatType === 'groupChat'
            ) {
              this._handleOnPressMessenger (
                this.props.location.state.userChatInfo
              );
            } else {
              this.setState (
                {
                  chatType: 'singleChat',
                  userChatInfo: this.props.location.state.userChatInfo,
                },
                () => {
                  this.setState ({
                    messengerKey: this._handleMessengerValue (
                      this.state.userChatInfo.oppositeUserUid
                    ),
                  });
                }
              );
            }
          }

          let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
          if (loginUser) {
            this.setState ({
              currentuserid: loginUser.docID,
            });
          }

          if (search) {
            const {conversationId} = queryString.parse (search);
            const conversation = messenger.filter (obj => {
              return obj.chatType === 'singleChat'
                ? obj.oppositeUserUid === conversationId
                : obj.groupId === conversationId;
            });
            if (conversation && conversation.length > 0) {
              const selectedConversation = conversation[0];
              this.setState (
                {
                  chatType: selectedConversation.chatType,
                  conversationId,
                },
                () => {
                  this.setState ({
                    messengerKey: this._handleMessengerValue (conversationId),
                  });
                }
              );
            }
          }
        }
      );
    } else {
      this.setState ({
        isAccess: false,
      });
    }
  };
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    messengerList: state.MessengerReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      //fetchMessengerList,
      deleteConversationDetails,
      getChatRecentMessagesDashboard,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (Messenger);
