import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {createNotification} from '../../../constants/notificationtoast';
import {Row, Col, Button, Media, Modal, ModalBody} from 'reactstrap';
import moment from 'moment';

import * as routes from "../../../constants/routes";
import PinInput from 'react-pin-input';
import pinImage from '../../../images/pay/pin.png';
import paymentSuccess from '../../../images/pay/paymentsuccess.png';

import './dashboard.css';

import sendicon from '../../../images/icons/sendMoney.png';
import requesticon from '../../../images/icons/requestMoney.png';
import requestSent from '../../../images/icons/pay/requestSent.png';


import topupicon from '../../../images/icons/pay/topup.png';
import topupicon1 from '../../../images/icons/pay/topup1.png';


import plusicon from '../../../images/pay/plus.png';
import Group21 from '../../../images/Group21.png';
import topup from '../../../images/topup.png';
import plus from '../../../images/plus.png';
import RequestLog from '../../../images/requestLog.png';
import wallet from '../../../images/Walet_IA.png';

import close_icon from '../../../images/close_icon.png';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import {
  getTransactionHistory,
  getContactList,
  sendAmountTransaction,
  askFormoney,
  fetchUserBalance,
  createPaymentSession
} from '../../../actions/Paymentaction';
import {RDEE_PASS_PRASE } from '../../../services/servicesConfig';
import {priceWithDecimal} from '../../../utility/commonFunctions';


class PaymentDashBoard extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      step: 1,
      sendStep: 1,
      value: '',
      modal: false,
      secondmodal: false,
      showDiv: true,
      walletBalance: 0,
      accountInfo: [],
      startPage: 0,
      transactionhistory: [],
      contactListing: [],
      selectSendToUser: '',
      searchContact: '',
      transactionAmount: '',
      transactionDescription: '',
      pinValue: '',
      showUserInfo: '',
      sendmoneyerror: '',
      recentHistory: '',
      isCall: true,
      askpinValue: '',
      userBalance: '',
      rdCredit: '',
      noDataFound: false,
      walletAddress: '',
      transfer_purposes:''
    };
  }
  openmodal (data) {
    this.setState ({modal: true, showUserInfo: data});
  }
  closemodal () {
    this.setState ({modal: false});
  }
  open_secondmodal () {
    this.setState ({secondmodal: true, step: 1});
  }
  close_secondmodal () {
    this.setState ({secondmodal: false});
  }
  goNext = () => {
    this.setState ({
      step: this.state.step + Number (1),
      sendStep: this.state.sendStep + Number (1),
    });
  };
  moveNext = () => {
    this.setState ({
      sendStep: this.state.sendStep + Number (1),
    });
  };
  showing () {
    this.setState ({showDiv: !this.state.showDiv}, () => {
      if (!this.state.showDiv) {
        this.setState ({
          selectSendToUser: '',
          searchContact: '',
          transactionAmount: '',
          transactionDescription: '',
          pinValue: '',
          showUserInfo: '',
          sendmoneyerror: '',
        });
        let sendRequest = {
          data: {},
        };
        this.props.getContactList (sendRequest);
      }
    });
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.payment) {
      if (nextProps && nextProps.payment.newcreatewallet) {
        this.setState (
          {
            // step: localStorage.getItem('address') ? 6 : 5,
            walletAddress: nextProps.payment.newcreatewallet.address,
          },
          () => {
            localStorage.setItem (
              'address',
              nextProps.payment.newcreatewallet.address
            );
          }
        );
      }
    }

    if (nextProps.userInfo) {
      if (
        nextProps.userInfo.user &&
        nextProps.userInfo.user.Data &&
        nextProps.userInfo.user.Data.userDetails
      ) {
        this.setState ({
          noDataFound: true,
        });

        if (
          nextProps.userInfo.user.Data.userDetails[0] &&
          nextProps.userInfo.user.Data.userDetails[0].transaction_history &&
          nextProps.userInfo.user.Data.userDetails[0].transaction_history
            .length > 0
        ) {
          this.setState ({
            recentHistory: nextProps.userInfo.user.Data.userDetails[0]
              .transaction_history,
          });
        }
      }
    }

    if (nextProps && nextProps.payment) {
     

      // nextProps.payment.history.balance.amount =
      // nextProps.payment.history.balance.amount =

      // console.log(nextProps.payment.history.rdeeBalance.amount);

      if (nextProps.payment.history && nextProps.payment.history.rdeeBalance) {
        nextProps.payment.history.rdeeBalance.amount =
          nextProps.payment.history.rdeeBalance.amount -
          Number (this.state.transactionAmount);
      }

      if (nextProps.payment.history && nextProps.payment.history.balance) {
        nextProps.payment.history.balance.amount =
          nextProps.payment.history.balance.amount -
          Number (this.state.transactionAmount);
      }

      this.setState ({
        userBalance: nextProps.payment.history &&
          nextProps.payment.history.balance
          ? nextProps.payment.history.balance
          : '',
        rdCredit: nextProps.payment.history &&
          nextProps.payment.history.rdeeBalance
          ? nextProps.payment.history.rdeeBalance
          : '',
      });
      // contactListing

      // if (nextProps.payment.accountbalance) {
      //   if (nextProps.payment.accountbalance.balanceOf) {
      //     this.setState ({
      //       userBalance: nextProps.payment.accountbalance.balance,
      //       rdCredit: nextProps.payment.accountbalance.rdeeBalanceOf,
      //       // accountInfo: nextProps.payment.accountbalance,
      //     });
      //   }
      // }
      if (
        nextProps.payment.contactList &&
        nextProps.payment.contactList.length > 0
      ) {
        this.setState ({
          contactListing: nextProps.payment.contactList,
        });
      }

      if (nextProps.payment.sendmoney) {
        this.setState ({
          sendStep: 4,
        });
      }

      if (nextProps.payment.askSendMoney) {
        this.setState ({
          step: 2,
        },()=>{
          this.props.history.push(routes.PAYMENT);
        });
      }

      if (nextProps.payment.sendmoneyerror) {
        this.setState ({
          sendmoneyerror: nextProps.payment.sendmoneyerror,
        });
      }
      if (nextProps && nextProps.payment.history) {
        const getAcccountInfo = nextProps.payment.history;

        if (
          getAcccountInfo &&
          getAcccountInfo.transaction_history &&
          getAcccountInfo.transaction_history.length > 0
        ) {
          for (let item of getAcccountInfo.transaction_history) {
            item.transactiontime = moment (item.transaction_date).format (
              'MM/DD/YY HH:mmA'
            );
          }
          let tempData = this.state.transactionhistory.concat (
            getAcccountInfo.transaction_history
          );

          // const newArray = tempData.filter((val, transaction_id, array) => array.indexOf(val) == transaction_id);
          this.setState (
            {
              // transactionhistory: this.state.transactionhistory.concat(getAcccountInfo.transaction_history),

              // transactionhistory: Array.from (new Set (tempData)),
              transactionhistory: this.removeDuplicates (
                tempData,
                'transaction_id'
              ),
            },
            () => {
            }
          );
        }

        this.setState ({
          // walletBalance: getAcccountInfo.accountbalance.balanceOf,
          accountInfo: getAcccountInfo,
        });
      }
    }
  }

  componentDidMount () {
 
    let walletAddress = localStorage.getItem ('address');
    if (walletAddress) {
      this.setState (
        {
          walletAddress: walletAddress,
        },
        () => {
          this.props.fetchUserBalance (this.state.walletAddress);

          let sendRequest = {
            data: {
              // address: this.state.walletAddress,
              // address:'1BA6r1CYGtCyJ6WoFPpDrAmxpRQRDhEQhkPv8R',
              limit: 10,
              offset: 0,
              // txid:''
            },
          };
          // walletAddress
          this.props.getTransactionHistory (sendRequest);
        }
      );
    }

    // if (this.props && this.props.walletAddress) {
    //   this.loadMore();

    // this.props.fetchUserBalance (this.props.walletAddress);

    // let sendRequest={
    //   // address: this.props.walletAddress,
    //   address:'1BA6r1CYGtCyJ6WoFPpDrAmxpRQRDhEQhkPv8R',
    //   limit: this.state.startPage,
    //   offset: 0
    // }
    // // walletAddress
    // this.props.getTransactionHistory(sendRequest);
    // }
  }

  removeDuplicates (originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push (lookupObject[i]);
    }
    return newArray;
  }
  loadMore () {
    this.setState (
      {
        startPage: this.state.startPage + Number (10),
      },
      () => {
        let sendRequest = {
          data: {
            limit: this.state.startPage,
            offset: this.state.startPage === 10
              ? this.state.startPage - Number (10)
              : this.state.startPage - Number (9),
          },
        };
        this.props.getTransactionHistory (sendRequest);
      }
    );
  }

  selectUser (item) {
    this.setState (
      {
        selectSendToUser: '',
        searchContact: '',
      },
      () => {
        const isError =
          !item.walletAddress ||
          item.walletAddress === null ||
          item.walletAddress === '';
        if (isError) {
          createNotification (
            'error',
            (item && item.firstName  ? item.firstName  : 'Recipient') + ' Wallet Address Not Found'
          );
          return false;
        }

        this.setState ({
          selectSendToUser: item,
          searchContact: item.firstName
            ? item.firstName
            : '',
        });
      }
    );
  }

  sendAmount () {
    let sendRequest = {
      sender_address: this.state.walletAddress,
      recipient_address: this.state.selectSendToUser.walletAddress,
      amount: Number (this.state.transactionAmount),
      passphrase: this.state.pinValue,
      transfer_purposes: this.state.transactionDescription,
    };

    this.props.sendAmountTransaction (sendRequest);
  }

  AsktosendAmount () {
    let sendRequest = {
      address: this.state.walletAddress,
      amount: 1000,
      passphrase: RDEE_PASS_PRASE,
      transfer_purposes:this.state.transfer_purposes

      // passphrase: this.state.askpinValue,
    };
    this.props.askFormoney (sendRequest);
  }
  render () {
    const {
      showDiv,
      accountInfo,
      transactionhistory,
      contactListing,
      showUserInfo,
      recentHistory,
      userBalance,
      rdCredit,
      noDataFound,
    } = this.state;

    return (
      <div>
        {showDiv
          ? <div>
              <div className=" payContainer" id="settlerightBox">

                <Row className="text-center " id="settlerightBox1">
                  <Col className="text-center colorWhite" id="settlerightBox2">
                    <p className="blanceTitle no_margin" id="settlerightBox3"> Balance </p>
                    <p className="balanceAmount no_margin" id="settlerightBox4">

                      {userBalance && userBalance.currency
                        ? userBalance.currency
                        : 'USD'}
                        $
                      <span>
                      {' '}
                        {userBalance && userBalance.amount
                          ? priceWithDecimal(userBalance.amount)
                          : 0}
                      </span>

                    </p>

                    <p className="balanceCredit no_margin" id="settlerightBox5">

                      R-DEE Credits: {' '}
                      {' USD$ '}

                      {rdCredit && rdCredit.amount ? priceWithDecimal(rdCredit.amount) : ''}
                      {' '}
                      {rdCredit && rdCredit.currency ? rdCredit.currency : ''}
                    </p>

                  </Col>

                </Row>
                <Row id="settlerightBox6">
                  <Col id="settlerightBox7" className=" colorWhite" style={{marginTop: 30,display:'inline-flex'}}>
                    <Button
                      id="settlerightBox8"
                      className="sendPayButton"
                      // onClick={() => this.showing ()}
                      onClick={()=>{
                        this.props.history.push(routes.NEWTRANSACTION+'/send')}
                      }
                      data-rh="Send payment"
                    >
                      <img
                        src={sendicon}
                        alt="Icon"
                        style={{width: 20, marginRight: 8}}
                        id="settlerightBox9"
                      />
                      Send
                    </Button>

                    <Button
                      className="sendPayButton"
                      // onClick={() => this.showing ()}
                      onClick={()=>{
                        this.props.history.push(routes.WEB_PAYMENT_WITHDRAW)}
                      }
                      data-rh="Send payment"
                      id="settlerightBox10"
                    >
                      <img
                        src={sendicon}
                        alt="Icon"
                        style={{width: 20, marginRight: 8}}
                        id="settlerightBox11"
                      />
                      WithDraw
                    </Button>
                    
                    {/*<Button className="requestButton"  onClick={()=>this.props.history.push(routes.NEWTRANSACTION+'/request')}
                    >
                      <img
                        src={requesticon}
                        alt="Icon"
                        style={{width: 22, marginRight: 8}}
                      />
                      Request
                    </Button>*/}

                  </Col>
                </Row>
                {/* <Row>

                <Button
                  onClick={() => this.open_secondmodal()}
                  className="topUpButton"
                >
                  <img
                    src={plusicon}
                    alt="Icon"
                    style={{ width: 20, marginRight: 12 }}
                  />
                  Top Up
                    </Button>


              </Row> */}
                <div className="borderDiv" id="settlerightBox12"/>
                <Row id="settlerightBox13">
                  <Col style={{marginTop: 25,marginBottom:20}} id="settlerightBox14">
                    <p id="settlerightBox15" className="transactionPayOption">R-DEE pay options</p>

                  </Col>
                </Row>

                <div id="settlerightBox16" className="rdeePayOptionsCSS">
                  <Button id="settlerightBox17" className="topUpOptionButton"  data-rh="TopUp" onClick={() => {
                    this.open_secondmodal()
                   
                  }}>
                    <img
                      src={plus}
                      alt="Icon"
                      style={{width: 20, marginRight: 12}}
                    />
                    TopUp
                  </Button>
                </div>
                <div id="settlerightBox18" className="rdeePayOptionsCSS">
                  <Button id="settlerightBox19" className="walletSettingOptionButton" onClick={()=>this.props.history.push(routes.ACCOUNT_SETTINGS)}
                  data-rh="Wallet setting"
                  >
                    <img
                      src={wallet}
                      alt="Icon"
                      style={{width: 22, marginRight: 10}}
                      id="settlerightBox20"
                    />
                    Wallet Setting
                  </Button>
                </div>
                {/*<div className="rdeePayOptionsCSS">
                  <Button className="viewRequestOptionButton" onClick={()=>this.props.history.push(routes.MYREQUESTS)}>
                    <img
                      src={RequestLog}
                      alt="Icon"
                      style={{width: 22, marginRight: 10}}
                    />
                    View Request Log
                  </Button>
            </div>*/}

                {/*<div>
                <Row>
                  <Col className=" colorWhite" style={{marginTop: 35}}>
                    <p className="transactionMainTitle">Transaction History</p>

                  </Col>
                </Row>

                {transactionhistory &&
                  transactionhistory.length === 0 &&
                  noDataFound &&
                  <Row>
                    <Col style={{marginTop: 35, textAlign: 'center'}}>
                      <p
                        className="transactionMainTitle recentText"
                        style={{fontSize: 20}}
                      >
                        No Transaction History
                      </p>

                    </Col>
                  </Row>}
                {transactionhistory &&
                  transactionhistory.length > 0 &&
                  <div>
                    <div className="transactionHistoryScrollContent">
                      <Row style={{marginTop: 20}}>
                        <p className="recentText">Today</p>
                        <Col
                          sm="12"
                          md="12"
                          lg="12"
                          className=" colorWhite transactionContainer"
                        >
                          {transactionhistory.map ((item, index) => (
                            <Col
                              sm="3"
                              md="3"
                              lg="3"
                              className="payCard"
                              onClick={() => this.openmodal (item)}
                              key={item.transaction_id}
                            >
                              <p className="transactionTitle">
                                {item.type === 'deposit' ? 'Received' : 'Sent'}
                              </p>
                              <Media>
                                <Media
                                  className="avatarImg"
                                  object
                                  src={
                                    item.profilePictureURL
                                      ? item.profilePictureURL
                                      : profilePlaceholder
                                  }
                                  alt="placeholder image"
                                />
                                <Media body className="bodyWidth">
                                  <Media heading className="usernameText">
                                    {item.displayName
                                      ? item.displayName
                                      : item.firstName && item.lastName
                                          ? item.firstName + ' ' + item.lastName
                                          : ''}

                                  </Media>

                                  <span className="payDate">
                                    {item.transactiontime
                                      ? item.transactiontime
                                      : ''}
                                    {' '}
                                  </span>
                                  <span className="payAmount">
                                    {' '}
                                    {' '}
                                    {' '}
                                    + {item.currency} {' $'}
                                    {' '}
                                    {item.total_amount.toFixed (2)}
                                  </span>
                                </Media>
                              </Media>
                            </Col>
                          ))}

                        </Col>

                      </Row>
                    </div>
                    <p className="loadMore">
                      <span
                        className="loadMore"
                        style={{cursor: 'pointer'}}
                        onClick={() => this.loadMore ()}
                      >
                        {' '}Load More
                      </span>

                    </p>
                  </div>}

              </div>*/}
                                    </div>
              <div>
                {/* <Button color="danger" onClick={this.openmodal}></Button> */}
                <Modal isOpen={this.state.modal} centered={true}>
                  {/* <ModalHeader>Received</ModalHeader> */}

                  {this.state.showUserInfo &&
                    <ModalBody
                      style={{backgroundColor: '#333333', borderRadius: 10}}
                      id="settlerightBoxshowUserInfo"
                    >
                      <div>
                        <div  id="settlerightBoxshowUserInfo1">
                          <img
                            src={close_icon}
                            className="closeimg"
                            onClick={() => this.closemodal ()}
                            id="settlerightBoxshowUserInfo2"
                          />
                        </div>
                        <div className="text-center"  id="settlerightBoxshowUserInfo3">
                          <p className="headtext_m"  id="settlerightBoxshowUserInfo4">
                            {showUserInfo.type === 'deposit'
                              ? 'Received'
                              : 'Sent'}
                          </p>
                        </div>
                        <div className="ModalborderStyle"  id="settlerightBoxshowUserInfo5"/>
                        <div className="payCard"  id="settlerightBoxshowUserInfo6">
                          <p
                            className="modal_headtext"
                            style={{marginBottom: 8}}
                            id="settlerightBoxshowUserInfo7"
                          >

                            {showUserInfo.type === 'deposit' ? 'From' : 'To'}
                          </p>
                          <Media  id="settlerightBoxshowUserInfo8">
                            <Media
                              className="avatarImg"
                              object
                              src={profilePlaceholder}
                              alt="placeholder image"
                              id="settlerightBoxshowUserInfo9"
                            />
                            <Media body className="bodyWidth">
                              <Media
                                heading
                                className="usernameText"
                                style={{margin: 0}}
                                id="settlerightBoxshowUserInfo10"
                              >

                                {showUserInfo && showUserInfo.displayName
                                  ? showUserInfo.displayName
                                  : showUserInfo.firstName &&
                                      showUserInfo.lastName
                                      ? showUserInfo.contact.firstName +
                                          ' ' +
                                          showUserInfo.lastName
                                      : ''}
                              </Media>
                              <span className="payDate"  id="settlerightBoxshowUserInfo11">
                                {showUserInfo.searchHandle}{' '}
                              </span>
                            </Media>
                          </Media>
                          <div>
                            <p
                              className="modal_headtext"
                              style={{opacity: 0.5}}
                              id="settlerightBoxshowUserInfo12"
                            >
                              Timestamp
                            </p>
                            <span className="payDate"  id="settlerightBoxshowUserInfo13">
                              {showUserInfo.transactiontime} (PST)
                            </span>
                          </div>
                          <div>
                            <p
                              className="modal_headtext"
                              style={{opacity: 0.5}}
                              id="settlerightBoxshowUserInfo14"
                            >
                              Amount
                            </p>
                            <span className="amountmodal"  id="settlerightBoxshowUserInfo15">
                              {' '}
                              {' '}
                              {' '}
                              {showUserInfo.currency}
                              {' '}
                              $
                              {' '}
                              {showUserInfo.total_amount}
                            </span>
                          </div>
                          <div>
                            <p
                              className="modal_headtext"
                              style={{opacity: 0.5}}
                              id="settlerightBoxshowUserInfo16"
                            >
                              Purpose
                            </p>
                            <span className="usernameText"  id="settlerightBoxshowUserInfo17">
                              {showUserInfo.transaction_purposes}
                            </span>
                            {/* <span className="usernameText">my company ordered earlier.Thankyou</span> */}
                          </div>
                        </div>

                      </div>
                    </ModalBody>}
                  {/* <ModalFooter>
           
            <Button color="secondary" onClick={()=>this.closemodal()}>Cancel</Button>
          </ModalFooter> */}
                </Modal>
              </div>
              <div>
                {/* <Button color="danger" onClick={this.openmodal}></Button> */}
                <Modal
                  isOpen={this.state.secondmodal}
                  centered={true}
                  style={{width: 327}}
                >
                  {/* <ModalHeader>Received</ModalHeader> */}
                  <ModalBody
                    className="text-center"
                    style={{
                      backgroundColor: '#17171D',
                      width: 327,
                      borderRadius: 30,
                    }}
                    id="settlerightBoxshowUserInfo18"
                  >
                    {this.state.step === 1 &&
                      <div>
                        <div>
                          <div className="modalHeaderTitle"  id="settlerightBoxshowUserInfo19">
                         Request Top Up
                            <img
                              src={close_icon}
                              className="closeimg"
                              onClick={() => this.close_secondmodal ()}
                              style={{cursor: 'pointer'}}
                              id="settlerightBoxshowUserInfo20"
                            />
                          </div>
                          <div  id="settlerightBoxshowUserInfo21" style={{textAlign:'left',paddingLeft:50,position:'relative',top:15}}>
                            <img  id="settlerightBoxshowUserInfo22" src={topupicon} style={{marginTop: '10px',width:100,opacity:0.9}} />
                           </div>
                           <div  id="settlerightBoxshowUserInfo23" style={{paddingLeft:20}}>
                            <img  id="settlerightBoxshowUserInfo24" src={topupicon1} style={{width:80,opacity:0.9}} />

                          </div>
                          <div  id="settlerightBoxshowUserInfo25" style={{marginTop: 30}}>
                            <p  id="settlerightBoxshowUserInfo26" className="para" style={{textAlign: 'initial'}}>
                              Click to request for RDEE Credit Faucet Top Up. You can only top up from faucet once in 24 hours. With each top up request, you will receive 1000.00 R-DEE Credits.
                            </p>
                          </div>
                          <div>
                         {/*} <p className="reasonTitle">Reason</p>
                            <input
                              style={{
                                height: 49,
                                background: '#333333',
                                borderRadius: 5,
                              }}
                              className="topup_purpose"
                              type="text"
                              placeholder="Reason *"
                              value={this.state.transfer_purposes}
                              onChange={e =>
                                this.setState ({
                                  transfer_purposes: e.target.value,
                                })}
                              />*/}
                          </div>
                          <div
                            className="pClass" 
                            style={{marginTop: 20}}
                            id="settlerightBoxshowUserInfo27"
                          >
                            <Button
                               id="settlerightBoxshowUserInfo28"
                           
                              className="commanButtonClass requestTopUpbutton"
                              onClick={() => {
                                let loginUser = JSON.parse(localStorage.getItem("loginuser"));

              
                                let data={
                                  user_id:  loginUser.docID,
                                  source_currency: "USD",
                                  destination_currency: "USD",
                                  destination_amount: 20,
                                  // redirect_url:`https://radiologexapp.firebaseapp.com${routes.PAYMENTSTATUS}/success`,
                                  // failed_redirect_url:`https://radiologexapp.firebaseapp.com${routes.PAYMENTSTATUS}/failed`
                              
                                  redirect_url:`https://${window.location.hostname}${routes.PAYMENTSTATUS}/success`,
                                  failed_redirect_url:`https://${window.location.hostname}${routes.PAYMENTSTATUS}/failed`
                                }
                                this.props.createPaymentSession(data);
                                // this.AsktosendAmount ();
                              }}
                            >
                            Place Request
                            </Button>
                          </div>
                        </div>
                      </div>}

                    {this.state.step === 2 &&
                      <div>
                      <div className="modalHeaderTitle"  id="settlerightBoxshowUserInfo29">
                      Top Up Request Sent
                          <img
                            src={close_icon}
                            className="closeimg"
                            onClick={() => this.close_secondmodal ()}
                            id="settlerightBoxshowUserInfo30"
                          />
                        </div>
                        <div>
                          <img
                            src={requestSent}
                            className="topup_img"
                            style={{marginTop: 45}}
                            id="settlerightBoxshowUserInfo31"
                          />
                        </div>
                        <div style={{marginTop: 30}}  id="settlerightBoxshowUserInfo32">
                        <p className="receivemsg"  id="settlerightBoxshowUserInfo33"> Your request has been received.</p>
                          <p className="para1"  id="settlerightBoxshowUserInfo34">
                          <span> You will receive </span>
                            {' '}
                           
                              1000.00 RDEE credit 
                           
                            {' '}
                            <span> to
                            your account shortly. </span>
                          </p>
                        </div>
                        <Col
                          xs="12"
                          sm="12"
                          lg="12"
                          className="pClass"
                          md="12"
                          style={{marginTop: 20}}
                          id="settlerightBoxshowUserInfo35"
                        >
                          <Button
                            className="commanButtonClass requestTopUpbutton"
                            onClick={() => {
                              this.close_secondmodal ();
                            }}
                            id="settlerightBoxshowUserInfo36"
                          >
                            Return to R-DEE Pay
                          </Button>
                        </Col>
                      </div>}

                  </ModalBody>
                </Modal>
              </div>
            </div>
          : <Row
              className="text-center "
              style={{marginTop: 30, width: '100%'}}
              id="settlerightBoxshowUserInfo37"
            >
              <Col className="text-center colorWhite"  id="settlerightBoxshowUserInfo38">

                {this.state.sendStep === 1 &&
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        padding: 25,
                        justifyContent: 'center ',
                      }}
                      id="settlerightBoxshowUserInfo39"
                    >
                      <div style={{marginRight: 150}}  id="settlerightBoxshowUserInfo40">
                        <div className="balance"  id="settlerightBoxshowUserInfo41">Balance</div>
                        <div className="pAYamiunt"  id="settlerightBoxshowUserInfo42">
                          {/* {userBalance && userBalance.currency
                            ? userBalance.currency
                          : 'US'}*/}
                          {' '}
                          USD$
                          {' '}
                          {userBalance && userBalance.amount
                            ? priceWithDecimal(userBalance.amount)
                            : 0}
                          {' '}
                        </div>
                        <div className="PAYRDECREDIT"  id="settlerightBoxshowUserInfo43">
                          R-DEE Credits:  USD$
                          {' '}
                          {rdCredit && rdCredit.amount ? priceWithDecimal(rdCredit.amount) : ''}
                          {' '}
                          {rdCredit && rdCredit.currency
                            ? rdCredit.currency
                            : ''}
                        </div>
                      </div>
                      <div  id="settlerightBoxshowUserInfo44">
                        <div className="sendto"  id="settlerightBoxshowUserInfo45">Send to </div>

                        <div className="listrecf"  id="settlerightBoxshowUserInfo46">

                          {/*<img src={paysearch} className="paysearch" />*/}
                          <input
                            type="text"
                            name="Contact"
                            id="Contact"
                            className="paysearcgrec"
                            placeholder="Contact*"
                            value={this.state.searchContact}
                            onChange={e =>
                              this.setState ({
                                searchContact: e.target.value,
                              })}
                              id="settlerightBoxshowUserInfo47"
                          />
                          <div className="Contacts"  id="settlerightBoxshowUserInfo48">Contacts</div>
                          <div className="line"  id="settlerightBoxshowUserInfo49"/>
                          <div className="userListContainer"  id="settlerightBoxshowUserInfo50">
                            {contactListing &&
                              contactListing.length > 0 &&
                              contactListing.map ((contact, index) => (
                                <div
                                  className="handpointer"
                                  onClick={() =>
                                    this.selectUser (contact.contact)}
                                  key={index}
                                  id={`settlerightBoxshowUserInfoContact${index}`}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={
                                          contact.contact &&
                                            contact.contact.profilePictureURL
                                            ? contact.contact.profilePictureURL
                                            : profilePlaceholder
                                        }
                                        className="payconnimh"
                                        id={`settlerightBoxshowUserInfoContactimg${index}`}
                                      />
                                    </div>
                                    <div>
                                      <div className="payMoore"  id={`settlerightBoxshowUserInfoContactFname${index}`}>
                                        {contact.contact &&
                                          contact.contact.displayName
                                          ? contact.contact.displayName
                                          : contact.contact.firstName &&
                                              contact.contact.lastName
                                              ? contact.contact.firstName +
                                                  ' ' +
                                                  contact.contact.lastName
                                              : ''}
                                      </div>
                                      <div className="paymarketing"  id={`settlerightBoxshowUserInfoContactposition${index}`}>
                                        {contact.contact &&
                                          contact.contact.position
                                          ? contact.contact.position
                                          : ''}
                                      </div>
                                      <div className="psymedical"  id={`settlerightBoxshowUserInfoContactFirmName${index}`}>
                                        {contact.contact &&
                                          contact.contact.firmName
                                          ? contact.contact.firmName
                                          : ''}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="line"  id={`settlerightBoxshowUserInfoContactline${index}`}/>
                                </div>
                              ))}
                          </div>
                        </div>

                        <Button
                          className="paynextbut"
                          disabled={!this.state.selectSendToUser}
                          onClick={() => {
                            this.moveNext ();
                          }}
                          id="settlerightBoxshowUserInfoContactNext"
                        >
                          Next
                        </Button>
                      </div>
                      {/*<div className="nextButtonClass">
                      <div>
                        <button
                        style={{marginTop: 23, position: "absolute",
                        bottom: 10}}
                          className="paynextbut"
                          disabled={!this.state.selectSendToUser}
                          onClick={() => {
                            this.moveNext ();
                          }}
                        >
                          Next
                        </button>
                        </div>
                        </div>*/}
                    </div>
                  </div>}
                {this.state.sendStep === 2 &&
                  <div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          padding: 25,
                          justifyContent: 'center ',
                        }}
                      >
                        <div>
                          <div style={{marginRight: 150}} id="settlerightBoxpay1">
                            <div className="balance" id="settlerightBoxpay2">Balance</div>
                            <div className="pAYamiunt" id="settlerightBoxpay3">
                              {userBalance && userBalance.currency
                                ? userBalance.currency
                                : 'USD$'}
                              {' '}
                              {userBalance && userBalance.amount
                                ? userBalance.amount
                                : 0}
                              {' '}
                            </div>
                            <div className="PAYRDECREDIT" id="settlerightBoxpay4">
                              R-DEE Credits:
                              {' USD$'}
                              {rdCredit && rdCredit.amount
                                ? rdCredit.amount
                                : ''}
                              {' '}
                              {rdCredit && rdCredit.currency
                                ? rdCredit.currency
                                : ''}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="boldHeadings textLeft" id="settlerightBoxpay5">
                              Sending To
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 24,
                              }}
                            >
                              <div>
                                <img
                                  src={
                                    this.state.selectSendToUser &&
                                      this.state.selectSendToUser
                                        .profilePictureURL
                                      ? this.state.selectSendToUser
                                          .profilePictureURL
                                      : profilePlaceholder
                                  }
                                  className="payconnimh"
                                  id="settlerightBoxpay6"
                                />
                              </div>
                              <div>
                                <div className="paysentdingna" id="settlerightBoxpay7">
                                  {this.state.selectSendToUser &&
                                    this.state.selectSendToUser.displayName
                                    ? this.state.selectSendToUser.displayName
                                    : this.state.selectSendToUser.firstName &&
                                        this.state.selectSendToUser.lastName
                                        ? this.state.selectSendToUser
                                            .firstName +
                                            ' ' +
                                            this.state.selectSendToUser.lastName
                                        : ''}
                                </div>
                                <div className="posenfpay" id="settlerightBoxpay8">
                                  {this.state.selectSendToUser &&
                                    this.state.selectSendToUser.position
                                    ? this.state.selectSendToUser.position
                                    : ''}
                                </div>
                                <div className="possenfng" id="settlerightBoxpay9">
                                  {this.state.selectSendToUser &&
                                    this.state.selectSendToUser.firmName
                                    ? this.state.selectSendToUser.firmName
                                    : ''}
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="owmuch" id="settlerightBoxpay10">How much?</div>
                          <div>
                            <input
                              style={{
                                height: 49,
                                background: '#333333',
                                borderRadius: 5,
                              }}
                              className="payinpiuyt"
                              type="text"
                              name="amount"
                              //  id="amount"
                              placeholder="Amount*"
                              value={this.state.transactionAmount}
                              onChange={e => {
                                if (isNaN (Number (e.target.value))) {
                                  return;
                                } else {
                                  this.setState ({
                                    transactionAmount: e.target.value,
                                  });
                                }
                              }}
                              id="settlerightBoxpay11"
                            />
                          </div>
                          <div>
                            <input
                              style={{
                                height: 49,
                                background: '#333333',
                                borderRadius: 5,
                              }}
                              className="amoupayin"
                              type="text"
                              name="purpose"
                              //  id="purpose"
                              placeholder="Purpose of Transaction*"
                              value={this.state.transactionDescription}
                              onChange={e =>
                                this.setState ({
                                  transactionDescription: e.target.value,
                                })}
                              id="settlerightBoxpay12"
                            />
                          </div>
                        </div>

                      </div>

                    </div>

                    <div>
                      <Button
                        disabled={
                          this.state.transactionAmount === '' ||
                            this.state.transactionDescription === ''
                        }
                        className="commanButtonClass "
                        onClick={() => {
                          this.moveNext ();
                        }}
                        id="settlerightBoxpay13"
                      >
                        Send
                      </Button>

                      {/*  <div className="sendContactList">
                      <Col>
                        <div>
                          <span className="sendtitle2">How Much ?</span>
                        </div>
                        <FormGroup>
                          <Input
                            type="text"
                            name="amount"
                            id="amount"
                            placeholder="Amount*"
                            value={this.state.transactionAmount}
                            onChange={e => {
                              if (isNaN (Number (e.target.value))) {
                                return;
                              } else {
                                this.setState ({
                                  transactionAmount: e.target.value,
                                });
                              }
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Input
                            type="text"
                            name="purpose"
                            id="purpose"
                            placeholder="Purpose of Transaction*"
                            value={this.state.transactionDescription}
                            onChange={e =>
                              this.setState ({
                                transactionDescription: e.target.value,
                              })}
                          />
                        </FormGroup>
                       
                        </Col>
                            </div>*/}
                    </div>
                  </div>}
                {this.state.sendStep === 3 &&
                  <div>
                    {/*<Button
                      className="backButton"
                      onClick={() => this.setState ({sendStep: 2})}
                      style={{float: 'left', marginLeft: 20, zIndex: 9999999}}
                    >
                      <img src={union} style={{marginRight: 10}} /> Back
                    </Button>*/}
                    <div className="PinBox " id="settlerightBoxpay14">
                      <Col id="settlerightBoxpay15">
                        <div>
                          <img src={pinImage} className="pinImage" id="settlerightBoxpay16"/>
                        </div>
                        <p className="pinText" id="settlerightBoxpay17">
                          Enter your PIN again to confirm sending  money.
                        </p>

                        <PinInput
                          length={4}
                          focus
                          secret
                          ref={p => (this.pin = p)}
                          type="numeric"
                          style={{
                            padding: '10px',
                            display: 'inline-flex',
                            width: 200,
                          }}
                          inputStyle={{
                            borderColor: 'transparent',
                            borderBottomColor: '#4092B7',
                            borderBottomWidth: 2,
                            borderTopWidth: 0,
                            borderRadius: 0,
                          }}
                          inputFocusStyle={{borderBottomColor: '#4092B7'}}
                          onComplete={(value, index) => {
                            this.setState (
                              {
                                pinValue: value,
                              },
                              () => {
                                this.sendAmount ();
                              }
                            );
                            // this.moveNext ();
                          }}
                          id="settlerightBoxpay18"
                        />
                        <p className="errorMessage" id="settlerightBoxpay19">
                          {this.state.sendmoneyerror
                            ? this.state.sendmoneyerror
                            : null}
                          {/*you have entered your PIN incorrectly more than 5 times. your PIN has been reset. kidly email support@radiologex.com to proceed further*/}
                        </p>
                      </Col>

                    </div>
                  </div>}

                {this.state.sendStep === 4 &&
                  <div>
                    <div className="sendContactList " id="settlerightBoxpay20">
                      <Col id="settlerightBoxpay21">
                        <div>
                          <img src={paymentSuccess} className="pinImage" id="settlerightBoxpay22"/>
                        </div>
                        <p className="congratulationText" id="settlerightBoxpay23">
                          Congratulations!
                        </p>
                        <p className="congratulationContent" id="settlerightBoxpay24">
                          You sent
                          {' '}
                          <span className="boldHeadings" id="settlerightBoxpay25">
                            {' '}
                            USD$
                            {' '}
                            {Number (this.state.transactionAmount).toFixed (2)}
                          </span>
                          <span className="boldHeadings" id="settlerightBoxpay26">
                            {' '}
                            to
                            {' '}
                            {this.state.selectSendToUser &&
                              this.state.selectSendToUser.displayName
                              ? this.state.selectSendToUser.displayName
                              : this.state.selectSendToUser.firstName &&
                                  this.state.selectSendToUser.lastName
                                  ? this.state.selectSendToUser.firstName +
                                      ' ' +
                                      this.state.selectSendToUser.lastName
                                  : ''}
                          </span>
                        </p>

                        <Button
                          className="commanButtonClass "
                          style={{marginTop: 23}}
                          onClick={() => {
                            this.setState (
                              {
                                sendStep: 1,
                              },
                              () => {
                                this.showing ();
                              }
                            );
                          }}
                          id="settlerightBoxpay27"
                        >
                          Return To Dashboard
                        </Button>

                        <p
                          className="anotherTransaction"
                          style={{marginTop: 25}}
                          onClick={() =>
                            this.setState ({
                              sendStep: 1,
                              selectSendToUser: '',
                              searchContact: '',
                              transactionAmount: '',
                              transactionDescription: '',
                              pinValue: '',
                              showUserInfo: '',
                              sendmoneyerror: '',
                            })}
                          id="settlerightBoxpay28"
                        >
                          Send Another Transaction
                        </p>

                      </Col>

                    </div>
                  </div>}
              </Col>
            </Row>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer,
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getTransactionHistory,
      getContactList,
      sendAmountTransaction,
      askFormoney,
      fetchUserBalance,
      createPaymentSession
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (PaymentDashBoard);
