import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from 'reactstrap';
import * as routes from '../../../../constants/routes';
import './browseProduct.css';
import leftbackarrow from '../../../../images/icons/leftbackarrow.png';
import MarketPlaceSidemenu from '../marketplaceSidemenu/marketSideMenu';
import  RightSideContainer from '../../../../components/UI/rightSideContainer/rightSideContainer';

import productDetails
  from '../../../../images/marketPlaceBuyer/productDetails.png';
  import {
    getProductListing,
    fetchProductManufacturer
  } from '../../../../actions/marketplaceaction';
  import {Dropdown} from 'primereact/dropdown';
import {priceWithDecimal} from '../../../../utility/commonFunctions';


const deviceType = [{label: 'MRI', value: 'MRI'}, {label: 'CT', value: 'CT'}];
const manufacture = [
  {label: 'Siemens Servicing', value: 'Siemens Servicing'},
  {label: 'Shimadzu Servicing', value: 'Shimadzu Servicing'},
  {label: 'GE Servicing', value: 'GE Servicing'},
];
const location = [
  {label: 'California', value: 'California'},
  {label: 'New york', value: 'New york'},
];
const price = [
  {label: '10', value: '10'},
  {label: '100', value: '100'},
  {label: '100,000', value: '100,000'},
  {label: '200,000', value: '200,000'},
];
const sortBy= [
  {label: 'Newest Arrivals', value: 1},
  {label: 'Price: Low to High', value: 2},
  {label: 'Price: High to Low', value: 3},
]
const radiologyTypes = [{label: 'MRI Scanners', value: 'MRI Scanners'}, 
                      {label: 'CT Systems', value: 'CT Systems'},
                      {label: 'Ultrasounds', value: 'Ultrasounds'}, 
                      {label: 'X-Ray Equipment', value: 'X-Ray Equipment'},
                      {label: 'Refurbished Systems', value: 'Refurbished Systems'}, 
                      {label: 'Medical Imaging Spare Parts', value: 'Medical Imaging Spare Parts'},
                      {label: 'Ultrasound Probes', value: 'Ultrasound Probes'},
                      {label: 'Everything else', value: 'Everything else'}];
const nonradiologyTypes = [{label: 'Hand Sanitizers', value: 'Hand Sanitizers'}, 
                          {label: 'Health and Personal Care', value: 'Health and Personal Care'},
                          {label: 'Cleaning and Hygiene Products', value: 'Cleaning and Hygiene Products'}, 
                          {label: 'Disinfectant Wipes and Sprays', value: 'Disinfectant Wipes and Sprays'},
                          {label: 'Face Masks (N95, KN95)', value: 'Face Masks (N95, KN95)'}, 
                          {label: 'Face Masks (Others)', value: 'Face Masks (Others)'},
                          {label: 'Everything else', value: 'Everything else'},
                        ];

class BrowseProduct extends Component {
  constructor (props) {
    super (props);
    this.state = {
      browsedeviceType: '',
      browselocation: '',
      browsemanufacture: '',
      browseprice: '',
      productListing:[],
      tempSaveProductList:[],
      sortByValue:'',
      priceItemCollection:[
        {label:'$0',value:'0'},
        {label:'$100',value:'100'},
        {label:'$1000',value:'1000'},
        {label:'$10000',value:'10000'},
        {label:'$50000',value:'50000'},
        {label:'$100000',value:'100000'},
        {label:'$200000',value:'200000'},
        {label:'$300000',value:'300000'},
        {label:'$400000',value:'400000'},
        {label:'$500000',value:'500000'},
        {label:'$600000',value:'600000'},
        {label:'$600000+',value:'600000+'},
      ],
      selectedMinIndex:1,
      manufactureCollection:[],
      productCategoryTypeOptions:[],
      productCategoryType:'',
      manufactureItemInfo:[],
      browsemaxprice:''
    };
    this.doSorting= this.doSorting.bind(this);
  }
  goDetailPage = (item) => {
    this.props.history.push (routes.PRODUCT_DETAIL + `/${item.docID}`);
  };
  componentDidMount(){
  
    if(this.props.location && this.props.location.state && this.props.location.state.searchObject){
      this.props.fetchProductManufacturer();
      let getData=this.props.location.state.searchObject;
      this.setState({
        browsedeviceType: getData.productType?getData.productType:'',
        browselocation: getData.location?getData.location:'',
        browsemanufacture: getData.manufacturer?getData.manufacturer:'',
        browseprice: getData.minPrice? getData.minPrice:'',
        browsemaxprice:getData.maxPrice?getData.maxPrice:'',
        productCategoryType:getData.productCategoryType?getData.productCategoryType:''
      },()=>{

        this.doSearchItems();

      })
    }      
  }
  
  componentWillReceiveProps (nextProps) {
   
    if(nextProps){
      if(nextProps.product && nextProps.product.manufactureItemValues){
        const obj= nextProps.product.manufactureItemValues;
        let productCategoryTypeOptions=[];
        Object.keys(obj).map((key) => {
          productCategoryTypeOptions.push({label:key,value:key});
        });
        this.setState({
          productCategoryTypeOptions:productCategoryTypeOptions,
          manufactureCollection:obj
        })
    }
      
      if (
        nextProps &&
        nextProps.product &&
        nextProps.product.productlist &&
        nextProps.product.productlist
      ) {
        this.setState ({
          productListing: nextProps.product.productlist,
          
        },()=>{
          this.setState ({
            tempSaveProductList:nextProps.product.productlist,
          })
        });
        // console.log(nextProps.product.productlist);
        // this.props.history.push (routes.MARKETPLACESEARCH ,{'issearch': true});
      }
    }

  }

  doSearchItems(){
    const {
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      productCategoryType,
      browsemaxprice
    } = this.state;


    const sendRequest = {
      data: {
        productCategoryType:productCategoryType?productCategoryType:'',
        productType: browsedeviceType?browsedeviceType:'',
        manufacturer: browsemanufacture?browsemanufacture:'',
        location: browselocation?browselocation:'',
        minPrice: browseprice?browseprice:'',
        maxPrice: browsemaxprice?browsemaxprice:'',

      },
    };

    this.props.getProductListing (sendRequest);
  }
  
  doSorting(){
    this.setState({
      productListing:[]
    },async ()=>{

    if(this.state.sortByValue===1){
      
      this.setState({
        productListing:this.state.tempSaveProductList.sort((a, b) =>{
          if(a.productListedDateAtISO){
                var dateA = new Date(a.productListedDateAtISO).getTime(); 
                var dateB = new Date(b.productListedDateAtISO).getTime(); 
                return dateB > dateA   ? 1 : -1;  
            // return   new Date(b.productListedDateAtISO) - new Date(a.productListedDateAtISO)

          }
            
          
          } )

      })
    }
    if(this.state.sortByValue===2){
      this.setState({
        productListing:this.state.tempSaveProductList.sort((a, b) => Number(a.priceUSD) - Number(b.priceUSD))
      })
    }
    if(this.state.sortByValue===3){
      this.setState({
        productListing:this.state.tempSaveProductList.sort((a, b) => Number(b.priceUSD) - Number(a.priceUSD))

      })
    }
  })

  }

  render () {
    const {
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      productListing,
      sortByValue,
      productCategoryType,
      browsemaxprice
    } = this.state;
  
    return (
      <div className="productBrowseContainer">
        <div>
          <div style={{textAlign: 'right'}}>
            <Button
              className="marketproducbackbutton"
              onClick={() => this.props.history.goBack ()}
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>

              Go <span> Back</span>
            </Button>
          </div>
          <div
           className="maindivbrowsese"
          >
            <div className="Browsinxsdcg">Browsing Marketplace</div>
            <div style={{display: 'flex', marginTop: 9}}>

                      <Dropdown
                        className="marketBrowseProductinput"
                        placeholder="Product Category"
                        value={this.state.productCategoryType}
                        options={this.state.productCategoryTypeOptions}
                        onChange={e =>{
                          this.setState ({productCategoryType: e.value},()=>{
                            let manufactureItemInfo=[];
                            if(this.state.manufactureCollection && this.state.manufactureCollection[this.state.productCategoryType]){
                              let res= Object.values(this.state.manufactureCollection[this.state.productCategoryType]);
                              if(res && res.length>0){
                                for(let items of res){
                                manufactureItemInfo.push({
                                  label:items,value:items
                                })
                              }
                              this.setState({
                                manufactureItemInfo:manufactureItemInfo
                              })
                              }
                              
                            }
         
                          })
                        }}
                      />

              
            <Dropdown
            className="marketBrowseProductinput"
            placeholder="Product Type"
            value={browsedeviceType}
            // options={deviceType}
            options={this.state.productCategoryType ?(this.state.productCategoryType==='Radiology Products'?radiologyTypes:nonradiologyTypes):[]}

            onChange={e => this.setState ({browsedeviceType: e.value},()=>{
              this.doSearchItems()
            })}
          />

          <Dropdown
            className="marketBrowseProductinput"
            placeholder="Location"
            value={browselocation}
            options={location}
            onChange={e => this.setState ({browselocation: e.value},()=>{
              this.doSearchItems()
            })}
          />
          <Dropdown
          className="marketBrowseProductinput"
          placeholder="Manufacturer"
          value={browsemanufacture}
          options={this.state.manufactureItemInfo}
          onChange={e => this.setState ({browsemanufacture: e.value},()=>{
            this.doSearchItems()
          })}
        />
        <Dropdown
          className="marketBrowseProductinput"
          placeholder="Min Price"
          value={browseprice}
          options={this.state.priceItemCollection.slice(0,10)}
          onChange={e => this.setState ({browseprice: e.value},()=>{
            var findItemindex = this.state.priceItemCollection.map( (img)=> { return img.value; }).indexOf(this.state.browseprice);
            this.setState({
              selectedMinIndex:findItemindex+1
            },()=>{
              this.doSearchItems()
            })
          })}
        />
        <Dropdown
          className="marketBrowseProductinput"
          placeholder="Max Price"
          value={browsemaxprice}
          options={this.state.priceItemCollection.slice(this.state.selectedMinIndex,20)}

          onChange={e => this.setState ({browsemaxprice: e.value},()=>{
            this.doSearchItems()
          })}
        />
            </div>
            <div style={{display: 'flex', marginTop: 10,width: 916,
            justifyContent: "space-between"}}>
              <div className="arketplaceresults">
                {productListing && productListing.length} Marketplace results for
                {productCategoryType && <span> Product Type: {productCategoryType} ,</span>}
                {browsedeviceType && <span> Product Type: {browsedeviceType} ,</span>}
                {browsemanufacture && <span> Manufacturer: {browsemanufacture} ,</span>}
                {browselocation && <span> Location: {browselocation} ,</span>}
                {browseprice && <span> Price(Min): {browseprice} ,</span>}
                {browsemaxprice && <span> Price(Max): {browsemaxprice}</span>}


              </div>
              <div>
              <Dropdown
                className="marketBrowseProductSortByinput"
                placeholder="Sort by: Newest Arrivals"
                value={sortByValue}
                options={sortBy}
                onChange={e => this.setState ({sortByValue: e.value},()=>this.doSorting())}
              />
              </div>
            </div>
            {productListing&& productListing.length===0 && <div className="noBrowseData">
            Product Not Found
          </div>}
            <div className="productHorizontalListView">
           
            {productListing && productListing.length>0 && productListing.map((item,i)=>(
                <div key={i} className="markeimagebox" onClick={()=>this.goDetailPage(item)}>
                <div>
                  <img
                    src={item && item.productImageList && item.productImageList.length>0?item.productImageList:productDetails }  onError={e => {
                      e.target.onerror = null;
                      e.target.src = productDetails;
                    }}
                   
                    alt={item.systemName}
                    style={{height: 209, width: 209}}
                  />
                </div>
                <div style={{background: '#4453D6',borderBottomRightRadius:10,borderBottomLeftRadius:10,paddingBottom:10}}>
                  <p className="siemenavantomarke">
                  {item && item.deviceSaleName
                    ? item.deviceSaleName
                    : ''}<br />
                    <span> USD$  {' '}
                    {item && item.priceUSD ? priceWithDecimal(item.priceUSD) : ''}</span>
                  </p>
                  <p className="DeviceType">Device Type: {' '} {item && item.deviceType ? item.deviceType : ''}</p>
                  <p className="LocCafornian">Location:   {' '}
                  {item && item.location ? item.location : ''}  
                  </p>
                </div>
              </div>))}
              </div>

          </div>
        </div>
        <RightSideContainer>
        <MarketPlaceSidemenu />
        </RightSideContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({
    getProductListing,
    fetchProductManufacturer
  }, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (BrowseProduct);
