import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import './fileDrive.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import axios from 'axios';

import {Loading} from '../../actions/LoadingAction';
// import {DEV_SERVER} from '../../services/servicesConfig';
import firebase from '../../config/firebase';
import store from '../../store';

import {RDEE_VAULT_URL } from '../../services/servicesConfig';
import {saveAs} from 'file-saver';
// import {JSZip} from 'JSZip';

import {Button, Progress, Modal, ModalBody} from 'reactstrap';
import {createNotification} from '../../constants/notificationtoast';
import profilePlaceholder from '../../images/profile_placeholder.png';

import deleteIcon from '../../images/icons/vault/deleteIcon.svg';
import closeIcon from '../../images/icons/crossButtonMarketPlacesuccess.png';
import seainputicon from '../../images/icons/seainputicon.png';

import folderIcon from '../../images/icons/vault/folderIcon.svg';
import blackFolderIcon from '../../images/icons/vault/blackFolderIcon.png';
import blackFilesIcon from '../../images/icons/vault/blackFilesIcon.png';
import whiteFileIcon from '../../images/icons/vault/whiteFileIcon.svg';
import getExternalIcon from '../../images/icons/vault/getExternalIcon.svg';


import blackStar from '../../images/icons/vault/blackStar.svg';
import shareWhite from '../../images/icons/vault/shareWhite.svg';


import starIcon from '../../images/icons/vault/starIcon.png';
import smallDownWhite from '../../images/icons/vault/whiteDown.png';
import largeDownWhite from '../../images/icons/vault/largeWhiteDown.png';
import whiteGridDisable from '../../images/icons/vault/gridViewdisable.png';
import whiteGridView from '../../images/icons/vault/gridView.png';
import whiteListIcon from '../../images/icons/vault/listViewIcon.png';
import whiteListDisable from '../../images/icons/vault/listViewdisable.png';

import shareBlack from '../../images/icons/vault/shareBlack.svg';
import whiteStar from '../../images/icons/vault/whiteStar.svg';
// import copyIcon from '../../images/icons/vault/copyIcon.svg';
import downloadIcon from '../../images/icons/vault/downloadIcon.svg';
// import pinIcon from '../../images/icons/vault/pinIcon.png';

import downWhiteArrow from '../../images/icons/vault/downWhiteArrow.svg';


import addIcon from '../../images/icons/vault/plusAddicon.png';
import searchIcon from '../../images/icons/vault/searchIcon.png';
// import linkIcon from '../../images/icons/vault/linkIcon.png';
import whiteCameraIcon from '../../images/icons/vault/whiteCameraIcon.svg';
import whitePlayIcon from '../../images/icons/vault/whitePlayIcon.svg';
import blueCheckmark from '../../images/icons/vault/blueCheckmark.png';
import forwordArrow from '../../images/icons/vault/forwordArrow.png';
// import InfiniteScroll from 'react-infinite-scroller';
import {checkAvailableAccess ,getMessage} from '../../constants/localMessage/errorMessage';
import vaultUpcoming from '../../images/comingsoon/vaultUpcoming.svg';
import pacsIcon from '../../images/icons/vault/pacsIcon.png';

import VaultGridView from './vaultGird/vaultGrid';
import {
  getVaultListing,
  getVaultFileDownload,
  putStar,
  createNewfolder,
  shareFileWithUsers,
  deleteVaultAction,
  deleteVaultFiles,
  deleteVaultBulkFiles,
  resetVaultListAction,
  getvaultGetFileDetails,
  resetCreatedItem,
  addNewFile,
  deleteBulkFilesVaultAction,
  deleteVaultfolder,
  getVaultBulkDownload,
  vaultExternalFileSharing,
  externalShareFileReset,
  getVaultSharedWithFiles
} from './../../actions/vaultAction';
import {getAllUserContactsFunc} from './../../actions/contacts';
import {vaultDataTimeFormat ,dataFormat} from '../../utility/convertTimeString';
import {formatFileSize ,removeWhiteSpace} from '../../utility/commonFunctions';
import {ShowNameAccordingToLength} from '../../utility/stringFunctions';
import CommonAccessContainer
  from './../../components/UI/commonAccessContainer/commonAccessContainer';

import * as routes from '../../constants/routes';

// import * as JSZip from 'jszip';
// import * as JSZipUtils from 'jszip-utils';
const WAIT_INTERVAL = 1000;

const proxyurl = 'https://cors-anywhere.herokuapp.com/';
class FileDrive extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      loginUserId: '',
      businessId: '',
      userListing: [],
      uploadedList: [],
      nextPageToken: '',
      searchText: '',
      isLoading: false,
      isAddnewFile: false,
      isFilterbyName: false,
      isFilterbyRecent: false,
      fileShareModal: false,
      vaultOptionModal: false,
      isListview: true,
      sortByName: '',
      sortByType: '',
      addnewFolder: false,
      newFolderName: '',
      selectedDetailStack: [],
      selectedItem: '',
      contactListing: [],
      searching: '',
      isSelectedContact: false,
      copyText: false,
      isStarFilter: false,
      foldersList: [],
      filesList: [],
      isOpenDetailModal: false,
      isShowTitleBox: true,
      isEnabledownload: false,
      isGetResponse:false,
      isOpenStack:false,
      sharingFileModal: false,
      externalSharingInfo:[],
      authToken:'',
      sharingConfirmFileModal:false,
      sharedWithmeItems:[],
      isSharedWithMe:false
    };
    this.fetchCollections = this.fetchCollections.bind (this);
    this.sortByNameData = this.sortByNameData.bind (this);
    this.selectItems = this.selectItems.bind (this);
    this.openFolder = this.openFolder.bind (this);
    this.selectedShareUser = this.selectedShareUser.bind (this);
    this.copyToClipboard = this.copyToClipboard.bind (this);
    this.onSelectDownloadFiles = this.onSelectDownloadFiles.bind (this);
    this.isEnableSelection = this.isEnableSelection.bind (this);


    

    this.nameSortByFilter = React.createRef ();
    this.mostRecentByFilter = React.createRef ();

    this.addNewVault = React.createRef ();
    this.openVaultFolderStatck = React.createRef ();


    
  }
  componentWillUnmount () {
    document.removeEventListener ('mousedown', this.handleClickOutside);
    this.unlisten ();
  }
  componentDidMount () {
    document.addEventListener ('mousedown', this.handleClickOutside);
    this.unlisten = this.props.history.listen ((location, action) => {
      // console.log("on route change");
      this.props.resetVaultListAction ();
      localStorage.setItem ('openFolderStack', '');
    });
    this.props.resetCreatedItem ();
    this.props.externalShareFileReset();

    if (
      checkAvailableAccess ('vault') &&
      checkAvailableAccess ('vault') === 'vault'
    ) {
      this.setState (
        {
          isAccess: true,
        },
        () => {
          // return;

          let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

          const currentState = store.getState();
          const { Authreducer } = currentState;


          if (loginUser) {
            this.setState (
              {
                loginUserId: loginUser.docID,
                authToken: Authreducer.authToken
              },
              () => {
                if (this.props && this.props.userinfo) {
                  // console.log (this.props.userinfo);
                  if (
                    this.props.userinfo &&
                    this.props.userinfo.isbusinessProfile
                  ) {
                    if (this.props.userinfo.isbusinessProfile) {
                      if (this.props.userinfo.isbusinessProfile.docID) {
                        // console.log (this.props.userinfo);
                        this.setState (
                          {
                            businessId: this.props.userinfo.isbusinessProfile
                              .docID,
                          },
                          async () => {
                            if (this.state.businessId) {
                              this.props.Loading (true);

                              this.props.getAllUserContactsFunc ({
                                data: {
                                  searchString: '',
                                },
                              });

                              let getStack = await localStorage.getItem (
                                'openFolderStack'
                              );
                              if (getStack) {
                                this.setState ({
                                  selectedDetailStack: JSON.parse (getStack),
                                  isShowTitleBox: false
                                });
                              }

                              this.fetchCollections ();
                            }
                          }
                        );
                      }
                    }
                  }
                }
              }
            );
          }
        }
      );
    } else {
      this.setState ({
        isAccess: false,
      });
    }

    this.props.Loading (false);
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps && nextProps.vaultData) {
        console.log(nextProps.vaultData);

        if(nextProps.vaultData.externalSharing && nextProps.vaultData.externalSharing.shareFile){
          this.setState({
            sharingFileModal: true,
            externalSharingInfo: nextProps.vaultData.externalSharing
        })
        }
       
          // console.log(nextProps.vaultData.filesList);
          if(this.state.isSharedWithMe){
            if (nextProps.vaultData.sharedWithme) {
            this.setState (
              {
                uploadedList: this.state.sortByName ? nextProps.vaultData.sharedWithme :nextProps.vaultData.sharedWithme.sort(this.compareByNameData ('asc')),
                // vaultOptionModal: !this.state.isOpenDetailModal ? false : true,
                vaultOptionModal: false,
                addnewFolder: false,
                fileShareModal: false,
                sharingConfirmFileModal:false
                // uploadedList:nextProps.vaultData.filesList
              },
              () => {
                if(this.state.authToken){
                  this.updateUserImage();
  
                }
                if (this.state.sortByName) {
                  // this.sortByNameData (this.state.sortByName);
  
                }
              }
            );
            }
          
          }else{
            if (nextProps.vaultData.filesList) {
            this.setState (
              {
                uploadedList: this.state.sortByName ? nextProps.vaultData.filesList :nextProps.vaultData.filesList.sort(this.compareByNameData ('asc')),
                // vaultOptionModal: !this.state.isOpenDetailModal ? false : true,
                vaultOptionModal: false,
                addnewFolder: false,
                fileShareModal: false,
                sharingConfirmFileModal:false
                // uploadedList:nextProps.vaultData.filesList
              },
              () => {
                if(this.state.authToken){
                  this.updateUserImage();
  
                }
                if (this.state.sortByName) {
                  // this.sortByNameData (this.state.sortByName);
  
                }
              }
            );
          }
          
        }

        // console.log(nextProps.vaultData.newupdatedItems);

        if (nextProps && nextProps.vaultData.newupdatedItems) {
          if (nextProps.vaultData.newupdatedItems) {
            this.setState (
              {
                selectedItem: nextProps.vaultData.newupdatedItems,
                vaultOptionModal: true,
              },
              () => {
                this.props.resetCreatedItem ();

                // if(this.state.userListing &&this.state.userListing.length>0){
                //   let index = this.state.userListing.findIndex (
                //     items =>  items.fullPath? items.fullPath === this.state.selectedItem.filePath:''
                //   );
                //   if (index > -1) {
                //     this.state.userListing[index]= this.state.selectedItem;
                //   }

                //   this.setState(this.state.userListing);
                // }
                // console.log(this.state.selectedItem)
              }
            );
          }
        }
      }

      if (nextProps.contacts) {
        this.setState ({
          contactListing: nextProps.contacts,
        });
      }
    }
  }

  handleClickOutside = event => {
    if (
      this.nameSortByFilter.current &&
      !this.nameSortByFilter.current.contains (event.target)
    ) {
      this.setState ({
        isFilterbyName: false,
      });
    }
    if (
      this.mostRecentByFilter.current &&
      !this.mostRecentByFilter.current.contains (event.target)
    ) {
      this.setState ({
        isFilterbyRecent: false,
      });
    }
    if (
      this.addNewVault.current &&
      !this.addNewVault.current.contains (event.target)
    ) {
      this.setState ({
        isAddnewFile: false,
      });
    }


    if (
      this.openVaultFolderStatck.current &&
      !this.openVaultFolderStatck.current.contains (event.target)
    ) {
      this.setState ({
        isOpenStack: false,
      });
    }
  };


  fetchSharedFiles(){
    const {searchText,sortByType,sortByName,
      // accessFileDiectory
    }=this.state;
    
    let sendRequest = {
      data: {
        searchString: removeWhiteSpace(searchText),
        limit: 50,
        offset: '',
        sortType: sortByType,
        sortOrder: sortByName,
        // fileDir: accessFileDiectory,
        // starred: isStarFilter,
        // searchDicoms: true,
      },
    };

    this.props.getVaultSharedWithFiles(sendRequest);
  }


  fetchCollections () {
    const {
      sortByName,
      sortByType,
      searchText,
      selectedDetailStack,
      loginUserId,
      businessId,
      uploadedList,
      nextPageToken,
      isStarFilter,
    } = this.state;

    this.setState ({userListing: [], isGetResponse:false});
    if (!businessId) {
      return;
    }
    let accessFileDiectory = '';
    if (selectedDetailStack && selectedDetailStack.length > 0) {
      // if(selectedDetailStack.length<2){
      //   accessFileDiectory=selectedDetailStack[selectedDetailStack.length-1].filePath;
      // }else{
      accessFileDiectory = `${selectedDetailStack[selectedDetailStack.length - 1].fileDir}/${selectedDetailStack[selectedDetailStack.length - 1].fileName}`;
      // }
    } else {
      accessFileDiectory = `vault/${businessId}/${loginUserId}`;
    }

    // console.log (accessFileDiectory);

    let sendRequest = {
      data: {
        searchString: removeWhiteSpace(searchText),
        limit: 50,
        offset: '',
        sortType: sortByType,
        sortOrder: sortByName,
        fileDir: accessFileDiectory,
        starred: isStarFilter,
        searchDicoms: true,
      },
    };
    this.props.getVaultListing (sendRequest);
    setTimeout(()=>{
      this.setState({
        isGetResponse:true
      })
    },3000)

   
  }

  getFileType (file) {
    if (file.type.match ('image.*')) return 'image';

    if (file.type.match ('video.*')) return 'video';

    if (file.type.match ('audio.*')) return 'audio';
    if (file.type.match ('dicom.*')) return 'dicom';

    // etc...

    return 'other';
  }

  checkNullItems (item) {
    let newitem = [];

    for (let newData of item) {
      if (newData) {
        newitem.push (newData);
      }
    }

    return newitem;
  }

    handleUploadingFiles (folderString) {
    const { userListing} = this.state;

    // console.log(loginUserId+' ' + businessId);
    // console.log(userListing);
    // console.log(folderString);


    const currentState = store.getState();
    const { Authreducer } = currentState;
    // console.log(Authreducer);

    // if(
    //     Authreducer &&
    //     Authreducer.authToken
    // ) {
    //     config.headers['Authorization'] = "Bearer " + Authreducer.authToken;
    // }else{
    //     delete config.headers['Authorization'];

    // }

    // const url= `${RDEE_VAULT_URL}/rdee-vault/${folderString}`;
    // console.log(url);

      this.state.userListing.map ((file, index) => {
      // console.log (file);
      if (file.progress === 0) {

    const bodyFormData = new FormData();
    bodyFormData.append('file', file.fileData);
    bodyFormData.append('fileType', file.fileType);
    bodyFormData.append('userFileType', 'file');

    if(folderString){
      bodyFormData.append('folder', `${folderString}`);
    }


    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:"Bearer " + Authreducer.authToken,
      },
      // data: JSON.stringify (sendRquest),
      // url,
      data: bodyFormData,
      url: `/rdee-vault/`, // route name
      baseURL:RDEE_VAULT_URL, //local url
      onUploadProgress: progress => {
        // console.log(progress);
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
        // console.log(uploadPercentage.toFixed(2));
        // console.log("total size in MB ==> ", totalSizeInMB);
        // console.log("uploaded size in MB ==> ", loadedSizeInMB);



        userListing[index].progress = uploadPercentage.toFixed (2);
        userListing[index].userFileType = 'file';
        userListing[index].fileType = 'other';

        userListing[index].updated = new Date ();
        userListing[index].lastUpdated = new Date ();
        userListing[index].fileName = file.fileName;
        
        // let metadata = snapshot.metadata.customMetadata;
        // // metadata1.metadata=metadata1;
        // userListing[index].rawObjectDetails = {metadata};
        this.setState (userListing);

      },
      encType: "multipart/form-data",
    };

    axios (options)
    .then (res => {
      // console.log (res);

      if(res && res.data && res.data.result && res.data.result.status){
        userListing[index] = null;

        this.setState (
          {
            userListing: this.checkNullItems (userListing),
          },
          () => {
            this.sortByNameData (this.state.sortByName);
          }
        );
        if(res.data.result.data){
          this.props.addNewFile (res.data.result.data);
        }
      }else{
        userListing[index] = null;
        this.setState ({
            userListing: this.checkNullItems (userListing),
        })
        createNotification ('error',getMessage(res.data.result.responseCode));
      }
    })
    .catch (err => {
      console.log(err);
  
      this.props.Loading (false);
      if (err && err.message) {
        createNotification ('error', err.message);
      }
      if(err.response && err.response.status === 401){
        if(err.response.data && err.response.data){
            createNotification('error', err.response.data);
            localStorage.clear();
            setTimeout(()=>{
             window.location.reload();
            },1000)
        }
    }
    });
  }
})
  }


  // handleUploadingFiles (folderString) {
  //   const {loginUserId, businessId, userListing} = this.state;
  //   const storageRef = firebase
  //     .storage ()
  //     .ref (`vault/${businessId}/${loginUserId}/${folderString}`);

  //   this.state.userListing.map ((file, index) => {
  //     // console.log (file);
  //     if (file.progress === 0) {
  //       let task = storageRef
  //         .child (`/${file.fileName}`)
  //         .put (file.fileData, file.newMetadata);
  //       task.on (
  //         'state_changed',
  //         snapshot => {
  //           var progress =
  //             snapshot.bytesTransferred / snapshot.totalBytes * 100;

  //           // console.log(snapshot.task);
  //           if (snapshot.task.metadata_) {
  //             // console.log(snapshot.task.metadata_);

  //             userListing[index] = snapshot.task.metadata_;
  //           }

  //           userListing[index].progress = progress.toFixed (0);
  //           userListing[index].fileType = 'file';
  //           userListing[index].updated = new Date ();
  //           userListing[index].lastUpdated = new Date ();

  //           userListing[index].fileName = file.fileName;

  //           let metadata = snapshot.metadata.customMetadata;
  //           // metadata1.metadata=metadata1;
  //           userListing[index].rawObjectDetails = {metadata};

  //           this.setState (userListing);

  //           // this.state.userListing[idx].progress = progress;
  //           // console.log("FILE # "+index,' Upload is ' + progress + '% done');
  //         },
  //         error => {
  //           console.log (error);
  //         },
  //         () => {
  //           // console.log(task);

  //           var starsRef = storageRef.child (file.fileName);

  //           // console.log(starsRef);
  //           // Get the download URL
  //           starsRef.getDownloadURL ().then (async url => {
  //             // console.log(url);
  //             // this.state.userListing[idx].progress = 100;

  //             userListing[index] = task.metadata_;
  //             userListing[index].progress = null;
  //             let metadata = {
  //               fileType: file.newMetadata.customMetadata.fileType,
  //               userFileType: 'file',
  //             };
  //             userListing[index].fileName = file.fileName;
  //             userListing[index].updated = new Date ();
  //             userListing[index].lastUpdated = new Date ();
  //             userListing[index].rawObjectDetails = {metadata};
  //             userListing[index].timeCreated = new Date ();
  //             userListing[index].shareableLink = url;
  //             this.props.addNewFile (userListing[index]);

  //             userListing[index] = null;

  //             this.setState (
  //               {
  //                 userListing: this.checkNullItems (userListing),
  //               },
  //               () => {
  //                 this.sortByNameData (this.state.sortByName);
  //               }
  //             );
  //           });
  //         }
  //       );
  //     }
  //   });
  // }

  uploadFile (e) {
    const { businessId, selectedDetailStack} = this.state;
    if (!businessId) {
      return;
    }
    let files = e.target.files;

    if (!files) {
      return;
    }

    // let userListing = this.state.userListing;
    // userListing.push ({
    //   fileName: files[0].name,
    //   progress: 0,
    // });

    let userListingData = this.state.userListing;
    // console.log(selectedDetailStack);
    var folderPathString = '';
    if (selectedDetailStack && selectedDetailStack.length > 0) {
      for (let item of selectedDetailStack) {
        folderPathString =`${folderPathString}${item.fileName}/` ;
        // folderPathString =`${folderPathString}/${item.fileName}` ;

      }
    }
    folderPathString = `${folderPathString}`;

    // console.log(folderPathString);

    // folderPathString =  folderPathString.substring(0, folderPathString.length - 1);;
    // console.log(folderPathString);

    // return;

    for (let item of e.target.files) {
      userListingData.push ({
        fileData: item,
        fileName: item.name,
        progress: 0,
        fileType: this.getFileType (item)
      });
    }

    this.setState (
      {
        userListing: userListingData,
      },
      () => {
        this.handleUploadingFiles (folderPathString);
      }
    );
  }

  uploadFileDropZoneFile (files) {
    const {businessId, selectedDetailStack} = this.state;
    if (!businessId) {
      return;
    }

    if (files && files.length > 0) {
      // console.log (files);

      let userListingData = this.state.userListing;

      let folderPathString = '';
      if (selectedDetailStack && selectedDetailStack.length > 0) {
        for (let item of selectedDetailStack) {
          // folderPathString = `${folderPathString}/${item.fileName}`;
          folderPathString =`${folderPathString}${item.fileName}/` ;
        }
      }
      folderPathString = `${folderPathString}`;

      for (let item of files) {
        userListingData.push ({
          fileData: item,
          fileName: item.name,
          progress: 0,
          fileType: this.getFileType (item),
          // newMetadata: {
          //   customMetadata: {
          //     userFileType: 'file',
          //     fileType: this.getFileType (item),
          //   },
          // },
        });
      }

      this.setState (
        {
          userListing: userListingData,
        },
        () => {
          this.handleUploadingFiles (folderPathString);
        }
      );
    }
  }

  deleteFile () {
    const {
      selectedItem,
      selectedDetailStack,
    } = this.state;

    // console.log(selectedItem);

    this.props.deleteVaultAction (selectedItem);

    // if (userListing.length > 0) {
    //   let index1 = userListing.findIndex (
    //     item => item.fullPath === selectedItem.fullPath
    //   );
    //   if (index1 > -1) {
    //     userListing.splice (index1, 1);
    //   }
    //   this.setState (userListing);
    // }
    this.setState ({
      vaultOptionModal: false,
    });

    if(selectedItem && selectedItem.fileUUID){
        // let sendRequest = {
        //   fileUUID: selectedItem.fileUUID,
        // };


        let sendRequest = {
          data:{
          recursive: true,
          fileUUIDList: [selectedItem.fileUUID]
          }
        };
        if(selectedItem.size>0){
          delete sendRequest.data.recursive;
        }
        this.props.deleteVaultFiles (sendRequest);
    }else{
    if (selectedItem && selectedItem.size > 0) {
      const storageRef = firebase.storage ().ref ();
      // Create a reference to the file to delete
      var desertRef = storageRef.child (
        selectedItem.docID ? selectedItem.filePath : selectedItem.fullPath
      );
      // Delete the file
      desertRef
        .delete ()
        .then (res => {
          this.setState ({
            vaultOptionModal: false,
          });

          createNotification ('success', 'Deleted Successfully');
          // this.fetchCollections();
          // File deleted successfully
        })
        .catch (error => {
          console.log (error);
          this.props.Loading (false);
          createNotification ('error', error.message);

          // Uh-oh, an error occurred!
        });
    } else {
      let folderString = '';

      if (selectedDetailStack && selectedDetailStack.length > 0) {
        for (let item of selectedDetailStack) {
          if (folderString) {
            folderString = `${folderString}${item.fileName}/`;
          } else {
            folderString = `${item.fileName}/`;
          }
        }
      }
      folderString = `${folderString}${selectedItem.fileName}/`;

      let sendRequest = {
        data: {
          folderPath: folderString,
        },
      };

      this.props.deleteVaultfolder (sendRequest);
    }
  }

  }

  searchText (e) {
    clearTimeout (this.timer);

    // this.setState({ searchText: e.target.value })

    // this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL)
    if (e) {
      this.setState ({searchText: e.target.value}, () => {
        if (this.state.searchText.length > 1) {
          this.timer = setTimeout (this.triggerChange, WAIT_INTERVAL);
        }
        if (!this.state.searchText) {
          this.timer = setTimeout (this.triggerChange, WAIT_INTERVAL);
        }
      });
    } else {
      this.setState ({searchText: ''}, () => {
        this.timer = setTimeout (this.triggerChange, WAIT_INTERVAL);
      });
    }
  }
  triggerChange = () => {
    if(this.state.isSharedWithMe){
      this.fetchSharedFiles();
    }else{
      this.fetchCollections ();

    }
  };

  closeModals () {
    this.setState ({
      isAddnewFile: false,
      isFilterbyName: false,
      isFilterbyRecent: false,
      fileShareModal: false,
      vaultOptionModal: false,
      addnewFolder: false,
      isOpenDetailModal: false,
    });
  }

  selectListView (status) {
    this.setState ({
      isListview: status,
      isShowTitleBox: false,
    });
  }

  sortByType (value) {
    const {uploadedList} = this.state;

    this.setState (
      {
        sortByType: value,
        isShowTitleBox: false,
      },
      () => {
        let arrayCopy = [...uploadedList];
        if (this.state.sortByType === 'recent') {
          arrayCopy.sort (this.compareByDateData ('desc'));
        } else {
          arrayCopy.sort (this.compareByDateData ('asc'));
        }
        this.setState ({uploadedList: arrayCopy});

        // this.fetchCollections ();
      }
    );
  }

  compareByNameData (status) {
    if (status === 'asc') {
      return function (a, b) {
        var nameA = a.fileName.toLowerCase (),
          nameB = b.fileName.toLowerCase ();
        // var nameA = a.fileName,
        //   nameB = b.fileName;
        if (
          nameA < nameB //sort string ascending
        )
          return -1;
        if (nameA > nameB) return 1;
        return 0;
      };
    } else {
      return function (a, b) {
        var nameA = a.fileName.toLowerCase (),
          nameB = b.fileName.toLowerCase ();
        // var nameA = a.fileName,
        //   nameB = b.fileName;
        if (
          nameB < nameA //sort string descending
        )
          return -1;
        if (nameB > nameA) return 1;
        return 0;
      };
    }
  }

  compareByDateData (status) {
    if (status === 'asc') {
      return function (a, b) {
        const unixEpochTimeMS = a.lastUpdated._seconds * 1000;
        const unixEpochTimeMS1 = b.lastUpdated._seconds * 1000;
        var nameA = new Date(unixEpochTimeMS);
        var nameB = new Date(unixEpochTimeMS1);

        // var nameA = a.updated, nameB = b.updated;
        if (
          nameA < nameB //sort string ascending
        )
          return -1;
        if (nameA > nameB) return 1;
        return 0;
      };
    } else {
      return function (a, b) {
        const unixEpochTimeMS = a.lastUpdated._seconds * 1000;
        const unixEpochTimeMS1 = b.lastUpdated._seconds * 1000;
        var nameA = new Date(unixEpochTimeMS);
        var nameB = new Date(unixEpochTimeMS1);

        // var nameA = a.updated, nameB = b.updated;
        if (
          nameB < nameA //sort string descending
        )
          return -1;
        if (nameB > nameA) return 1;
        return 0;
      };
    }
  }

  sortByNameData (value) {
    const { uploadedList} = this.state;
    this.setState (
      {
        sortByName: value,
        isShowTitleBox: false,
      },
      () => {
        let arrayCopy = [...uploadedList];
        if (this.state.sortByName === 'asc') {
          arrayCopy.sort (this.compareByNameData ('asc'));
        } else {
          arrayCopy.sort (this.compareByNameData ('desc'));
        }
        this.setState ({uploadedList: arrayCopy});
      }
    );
  }

  submitAddnew () {
    

    const {
      newFolderName,
      selectedDetailStack,
    } = this.state;

    let folderString = '';
    if (selectedDetailStack && selectedDetailStack.length > 0) {
      for (let item of selectedDetailStack) {
        if (folderString) {
          folderString = `${folderString}/${item.fileName}`;
        } else {
          folderString = `${item.fileName}`;
        }
      }
      // let accessFileDiectory='';
      // if(selectedDetailStack && selectedDetailStack.length>0){
      //   folderString=selectedDetailStack[selectedDetailStack.length-1].filePath;
      // }
    }

    // console.log (folderString);
    // console.log (selectedDetailStack);

    // folderString = `${folderString}/${newFolderName}`;
    // console.log(folderString);

    // return;
    // console.log(folderString);
    if (folderString) {
      folderString = `${folderString}/${newFolderName}`;
    } else {
      folderString = `${newFolderName}`;
    }
    // let sendRequest = {
    //   data: {
    //     folderPath: folderString,
    //   },
    // };
    let sendRequest = {
        folderPath: folderString,
        userFileType: 'folder'
    };
    this.props.createNewfolder (sendRequest);

    
  }

  openFolder (data) {
    // const {selectedDetailStack} = this.state;
    // console.log (selectedDetailStack);
    // console.log (data);
    this.setState (
      {
        selectedDetailStack: this.state.selectedDetailStack.concat (data),
        isShowTitleBox: false,
        searchText: ''
      },
      () => {
        localStorage.setItem (
          'openFolderStack',
          JSON.stringify (this.state.selectedDetailStack)
        );
        // console.log (this.state.selectedDetailStack);
        this.fetchCollections ();
      }
    );
  }
  openFolderFromStack (index) {
    const {selectedDetailStack} = this.state;
    let newArrayStack = [];
    if (index !== 'reset') {
      newArrayStack = selectedDetailStack.slice (0, index + 1);
    }
    this.setState (
      {
        selectedDetailStack: newArrayStack,
        isShowTitleBox: false,
        isOpenStack:false,
        searchText: ''
      },
      () => {
        if (this.state.selectedDetailStack.length > 0) {
          localStorage.setItem (
            'openFolderStack',
            JSON.stringify (this.state.selectedDetailStack)
          );
        } else {
          localStorage.setItem ('openFolderStack', '');
        }
        this.fetchCollections ();
      }
    );
  }

  selectItems (item) {
    // console.log (item);
    // if (item && !item.docID) {
    //   let sendRequest = {
    //     data: {
    //       filePath: item.size > 0 ? item.fullPath : item.name,
    //     },
    //   };
    //   this.props.getvaultGetFileDetails (sendRequest);
    // }
    const contactListing = [...this.state.contactListing];

    if(item && item.sharedUIDList &&  item.sharedUIDList.length>0 && contactListing.length>0){
        for(const myContact of contactListing){
          myContact.contact.isSelected=false;
          for(const shareListItem of item.sharedUIDList){
          if (myContact && myContact.contact && myContact.contact.contactDetail && myContact.contact.contactDetail.docID && (myContact.contact.contactDetail.docID===shareListItem)) {
            myContact.contact.isSelected=true;
          }
        }
      }
    }else{
      for(const myContact of contactListing){
        if (myContact && myContact.contact){
          myContact.contact.isSelected=false;
        }
      }
    }
    console.log(item);

    
    this.setState ({
      selectedItem: item,
      vaultOptionModal: true,
      isOpenDetailModal: true,
      isShowTitleBox: false,
      contactListing:contactListing,
      isSelectedContact: false,
    });
  }

  // _imageEncode (arrayBuffer) {
  //     let u8 = new Uint8Array(arrayBuffer)
  //     let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
  //     let mimetype="image/jpeg"
  //     return "data:"+mimetype+";base64,"+b64encoded
  // }

  async downloadFile  () {
    const {selectedItem} = this.state;

      //  let myBlob = await this.getBlobFromUrl (proxyurl + selectedItem.shareableLink);
      // console.log(myBlob);

      // let myImageData = await this.getDataFromBlob (myBlob);
      // console.log(myImageData);
      // return;


    this.props.Loading (true);
    const currentState = store.getState();
    const { Authreducer } = currentState;
    const options = {
      method: 'GET',
      headers: {
        Authorization:"Bearer " + Authreducer.authToken,
      },
      baseURL:selectedItem.shareableLink,
      responseType : "blob"
    };

    axios(options).then(async responses=>{
      saveAs(responses.data, selectedItem.fileName);
      this.props.Loading (false);
    })
    .catch (err => {
      console.log(err);
  
      this.props.Loading (false);
      if (err && err.message) {
        createNotification ('error', err.message);
      }
    });


    // const options = {
    //   method: 'GET',
    //   headers: {
    //     Authorization:"Bearer " + Authreducer.authToken,
    //   },
    //   baseURL:selectedItem.shareableLink,
    //   responseType : "arraybuffer"
    // };

    // // if(selectedItem.fileType=== "dicom"){
    // // delete (options.responseType)
    // // }

    // axios(options).then(async responses=>{
    //   console.log(responses.data);
    //   // console.log(this._imageEncode(responses.data))
    //   saveAs(this._imageEncode(responses.data), selectedItem.fileName);
    // });







    // const storageRef = firebase.storage ().ref ();
    // var starsRef = storageRef.child (selectedItem.filepath);
    // // Get the download URL
    // starsRef.getDownloadURL ().then (url => {
    //     console.log (url);
    //     saveAs (shareableLink);
    //   })
    //   .catch (error => {
    //     console.log (error);
    //     createNotification ('error', error.message);
    //   });
  }



  copyToClipboard () {
    // const {selectedItem} = this.state;
    // console.log(selectedItem);

    // /* Get the text field */
    // var copyTextString = document.getElementById ('myShareLink');

    // /* Select the text field */
    // copyTextString.select ();
    // copyTextString.setSelectionRange (0, 99999); /*For mobile devices*/

    // console.log(copyTextString)

    /* Copy the text inside the text field */
    // document.execCommand ('copy');


    var copyText = document.getElementById("myShareLink");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();

    this.setState (
      {
        copyText: true,
      },
      () => {
        // setTimeout (() => {
        //   this.setState ({
        //     copyText: false,
        //   });
        // }, 1000);
      }
    );
  }

  putStarFiles () {
    this.props.resetCreatedItem ();
    const {selectedItem} = this.state;
    let sendRequest = {
      data: [{
        docID: selectedItem.docID,
        star: selectedItem.starred ? false : true,
      }],
    };

    if (this.state.userListing && this.state.userListing.length > 0) {
      let index = this.state.userListing.findIndex (
        items => items.docID === this.state.selectedItem.docID
      );
      if (index > -1) {
        this.state.userListing[index].starred = selectedItem.starred
          ? false
          : true;
      }
      this.setState (this.state.userListing);
    }
    this.props.putStar (sendRequest);
  }

  selectedShareUser (user, status) {
    const {contactListing} = this.state;
    const index = contactListing.findIndex (
      emp => emp.contact.contactDetail.docID === user.contactDetail.docID
    ),
      contacts = [...this.state.contactListing]; // important to create a copy, otherwise you'll
    contacts[index].contact.isSelected = status ? true : false;

    let isSelectedItems = false;
    for (let item of contactListing) {
      if (item && item.contact && item.contact.isSelected) {
        isSelectedItems = true;
      }
    }

    this.setState ({
      contactListing: contacts,
      isSelectedContact: isSelectedItems,
    });
  }

  shareFiles () {
    const {
      contactListing,
      selectedItem,
    } = this.state;
    let seletedUsers = [];
    // seletedUsers.push("everyone");
    for (let item of contactListing) {
      if (item && item.contact && item.contact.isSelected) {
        seletedUsers.push (item.contact.contactDetail.docID);
      }
    }
    // console.log (seletedUsers);
    // "userShareList": ["everyone", "gQ8GoJ5LVaQJ83mzLafCXbp7ggl2"],

    let sendRequest = {
      data: {
        docID: selectedItem.docID,
        userShareList: seletedUsers,
        addSharedUser: true,
        unshareAll: seletedUsers.length>0 ?false:true,
      },
    };
    this.props.shareFileWithUsers (sendRequest);

  }

  filterList () {
    this.setState (
      {
        isStarFilter: !this.state.isStarFilter,
      },
      () => {
        this.fetchCollections ();
      }
    );
  }

  getShareWithMe(){
    this.setState (
      {
        isSharedWithMe: !this.state.isSharedWithMe,
      },
      () => {
      if(this.state.isSharedWithMe){
          this.fetchSharedFiles();
      }else{
        this.fetchCollections();
      }

      }
    );
  }

 
  handleResetFileClick = event => {
    const {target = {}} = event || {};
    target.value = '';
  };

  

  /* Start Multiple file zip creator  */
  getBlobFromUrl = myImageUrl => {
    return new Promise ((resolve, reject) => {
      let request = new XMLHttpRequest ();
      request.open ('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve (request.response);
      };
      request.onerror = reject;
      request.send ();
    });
  };
  getDataFromBlob = myBlob => {
    return new Promise ((resolve, reject) => {
      let reader = new FileReader ();
      reader.onload = () => {
        resolve (reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL (myBlob);
    });
  };

  async downloadZip () {
    const {uploadedList, userListing} = this.state;
    let filterData = [];
    console.log(uploadedList);
    console.log(userListing);

    for (let item of uploadedList.concat (userListing)) {
      if (item.size > 0 && item.isSelected) {
        filterData.push (item.docID);
      }
    }
    if (filterData.length === 0) {
      return;
    }
    // console.log(filterData);

    // if(request && request.length>0){
    //   for(let item of request){
    //     if(item.fileUUID){
    //       fileUUIDLinks.push(item.fileUUID)
    //     }
    //   }
    // }
    let sendRequest={
      data:{
        docID:filterData
      }
    }
    // console.log(sendRequest);


    this.props.getVaultBulkDownload(sendRequest);
  }
  /* End Multiple file zip creator  */

  onSelectDownloadFiles (item, status) {
    const {uploadedList} = this.state;

    const index = uploadedList.findIndex (
      emp => emp.fileName === item.fileName
    );
    const newuploadedList = [...this.state.uploadedList]; // important to create a copy, otherwise you'll
    newuploadedList[index].isSelected = status ? false : true;
    this.setState ({
      uploadedList: newuploadedList,
    });
    if(!status && !item.docID){
        let sendRequest = {
          data: {
            filePath: item.size > 0 ? item.fullPath : item.name,
          },
        };
      
        this.props.getvaultGetFileDetails(sendRequest,true);
    }
  }

  bulkDeleteFiles () {
    const {uploadedList} = this.state;
    let filterData = [];

    // console.log(uploadedList);
    for (let item of uploadedList) {
      if (item.isSelected) {
        filterData.push (item);
      }
    }
    if (filterData.length === 0) {
      return;
    }
    let newItems = [];
    let deletedItems=[];
    let olddeleteItems=[];


    for (let item of uploadedList) {
      if (item.isSelected) {
        if(item.fileUUID){
          deletedItems.push(item.fileUUID);
        }else{
          olddeleteItems.push(item);
        }
        item = null;
      }
      if (item) {
        newItems.push (item);
      }
    }

    // console.log(newItems);
    // console.log(uploadedList);
    // console.log(deletedItems);

    this.props.deleteBulkFilesVaultAction (newItems);


    this.setState({
      vaultOptionModal: false
    })

    if(deletedItems.length>0){
      let sendRequest={
        data:{
          fileUUIDList: deletedItems,
          recursive: true
        }
      }
      this.props.deleteVaultBulkFiles(sendRequest);
    }
    if(olddeleteItems.length>0){

    for (let item of olddeleteItems) {
      if (item && item.size > 0) {
        const storageRef = firebase.storage ().ref ();
        // Create a reference to the file to delete
        var desertRef = storageRef.child (
          item.docID ? item.filePath : item.fullPath
        );
        // Delete the file
        desertRef
          .delete ()
          .then (res => {
            // createNotification ('success', 'Deleted Successfully');
            // this.fetchCollections();
            // File deleted successfully
          })
          .catch (error => {
            console.log (error);
            // this.props.Loading (false);
            // createNotification ('error', error.message);
            // Uh-oh, an error occurred!
          });
      }
    }
  }

  }

  onStarMultiple(status){
      const {uploadedList} = this.state;
      let filterData = [];
      for (let item of uploadedList) {
        if (item.isSelected) {
          filterData.push (item);
        }
      }
      if (filterData.length === 0) {
        return;
      }
      let newItems = [];
      for (let item of uploadedList) {
        if (item.isSelected) {
            newItems.push({docID:item.docID ,star: status?true:false});
        }
       
      }

      let sendRequest = {
        data: newItems,
      };

      this.props.putStar (sendRequest);
      createNotification ('success', `All ${status? 'Starred' :  'Unstarred'} Successfully`);

  }



  multipleFilesSelection(item){
    const {isEnabledownload}= this.state;
    if (isEnabledownload) {
      // if (
      //   item &&
      //   item.rawObjectDetails &&
      //   item.rawObjectDetails
      //     .metadata &&
      //   item.rawObjectDetails
      //     .metadata.userFileType &&
      //   item.rawObjectDetails
      //     .metadata.userFileType ===
      //     'file'
      // ) {
        if (item  && item.userFileType ==='file') {
          this.onSelectDownloadFiles (
            item,
            item.isSelected
          );
        }else{
          if (item  && item.userFileType ==='folder' &&  item.fileUUID) {
            this.onSelectDownloadFiles (
              item,
              item.isSelected
            );
          }
        }
    }

  }

  isEnableSelection(){
    this.setState({
      isEnabledownload: !this.state.isEnabledownload
    })
    
  }

  shareExternalFile(status){
    const { selectedItem} = this.state;

    let sendRequest={
      data: {
          docID: selectedItem.docID,
          shareFile: status
      }
    }
    this.props.vaultExternalFileSharing(sendRequest);
  }




    getPrimaryImage =  (base_url) => {

        let productData= null;
    
        const options = {
          method: 'GET',
          headers: {
            Authorization:"Bearer " + this.state.authToken,
          },
          baseURL:base_url,
          responseType: 'arraybuffer'
        };
    
         axios(options).then(response => {
          // console.log("data:image/jpeg;base64,"+Buffer.from(response.data, 'binary').toString('base64'));
          return productData = "data:image/jpeg;base64,"+Buffer.from(response.data, 'binary').toString('base64')
          // return Buffer.from(response.data, 'binary').toString('base64');
            
            // console.log(Buffer.from(response.data, 'binary').toString('base64'));
          })
          .catch((error) => {
            console.log(error);
          });
  }


  
  updateUserImage(){
    this.state.uploadedList.map ((file, index) => {
      if (file && (file.fileType=== "image" || file.fileType=== "dicom") && file.thumbnailLink) {
    

    const options = {
      method: 'GET',
      headers: {
        Authorization:"Bearer " + this.state.authToken,
      },
      baseURL:file.thumbnailLink,
      responseType: 'arraybuffer'
    };
    axios (options)
    .then (response => {
      // this.state.uploadedList[index].base64 = "data:image/jpeg;base64,"+Buffer.from(response.data, 'binary').toString('base64');
      

      const newupdatedPictureList = [...this.state.uploadedList]; // important to create a copy, otherwise you'll
      newupdatedPictureList[index].base64 = "data:image/jpeg;base64,"+Buffer.from(response.data, 'binary').toString('base64');



      this.setState({
        uploadedList: newupdatedPictureList
      },()=>{
      })
    })
    .catch (err => {
      console.log(err);
    });
  }
})
  }

  render () {
    const {
      userListing,
      uploadedList,
      sharingConfirmFileModal,
      searchText,
      isAddnewFile,
      isFilterbyName,
      isFilterbyRecent,
      fileShareModal,
      vaultOptionModal,
      isListview,
      addnewFolder,
      newFolderName,
      sortByName,
      sortByType,
      selectedDetailStack,
      selectedItem,
      contactListing,
      searching,
      businessId,
      isStarFilter,
      isShowTitleBox,
      isAccess,
      isEnabledownload,
      isGetResponse,
      isOpenStack,
      sharingFileModal,
      externalSharingInfo,
      copyText,
      // sharedWithmeItems,
      // isSharedWithMe
    } = this.state;

    let filterData = uploadedList.concat (userListing);
    let checkMultipleSelection= false;
    for(let items of filterData){
      if(items.isSelected){
        checkMultipleSelection= true;
      }

    }


  

    // let filterContactListing= contactListing;

    let filterContactListing = searching
      ? contactListing.filter (
          row =>
            (row.contact &&
              row.contact.contactDetail &&
              row.contact.contactDetail.firstName &&
              (row.contact.contactDetail.firstName +
                ' ' +
                row.contact.contactDetail.lastName)
                .toLowerCase ()
                .indexOf (searching.toLowerCase ()) > -1) ||
            (row.contact &&
              row.contact.contactDetail &&
              row.contact.contactDetail.emailAddress &&
              row.contact.contactDetail.emailAddress
                .toLowerCase ()
                .indexOf (searching.toLowerCase ()) > -1)
        )
      : contactListing;

    // let folderItem = [];
    // let filelistItem = [];




    // for (let item of filterData) {
    //   if (
    //     item &&
    //     item.rawObjectDetails &&
    //     item.rawObjectDetails.metadata &&
    //     item.rawObjectDetails.metadata.userFileType &&
    //     item.rawObjectDetails.metadata.userFileType === 'folder'
    //   ) {
    //     folderItem.push (item);
    //   } else {
    //     filelistItem.push (item);
    //   }
    // }

    return (
      <div>

        {isAccess &&
          <Dropzone
            style={{outline: 'none'}}
            onDrop={acceptedFiles => {
              // console.log(acceptedFiles)
              if (acceptedFiles && acceptedFiles.length > 0) {
                this.uploadFileDropZoneFile (acceptedFiles);
              }
            }}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
              isFileTooLarge,
            }) => (
              <div {...getRootProps ()} >
                {/*<input {...getInputProps ()} />*/}

                {/* <input type="file" webkitdirectory mozdirectory directory /> */}

                {!isDragActive && <div />}
                {isDragActive &&
                  !isDragReject &&
                  <div className="dropBoxArea" id="vaultDrive1">
                    <div className="dropBoxMainArea"  id="vaultDrive2">

                      <p className="dropFiletitle" style={{fontSize: 16}}  id="vaultDrive3">
                        {' '}Drop files to instantly upload them to
                      </p>
                      <p
                        className="dropFiletitle"
                        style={{
                          fontSize: 22,
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                        id="vaultDrive4"
                      >

                        <img
                          src={folderIcon}
                          alt="file type"
                          style={{
                            width: 28,
                            height: 23,
                            marginRight: 10,
                          }}
                          id="vaultDrive5"
                        />
                        {selectedDetailStack && selectedDetailStack.length > 0
                          ? selectedDetailStack[selectedDetailStack.length - 1]
                              .fileName
                          : 'My Vault'}

                      </p>

                    </div>
                  </div>}
                {isDragReject && 'File type not accepted, sorry!'}
                {isFileTooLarge &&
                  <div className="text-danger mt-2"  id="vaultDrive6">
                    File is too large.
                  </div>}

                <div className="fileDriveContainer"  id="vaultDrive7">
                  {isShowTitleBox &&
                    selectedDetailStack &&
                    selectedDetailStack.length === 0 &&
                    <div>
                      <div className="rdee-vaultTitle"  id="vaultDrive8">R-DEE Vault</div>
                      <div className="rdee-vaultSubTitle"  id="vaultDrive9">
                        Securely store, share and manage your files
                      </div>

                    </div>}
                  <div style={{display: 'inline-flex'}}  id="vaultDrive10">
                    <div
                      className="vaultInnerContainer"
                      style={{
                        background: isDragActive && !isDragReject
                          ? 'rgba(51, 51, 51, 0.5)'
                          : '',
                      }}
                      id="vaultDrive11"
                    >
                      <div className="topFileHeader"  id="vaultDrive11">
                        <div>
                          {/*<Button
                              onClick={() => this.fetchCollections ()}
                              className="selectFileButton"
                            >
                              Refresh
                            </Button>
                          {filterData.length > 0 &&
                            <Button
                              onClick={() =>
                                this.setState ({
                                  isEnabledownload: !isEnabledownload,
                                })}
                              className="selectFileButton"
                            >
                              {isEnabledownload ? 'Unselect ' : 'Select '} Files
                              </Button>}
                          {isEnabledownload &&
                            <Button
                              className="selectFileButton"
                              onClick={() => this.downloadZip ()}
                            >
                              Download
                            </Button>}
                          {isEnabledownload &&
                            <Button
                              className="selectFileButton"
                              onClick={() => this.bulkDeleteFiles ()}
                            >
                              Delete
                            </Button>}

                            {isEnabledownload &&
                              <Button
                                className="selectFileButton"
                                onClick={() => this.onStarMultiple (true)}
                              >
                              Starred
                              </Button>}
                              {isEnabledownload &&
                                <Button
                                  className="selectFileButton"
                                  onClick={() => this.onStarMultiple (false)}
                                >
                                Unstarred
                                </Button>*/}

                            

                        </div>
                      </div>
                      <div className="topFileHeader"  id="vaultDrive12">
                        <div>

                          <div className="myVaultTitle"  id="vaultDrive13">
                            <div
                              style={{
                                cursor: businessId ? 'pointer' : 'inherit',
                              }}
                              onClick={() => {
                                if (businessId) {
                                  this.openFolderFromStack ('reset');
                                }
                              }}
                              id="vaultDrive14"
                            >
                              MY<span>VAULT </span>
                            </div>
                            {selectedDetailStack &&
                              selectedDetailStack.length > 3 &&<div  id="vaultDrive15" className="stackFilenameBox"   ref={this.openVaultFolderStatck}>

                              <img
                              style={{
                                width: 11,
                                height: 18,
                                marginRight: 10,
                              }}
                              src={forwordArrow}
                              alt="img"
                              id="vaultDrive16"
                            />
                              <Button  id="vaultDrive17" className="folderStackButton" onClick={() =>
                                this.setState ({
                                  isOpenStack: !isOpenStack,
                                })}>
                                •••
                               
                              </Button>



                              {
                                <div
                                  style={{
                                    display: isOpenStack ? 'block' : 'none',
                                  }}
                                  id="vaultDrive18"
                                >
                                  <div className="folderStackOpenContent"  id="vaultDrive19">

                                    {selectedDetailStack &&
                                      selectedDetailStack.length > 0  &&
                                      selectedDetailStack.map ((item, i) => (<div
                                        id={`vaultDriveStackList${i}`}
                                        key={i}
                                        className="listText"
                                        onClick={() => {
                                          this.openFolderFromStack (i);
                                        }}
                                      >
                                      <img
                                        className="listedIcons"
                                        src={blackFolderIcon}
                                        alt="folderIcon"
                                        id={`vaultDriveStackListimg${i}`}
                                      />
                                      <div className="overFlowFolderName"  id={`vaultDriveStackListname${i}`}>{item.fileName}</div>

                                    </div>))}
                                  </div>
                                </div>
                              }
                                 
                            
                            </div>}
                            { selectedDetailStack &&
                              selectedDetailStack.length > 3 &&
                                <div
                                  style={{cursor: 'pointer',paddingLeft:10}}
                                  className="alignItem"
                                  onClick={() => this.openFolderFromStack (1)}
                                  id="vaultDrive22"
                                >
                                  {/*<Button className="filterFileButton">

                                    <img
                                      style={{
                                        width: 11,
                                        height: 18,
                                        marginRight: 10,
                                      }}
                                      src={forwordArrow}
                                      alt="img"
                                    />
                                    </Button>*/}
                                    <div className="overflowFolderText"  id="vaultDrive23">
                                 { selectedDetailStack[1].fileName}
                                 </div>
                                </div>}

                                { selectedDetailStack &&
                                  selectedDetailStack.length >= 4 &&
                                    <div
                                      style={{cursor: 'pointer',paddingLeft:10}}
                                      className="alignItem"
                                      onClick={() => this.openFolderFromStack ([selectedDetailStack.length-1])}
                                      id="vaultDrive24"
                                    >
                                      {<Button className="filterFileButton" id="vaultDrive25">
    
                                        <img
                                          style={{
                                            width: 11,
                                            height: 18,
                                            marginRight: 10,
                                          }}
                                          src={forwordArrow}
                                          alt="img"
                                          id="vaultDrive26"
                                        />
                                        </Button>}
                                        <div className="overflowFolderText" id="vaultDrive27">
                                      { selectedDetailStack[selectedDetailStack.length-1].fileName}
                                      </div>
                                     
                                    </div>}

                            {selectedDetailStack &&
                              (selectedDetailStack.length > 0 && selectedDetailStack.length < 4 )&&
                              selectedDetailStack.map ((item, i) => (
                                <div
                                  style={{cursor: 'pointer'}}
                                  key={i}
                                  className="alignItem"
                                  onClick={() => this.openFolderFromStack (i)}
                                  id={`vaultListingStackBox${i}`}
                                >
                                  <Button className="filterFileButton" id={`vaultListingStackBoxButton${i}`}>

                                    <img
                                      style={{
                                        width: 11,
                                        height: 18,
                                        marginRight: 10,
                                      }}
                                      src={forwordArrow}
                                      alt="img"
                                      id={`vaultListingStackforwordArrow${i}`}
                                    />
                                  </Button>
                                  <div className="overflowFolderText" d={`vaultListingStackforwordName${i}`}>
                                  {item.fileName}
                                  </div>
                                </div>
                                    ))}
                            <div
                              ref={this.addNewVault}
                              style={{paddingBottom: 5}}
                            >
                              <Button
                                className="filterFileButton"
                                onClick={() =>
                                  this.setState ({
                                    isAddnewFile: !isAddnewFile,
                                  })}

                                  id="filterFileAddButton"
                              >
                                <img
                                  style={{width: 25, height: 15}}
                                  src={largeDownWhite}
                                  alt="img"
                                  id="filterFileAddButtonImg"
                                />
                              </Button>
                              {
                                <div
                                  style={{
                                    position: 'relative',
                                    display: isAddnewFile ? 'block' : 'none',
                                  }}
                                >
                                  {/*<div style={{textAlign: 'right'}}>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="addVaultPopupBoxArrow"
                          >
                            <path d="M9 0L0 18H18L9 0Z" fill="#FFFFFF" />
                          </svg>
                      </div>*/}
                                  <div className="addVaultPopup"  id="vaultaddVaultPopupBox"> 

                                    <div
                                      className="listText"
                                      onClick={() => {
                                        this.closeModals ();
                                        this.setState ({
                                          addnewFolder: true,
                                        });
                                      }}
                                      id="vaultaddVaultPopuplistText"
                                    >
                                      <img
                                        className="listedIcons"
                                        src={blackFolderIcon}
                                        alt="folderIcon"
                                        id="vaultaddVaultPopuplblackFolderIcon"
                                      />
                                      <div>New Folder</div>

                                    </div>
                                    <label
                                      className="listText"
                                      onClick={() => this.closeModals ()}
                                      style={{
                                        borderBottom: 0,
                                        paddingBottom: 0,
                                      }}
                                    >
                                      <img
                                        style={{width: 20, height: 23}}
                                        className="listedIcons"
                                        src={blackFilesIcon}
                                        alt="folderIcon"
                                        id="vaultaddVaultPopuplblackFilesIcon"
                                      />
                                      <div>Upload Files</div>

                                      <input
                                        type="file"
                                        onChange={e => this.uploadFile (e)}
                                        onClick={this.handleResetFileClick}
                                        multiple
                                      />

                                    </label>
                                  </div>
                                </div>
                              }
                            </div>

                            <div>
                              <label
                                style={{
                                  cursor: businessId ? 'pointer' : 'inherit',
                                }}
                                disabled={!businessId}
                                className="fileuploading"
                                id="vaultaddVaultPopuplfileuploading"
                              >
                                <img src={addIcon} alt="addIcon"  id="vaultaddVaultPopuplfileuploadingaddIcon"/>
                                Add to Vault
                                <input
                                  type="file"
                                  onChange={e => this.uploadFile (e)}
                                  multiple
                                  onClick={this.handleResetFileClick}
                                />
                              </label>

                            </div>

                          </div>
                        </div>
                        <div>
                          <div style={{display: 'inline-flex'}}>
                            {searchText &&
                              searchText.length > 0 &&
                              <Button
                                className="filterFileButton"
                                style={{
                                  position: 'relative',
                                  left: 340,
                                  zIndex: 2,
                                }}
                                onClick={() => this.searchText ('')}
                                id="vaultfilterFileButtonClear"
                              >
                                Clear
                              </Button>}
                            <img
                              className="searchImageIcon"
                              src={searchIcon}
                              alt="searchIcon"
                              id="vaultfilterFileButtonsearchIcon"
                            />
                            <input
                              className="searchFileinput"
                              type="text"
                              placeholder="Search my Vault"
                              value={searchText}
                              onChange={val => this.searchText (val)}
                              readOnly={!businessId}
                              style={{
                                cursor: businessId ? 'initial' : 'initial',
                              }}
                              id="vaultfilterFileButtonsearchFileinput"
                            />

                            <Button
                              className="filterFileButton"
                              style={{marginLeft: 10, marginRight: 10}}
                              onClick={() => this.selectListView (false)}
                              id="vaultfilterFileButtonfilterFileButton"
                            >
                              <img
                                id="vaultfilterFileButtonfilterFileButtonimg"
                                style={{width: 25, height: 25}}
                                src={
                                  isListview ? whiteGridDisable : whiteGridView
                                }
                                alt="img"
                              />
                            </Button>
                            <Button
                              className="filterFileButton"
                              onClick={() => this.selectListView (true)}
                              id="vaultlistViewButton"
                            >
                              <img
                                id="vaultlistViewButtonimg"
                                style={{width: 25, height: 25}}
                                src={
                                  isListview ? whiteListIcon : whiteListDisable
                                }
                                alt="img"
                              />
                            </Button>

                          </div>
                        </div>
                      </div>
                      {businessId
                        ? <div>
                            {isListview &&
                              <div id="vaultlistisListview">
                                {filterData.length > 0
                                  ? <div>
                                      {/*<InfiniteScroll
                                        pageStart={0}
                                        initialLoad={false}
                                        loadMore={this.fetchCollections}
                                        // hasMore={true}
                                        hasMore={
                                          nextPageToken && !searchText
                                            ? true
                                            : false
                                        }
                                        loader={
                                          <div className="loadingFiles" key={0}>
                                            {' '}Loading ...
                                          </div>
                                        }
                                      >*/}
                                      <div style={{display: 'table-caption'}} id="vaultlistTable">
                                        <div className="uploadingList uploadingListHeader" id="vaultlistuploadingListHeader">

                                          <div ref={this.nameSortByFilter}>

                                            <div
                                              className="headerNameTitle"
                                              style={{
                                                paddingLeft: 62,
                                                marginRight: 60,
                                              }}
                                              id="vaultlistheaderNameTitle"
                                            >
                                              Name
                                              <Button
                                                className="filterFileButton"
                                                onClick={() =>
                                                  this.setState ({
                                                    isFilterbyName: !isFilterbyName,
                                                  })}
                                                  id="vaultlist50"
                                              >
                                                <img
                                                  src={smallDownWhite}
                                                  alt="img"
                                                  id="vaultlist51"
                                                />
                                              </Button>
                                            </div>

                                            {isFilterbyName &&
                                              <div
                                                style={{
                                                  position: 'relative',
                                                  width: 250,
                                                }}
                                              >

                                                <div
                                                  className="nameFilterVaultPopup"
                                                  onClick={() =>
                                                    this.closeModals ()}
                                                    id="vaultlist52"
                                                >

                                                  <div
                                                    id="vaultlist53"
                                                    className="listText"
                                                    onClick={() =>
                                                      this.sortByNameData (
                                                        'asc'
                                                      )}
                                                  >
                                                    <div className="checkBoxDiv"  id="vaultlist54">
                                                      {sortByName === 'asc' &&
                                                        <img
                                                          className="checkBlueimage"
                                                          src={blueCheckmark}
                                                          alt="blueCheckmark"
                                                          id="vaultlist55"
                                                        />}
                                                    </div>
                                                    Sort by Name A-Z

                                                  </div>
                                                  <div
                                                    id="vaultlist56"
                                                    className="listText"
                                                    onClick={() =>
                                                      this.sortByNameData (
                                                        'desc'
                                                      )}
                                                    style={{borderBottom: 0}}
                                                  >
                                                    <div className="checkBoxDiv"  id="vaultlist57">
                                                      {sortByName === 'desc' &&
                                                        <img
                                                          className="checkBlueimage"
                                                          src={blueCheckmark}
                                                          alt="blueCheckmark"
                                                          id="vaultlist58"
                                                        />}
                                                    </div>
                                                    Sort by Name Z-A

                                                  </div>
                                                  {/*<div
                                className="listText"
                                onClick={() => {
                                }}
                              >
                                <div className="checkBoxDiv">
                                  {sortByName === 'fileType' &&
                                    <img
                                      className="checkBlueimage"
                                      src={blueCheckmark}
                                      alt="blueCheckmark"
                                    />}
                                </div>
                                Sort by File Type

                              </div>*/}
                                                </div>
                                              </div>}
                                          </div>
                                          <div ref={this.mostRecentByFilter}  id="vaultlist59">
                                            <div className="headerDateTitle"  id="vaultlist60">
                                              Most Recent Activity
                                              <Button
                                                className="filterFileButton"
                                                onClick={() =>
                                                  this.setState ({
                                                    isFilterbyRecent: !isFilterbyRecent,
                                                  })}
                                                  id="vaultlist61"
                                              >
                                                <img
                                                  src={smallDownWhite}
                                                  alt="img"
                                                  id="vaultlist62"
                                                />
                                              </Button>
                                            </div>
                                            {isFilterbyRecent &&
                                              <div
                                                style={{
                                                  position: 'relative',
                                                  width: 200,
                                                }}
                                                id="vaultlist63"
                                              >
                                                <div className="filterVaultRecentPopup" id="vaultlist64">

                                                  <div
                                                    className="listText"
                                                    onClick={() => {
                                                      this.sortByType (
                                                        'recent'
                                                      );
                                                      this.closeModals ();
                                                    }}
                                                    id="vaultlist65"
                                                  >
                                                    <div className="checkBoxDiv" id="vaultlist66">
                                                      {sortByType ===
                                                        'recent' &&
                                                        <img
                                                          className="checkBlueimage"
                                                          src={blueCheckmark}
                                                          alt="blueCheckmark"
                                                          id="vaultlist67"
                                                        />}
                                                    </div>
                                                    {' '}
                                                    Most Recent Activity

                                                  </div>
                                                  <div
                                                    className="listText"
                                                    id="vaultlist68"
                                                    onClick={() => {
                                                      this.sortByType (
                                                        'created'
                                                      );
                                                      this.closeModals ();
                                                    }}
                                                    style={{borderBottom: 0}}
                                                  >

                                                    <div className="checkBoxDiv" id="vaultlist69">
                                                      {sortByType ===
                                                        'created' &&
                                                        <img
                                                          className="checkBlueimage"
                                                          src={blueCheckmark}
                                                          alt="blueCheckmark"
                                                          id="vaultlist70"
                                                        />}
                                                    </div>

                                                    Oldest Activity

                                                  </div>
                                                </div>
                                              </div>}
                                          </div>
                                          <div className="headerDateTitle" id="vaultlist71">
                                            Created
                                          </div>
                                          <div className="headerFileSizeTitle" id="vaultlist72">
                                            File Size
                                          </div>
                                          <div className="fileActionBox" id="vaultlist73">
                                          {filterData.length > 0 &&
                                            <Button
                                              onClick={() =>this.isEnableSelection()}
                                              className="selectFileButton"
                                              id="vaultlist74"
                                            >
                                              {isEnabledownload ? 'Unselect ' : 'Select '}
                                            </Button>}
                                          </div>

                                        </div>
                                              <div id="vaultlist75" className="innerTableScroller" style={{height:`calc(100vh - ${isShowTitleBox?300:210}px)`}}>
                                        {filterData.length > 0 &&
                                          filterData.map ((item, i) => {
                                            return (
                                            <div
                                              id={`vaultlistuploadingMainBox${i}`}
                                              key={i}
                                              className="uploadingMainBox"
                                              style={{
                                                opacity: isEnabledownload &&
                                                  (item.userFileType === 'folder' && !item.fileUUID )
                                                  ? 0.5
                                                  : '',

                                                cursor: isEnabledownload && (item.userFileType === 'file' || (item.userFileType === 'folder' && item.fileUUID ))  ? 'pointer' : '',
                                                background: isEnabledownload &&
                                                  item.isSelected
                                                  ? '#4453D6'
                                                  :  (item.fileType ===
                                                    'dicom'? '#17171D':'#333333'),
                                              }}
                                              
                                            >
                                            <div  className="uploadingList"  id={`vaultlistuploadingMainBoxinner${i}`}>
                                          
                                              {/* Files*/}
                                              {item && item.userFileType === 'file'
                                                ? <div className="fileTypeBox" onClick={() => {
                                                  this.multipleFilesSelection(item);
                                              }}  id={`vaultlistuploadingMainfileTypeBox${i}`}>
                                                    {item.fileType ===
                                                      'dicom' &&
                                                      <img
                                                        id={`vaultlistuploadingdicomImg${i}`}
                                                        // src={pacsIcon}
                                                        onError={e => {
                                                          e.target.onerror = null;
                                                          e.target.src = pacsIcon;
                                                        }}
                                                        src={item.base64 ? item.base64: pacsIcon}
                                                        alt="file type"
                                                        style={{
                                                          width: 26,
                                                          height: 28,
                                                        }}
                                                      />}
                                                    {item.fileType ===
                                                      'other' &&
                                                      <img
                                                        id={`vaultlistuploadingOtherImg${i}`}
                                                        src={whiteFileIcon}
                                                        alt="file type"
                                                        style={{
                                                          width: 20,
                                                          height: 30,
                                                        }}
                                                      />}
                                                    {item.fileType ===
                                                      'image' &&
                                                      <img
                                                        // src={whiteCameraIcon}
                                                        onError={e => {
                                                          e.target.onerror = null;
                                                          e.target.src = whiteCameraIcon;
                                                        }}
                                                        src={item.base64 ? item.base64: whiteCameraIcon}

                                                        

                                                        // src={
                                                        //   item.thumbnailLink
                                                        //     ? item.thumbnailLink: whiteCameraIcon
                                                        // }
                                                        
                                                        alt="file type"
                                                        style={{
                                                          width: 28,
                                                          height: 28,
                                                        }}
                                                        id={`vaultlistuploadingFilteTypeImg${i}`}

                                                      />}
                                                    {(item.fileType ===
                                                      'video' ||
                                                      item.fileType ===
                                                        'audio') &&
                                                      <img
                                                        src={whitePlayIcon}
                                                        alt="file type"
                                                        style={{
                                                          width: 30,
                                                          height: 30,
                                                        }}
                                                        id={`vaultlistuploadingAudioVideo${i}`}

                                                      />}

                                                  </div>
                                                : <div
                                                    className="fileTypeBox"
                                                    style={{
                                                      cursor: isEnabledownload
                                                        ? ''
                                                        : 'pointer',
                                                    }}
                                                    onClick={() => {
                                                      if (!isEnabledownload) {
                                                        this.openFolder (item);
                                                      }else{
                                                        this.multipleFilesSelection(item);
                                                      }
                                                    }}
                                                    id={`vaultlistuploadingFileTypeBox${i}`}

                                                  >
                                                    <img
                                                      src={folderIcon}
                                                      alt="file type"
                                                      style={{
                                                        width: 28,
                                                        height: 23,
                                                      }}
                                                      id={`vaultlistuploadingFileTypeBoxfolderIcon${i}`}
                                                    />

                                                  </div>}

                                              {item && item.userFileType !== 'file'
                                                ? <div
                                                    className="fileNameText"
                                                    style={{
                                                      cursor: isEnabledownload
                                                        ? ''
                                                        : 'pointer',
                                                    }}
                                                    onClick={() => {
                                                      if (!isEnabledownload) {
                                                        this.openFolder (item);
                                                      }else{
                                                        this.multipleFilesSelection(item);
                                                      }
                                                    }}
                                                    id={`vaultlistuploadingFileTypefileNameText${i}`}

                                                  >
                                                    {item.fileName}
                                                  </div>
                                                : <div className="fileNameText" id={`vaultlistuploadingFileTypefileName${i}`} onClick={() => {
                                                  this.multipleFilesSelection(item);
                                              }}>
                                                    {item.fileName}
                                                  </div>}

                                              <div className="fileDateBox" id={`vaultlistuploadingFileTypeDateBox${i}`} onClick={() => {
                                                this.multipleFilesSelection(item);
                                            }}>
                                                {item.lastUpdated
                                                  ? vaultDataTimeFormat (
                                                      item.lastUpdated
                                                    )
                                                  : 'N/A'}
                                              </div>
                                              <div className="fileDateBox" id={`vaultlistuploadingFileTypeCreatedDateBox${i}`}  onClick={() => {
                                                this.multipleFilesSelection(item);
                                            }}>
                                                {item.updated
                                                  ? vaultDataTimeFormat (
                                                      item.updated
                                                    )
                                                  : 'N/A'}

                                              </div>

                                              {item.progress &&
                                                item.progress <= 100 &&
                                                <div className="fileStatusBox">
                                                  <Progress
                                                    value={
                                                      item.progress
                                                        ? item.progress
                                                        : 0
                                                    }
                                                    className="uploadProgressBar"
                                                    id={`vaultlistuploadingProgressbarlist${i}`}
                                                  />
                                                  {' '} {item.progress} %

                                                </div>}

                                              {!item.progress &&
                                                <div className="fileSizeBox" id={`vaultlistuploadingProgressbarSizelist${i}`} onClick={() => {
                                                  this.multipleFilesSelection(item);
                                              }}>
                                                  {item.size && item.userFileType ===
                                                      'file'
                                                    ? formatFileSize (item.size)
                                                    : ''}
                                                </div>}
                                              {!item.progress &&
                                                <div className="fileActionBox" id={`vaultlistuploadingMenuBox${i}`}>
                                                  {/*<Button
                              className="shareFileButton"
                              style={{marginRight: 10}}
                              
                              onClick={() =>{
                                this.selectItems(item)
                                
                                // this.setState ({
                                //   fileShareModal: true,
                                //   selectedItem:item
                                // })

                              }
                                }
                            >
                              Share
                              </Button>*/}
                                                  {item.starred
                                                    ? <Button className="fileMenuOption" id={`vaultlistuploadingMenuBoxStarButton${i}`}>
                                                        <img
                                                          src={starIcon}
                                                          alt="starIcon"
                                                          id={`vaultlistuploadingMenuBoxStarButtonImg${i}`}
                                                        />
                                                      </Button>
                                                    : <div
                                                        style={{width: 30}}
                                                        className="fileMenuOption"
                                                      />}
                                                  <Button
                                                    id={`vaultlistuploadingMenuBoxFileOptionButton${i}`}
                                                    className="fileMenuOption"
                                                    data-rh="Menu"
                                                    style={{
                                                      marginLeft: 5,
                                                      lineHeight: '20px',
                                                      fontSize: 30,
                                                      cursor: isEnabledownload ?  ((item.userFileType === 'file' || (item.userFileType === 'folder' && item.fileUUID ))? 'pointer' : 'initial') : 'pointer',
                                                    }}
                                                    onClick={() => {
                                                      if ( isEnabledownload && (item.userFileType === 'file' || (item.userFileType === 'folder' && item.fileUUID ))  && isEnabledownload) {
                                                        this.selectItems (item);
                                                      }else{
                                                        if(!isEnabledownload){
                                                          this.selectItems (item);
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    •••
                                                  </Button>
                                                  

                                                  

                                                </div>}
                                                </div>

                                                {item.dicomFields && <div id={`vaultDicomField${i}`} style={{paddingLeft:70,paddingRight:10,cursor:'initial'}}>
                                                  
                                                  <div id={`vaultDicomSpecification${i}`}   className="dicomSpecification" >

                                                  <div  id={`vaultDicomSpecificationDetail${i}`}   className="dicomSpecificationDetailBox" style={{borderBottom:'1px solid #FFFFFF90'}}>
                                          
                                                    <div className="dicomSpecificationText"  id={`vaultDicomSpecificationHeaderPatientID${i}`} >
                                                        <div style={{  fontSize:14,opacity:0.7}} >Patient Id</div>
                                                         </div>
                                                    <div className="dicomSpecificationText"  id={`vaultDicomSpecificationHeaderPatientName${i}`}>
                                                        <div style={{  fontSize:14,opacity:0.7}}>Patient Name</div>
                                                         </div>
                                                    <div className="dicomSpecificationText"  id={`vaultDicomSpecificationHeaderPatientDOB${i}`}>
                                                        <div style={{  fontSize:14,opacity:0.7}}>Date Of Birth</div>
                                                        </div>
                                                    <div className="dicomSpecificationText"  id={`vaultDicomSpecificationHeaderPatientStudyDate${i}`}>
                                                        <div style={{  fontSize:14,opacity:0.7}}>Study Date</div>
                                                         </div>
                                                   
                                                    <div className="dicomSpecificationText"  id={`vaultDicomSpecificationHeaderPatientBodyPart${i}`}>
                                                        <div style={{  fontSize:14,opacity:0.7}}>Body Part Examined </div>
                                                         </div>
                                                    </div>
                                                    </div>
                                                    <div  className="dicomSpecification"  id={`vaultDicomSpecificationBodyBox${i}`} >
                                                  <div  className="dicomSpecificationDetailBox"  id={`vaultDicomSpecificationBodyBoxing${i}`} >
                                          
                                                    <div className="dicomSpecificationText" id={`vaultDicomSpecificationHeaderPatientIDValue${i}`}>
                                                        <div style={{fontSize:13}} >{item.dicomFields.PatientID? item.dicomFields.PatientID:'N/A'}</div>
                                                    </div>
                                                    <div className="dicomSpecificationText" id={`vaultDicomSpecificationHeaderPatientNameValue${i}`}>
                                                        <div style={{fontSize:13}}>
                                                        {item.dicomFields.PatientName && item.dicomFields.PatientName && item.dicomFields.PatientName.Alphabetic? item.dicomFields.PatientName.Alphabetic:
                                                       ( item.dicomFields.PatientName? item.dicomFields.PatientName:
                                                        'N/A')}
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="dicomSpecificationText" id={`vaultDicomSpecificationHeaderPatientDOBValue${i}`}>
                                                        <div style={{fontSize:13}}>{item.dicomFields.PatientBirthDate? vaultDataTimeFormat(item.dicomFields.PatientBirthDate):'N/A'}</div>
                                                    </div>
                                                    <div className="dicomSpecificationText" id={`vaultDicomSpecificationHeaderPatientStudyValue${i}`}>
                                                        <div style={{fontSize:13}}>{item.dicomFields.StudyDate? dataFormat(item.dicomFields.StudyDate):'N/A'}</div>
                                                    </div>
                                                   
                                                    <div className="dicomSpecificationText" id={`vaultDicomSpecificationHeaderPatientBodyPartValue${i}`}>
                                                        <div style={{fontSize:13}}>{item.dicomFields.BodyPartExamined? item.dicomFields.BodyPartExamined:'N/A'}</div>
                                                    </div>
                                                    </div>
                                                   
                                                    <div>
                                                    {item.dicomFields &&<Button
                                                      className={" fileMenuOption "}
                                                      data-rh={item.isOpen ? "LESS":"MORE"}
                                                      style={{
                                                        display:'flex',
                                                        alignItems:'center',
                                                        opacity:0.7
                                                      }}
                                                      id={`vaultDicomSpecificationHeaderPatientCollapse${i}`}
                                                      onClick={() => {
                                                        const newupdateList = [...this.state.uploadedList]; // important to create a copy, otherwise you'll
                                                        newupdateList[i].isOpen = newupdateList[i].isOpen ? false : true;
                                                        this.setState ({
                                                          uploadedList: newupdateList,
                                                        });
                                                      }}
                                                    >
                                                      
                                                      <span style={{fontSize:12,marginRight:5}}> {item.isOpen ?'LESS' :'MORE'}</span>
                                                      <img id={`vaultDicomSpecificationHeaderPatientCollapseImg${i}`} className={(item.isOpen?' rotateArrow180 ' :' ')} style={{width:20}} src={downWhiteArrow} alt="downWhiteArrow" />
                                                    </Button>}
                                                    </div>
                                                    </div>

                                                   {item.isOpen &&  <div style={{borderTop:'1px dashed #FFFFFF80',marginTop:5,width:1025}} >

                                                   
                                                    
                                                      <div   id={`vaultDicomSpecificationHeaderPatientCollapseGrid${i}`}  className="dicomSpecificationGrid" >
                                                        <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientAccessionNumber${i}`}>AccessionNumber </div>
                                                          <div className="dicomSpecificationGridValue" id={`vaultDicomSpecificationHeaderPatientAccessionNumberValue${i}`}>{item.dicomFields.AccessionNumber? item.dicomFields.AccessionNumber:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientManufacturer${i}`}>Manufacturer </div>
                                                          <div className="dicomSpecificationGridValue"  id={`vaultDicomSpecificationHeaderPatientManufacturerValue${i}`}>{item.dicomFields.Manufacturer? item.dicomFields.Manufacturer:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientModality${i}`}>Modality </div>
                                                          <div className="dicomSpecificationGridValue"  id={`vaultDicomSpecificationHeaderPatientModalityValue${i}`}>{item.dicomFields.Modality? item.dicomFields.Modality:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientPatientAge${i}`}>  PatientAge </div>
                                                          <div className="dicomSpecificationGridValue" id={`vaultDicomSpecificationHeaderPatientPatientAgeValue${i}`}>{item.dicomFields.PatientAge? item.dicomFields.PatientAge:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientPatientBirthDate${i}`}>PatientBirthDate </div>
                                                          <div className="dicomSpecificationGridValue"  id={`vaultDicomSpecificationHeaderPatientPatientBirthDateValue${i}`}>{item.dicomFields.PatientBirthDate? item.dicomFields.PatientBirthDate:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientPatientSex${i}`}>PatientSex </div>
                                                          <div className="dicomSpecificationGridValue" id={`vaultDicomSpecificationHeaderPatientPatientSexValue${i}`}>{item.dicomFields.PatientSex? item.dicomFields.PatientSex:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientPatientWeight${i}`}>PatientWeight </div>
                                                          <div className="dicomSpecificationGridValue"  id={`vaultDicomSpecificationHeaderPatientPatientWeightValue${i}`}>{item.dicomFields.PatientWeight? item.dicomFields.PatientWeight:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">

                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientReferringPhysicianName${i}`}>Referring Physician Name </div>
                                                          <div className="dicomSpecificationGridValue"  id={`vaultDicomSpecificationHeaderPatientReferringPhysicianNameValue${i}`}>{item.dicomFields.ReferringPhysicianName && item.dicomFields.ReferringPhysicianName.Alphabetic? item.dicomFields.ReferringPhysicianName.Alphabetic:'N/A'} </div>
                                                          </div>
                                                          <div className="dicomSpecificationGridBox">
                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientStudyDescription${i}`}>Study Description  </div>
                                                          <div className="dicomSpecificationGridValue" id={`vaultDicomSpecificationHeaderPatientStudyDescriptionValue${i}`}>{item.dicomFields.StudyDescription? item.dicomFields.StudyDescription:'N/A'} </div>
                                                          </div>

                                                          <div className="dicomSpecificationGridBox">
                                                          <div className="dicomSpecificationGridLabel"  id={`vaultDicomSpecificationHeaderPatientBodyPartExamined${i}`}>Body Part Examined  </div>
                                                          <div className="dicomSpecificationGridValue" id={`vaultDicomSpecificationHeaderPatientBodyPartExaminedValue${i}`}>{item.dicomFields.BodyPartExamined? item.dicomFields.BodyPartExamined:'N/A'} </div>
                                                          </div>

                                                          


                                                          </div>
                                                    </div>}

                                                    
                                                </div>}

                                            </div>
                                          )})}

                                          </div>
                                        {/*filterData.length > 0 &&
                  filterData.map ((item, i) => (
                    <div className="uploadingList" key={i}>
                      <div className="fileNameText">{item.fileName}</div>

                      {item.progress < 100
                        ? <div className="fileStatusBox">
                            <Progress
                              value={item.progress ? item.progress : 0}
                              className="uploadProgressBar"
                            />
                            {' '} {item.progress} %

                          </div>
                        : <div className="fileStatusBox">
                            <Button
                              className="viewFileDocument"
                              onClick={() => this.downloadFile (item.filePath)}
                            >
                              Download
                            </Button>
                            <Button
                              className="viewFileDocument"
                              onClick={() =>
                                this.deleteFile (item.deleteFile, i, 0)}
                            >
                              <img
                                style={{width: 20}}
                                src={deleteIcon}
                                alt="deleteIcon"
                              />
                            </Button>

                          </div>}

                    </div>
                              ))*/}
                                      </div>
                                      {/*</InfiniteScroll>*/}

                                    </div>
                                  : <div className="noVaultFile">
                                      {isGetResponse ? 'No Data Found':''}
                                    </div>}
                              </div>}
                            {!isListview &&
                              <div className="innerTableScroller" style={{height:`calc(100vh - ${isShowTitleBox?300:160}px)`}}>
                                {' '}{filterData.length > 0
                                  ? <VaultGridView
                                      isEnabledownload={isEnabledownload}
                                      isEnableSelection={this.isEnableSelection}
                                      uploadedList={filterData}
                                      sortByName={this.sortByNameData}
                                      selectedDetailStack={selectedDetailStack}
                                      selectItems={this.selectItems}
                                      openFolder={this.openFolder}
                                      onSelectDownloadFiles={this.onSelectDownloadFiles}
                                      // folderItem={folderItem}
                                      // folderItem={sortByName==='asc'? folderItem.sort(this.compareByAsc('fileName')): folderItem.sort(this.compareByDesc('fileName'))}
                                      // filelistItem={filelistItem}
                                    />
                                  : <div className="noVaultFile">
                                      No Data Found
                                    </div>}
                              </div>}
                          </div>
                        : <div style={{textAlign: 'center'}}>
                            <div className="noVaultFile">
                              Please complete the business registration to use the Vault
                            </div>
                            <Button
                              className="addnewVaultbusiness"
                              onClick={() =>
                                this.props.history.push (
                                  routes.BUSINESS_REGISTER
                                )}
                                id="vaultRequiredBusiness"
                            >
                              Add new business
                            </Button>
                          </div>}

                    </div>

                    <div className="vaultFilters">

                      <div style={{textAlign:'left',display:'inline-grid'}}>

                        {this.state.isSharedWithMe ?<Button
                          className="vaultFiltersButton"
                          onClick={() => this.getShareWithMe ()}
                          id="vaultGlobalStarred"
                        >
                          My Files
                        </Button>:
                        <Button
                          className="vaultFiltersButton"
                          onClick={() => this.getShareWithMe ()}
                          id="vaultGlobalStarred"
                        >

                          <img
                            style={{width: 24, height: 22, marginRight: 10}}
                            src={shareWhite}
                            alt="whiteStar"
                            id="vaultGlobalStarredImg"
                          />
                          Shared with me
                        </Button>
                        }

                        <Button
                          className="vaultFiltersButton"
                          onClick={() => this.filterList ()}
                          id="vaultGlobalStarred"
                        >

                          <img
                            style={{width: 24, height: 22, marginRight: 10}}
                            src={isStarFilter ? starIcon : whiteStar}
                            alt="whiteStar"
                            id="vaultGlobalStarredImg"
                          />
                          Starred files
                        </Button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </Dropzone>}

        {!isAccess &&
          <CommonAccessContainer
            bgImage={vaultUpcoming}
            message={checkAvailableAccess ('vault')}
          />}

        <Modal
          size="sm"
          isOpen={fileShareModal}
          centered={true}
          toggle={() => this.closeModals ()}
        >
          <ModalBody className="shareFilesModalBox" id="vaultshareFilesModalBox">
            <div>

              <div
                className="confirmTitle"
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                id="vaultshareFilesModalBox1"
              >
               Manage Sharing Permissions...

                <Button
                  className="closeFileshareModal"
                  onClick={() => this.closeModals ()}
                  data-rh="Close"
                  id="vaultshareFilesModalBox2"
                >
                  <img style={{width: 35}} src={closeIcon} alt="closeIcon" id="vaultshareFilesModalBox3"/>
                </Button>
              </div>
              <div style={{marginLeft:10}}>

        


               {(selectedItem &&
                  selectedItem.size > 0 )&& !isEnabledownload && <div className="copylinkTitle" id="vaultshareFilesModalBox4" style={{cursor:'pointer'}} onClick={()=>{
                    this.setState({
                      sharingConfirmFileModal: true,
                      copyText:false
                    })
                    }}>
                {' '}
                <img
                  style={{width: 24, height: 24, marginRight: 10}}
                  src={getExternalIcon}
                  alt="linkIcon"
                  id="vaultshareFilesModalBox5"
                />
                {' '}
                {selectedItem.shareFile? 'Disable  ' :'Get '}  external sharing link
              </div>}
              <p className="searchFileMembers">Search for members or groups:</p>

              <div className="searchinputShareuser" id="vaultshareFilesModalBox6">
                <img
                  alt="seainputicon"
                  src={seainputicon}
                  style={{width: 34, height: 22, marginLeft: 10}}
                />
                <input
                  className="findUserSearch"
                  placeholder="Search by name or email"
                  value={searching}
                  onChange={e => this.setState ({searching: e.target.value})}
                  id="vaultshareFilesModalBox7"
                />
              </div>

              <p className="searchFileMembers" id="vaultshareFilesModalBox8">Contacts:</p>
              <div className="fileShareContainer" id="vaultshareFilesModalBox9">

                {filterContactListing &&
                  filterContactListing.length > 0 &&
                  filterContactListing.map ((obj, i) => (
                    <div key={i} className="shareFilememberList" id={`vaultshareFilesModalBoxContact${i}`}>
                      <div style={{width: 60, textAlign: 'left'}}>
                      <div className="shareUserimageBox">
                        <img
                          id={`vaultshareFilesModalBoxContactProfile${i}`}
                          className="shareUserimage"
                          src={
                            obj &&
                              obj.contact &&
                              obj.contact.contactDetail &&
                              obj.contact.contactDetail.profilePictureURL
                              ? obj.contact.contactDetail.profilePictureURL
                              : profilePlaceholder
                          }
                          alt="profileimg"
                        />
                      </div>

                      </div>
                      <div style={{width: 200}}>
                        <div className="shareUsername" id={`vaultshareFilesModalBoxContactProfileName${i}`}>
                          {obj && obj.contact && obj.contact.contactDetail
                            ? ShowNameAccordingToLength (
                                obj.contact.contactDetail.firstName +
                                  ' ' +
                                  obj.contact.contactDetail.lastName
                              )
                            : ''}
                        </div>
                        {/* <div className="shareUserLastSeen" id={`vaultshareFilesModalBoxContactProfileLastseen${i}`}>
                          last seen recently
                        </div> */}

                      </div>
                      <div>
                      { obj.contact && obj.contact.busDetails && obj.contact.busDetails.docID && businessId && (obj.contact.busDetails.docID === businessId )&& <div>
                        {obj.contact && obj.contact.isSelected
                          ? <Button
                              className="removeFromShare"
                              style={{background: '#17171D'}}
                              onClick={() =>
                                this.selectedShareUser (obj.contact, 0)}
                                id={`vaultshareFilesModalBoxContactProfileRemove${i}`}
                            >
                              Remove{' '}
                            </Button>
                          : <Button
                              onClick={() =>
                                this.selectedShareUser (obj.contact, 1)}
                              className="removeFromShare"
                              style={{background: '#4453D6'}}
                              id={`vaultshareFilesModalBoxContactProfileAdd${i}`}
                            >
                              Add{' '}
                            </Button>}

                      </div>}
                    </div>

                    </div>
                  ))}
              </div>

              <Button
                // style={{cursor: isSelectedContact ? 'pointer' : 'initial'}}
                // disabled={!isSelectedContact}
                className="sharePopupFileButton"
                onClick={() => this.shareFiles ()}
                id="vaultshareFilesModalBoxContactProfileShareFile"
              >
                Share
              </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="md"
          isOpen={vaultOptionModal}
          centered={true}
          toggle={() => this.closeModals ()}
        >
          <ModalBody className="vaultOptionModal" id="vaultOptionModal1">
            <div>

              <div
                className="confirmTitle"
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                id="vaultOptionModal2"
                
              >
                Vault
                {' '}
                {selectedItem && selectedItem.size > 0 ? 'File ' : 'Folder '}
                {' '}
                Options...

                {/*}<Button
                  className="closeFileshareModal"
                  onClick={() => this.closeModals ()}
                  data-rh="Close"
                >
                  <img style={{width: 35}} src={closeIcon} alt="closeIcon" />
                </Button>*/}
              </div>
              {/*<div style={{height: 5}}  id="vaultOptionModal3">

                {
                  <input
                    className="hiddenCopy"
                    type="text"
                    value={
                      selectedItem && selectedItem.shareableLink
                        ? `${selectedItem.shareableLink}`
                        : ''
                    }
                    // id="myShareLink"
                  />
                }
              </div>*/}

              <div className="manageOptionContainer"  id="vaultOptionModal4">

                {/*selectedItem &&
                  selectedItem.size > 0 &&
                  <div
                    style={{color: this.state.copyText && '#32CD32'}}
                    className="sharingOptions"
                    onClick={() => this.copyToClipboard ()}
                    id="vaultOptionModal5"
                  >

                    <img
                      style={{width: 20, height: 24}}
                      src={copyIcon}
                      alt="shareIcon"
                      id="vaultOptionModal6"
                    />

                    {this.state.copyText ? 'Copied!' : 'Copy Shareable link'}
                </div>*/}

                  {selectedItem &&
                  selectedItem.size > 0 && !selectedItem.internalShared && <div id="vaultOptionModalManageper1" className="sharingOptions"  onClick={() =>{
                                this.setState ({
                                  fileShareModal: true,
                                  vaultOptionModal: false
                                })
                              }
                                }>
                    <img id="vaultOptionModalManageper2" src={shareBlack} alt="shareIcon"  style={{width: 20, height: 24}}/>
                    Manage permissions
                  </div>}

                {!selectedItem.internalShared &&<div
                  className="sharingOptions"
                  id="vaultOptionModal7"
                  style={{
                    borderBottom: selectedItem && selectedItem.size > 0
                      ? '1px dotted #A2A2A5'
                      : 0,
                  }}
                  onClick={() => {
                    if(isEnabledownload && checkMultipleSelection){
                      this.onStarMultiple(!selectedItem.starred);
                    }else{
                      this.putStarFiles ();
                    }

                    this.closeModals ();
                  }}
                >
                  <img
                    style={{width: 24, height: 23}}
                    src={
                      selectedItem && selectedItem.starred
                        ? starIcon
                        : blackStar
                    }
                    alt="shareIcon"
                    id="vaultOptionModal8"
                  />
                  
                  {isEnabledownload ?  (checkMultipleSelection && !selectedItem.starred  ?'Starred Selected Files': (!checkMultipleSelection && !selectedItem.starred ? 'Starred Selected Files':'Unstarred Selected Files')) :''}

                  {!isEnabledownload ? (selectedItem.starred  ?'Make unstarred item': 'Make starred item') :''}

                </div>}
                {selectedItem &&
                  selectedItem.size > 0 &&
                  <div
                    style={{
                      borderBottom: selectedItem && selectedItem.size > 0
                        ? 0
                        : '1px dotted #A2A2A5',
                    }}
                    className="sharingOptions"
                    onClick={() => {
                      if(isEnabledownload && checkMultipleSelection){
                        this.downloadZip();
  
                      }else{
                        this.downloadFile();
                      }
                      this.closeModals ();
                    }}
                    id="vaultOptionModal9"
                  >
                    <img
                      style={{width: 20, height: 24}}
                      src={downloadIcon}
                      alt="shareIcon"
                      id="vaultOptionModal10"
                    />
                    Download {isEnabledownload && checkMultipleSelection  && 'Selected Files'}
                  </div>}
                { !selectedItem.internalShared &&<div
                  style={{border: 'none'}}
                  className="sharingOptions"
                  onClick={() => {
                    if(isEnabledownload && checkMultipleSelection){
                      this.bulkDeleteFiles();
                    }else{
                      this.deleteFile ();
                    }
                    this.closeModals ();
                  }}
                  id="vaultOptionModal11"
                >
                  <img
                    style={{width: 22, height: 27}}
                    src={deleteIcon}
                    alt="shareIcon"
                    id="vaultOptionModal12"
                  />
                  {isEnabledownload && checkMultipleSelection  ?' Delete Selected Files' :' Delete from my Vault'}
                </div>}



                {/* {(selectedItem &&
                  selectedItem.size > 0 )&& !isEnabledownload &&  <div id="vaultOptionModalManageper1" className="sharingOptions"  onClick={() =>{
                  this.shareExternalFile(selectedItem.shareFile? false: true);
                  }}>
                    <img id="vaultOptionModalManageper2" src={shareBlack} alt="shareIcon"  style={{width: 20, height: 24}}/>
                {selectedItem.shareFile? 'Stop ' :''} Share File Externally
                  </div>} */}
                
                  {/*<div className="sharingOptions">
                  <img src={editIcon} alt="shareIcon" />
                  Rename
                </div>
                <div className="sharingOptions">
                  <img src={shareMoveIcon} alt="shareIcon" />
                  Move to...
                </div>
                <div className="sharingOptions">
                  <img src={copyIcon} alt="shareIcon" />
                  Create a copy
                </div>
                <div className="sharingOptions">
                  <img src={pinIcon} alt="shareIcon" />
                  Pin to R-DEE Dashboard
                </div>
                */}
              </div>

            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={addnewFolder}
          centered={true}
          toggle={() => this.closeModals ()}
        >
          <ModalBody className="addNewfolderModalBox"  id="addNewfolderModalBox">
            <div  id="addNewfolderModalBox1">

              <div className="addNewfolderModalTitle"  id="addNewfolderModalBox2">Create New Folder</div>

              <div  id="addNewfolderModalBox3">

                <input
                  placeholder="Enter folder name"
                  className="addNewfolderinput"
                  type="text"
                  onChange={e => {
                    this.setState ({
                      newFolderName: e.target.value,
                    });
                  }}
                  maxLength="25"
                  id="addNewfolderModalBox4"
                />
              </div>
              <div
                style={{marginTop: 15, textAlign: 'right', paddingRight: 10}}
              >

                <Button
                  className="cancelCreateFolder"
                  style={{marginRight: 20}}
                  onClick={() => this.closeModals ()}
                  id="addNewfolderModalBox5"
                >
                  {' '}Cancel
                </Button>
                <Button
                  className="createFolder"
                  style={{cursor: newFolderName ? 'pointer' : 'inherit'}}
                  // disabled={!newFolderName}
                  onClick={() => this.submitAddnew ()}
                  id="addNewfolderModalBox6"
                >
                  Create
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>


         <Modal
            size="md"
            isOpen={sharingFileModal}
            centered={true}
            toggle={() => {this.setState({
              sharingFileModal:false,
              copyText:false
            })
            this.props.externalShareFileReset();
          
          }}
          >
            <ModalBody className="shareDriveConfirm" id="ShareExternalConfirm">
              <div>

                <div className="confirmTitle" id="ShareExternalConfirm1">External Sharing Link</div>
                    
                <div className="confirmmsgText"  id11="ShareExternalConfirm2" id="myShareLink" >
                <p style={{color:'#4453D6',margin:10}}>Copy the following text to share this file with a non-R-DEE user:</p>
                <div style={{marginLeft:16,opacity: 0.8}}>
                I am sharing an R-DEE document with you. To access this file without registering for R-DEE, access the following URL where you will be prompted to enter the password for access to the document.

                  {externalSharingInfo && externalSharingInfo.externalShareURL && <div style={{marginTop:10}}> URL: {externalSharingInfo.externalShareURL} </div>}
                  {externalSharingInfo && externalSharingInfo.shareCode &&<div style={{marginTop:10}}>password:  {externalSharingInfo.shareCode}</div>}

                  </div>
                  </div>
                 
                <div style={{marginTop:15}} className="buttonDivCopyLink"  id="ShareExternalConfirm3">
                  {!copyText && <Button
                    className="buttons copyClipboardButton"
                    onClick={() => this.copyToClipboard ()}
                    id="ShareExternalConfirm4copy"
                    style={{background:'#17171D'}}
                  >
                   Copy Text to Clipboard
                  </Button>}
                  {copyText && <Button
                    className="buttons copyClipboardButton"
                    onClick={() => {
                      this.setState({
                        sharingFileModal:false,
                        copyText:false
                      })
                     this.props.externalShareFileReset();

                    }}
                    id="ShareExternalConfirm4copyComplete"
                    style={{background:'#4453D6'}}
                  >
                  Text Copied
                  </Button>}
                </div>
              </div>
            </ModalBody>
          </Modal>
        

          <Modal
            size="sm"
            isOpen={sharingConfirmFileModal}
            centered={true}
            toggle={() => {this.setState({
              sharingConfirmFileModal:false
            })
            this.props.externalShareFileReset();
          
          }}
          >
            <ModalBody className="shareDriveConfirm" id="ShareExternalConfirmSharing">
              <div>
             

                <p className="confirmTitle" id="ShareExternalConfirm1Sharing">
               
                {selectedItem.shareFile? 'Remove External Sharing Link ' :'Security Warning '}
                </p>
                    
                <div className="confirmmsgText"  id="myShareLinkSharing" >

                {selectedItem.shareFile ? 'Disabling this external sharing link will disable access for anyone with the link.Do you wish to continue?' 
                : 'Externally shared files are not as secure as R-DEE shared files. Do you wish to continue?' }
                 </div>
                 
                <div className="buttonDiv"  id="ShareExternalConfirm3Sharing">

                <Button
                    className="buttons nobutton"
                    onClick={() => {this.setState({
              sharingConfirmFileModal:false
            })}}
                    id="ShareExternalConfirm5Sharing"
                  >
                    Cancel
                  </Button>
                  {selectedItem.shareFile? <Button
                    className="buttons yesButton"
                    id="ShareExternalConfirm4Sharing"
                    onClick={() =>{this.shareExternalFile(false)}}
                  >
                    Remove Link
                  </Button>:
                  <Button
                    className="buttons yesButton"
                    id="ShareExternalConfirm4Sharing"
                    onClick={() =>{this.shareExternalFile(true)}}
                  >
                    I Understand
                  </Button>

                  }
                </div>
              </div>
            </ModalBody>
          </Modal>
        

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    vaultData: state.vaultReducers,
    contacts: state.ContactsReducer.contacts,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      Loading,
      getVaultListing,
      getVaultFileDownload,
      putStar,
      getAllUserContactsFunc,
      createNewfolder,
      shareFileWithUsers,
      deleteVaultAction,
      deleteVaultFiles,
      resetVaultListAction,
      getvaultGetFileDetails,
      resetCreatedItem,
      addNewFile,
      deleteBulkFilesVaultAction,
      deleteVaultBulkFiles,
      deleteVaultfolder,
      getVaultBulkDownload,
      vaultExternalFileSharing,
      externalShareFileReset,
      getVaultSharedWithFiles
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (FileDrive);
