import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import { Carousel } from 'react-responsive-carousel'
import StarRatingComponent from 'react-star-rating-component'
import './sellerOrderDetail.css'
import leftbackarrow from '../../../../../images/icons/leftbackarrow.png'
// import dropdownpro
//   from '../../../../../images/marketPlaceBuyer/dropdownpro.png';
import dropdownpro from '../../../../../images/icons/downwhiteArrow.png'
import fileRemoveIcon from '../../../../../images/marketPlaceBuyer/fileRemoveIcon.svg'

// import markefilter from '../../../images/marketPlaceBuyer/markefilter.png';
import downBlackArrow from '../../../../../images/icons/downBlackArrow.png'
import moment from 'moment'
import SellerConsoleSidemenu from '../../sellerConsole/sellerSidemenu/sellerConsoleSidemenu'
import placeholderProfile from '../../../../../images/profile/pronew.png'
import downloadInvoice from '../../../../../images/marketPlaceBuyer/downloadInvoice.svg'

import productDetails from '../../../../../images/marketPlaceBuyer/productDetails.png'
import * as routes from '../../../../../constants/routes'
import profilePlaceholder from '../../../../../images/profile_placeholder.png'
import PinInput from 'react-pin-input'

import { EncryptFunction } from '../../../../../utility/encryption'
import { createNotification } from '../../../../../constants/notificationtoast'
import { CapitalizeFirstLetter } from '../../../../../constants/helper'
import copyIcon from '../../../../../images/marketPlaceBuyer/copyIcon.svg'

import { saveAs } from 'file-saver'
import { ShowNameAccordingToLength } from '../../../../../utility/stringFunctions'
import { Dropdown } from 'primereact/dropdown'
import {
  getSellerOrder,
  orderSellerConfirmStatus,
  getMarketPlaceOrder,
  cancelPlaceOrder,
  marketplaceOrderShippedStatus,
  sellerGiveReviewTobuyer,
  resetdoOrderValue,
  doCancelledRefundItem,
  sellerVerifyPayment,
  marketPlaceCloseDisputeSellerSide,
  marketPlaceRefuseDisputeSellerSide,
  marketplaceOrderRefundedStatus,
  marketPlaceInternalRefundSellerSide,
  resetOrderList
} from '../../../../../actions/marketplaceaction'
import {getTransactionHistory} from '../../../../../actions/Paymentaction';

import { dataTimeFormat } from '../../../../../utility/convertTimeString'
import RightSideContainer from '../../../../../components/UI/rightSideContainer/rightSideContainer'
import { priceWithDecimal,shippingTrackingURL } from '../../../../../utility/commonFunctions'
import ProductZoomDetail from '../../../../UI/productZoom/productZoom'

class SellerOrderDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      specification: true,
      logistics: true,
      titleInfo: true,
      inspection: true,
      overView: true,
      otherInfo: true,
      dimentions: true,
      compliance: true,
      condition: true,
      productDetail: [],
      sellerConfirm: false,
      cancelOrder: false,
      shippingConfirm: false,
      leaveFeedBack: false,
      rating: 0,
      reviewText: '',
      leaveFeedsuccess: false,
      transfer_purposes: '',
      payOrderError: '',
      productCategoryType: '',
      imagePreviewModal: false,
      imgIndex: 0,
      uploadInvoiceModal: false,
      selectedInvoiceFile: '',
      previewInvoiceFile: '',
      confirmPaymentVerification: false,
      selectShippingAgent: '',
      shippingAgentList: [
        {
          label: 'FedEx',
          value: 'FedEx'
        },
        {
          label: 'DHL',
          value: 'DHL'
        },
        {
          label: 'UPS',
          value: 'UPS'
        },
        {
          label: 'Aramex',
          value: 'Aramex'
        }
      ],
      trackingNumber: '',
      trackingURL: '',
      sellerViewDispute: '',
      selectedDisputeOption: '',
      disputeResolution: '',
      streetAddress: '',
      city: '',
      state: '',
      zipcode: '',
      line1: '',
      rmaNumber: '',
      sellerTrackOrder: false,
      copyText: false,
      refundPayment: false,
      selectPaymentMethod: '',
      previewPayInvoiceFile: '',
      selectedPayInvoiceFile: '',
      paymentUploadInvoiceModal: false,
      refundInternalPayment: false,
      userBalance:'',
      rdeeCredit:'',
      isCancelBeforeshipment:false
    }
    this.closeModal = this.closeModal.bind(this)
    this.showImage = this.showImage.bind(this)
  }


  openDetailDropDown (status) {
    if (status === 'specification') {
      this.setState({
        specification: !this.state.specification
      })
    } else if (status === 'logistics') {
      this.setState({
        logistics: !this.state.logistics
      })
    } else if (status === 'titleInfo') {
      this.setState({
        titleInfo: !this.state.titleInfo
      })
    } else if (status === 'overView') {
      this.setState({
        overView: !this.state.overView
      })
    } else if (status === 'otherInfo') {
      this.setState({
        otherInfo: !this.state.otherInfo
      })
    } else if (status === 'dimentions') {
      this.setState({
        dimentions: !this.state.dimentions
      })
    } else if (status === 'compliance') {
      this.setState({
        compliance: !this.state.compliance
      })
    } else if (status === 'condition') {
      this.setState({
        condition: !this.state.condition
      })
    } else {
      this.setState({
        inspection: !this.state.inspection
      })
    }
  }

  componentDidMount () {
    this.props.resetdoOrderValue()
    if (this.props.match.params && this.props.match.params.orderId) {
      this.props.resetOrderList();
      let sendSellerOrderRequest = {
        data: {
          docID: this.props.match.params.orderId
        }
      }

      let sendRequest = {
        data: {
          limit: 0,
          offset: 0,
        },
      };
      this.props.getTransactionHistory (sendRequest);

      this.props.getSellerOrder(sendSellerOrderRequest)

      // this.props.getMarketPlaceOrder (sendSellerOrderRequest);
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps && nextProps.payment) {
        // console.log(nextProps.payment);
        this.setState ({
          userBalance: nextProps.payment.history &&
            nextProps.payment.history.balance
            ? nextProps.payment.history.balance
            : '',
            rdeeCredit: nextProps.payment.history &&
            nextProps.payment.history.rdeeBalance
            ? nextProps.payment.history.rdeeBalance
            : '',
        });
      }
      

      if (nextProps.product && nextProps.product.buyerFeedback) {
        this.setState({
          leaveFeedBack: false,
          leaveFeedsuccess: true
        })
      }
      if (nextProps.product && nextProps.product.payOrderError) {
        this.setState({
          payOrderError: nextProps.product.payOrderError
          // modal: false,
          // orderConfirmation: false,
          // pay: false,
        })
      }
      if (nextProps.product && nextProps.product.payOrdersuccess) {
        this.setState({
          refundInternalPayment:false
        })
      }

      if (nextProps.product && nextProps.product.confirmOrder) {
        this.setState({
          sellerConfirm: false,
          shippingConfirm: false,
          uploadInvoiceModal: false
        })
      }
      if (nextProps.product.cancelOrder) {
        if (this.state.cancelConfirmation) {
          this.setState({
            cancelOrder: false
          })
        }
      }
      if (nextProps.product && nextProps.product.sellerordershistory) {
        if (nextProps.product.sellerordershistory.length > 0) {
          this.setState(
            {
              productDetail: nextProps.product.sellerordershistory[0]
            },
            () => {
              this.setState(
                {
                  productInfo: this.state.productDetail.products[0],
                  confirmPaymentVerification: false,
                  sellerViewDispute: false,
                  selectedDisputeOption: '',
                  paymentUploadInvoiceModal: false,
                  refundPayment: false
                  // productInfo: this.state.productDetail.productDetails[0],
                },
                () => {
                  this.setState({
                    productCategoryType: this.state.productInfo
                      .productCategoryType
                  })
                }
              )
            }
          )
        }
      }
    }
  }
  closeModal () {
    this.setState({
      sellerConfirm: false,
      cancelOrder: false,
      shippingConfirm: false,
      leaveFeedBack: false,
      leaveFeedsuccess: false,
      imagePreviewModal: false,
      uploadInvoiceModal: false,
      viewInvoiceModal: false,
      confirmPaymentVerification: false,
      sellerViewDispute: false,
      sellerTrackOrder: false,
      selectedDisputeOption: '',
      refundPayment: false,
      paymentUploadInvoiceModal: false,
      refundInternalPayment: false
    })
  }
  doConfirmOrder () {
    let sendRequest = {
      data: {
        docID: this.state.productDetail.docID
      }
    }

    const { productDetail } = this.state

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + productDetail.orderID + ' confirmed!',
        body:(productDetail &&
          productDetail.products[0].productCategoryType &&
          productDetail.products[0].productCategoryType === 'Radiology'
            ? productDetail.products[0].deviceSaleName
            : (productDetail &&
              productDetail.products &&
              productDetail.products[0] &&
              productDetail.products[0].productName 
              ? productDetail.products[0].productName
              : '')) +
              ' ' +
              ((loginUser.title+' ')+ ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              ' has  confirmed the order' +
              productDetail.orderID) +
              '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Order ' + productDetail.orderID + ' confirmed!',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.orderSellerConfirmStatus(sendRequest, paramsAddNotiHistory)
  }
  openUploadInvoice () {
    this.setState({
      uploadInvoiceModal: true
    })
  }
  onChangeSelectInvoice = e => {
    // console.warn ('warning function call details');
    if (e && e.target && e.target.files) {
      let files = e.target.files
      let response = files[0].size / 1000000
      // console.log(Math.floor(response));

      if (response && response > 5) {
        createNotification(
          'error',
          'The uploaded file is too large. File can only be a maximum of 5 MB.'
        )
        return false
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          previewInvoiceFile: reader.result
        })
      })
      reader.readAsDataURL(e.target.files[0])

      this.setState({
        selectedInvoiceFile: e.target.files[0]
      })
    }
  }
  uploadInvoice () {
    const {
      selectedInvoiceFile,
      previewInvoiceFile,
      productDetail
    } = this.state

    let sendRequest = {
      data: {
        docID: this.state.productDetail.docID,
        invoiceFileName: selectedInvoiceFile.name,
        invoiceFileType: selectedInvoiceFile.type,
        invoice: previewInvoiceFile
      }
    }

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + productDetail.orderID + ' confirmed!',
        body:(productDetail &&
          productDetail.products[0].productCategoryType &&
          productDetail.products[0].productCategoryType === 'Radiology'
            ? productDetail.products[0].deviceSaleName
            : (productDetail &&
              productDetail.products &&
              productDetail.products[0] &&
              productDetail.products[0].productName 
              ? productDetail.products[0].productName
              : '')) +
              ' ' +
              (
                ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              ' has  confirmed the order' +
              productDetail.orderID) +
              '.Complete payment to proceed.',   
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Order ' + productDetail.orderID + ' confirmed!',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.orderSellerConfirmStatus(sendRequest, paramsAddNotiHistory)
  }

  doConfirmShipment () {
    const {
      productDetail,
      selectedInvoiceFile,
      previewInvoiceFile,
      trackingNumber,
      selectShippingAgent,
      trackingURL
    } = this.state

    let sendRequest = {
      data: {
        docID: this.state.productDetail.docID,
        trackingNumber: trackingNumber,
        trackingURL: trackingURL,
        shippingDocumentFileName: selectedInvoiceFile.name,
        shippingDocumentFileType: selectedInvoiceFile.type,
        shippingDocument: previewInvoiceFile,
        shippingCompany: selectShippingAgent
      }
    }
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + productDetail.orderID + ' shipped!',
        body:((loginUser.title+' ')+ ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )+' ' +
              ' has  confirmed shipment of the order' +
              productDetail.orderID) +
              '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Order ' + productDetail.orderID + ' shipped!',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.marketplaceOrderShippedStatus(sendRequest, paramsAddNotiHistory)
  }

  doCancelOrders () {
    const {productDetail}= this.state;
    let sendRequest = {
      data: {
        docID: this.state.productDetail.docID
      }
    }
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + productDetail.orderID + ' cancelled!',
        body:
          (productDetail &&
          productDetail.products[0].productCategoryType &&
          productDetail.products[0].productCategoryType === 'Radiology'
            ? productDetail.products[0].deviceSaleName
            : (productDetail &&
              productDetail.products &&
              productDetail.products[0] &&
              productDetail.products[0].productName 
              ? productDetail.products[0].productName
              : '')) +
              ' ' +
              ((loginUser.title+' ')+ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              ' has  cancelled  the order' +productDetail.orderID  +'.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Order ' + productDetail.orderID + ' cancelled!',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.cancelPlaceOrder(sendRequest,paramsAddNotiHistory)
  }

  _handleKeyVal (receiver) {
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    let keyVal = ''
    let userId = loginUser.docID
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`
    } else {
      keyVal = `${userId}${receiver}`
    }
    return keyVal
  }
  goToChat () {
    const { productDetail, productInfo } = this.state

    if (productDetail) {
      const buyerInfo = productDetail.buyerDetails

      let userChatInfo = {
        oppositeUserUid: productDetail.orderedBy,
        profilePictureURL:
          buyerInfo && buyerInfo.profilePictureURL
            ? buyerInfo.profilePictureURL
            : profilePlaceholder,
        displayName: buyerInfo.firstName
      }
      this.props.history.push(routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal(productDetail.orderedBy)
      })
    }
  }

  onStarClick (nextValue, prevValue, name) {
    this.setState({ rating: nextValue })
  }

  doLeaveFeedback () {
    const { productDetail } = this.state

    let sendRequest = {
      data: {
        docID: productDetail.docID,
        reviewText: this.state.reviewText,
        reviewScore: this.state.rating
      }
    }

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + productDetail.orderID + ' feedback received!',
        body:
          (productDetail &&
          productDetail.products[0].productCategoryType &&
          productDetail.products[0].productCategoryType === 'Radiology'
            ? productDetail.products[0].deviceSaleName
            : (productDetail &&
              productDetail.products &&
              productDetail.products[0] &&
              productDetail.products[0].productName
              ? productDetail.products[0].productName
              : '')) +
              ' ' +
              ((loginUser.title+' ')+ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              'has left feedback for the order ' +
              productDetail.orderID) +
              '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Order ' + productDetail.orderID + ' feedback received!',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.sellerGiveReviewTobuyer(sendRequest, paramsAddNotiHistory)
  }

  doCancelledItemRefund () {
    const { productDetail } = this.state

    let productRequest = {
      data: {
        docID: this.state.productDetail.docID
      }
    }

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + productDetail.orderID + ' Refund send!',
        body:(productDetail &&
              productDetail.products[0].productCategoryType &&
              productDetail.products[0].productCategoryType === 'Radiology'
                ? productDetail.products[0].deviceSaleName
                : (productDetail &&
                  productDetail.products &&
                  productDetail.products[0] &&
                  productDetail.products[0].productName
                  ? productDetail.products[0].productName
                  : '')) +
                  ' ' +
                  (
                    ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
                  +' ' +
                  'has refund for the order ' +
                  productDetail.orderID) +
                  '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Order ' + productDetail.orderID + ' Refund send!',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    let hashKeyPIN = EncryptFunction(
      loginUser.emailAddress,
      this.state.pinValue
    )

    let sendRequest = {
      seller_address: productDetail.sellerWallet,
      buyer_address: productDetail.buyerDetails.buyerWallet,
      seller_passphrase: hashKeyPIN,
      order_amount: productDetail.totalOrderPrice,
      order_id: this.state.productDetail.docID,
      transfer_purposes: this.state.transfer_purposes
    }

    this.props.doCancelledRefundItem(
      sendRequest,
      productRequest,
      paramsAddNotiHistory
    )
  }

  showImage (index) {
    this.setState({
      imagePreviewModal: true,
      imgIndex: index
    })
  }

  downloadInvoice () {
    const { productDetail } = this.state
    saveAs(productDetail.invoiceURL, productDetail.invoice)
  }

  onChangeSelectShipmentFile = e => {
    // console.warn ('warning function call details');
    let files = e.target.files
    let response = files[0].size / 1000000
    // console.log(Math.floor(response));

    if (response && response > 5) {
      createNotification(
        'error',
        'The uploaded file is too large. File can only be a maximum of 5 MB.'
      )
      return false
    }
    // console.log(e.target.files[0])

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      this.setState({
        previewInvoiceFile: reader.result
      })
    })
    reader.readAsDataURL(e.target.files[0])

    this.setState({
      selectedInvoiceFile: e.target.files[0]
    })
  }

  doSellerVerifyPayment () {
    const { productDetail } = this.state

    let productRequest = {
      data: {
        docID: this.state.productDetail.docID
      }
    }
    let loginUser = JSON.parse(localStorage.getItem('loginuser'));
    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Payment confirmed for order ' + productDetail.orderID+'.',
         body:((loginUser.title+' ')+ ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
                    +' ' +
                    ' has confirmed the payment for the order' +
                    productDetail.orderID +'.') ,
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Payment confirmed for order ' + productDetail.orderID+'.',
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }
    this.props.sellerVerifyPayment(productRequest,paramsAddNotiHistory)
  }
  doCloseDispute () {
    const { productDetail, disputeResolution } = this.state

    let sendRequest = {
      data: {
        docID: productDetail.docID,
        disputeResolution: disputeResolution
      }
    }
    // console.log(sendRequest);
    let loginUser = JSON.parse(localStorage.getItem('loginuser'));
    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Dispute declined for  Order ' + productDetail.orderID ,
        body:((loginUser.title+' ')+ ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              ' declined the dispute for the order ' +
              productDetail.orderID) +
              '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Dispute declined for  Order ' + productDetail.orderID,
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.marketPlaceCloseDisputeSellerSide(sendRequest,paramsAddNotiHistory)
  }
  selectDisputeOption (status) {
    this.setState({
      selectedDisputeOption: status,
      disputeResolution: ''
    })
  }

  doRefuseDispute () {
    const {
      productDetail,
      streetAddress,
      city,
      state,
      zipcode,
      line1,
      rmaNumber
    } = this.state

    let sendRequest = {
      data: {
        docID: productDetail.docID,
        rmaNumber: rmaNumber, // "RMA_567112"
        returnAddress: {
          name: streetAddress,
          // streetAddress: streetAddress,
          city: city,
          state: state,
          line1: line1,
          zipcode: zipcode
        }
      }
    }
    // console.log(sendRequest);

    let loginUser = JSON.parse(localStorage.getItem('loginuser'));
    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Dispute accepted for Order  ' + productDetail.orderID ,
        body:((loginUser.title+' ')+ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
                    +' ' +
                    ' has declined the accepted dispute for the order ' +
                    productDetail.orderID +'Please ship back the order.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Dispute accepted for Order  ' + productDetail.orderID,
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }
    this.props.marketPlaceRefuseDisputeSellerSide(sendRequest,paramsAddNotiHistory)
  }

  onChangeInvoiceselectProof = e => {
    // console.warn ('warning function call details');
    if (e && e.target.files) {
      let files = e.target.files
      let response = files[0].size / 1000000
      // console.log(Math.floor(response));

      if (response && response > 5) {
        createNotification(
          'error',
          'The uploaded file is too large. File can only be a maximum of 5 MB.'
        )
        return false
      }
      // console.log(e.target.files[0])

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          previewPayInvoiceFile: reader.result
        })
      })
      reader.readAsDataURL(e.target.files[0])

      this.setState({
        selectedPayInvoiceFile: e.target.files[0]
      })
    }
  }
  doRefundPayforOrder () {
    const {
      selectedPayInvoiceFile,
      previewPayInvoiceFile,
      productDetail
    } = this.state
    let sendRequest = {
      data: {
        docID: productDetail.docID,
        refundReceiptFileName: selectedPayInvoiceFile.name,
        refundReceiptFileType: selectedPayInvoiceFile.type,
        refundReceipt: previewPayInvoiceFile,
        paymentType: 'external'
      }
    }
    // console.log(sendRequest.data);

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Refund received for Order ' + productDetail.orderID ,
        body:((loginUser.title+' ')+ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              ' has refunded for the order ' +
              productDetail.orderID) +
              '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName:'Refund received for Order '+ productDetail.orderID ,
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.marketplaceOrderRefundedStatus(sendRequest,paramsAddNotiHistory)
  }

  doRefundInternal () {
    const { productDetail } = this.state
    // console.log(productDetail)
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let hashKeyPIN = EncryptFunction(
      loginUser.emailAddress,
      this.state.pinValue
    )

    let walletAddress = localStorage.getItem ('address');
    let sendRequest = {
        seller_address: walletAddress,
        buyer_address: this.state.productDetail.buyerDetails.buyerWallet,
        seller_passphrase: hashKeyPIN,
        order_id: this.state.productDetail.docID,
        order_amount:this.state.productDetail.totalOrderPrice,
        // transfer_purposes:this.state.transfer_purposes,
    }
    // console.log(sendRequest);




    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Refund received for Order ' + productDetail.orderID,
        body:((loginUser.title+' ')+ShowNameAccordingToLength(loginUser.firstName ? loginUser.firstName+ ' ' : ''  + loginUser && loginUser.lastName  ? loginUser.lastName: '' )
              +' ' +
              '  has refunded for the order' +
              productDetail.orderID) +
              '.',
        imageURL: loginUser.profilePictureURL,
        targetUID: productDetail.orderedBy,
        titleName: 'Refund received for Order ' + productDetail.orderID,
        navigateToID: productDetail.docID,
        orderScreenType: 'buyer'
      }
    }

    this.props.marketPlaceInternalRefundSellerSide(sendRequest,paramsAddNotiHistory);

    // "docID": "r7gNaHq7CDGrM308ovA4",
    // "paymentTransactionID": "8370jkniuh4iojhieuydgfi48hho",
    // "refundPaymentAddress": "R9745iknin5uioh54",
    // "originalEscrowTransactionID": "091343kjbnsuihvierknfu"

  }
  updateTrackURL(){
    const {trackingNumber , selectShippingAgent}= this.state;
    if(selectShippingAgent && trackingNumber && trackingNumber.length>0){

      this.setState({
        trackingURL:shippingTrackingURL(selectShippingAgent)+trackingNumber
      })

    }else{
      this.setState({
        trackingURL:''
      })
    }

  }

  render () {
    const {
      productDetail,
      productInfo,
      transfer_purposes,
      productCategoryType,
      imagePreviewModal,
      imgIndex,
      uploadInvoiceModal,
      selectedInvoiceFile,
      viewInvoiceModal,
      confirmPaymentVerification,
      sellerViewDispute,
      selectedDisputeOption,
      disputeResolution,
      streetAddress,
      city,
      state,
      zipcode,
      line1,
      rmaNumber,
      sellerTrackOrder,
      refundPayment,
      selectPaymentMethod,
      previewPayInvoiceFile,
      selectedPayInvoiceFile,
      paymentUploadInvoiceModal,
      refundInternalPayment,
      rdeeCredit
    } = this.state
    // console.log(productDetail);
    return (
      <div className='sellerorderDEtailContainer' id='sellerOrderDetailsPage'>
        <div>
          <div style={{ textAlign: 'right' }} id='sellerOrderDetailsPage1'>
            <Button
              className='sellerdetsbackbutton'
              onClick={() => this.props.history.goBack()}
              id='sellerOrderDetailsPage2'
            >
              <div>
                <img
                  src={leftbackarrow}
                  alt='backIcon'
                  id='sellerOrderDetailsPage3'
                />
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className='sellerdetailmaindiv' id='sellerOrderDetailsPage4'>
            <div>
              <div
                className='sellergvSiemensAvapro'
                id='sellerOrderDetailsPage5'
              >
                {productInfo && productInfo.productCategoryType === 'Radiology'
                  ? productInfo.deviceSaleName
                  : (productInfo && productInfo.productName
                    ? productInfo.productName
                    : '')}
                <p id='sellerOrderDetailsPage6'>
                  {' '}
                  USD${' '}
                  {productDetail && productDetail.totalOrderPrice
                    ? priceWithDecimal(productDetail.totalOrderPrice)
                    : ''}
                </p>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                id='sellerOrderDetailsPage7'
              >
                <div>
                  <div className='sellerOrderInfo' id='sellerOrderDetailsPage8'>
                    <p style={{ margin: 0 }} id='sellerOrderDetailsPage9'>
                      {' '}
                      Order #:{' '}
                      {productDetail && productDetail.orderID
                        ? productDetail.orderID
                        : ''}
                    </p>

                    <p style={{ margin: 0 }} id='sellerOrderDetailsPage10'>
                      Quantity:{' '}
                      {productDetail && productDetail.productQuantity
                        ? productDetail.productQuantity
                        : 0}
                    </p>
                  </div>
                  <div
                    className='orderPlacerData'
                    id='sellerOrderDetailsPage11'
                  >
                    Order Placed:
                    {productDetail && productDetail.orderDate
                      ? dataTimeFormat(productDetail.orderDate)
                      : ''}
                  </div>

                  {productDetail && productDetail.shippingAddress && (
                    <p
                      className='orderPlacerData'
                      id='sellerOrderDetailssPage11'
                    >
                      Shipping Address:{' '}
                      {productDetail &&
                      productDetail.shippingAddress &&
                      productDetail.shippingAddress.streetAddress
                        ? productDetail.shippingAddress.streetAddress
                        : ''}
                      {productDetail &&
                      productDetail.shippingAddress &&
                      productDetail.shippingAddress.city
                        ? ', ' + productDetail.shippingAddress.city
                        : ''}
                      {productDetail &&
                      productDetail.shippingAddress &&
                      productDetail.shippingAddress.state
                        ? ', ' + productDetail.shippingAddress.state
                        : ''}
                      {productDetail &&
                      productDetail.shippingAddress &&
                      productDetail.shippingAddress.country
                        ? ', ' + productDetail.shippingAddress.country
                        : ''}
                      {productDetail &&
                      productDetail.shippingAddress &&
                      productDetail.shippingAddress.zipcode
                        ? ', ' + productDetail.shippingAddress.zipcode
                        : ''}
                    </p>
                  )}
                </div>

                <div
                  className='sellerconfcsirmedb'
                  id='sellerOrderDetailsPage12'
                >
                  {productDetail && productDetail.orderStatus === 'ordered' && (
                    <p id='sellerOrderDetailsPage13'>
                      Order placed. Awaiting your confirmation.
                    </p>
                  )}
                  {productDetail &&
                    productDetail.orderStatus === 'seller confirmed' && (
                    <p id='sellerOrderDetailsPage14'>
                        Order confirmed by seller. Awaiting payment.
                    </p>
                  )}

                  {productDetail &&
                    productDetail.orderStatus === 'canceled' && (
                    <p id='sellerOrderDetailsPage15'>
                        Order canceled by seller{' '}
                      {productDetail &&
                        productDetail &&
                        productDetail.orderStatusLastUpdated
                        ? dataTimeFormat(productDetail.orderStatusLastUpdated)
                        : 'N/A'}
                    </p>
                  )}

                  {productDetail && productDetail.orderStatus === 'paid' && (
                    <div id='sellerOrderDetailsPage16'>
                      <p id='sellerOrderDetailsPage17'>
                        Payment completed to RDEE Escrow.
                      </p>
                      <p id='sellerOrderDetailsPage18'>
                        {' '}
                        Awaiting buyer confirmation of receipt.
                      </p>
                    </div>
                  )}

                  {productDetail && productDetail.orderStatus === 'shipped' && (
                    <div id='sellerOrderDetailsPage19'>
                      <p id='sellerOrderDetailsPage20'>
                        Item shipped on{' '}
                        {productDetail &&
                        productDetail &&
                        productDetail.orderShippedDate
                          ? dataTimeFormat(productDetail.orderShippedDate)
                          : 'N/A'}
                        .
                      </p>
                      <p id='sellerOrderDetailsPage21'>
                        {' '}
                        Awaiting buyer confirmation of receipt.
                      </p>
                    </div>
                  )}

                  {productDetail && productDetail.orderStatus === 'delivered' && (
                    <div id='sellerOrderDetailsPage22'>
                      <p id='sellerOrderDetailsPage23'>
                        Order received by buyer{' '}
                        {productDetail &&
                        productDetail &&
                        productDetail.orderDeliveredDate
                          ? dataTimeFormat(productDetail.orderDeliveredDate)
                          : 'N/A'}
                      </p>
                    </div>
                  )}
                  {productDetail && productDetail.orderStatus === 'completed' && (
                    <div id='sellerOrderDetailsPage24'>
                      <p id='sellerOrderDetailsPage25'>Order complete.</p>
                      {/* <div style={{ display: 'inline-flex' }}>
                        <p
                          style={{ paddingRight: 5 }}
                          id='sellerOrderDetailsPage26'
                        >
                          I rated:
                        </p>
                        <StarRatingComponent
                            name={'rating'}
                            starCount={5}
                            // value={
                            //   productDetail &&
                            //   productDetail.reputationDetails &&
                            //   productDetail.reputationDetails.latestReview
                            //     ? productDetail.serviceProfile.totalServicesOffered
                            //     : 0
                            // }
                            emptyStarColor={'#E4E4E4'}
                            starColor={'#F5D028'}
                            style={{fontSize: '80px', margin: 0}}
                            //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                          />
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 10 }} />

              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  maxWidth: 500
                }}
                id='sellerOrderDetailsPage27'
              >
                <Button
                  className='sellermessagebuton'
                  onClick={() => this.goToChat()}
                  data-rh='Message to buyer'
                  id='sellerOrderDetailsPage28'
                >
                  Message Buyer
                </Button>
                {/* {productDetail &&
                  productDetail.orderStatus === 'ordered' &&
                  <Button
                    className="sellerconfirerbuton"
                    onClick={() => this.setState ({sellerConfirm: true})}
                    data-rh="Confirm order"
                    id="sellerOrderDetailsPage29"
                  >
                    Confirm Order
                  </Button>} */}

                {productDetail && productDetail.orderStatus === 'ordered' && (
                  <Button
                    className='sellerconfirerbuton'
                    id='sellerOrderDetailsPageInvoiceUpload'
                    onClick={() => {
                      this.openUploadInvoice()
                      // this.setState ({sellerConfirm: true})
                    }}
                  >
                    Confirm Order
                  </Button>
                )}

                {productDetail &&
                  productDetail.invoiceURL &&
                  productDetail.orderStatus === 'seller confirmed' && (
                  <Button
                    className='sellercancelleddiv'
                    id='sellerOrderDetailsinvoiceURLPage30'
                    onClick={() => this.setState({ viewInvoiceModal: true })}
                  >
                      View Invoice
                  </Button>
                )}

                {productDetail &&
                  productDetail.orderStatus === 'seller confirmed' && (
                  <Button
                    className='sellercancelleddiv'
                    id='sellerOrderDetailsPage30'
                  >
                      Order Confirmed
                  </Button>
                )}
                {productDetail &&
                  productDetail.paymentType &&
                  productDetail.orderStatus === 'paid' &&
                  productDetail.paymentType === 'internal' && (
                  <Button
                    className='sellerconfirerbuton'
                    onClick={() => this.setState({ shippingConfirm: true })}
                    data-rh='Confirm shipment'
                    id='sellerOrderDetailsPage31'
                  >
                      Confirm Shipment
                  </Button>
                )}
                {productDetail &&
                  productDetail.paymentType &&
                  productDetail.orderStatus === 'payment verified' &&
                  productDetail.paymentType === 'external' && (
                  <Button
                    className='sellerconfirerbuton'
                    onClick={() => this.setState({ shippingConfirm: true })}
                    data-rh='Confirm shipment'
                    id='sellerOrderDetailsPage31'
                  >
                      Confirm Shipment
                  </Button>
                )}
                {productDetail &&
                  productDetail.paymentType &&
                  productDetail.orderStatus === 'paid' &&
                  productDetail.paymentType === 'external' && (
                  <Button
                    className='sellerconfirerbuton'
                    onClick={() =>
                      this.setState({ confirmPaymentVerification: true })
                    }
                    data-rh='Confirm shipment'
                    id='sellerOrderDetailsPage31'
                  >
                      Confirm Payment
                  </Button>
                )}

                {productDetail && productDetail.orderStatus && productDetail.orderStatus === 'shipped' && (
                  <Button
                    className='sellercancelleddiv'
                    data-rh='Shipped'
                    id='sellerOrderDetailsPage32'
                  >
                    Shipped
                  </Button>
                )}

                {productDetail &&
                  (productDetail.orderStatus === 'delivered' &&
                    productDetail.buyerReviewed === false) && (
                    <Button
                    className='sellerconfirerbuton'
                    onClick={() => this.setState({ leaveFeedBack: true })}
                    data-rh='Leave feedback'
                    id='sellerOrderDetailsPage33'
                  >
                      Leave Feedback
                  </Button>
                )}

                {productDetail && productDetail.orderStatus === 'disputed' && (
                  <Button
                    className='sellerconfirerbuton'
                    onClick={() => this.setState({ sellerViewDispute: true })}
                    data-rh='View Dispute'
                    id='sellersellerdisputeOrderDetailsPage33'
                  >
                    View Buyer Dispute
                  </Button>
                )}

                {productDetail && productDetail.orderStatus === 'rma issued' && (
                  <Button
                    className='sellerconfirerbuton'
                    // onClick={() => this.setState({ sellerViewDispute: true })}
                    data-rh='RMA issued'
                    id='sellersellerdisputeRMAOrderDetailsPage33'
                  >
                    RMA issued
                  </Button>
                )}

                {productDetail && productDetail.orderStatus === 'returned' && (
                  <Button
                    className='sellerconfirerbuton'
                    onClick={() => this.setState({ refundPayment: true })}
                    data-rh='Refund Payment'
                    id='sellersellerdisputeRefundOrderDetailsPage33'
                  >
                    Refund Payment
                  </Button>
                )}

                {productDetail && productDetail.orderStatus === 'returned' && (
                  <Button
                    className='selletOrderTrackbutton'
                    onClick={() => this.setState({ sellerTrackOrder: true })}
                    data-rh='Track Order'
                    id='sellersellerdisputeReturnOrderDetailsPage33'
                  >
                    Track Order
                  </Button>
                )}

                {productDetail && productDetail.orderStatus &&
                  ((productDetail.orderStatus !== 'delivered' &&
                    productDetail.orderStatus !== 'completed' &&
                    productDetail.orderStatus !== 'seller confirmed' &&
                    productDetail.orderStatus !== 'disputed' &&
                    productDetail.orderStatus !== 'returned' &&
                    productDetail.orderStatus !== 'rma issued' &&
                    productDetail.orderStatus !== 'refunded'&&
                    productDetail.orderStatus !== 'paid'&&
                    productDetail.orderStatus !== 'payment verified' && 
                    productDetail.orderStatus !== 'shipped' && productDetail.orderStatus !== 'canceled')) && (
                    <Button
                    className='sellercamcelorderbuton'
                    onClick={() => this.setState({ cancelOrder: true ,isCancelBeforeshipment:false})}
                    data-rh='Cancel order'
                    id='sellerOrderDetailsPage34'
                  >
                      Cancel Order
                  </Button>
                )}
                {productDetail && productDetail.orderStatus && productDetail.paymentType &&( productDetail.orderStatus === 'payment verified' ||
                    productDetail.orderStatus === 'paid') && (
                    <Button
                    className='sellercamcelorderbuton'
                    onClick={() => this.setState({ refundPayment: true ,isCancelBeforeshipment:true})}
                    data-rh='Cancel order'
                    id='sellerOrderDetailsPagev34'
                  >
                      Cancel Order
                  </Button>
                )}
                {productDetail && productDetail.orderStatus === 'canceled' && (
                  <Button
                    className='sellercancelleddiv'
                    data-rh='Cancel'
                    id='sellerOrderDetailsPage35'
                    style={{cursor:'inherit'}}
                  >
                    Order Cancelled
                  </Button>
                )}

                {productDetail && productDetail.orderStatus === 'refunded' && (
                  <Button
                    className='sellercancelleddiv'
                    data-rh='Payment Refunded '
                    id='sellerOrderrefundDetailsPage35'
                  >
                    Payment Refunded
                  </Button>
                )}
              </div>
              {productCategoryType === 'Radiology' && (
                <div>
                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.specification
                        ? ' activeYellowCollapse '
                        : '')
                    }
                    id='sellerOrderDetailsPage36'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage37'
                    >
                      SPECIFICATIONS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('specification')}
                      id='sellerOrderDetailsPage38'
                    >
                      <img
                        alt='DownArrow'
                        src={
                          this.state.specification
                            ? dropdownpro
                            : downBlackArrow
                        }
                        className={
                          this.state.specification ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.specification && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage39'
                    >
                      <div>
                        <div
                          className='sellerMureproscdv'
                          id='sellerOrderDetailsPage40'
                        >
                          Manufacturer
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage41'
                        >
                          System Name
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage42'
                        >
                          {' '}
                          Date of Manufacture
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage43'
                        >
                          {' '}
                          Working Condition
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage44'
                        >
                          {' '}
                          Condition
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage45'
                        >
                          {' '}
                          Console Type
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage46'
                        >
                          {' '}
                          Available Quantity
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage47'
                        >
                          {' '}
                          {productInfo && productInfo.manufacturer
                            ? productInfo.manufacturer
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage48'
                        >
                          {' '}
                          {productInfo && productInfo.systemName
                            ? productInfo.systemName
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage49'
                        >
                          {' '}
                          {productInfo &&
                          productInfo &&
                          productInfo.dateOfManufacture
                            ? moment(productInfo.dateOfManufacture).format(
                              'MM/DD/YYYY'
                            )
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage50'
                        >
                          {productInfo && productInfo.workingCondition
                            ? productInfo.workingCondition
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage51'
                        >
                          {' '}
                          {productInfo && productInfo.condition
                            ? productInfo.condition
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage52'
                        >
                          {' '}
                          {productInfo && productInfo.consoleType
                            ? productInfo.consoleType
                            : 'N/A'}
                        </div>

                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage53'
                        >
                          {' '}
                          {productInfo && productInfo.inventoryQuantity
                            ? productInfo.inventoryQuantity
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage54' />

                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.logistics ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage55'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage56'
                    >
                      LOGISTICS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('logistics')}
                      id='sellerOrderDetailsPage57'
                    >
                      <img
                        alt='downArrow'
                        src={
                          this.state.logistics ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.logistics ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.logistics && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage58'
                    >
                      <div>
                        <div
                          className='Manufacturerpro'
                          id='sellerOrderDetailsPage59'
                        >
                          Location
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage60'
                        >
                          Accessibility
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage61'
                        >
                          {' '}
                          Rigging
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage62'
                        >
                          {' '}
                          {productInfo && productInfo.logisticsLocation
                            ? productInfo.logisticsLocation
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage63'
                        >
                          {' '}
                          {productInfo && productInfo.accessibility
                            ? productInfo.accessibility
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage64'
                        >
                          {' '}
                          {productInfo && productInfo.rigging
                            ? productInfo.rigging
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage65' />

                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.titleInfo ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage66'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage67'
                    >
                      TITLE INFORMATION
                    </div>
                    <div
                      className='sellereTSeapro'
                      id='sellerOrderDetailsPage68'
                    >
                      Title Search
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('titleInfo')}
                      id='sellerOrderDetailsPage69'
                    >
                      <img
                        alt='downArrow'
                        src={
                          this.state.titleInfo ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.titleInfo ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.titleInfo && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage70'
                    >
                      <div>
                        <div
                          className='Manufacturerpro'
                          id='sellerOrderDetailsPage71'
                        >
                          Ownership Status
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage72'
                        >
                          Title Availability
                        </div>
                        <div
                          className='SystemNamepro'
                          id='sellerOrderDetailsPage73'
                        >
                          Title Search link
                        </div>

                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage74'
                        >
                          {' '}
                          Liens/Loans on Equipment
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage75'
                        >
                          {' '}
                          {productInfo && productInfo.ownershipStatus
                            ? productInfo.ownershipStatus
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage76'
                        >
                          {productInfo && productInfo.titleAvailability
                            ? productInfo.titleAvailability
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage77'
                        >
                          {productInfo && productInfo.titleSearchLink
                            ? productInfo.titleSearchLink
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage78'
                        >
                          {productInfo && productInfo.equipmentLiensLoans
                            ? productInfo.equipmentLiensLoans
                            : 'N/A'}{' '}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage79' />
                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.inspection ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage80'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage81'
                    >
                      INSPECTION REPORT
                    </div>
                    <div
                      className='selleradrepipro'
                      id='sellerOrderDetailsPage82'
                    >
                      {productInfo &&
                      productInfo.inspectionReportName &&
                      productInfo.inspectionReportURL ? (
                        <a
                            href={productInfo.inspectionReportURL}
                            target='_blank'
                          >
                          Download Report
                          </a>
                        ) : (
                          'Download Report'
                        )}
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('inspection')}
                      id='sellerOrderDetailsPage83'
                    >
                      <img
                        alt='downArrow'
                        src={
                          this.state.inspection ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.inspection ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.inspection && (
                    <div id='sellerOrderDetailsPage84'>
                      <div
                        className='sellerOrderDetailContentBox'
                        id='sellerOrderDetailsPage85'
                      >
                        <div>
                          <div
                            className='Manufacturerpro'
                            id='sellerOrderDetailsPage86'
                          >
                            Type
                          </div>
                          <div
                            className='sellerSystmepro'
                            id='sellerOrderDetailsPage87'
                          >
                            File
                          </div>
                        </div>
                        <div>
                          <div
                            className='sellerordMRIpro'
                            id='sellerOrderDetailsPage88'
                          >
                            {' '}
                            Self-declared
                          </div>
                          <div
                            className='placeSonyprof'
                            id='sellerOrderDetailsPage89'
                          >
                            {productInfo && productInfo.inspectionReportName
                              ? productInfo.inspectionReportName
                              : 'N/A'}
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage90' />
                </div>
              )}

              {productCategoryType !== 'Radiology' && (
                <div>
                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.overView ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage91'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage92'
                    >
                      OVERVIEW
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('overView')}
                      id='sellerOrderDetailsPage93'
                    >
                      <img
                        alt=''
                        src={this.state.overView ? dropdownpro : downBlackArrow}
                        className={
                          this.state.overView ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.overView && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage94'
                    >
                      <div>
                        <div
                          className='sellerMureproscdv'
                          id='sellerOrderDetailsPage95'
                        >
                          Seller SKU
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage96'
                        >
                          Brand
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage97'
                        >
                          {' '}
                          Expiration Date on Product?
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage98'
                        >
                          Manufacturer
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage99'
                        >
                          {' '}
                          {productInfo && productInfo.productSku
                            ? productInfo.productSku
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage100'
                        >
                          {' '}
                          {productInfo && productInfo.brand
                            ? productInfo.brand
                            : 'N/A'}
                        </div>

                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage101'
                        >
                          {' '}
                          {productInfo && productInfo.productExpires
                            ? productInfo.productExpires
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage102'
                        >
                          {' '}
                          {productInfo && productInfo.manufacturer
                            ? productInfo.manufacturer
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage103' />

                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.otherInfo ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage104'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage105'
                    >
                      OTHER INFO
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('otherInfo')}
                      id='sellerOrderDetailsPage106'
                    >
                      <img
                        alt=''
                        src={
                          this.state.otherInfo ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.otherInfo ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.otherInfo && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage107'
                    >
                      <div>
                        <div
                          className='sellerMureproscdv'
                          id='sellerOrderDetailsPage108'
                        >
                          Catalog Number
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage109'
                        >
                          Generic Keywords
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage110'
                        >
                          {' '}
                          Importer Nameoduct ID
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage111'
                        >
                          {' '}
                          {productInfo && productInfo.catalogNumber
                            ? productInfo.catalogNumber
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage112'
                        >
                          {' '}
                          {productInfo && productInfo.genericKeywords
                            ? productInfo.genericKeywords
                            : 'N/A'}
                        </div>

                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage113'
                        >
                          {' '}
                          {productInfo && productInfo.importerName
                            ? productInfo.importerName
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage114' />
                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.dimentions ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage115'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage116'
                    >
                      DIMENSIONS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('dimentions')}
                      id='sellerOrderDetailsPage117'
                    >
                      <img
                        alt=''
                        src={
                          this.state.dimentions ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.dimentions ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.dimentions && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage118'
                    >
                      <div>
                        <div
                          className='sellerMureproscdv'
                          id='sellerOrderDetailsPage119'
                        >
                          Size
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage120'
                        >
                          Color
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage121'
                        >
                          {' '}
                          Weight
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage122'
                        >
                          {' '}
                          {productInfo && productInfo.size
                            ? productInfo.size
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage123'
                        >
                          {' '}
                          {productInfo && productInfo.color
                            ? productInfo.color
                            : 'N/A'}
                        </div>

                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage124'
                        >
                          {' '}
                          {productInfo && productInfo.weight
                            ? productInfo.weight
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage125' />
                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.compliance ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage126'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage127'
                    >
                      COMPLIANCE
                    </div>
                    <div onClick={() => this.openDetailDropDown('compliance')}>
                      <img
                        alt=''
                        src={
                          this.state.compliance ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.compliance ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.compliance && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage128'
                    >
                      <div>
                        <div
                          className='sellerMureproscdv'
                          id='sellerOrderDetailsPage129'
                        >
                          Country of Origin
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage130'
                        >
                          {' '}
                          Specific Product Use
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage131'
                        >
                          {' '}
                          {productInfo && productInfo.countryOfOrigin
                            ? productInfo.countryOfOrigin
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage132'
                        >
                          {' '}
                          {productInfo && productInfo.specificProductUse
                            ? productInfo.specificProductUse
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='whiteBgDiv' id='sellerOrderDetailsPage133' />
                  <div
                    className={
                      'sellerordopownpro ' +
                      (!this.state.condition ? ' activeYellowCollapse ' : '')
                    }
                    id='sellerOrderDetailsPage134'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='sellerOrderDetailsPage135'
                    >
                      CONDITION
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('condition')}
                      id='sellerOrderDetailsPage136'
                    >
                      <img
                        alt=''
                        src={
                          this.state.condition ? dropdownpro : downBlackArrow
                        }
                        className={
                          this.state.condition ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15, width: 20 }}
                      />
                    </div>
                  </div>
                  {this.state.condition && (
                    <div
                      className='sellerOrderDetailContentBox'
                      id='sellerOrderDetailsPage137'
                    >
                      <div>
                        <div
                          className='sellerMureproscdv'
                          id='sellerOrderDetailsPage138'
                        >
                          {' '}
                          Item Condition
                        </div>
                        <div
                          className='sellerSystmepro'
                          id='sellerOrderDetailsPage139'
                        >
                          Condition Note
                        </div>
                      </div>
                      <div>
                        <div
                          className='sellerordMRIpro'
                          id='sellerOrderDetailsPage140'
                        >
                          {' '}
                          {productInfo && productInfo.itemCondition
                            ? productInfo.itemCondition
                            : 'N/A'}
                        </div>
                        <div
                          className='placeSonyprof'
                          id='sellerOrderDetailsPage141'
                        >
                          {' '}
                          {productInfo && productInfo.conditionNote
                            ? productInfo.conditionNote
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='whiteBgDiv' id='sellerOrderDetailsPage142' />
                </div>
              )}
            </div>
            <div style={{ marginLeft: 50 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 300 }}>
                  <Carousel showThumbs={false}>
                    {productInfo &&
                    productInfo.productImageList &&
                    productInfo.productImageList.length > 0 ? (
                        productInfo.productImageList.map((item, i) => (
                          <div id={`sellerOrderDetailsPage140imgBox${i}`} key={i}>
                            <img
                              id={`sellerOrderDetailsPage140imgBoximg${i}`}
                              src={item || productDetails}
                              onError={e => {
                                e.target.onerror = null
                                e.target.src = productDetails
                              }}
                              alt='products'
                              // style={{height: 299, width: 209}}
                              style={{
                                height: '100%',
                                width: '100%',
                                minHeight: 299,
                                minWidth: 209
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        <div>
                          <img
                            src={productDetails}
                            onError={e => {
                              e.target.onerror = null
                              e.target.src = productDetails
                            }}
                            alt='products'
                            // style={{height: 299, width: 209}}
                            style={{
                              height: '100%',
                              width: '100%',
                              minHeight: 299,
                              minWidth: 209
                            }}
                            id='sellerOrderDetailsPage143'
                          />
                        </div>
                      )}
                  </Carousel>
                </div>

                <div>
                  <div
                    className='placerigCircleImage'
                    id='sellerOrderDetailsPage144'
                  >
                    {productInfo &&
                      productInfo.productImageList &&
                      productInfo.productImageList.length > 0 &&
                      productInfo.productImageList.map((item, i) => (
                        <img
                          id={`sellerOrderDetailsPage140imgBoxproductsimg${i}`}
                          key={i}
                          alt='products'
                          src={item}
                          onError={e => {
                            e.target.onerror = null
                            e.target.src = productDetails
                          }}
                          className='circleimage1'
                          onClick={() => this.showImage(i)}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className='sellerelinfobopro' id='sellerOrderDetailsPage145'>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <div
                    className='sellersnfoheadiung'
                    id='sellerOrderDetailsPage146'
                  >
                    BUYER INFORMATION
                  </div>
                  <div>
                    <div
                      className='sellertcsdcdpro'
                      id='sellerOrderDetailsPage147'
                    >
                      {' '}
                      TOP 10%
                    </div>
                    <div
                      style={{ marginLeft: 135, marginTop: 10 }}
                      id='sellerOrderDetailsPage148'
                    >
                      <StarRatingComponent
                        id='sellerOrderDetailsPage149'
                        name={'rating'}
                        starCount={5}
                        value={
                          productDetail &&
                          productDetail.buyerDetails &&
                          productDetail.buyerDetails.reputationData &&
                          productDetail.buyerDetails.reputationData
                            .buyerRatings &&
                          productDetail.buyerDetails.reputationData.buyerRatings
                            .buyerRating
                            ? productDetail.buyerDetails.reputationData
                              .buyerRatings.buyerRating
                            : 0
                        }
                        emptyStarColor={'#E4E4E4'}
                        starColor={'#F5D028'}
                        style={{ fontSize: '80px', margin: 0 }}
                        //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex' }} id='sellerOrderDetailsPage150'>
                  <div>
                    <img
                      alt='products'
                      // src={productDetails}
                      src={
                        productDetail &&
                        productDetail.buyerDetails &&
                        productDetail.buyerDetails.profilePictureURL
                          ? productDetail.buyerDetails.profilePictureURL
                          : placeholderProfile
                      }
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = placeholderProfile
                      }}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        marginLeft: 27
                      }}
                      id='sellerOrderDetailsPage151'
                    />
                  </div>
                  <div>
                    <p
                      className='sellerccdcrthapro'
                      id='sellerOrderDetailsPage152'
                    >
                      {productDetail &&
                      productDetail.buyerDetails &&
                      productDetail.buyerDetails.firstName
                        ? productDetail.buyerDetails.firstName
                        : ''}{' '}
                      {productDetail &&
                      productDetail.buyerDetails &&
                      productDetail.buyerDetails.lastName
                        ? productDetail.buyerDetails.lastName
                        : ''}
                    </p>
                    <p
                      className='sellerhjvfonpro'
                      id='sellerOrderDetailsPage153'
                    >
                      Reputation:
                      {productDetail &&
                      productDetail.buyerDetails &&
                      productDetail.buyerDetails.reputationData &&
                      productDetail.buyerDetails.reputationData
                        .overallReputationScore
                        ? productDetail.buyerDetails.reputationData
                          .overallReputationScore
                        : 0}
                      /5.0
                    </p>
                  </div>
                  <div>
                    <Button
                      className='sellervbuttonprdcdco'
                      onClick={() => {
                        this.props.history.push(
                          routes.CONTACTUSERDETAIL +
                            `/${productDetail.orderedBy}`
                        )
                      }}
                      data-rh='Visit profile'
                      id='sellerOrderDetailsPage154'
                    >
                      Visit Profile{' '}
                    </Button>
                  </div>
                </div>
                <div
                  className='sellerRevprdcdco'
                  id='sellerOrderDetailsPage155'
                >
                  Recent Reviews:
                </div>
                {productDetail &&
                  productDetail.buyerDetails &&
                  productDetail.buyerDetails.latestReview &&
                  productDetail.buyerDetails.latestReview&&productDetail.buyerDetails.latestReview.length>0&&productDetail.buyerDetails.latestReview[0] &&productDetail.buyerDetails.latestReview[0].length>0 ?productDetail.buyerDetails.latestReview[0].map((item,i)=>(
                  <div
                    className='sellerxrescdfpropro'
                    id={`sellerOrderDetailsPage156review${i}`}
                    key={i}
                    style={{marginTop:10}}
                  >
                    <div style={{ display: 'flex' }}>
                      <div>
                        <img
                          alt='profile'
                          src={
                            item &&item.reviewerProfilePictureURL
                              ? item.reviewerProfilePictureURL
                              : placeholderProfile
                          }
                          onError={e => {
                            e.target.onerror = null
                            e.target.src = placeholderProfile
                          }}
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50
                          }}
                          id={`sellerOrderDetailsPage157review${i}`}
                        />
                      </div>
                      <div style={{width:210}}>
                        <p
                          className='sellereFcdcdaulpro'
                          id={`sellerOrderDetailsPage158review${i}`}
                        >
                          {item && item.reviewerTitle
                            ? item.reviewerTitle
                            : placeholderProfile}{' '}
                          {item &&
                           item.reviewerFirstName
                            ? item.reviewerFirstName
                            : placeholderProfile}{' '}
                          {/*
                            productDetail && productDetail.buyerDetails &&
                            productDetail.buyerDetails.latestReview&&productDetail.buyerDetails.latestReview.reviewerLastName
                              ? productDetail.buyerDetails.latestReview.reviewerLastName : placeholderProfile
                          */}
                        </p>
                        <p
                          className='sellerfjkrorpro'
                          id={`sellerOrderDetailsPage159review${i}`}
                        >
                          {item && item.reviewDate
                            ? moment(item.reviewDate).format('MM/DD/YYYY ; h:MMa')
                            : 'N/A'}
                        </p>
                      </div>
                      <div
                        style={{ marginLeft: 10 }}
                        id={`sellerOrderDetailsPage160review${i}`}
                      >
                        <StarRatingComponent
                          id={`sellerOrderDetailsPage161review${i}`}
                          name={'rating'}
                          starCount={5}
                          value={item && item.reviewScore ? item.reviewScore : 0 }
                          // value={
                          //   userProfile &&
                          //     userProfile.serviceProfile &&
                          //     userProfile.serviceProfile.totalServicesOffered
                          //     ? userProfile.serviceProfile.totalServicesOffered
                          //     : 0
                          // }
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          style={{ fontSize: '80px', margin: 0 }}
                          //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                        />
                      </div>
                    </div>
                    <div
                      className='sellerudcfdvetthes'
                      id={`sellerOrderDetailsPage162review${i}`}
                      style={{paddingLeft:63}}
                    >
                      {item && item.reviewText  ? item.reviewText : 'N/A'}{' '}
                    </div>
                  </div>
                )):<div style={{textAlign:"center",fontSize:20}}>
                  No Review</div>}
              </div>
            </div>
          </div>
        </div>
        <RightSideContainer>
          <SellerConsoleSidemenu />
        </RightSideContainer>

        <Modal
          size='sm'
          isOpen={this.state.sellerConfirm}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='sellerConfirmOrderModal'
            id='sellerOrderDetailsPage163'
          >
            <div id='sellerOrderDetailsPage164'>
              <p className='confirmTitle' id='sellerOrderDetailsPage165'>
                Confirm Order
              </p>
              <p className='confirmmsg' id='sellerOrderDetailsPage166'>
                Do you wish to confirm this order from{' '}
                {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName
                  ? productDetail.buyerDetails.firstName
                  : ''}
                {/* productDetail && productDetail.buyerDetails && productDetail.buyerDetails.lastName
                  ? productDetail.buyerDetails.lastName
                : '' */}
                ?
              </p>
              <div className='buttonDiv' id='sellerOrderDetailsPage167'>
                <Button
                  className='buttons'
                  onClick={() => this.closeModal()}
                  data-rh='Cancel'
                  id='sellerOrderDetailsPage168'
                >
                  No
                </Button>
                <Button
                  className='buttons yesButton'
                  onClick={() => this.doConfirmOrder()}
                  data-rh='Confirm'
                  id='sellerOrderDetailsPage169'
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='sm'
          isOpen={this.state.cancelOrder}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='sellerConfirmOrderModal'
            id='sellerOrderDetailsPage170'
          >
            <div>
              <p className='confirmTitle' id='sellerOrderDetailsPage171'>
                Cancel Order
              </p>
              <p className='confirmmsg' id='sellerOrderDetailsPage172'>
                Do you wish to cancel this order from{' '}
                {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName
                  ? productDetail.buyerDetails.firstName
                  : ''}
                {/* productDetail && productDetail.buyerDetails && productDetail.buyerDetails.lastName
                    ? productDetail.buyerDetails.lastName
                  : '' */}{' '}
                ? This can’t be undone.
              </p>
              {productDetail &&
                (productDetail.orderStatus === 'paid' ||
                  productDetail.orderStatus === 'shipped') && (
                  <div>
                  <p
                      className='confirmSellerPin'
                      id='sellerOrderDetailsPage173'
                  >
                      Enter your PIN to Refund.{' '}
                    </p>
                  <PinInput
                      length={4}
                      focus={false}
                      secret
                    // ref={p => (this.pin = p)}
                      type='numeric'
                      style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                      inputStyle={{
                      borderColor: 'transparent',
                      borderBottomColor: '#4AEA87',
                      borderBottomWidth: 2,
                      borderTopWidth: 0,
                      borderRadius: 0,
                      color: '#FFFFFF'
                    }}
                      inputFocusStyle={{
                      borderBottomColor: '#4AEA87',
                      color: '#FFFFFF'
                    }}
                      onChange={(value, index) => {
                      this.setState(
                        {
                          pinValue: value
                        },
                        () => {}
                      )
                    }}
                      id='sellerOrderDetailsPage175'
                  />
                  {this.state.payOrderError && (
                    <div
                      className='payforwrongpinmessa'
                      style={{ width: 'auto' }}
                      id='sellerOrderDetailsPage174'
                    >
                      {this.state.payOrderError}
                      {/* You have entered your PIN incorrectly
                more than 5 times. Your PIN has been reset.
                Kindly email support@radiologex.com
                to proceed further. */}
                    </div>
                  )}

                  <p
                      className='sellerpurposeText'
                      id='sellerOrderDetailsPage176'
                      style={{ marginTop: 10 }}
                  >
                      Purpose
                    </p>

                  <input
                      className='sellerpurposeInput'
                      type='text'
                      placeholder='Enter Purpose *'
                      value={transfer_purposes}
                      onChange={e =>
                      this.setState({
                        transfer_purposes: e.target.value
                      })
                    }
                      id='sellerOrderDetailsPage177'
                  />
                </div>
              )}

              <div className='buttonDiv'>
                <Button
                  className='buttons'
                  onClick={() => this.closeModal()}
                  data-rh='Cancel'
                  id='sellerOrderDetailsPage178'
                >
                  No
                </Button>
                {productDetail && productDetail.orderStatus === 'ordered' && (
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.doCancelOrders()}
                    data-rh='Yes'
                    id='sellerOrderDetailsPage179'
                  >
                    Yes
                  </Button>
                )}
                {productDetail &&
                  (productDetail.orderStatus === 'paid' ||
                    productDetail.orderStatus === 'shipped') && (
                    <Button
                    disabled={
                      !this.state.pinValue ||
                        this.state.pinValue.length < 4 ||
                        !transfer_purposes
                    }
                    className='buttons yesButton'
                    onClick={() => this.doCancelledItemRefund()}
                    data-rh='Yes'
                    id='sellerOrderDetailsPage180'
                  >
                      Yes
                  </Button>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Shipment confirmation */}

        <Modal
          size='sm'
          isOpen={this.state.shippingConfirm}
          centered
          toggle={() => this.closeModal()}
        >
          {/* <ModalBody
            className='sellerConfirmOrderModal'
            id='sellerOrderDetailsPage181'
          >
            <div id='sellerOrderDetailsPage182'>
              <p className='confirmTitle' id='sellerOrderDetailsPage183'>
                Confirm Shipment
              </p>
              <p className='confirmmsg' id='sellerOrderDetailsPage184'>
                Has this order from{' '}
                {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName
                  ? productDetail.buyerDetails.firstName
                  : ''}{' '}
                been successfully shipped?
              </p>
              <div style={{ textAlign: 'center' }}>
                <label
                  className='selectInvoiceFileButton'
                  data-rh='Select File'
                >
                  <input
                    type='file'
                    name='profilePicture'
                    accept='application/pdf'
                    onChange={e => this.onChangeSelectShipmentFile(e)}
                  />
                  Select File
                </label>

                <div
                  className='uploadInvoiceText'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  {selectedInvoiceFile && selectedInvoiceFile.name && (
                    <div>{selectedInvoiceFile && selectedInvoiceFile.name}</div>
                  )}
                  <div style={{ opacity: 0.5 }}>
                    Upload PDF files only. Max: 5 MB
                  </div>
                </div>
              </div>

              <div className='buttonDiv' id='sellerOrderDetailsPage185'>
                <Button
                  className='buttons'
                  onClick={() => this.closeModal()}
                  data-rh='Cancel'
                  id='sellerOrderDetailsPage186'
                >
                  No
                </Button>
                <Button
                  className='buttons yesButton'
                  disabled={!(selectedInvoiceFile && selectedInvoiceFile.name)}
                  onClick={() => this.doConfirmShipment()}
                  data-rh='Yes'
                  id='sellerOrderDetailsPage187'
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody> */}

          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>Add Shipping Info...</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName
                          : '' + ' ' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='confirmShipmentDropdown'>
                  <Dropdown
                    className='confirmShipmentTypeDropdown'
                    placeholder='Shipping Agent'
                    value={this.state.selectShippingAgent}
                    options={this.state.shippingAgentList}
                    onChange={e => {
                      if (e && e.value) {
                        this.setState({
                          selectShippingAgent: e.value
                        },()=>{
                          this.updateTrackURL();
                        })
                      }
                    }}
                  />
                </div>
                

                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20,
                    marginBottom: 15
                  }}
                >
                  <input
                    value={this.state.trackingNumber}
                    type='text'
                    className='confirmShipmentTrackNumberField'
                    placeholder='Tracking Number'
                    onChange={e => {
                      if (e && e.target && e.target.value) {
                        this.setState({
                          trackingNumber: e.target.value
                        },()=>{
                          this.updateTrackURL();
                        })
                      } else {
                        this.setState({
                          trackingNumber: '',
                          trackingURL:''
                        },()=>{
                          this.updateTrackURL();
                        })
                      }
                    }}
                  />
                </div>
                {this.state.trackingNumber && this.state.trackingNumber.length>0 &&<div
                  style={{
                    // textAlign: 'center',
                    marginTop: 20,
                    marginBottom: 15,
                    wordBreak:'break-all'
                  }}
                >
                Tracking URL :   {this.state.trackingURL}
                  {/* <input
                    value={this.state.trackingURL}
                    type='text'
                    className='confirmShipmentTrackNumberField'
                    placeholder='Tracking URL'
                    onChange={e => {
                      if (e && e.target && e.target.value) {
                        this.setState({
                          trackingURL: e.target.value
                        })
                      } else {
                        this.setState({
                          trackingURL: ''
                        })
                      }
                    }}
                  /> */}
                </div>}

                <div className='invoiceFileTitle'>
                  {!this.state.previewInvoiceFile ? 'Upload ' : ''} Shipping
                  Document:
                </div>

                {!this.state.previewInvoiceFile && (
                  <div style={{ textAlign: 'right' }}>
                    <label
                      className='sellerselectUploadProofButton'
                      data-rh='Select File'
                    >
                      Select
                      <input
                        type='file'
                        name='profilePicture'
                        accept='image/jpeg,application/pdf'
                        onChange={e => this.onChangeSelectShipmentFile(e)}
                      />
                    </label>
                  </div>
                )}
                {!selectedInvoiceFile && (
                  <div
                    className='invoiceFileTitle'
                    style={{ marginTop: 10, marginBottom: 5, opacity: 0.5 }}
                  >
                    PDF or JPG files only. Max: 5 MB
                  </div>
                )}

                {selectedInvoiceFile && selectedInvoiceFile.name && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={this.state.previewInvoiceFile}
                      target='_blank'
                    >
                      {selectedInvoiceFile.name}
                    </a>

                    <Button
                      className='sellerfileRemoveIconStyle'
                      onClick={() => {
                        this.setState({
                          previewInvoiceFile: '',
                          selectedInvoiceFile: ''
                        })
                      }}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={fileRemoveIcon}
                        alt='fileRemoveIcon'
                      />
                    </Button>
                  </div>
                )}
                {selectedInvoiceFile && this.state.previewInvoiceFile && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={this.state.previewInvoiceFile}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                  <Button
                    className='sellerSendInvoiceButton'
                    data-rh='Verify Shipment'
                    disabled={
                      !(selectedInvoiceFile && selectedInvoiceFile.name) ||
                      !this.state.trackingNumber ||
                      !this.state.selectShippingAgent ||
                      !this.state.trackingURL
                    }
                    onClick={() => this.doConfirmShipment()}
                  >
                    Verify Shipment
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Feed Back Modal */}

        <Modal
          size='sm'
          isOpen={this.state.leaveFeedBack}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='sellerConfirmOrderModal'
            id='sellerOrderDetailsPage188'
          >
            <div>
              <p className='confirmTitle' id='sellerOrderDetailsPage189'>
                Leave Feedback
              </p>
              <p className='confirmmsg' id='sellerOrderDetailsPage190'>
                How would you rate your experience with{' '}
                {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName
                  ? productDetail.buyerDetails.firstName
                  : ''}{' '}
                ?{' '}
              </p>
              <div
                style={{ marginTop: 10, textAlign: 'center' }}
                id='sellerOrderDetailsPage191'
              >
                <StarRatingComponent
                  id='sellerOrderDetailsPage192'
                  name={'rating'}
                  starCount={5}
                  // value={
                  //   productDetail &&
                  //   productDetail.reputationDetails &&
                  //   productDetail.reputationDetails.latestReview
                  //     ? productDetail.serviceProfile.totalServicesOffered
                  //     : 0
                  // }
                  value={this.state.rating}
                  emptyStarColor={'#E4E4E4'}
                  starColor={'#F5D028'}
                  style={{ fontSize: '100px', margin: 0 }}
                  onStarClick={this.onStarClick.bind(this)}
                  //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                />

                <textarea
                  className='textAreainput'
                  placeholder='Leave a review of your experience'
                  value={this.state.reviewText}
                  onChange={event => {
                    this.setState({
                      reviewText: event.target.value
                    })
                  }}
                  id='sellerOrderDetailsPage193'
                />
              </div>
              <div className='buttonDiv' id='sellerOrderDetailsPage194'>
                <Button
                  className='buttons'
                  onClick={() => this.closeModal()}
                  data-rh='Cancel'
                  id='sellerOrderDetailsPage195'
                >
                  No
                </Button>
                <Button
                  className='buttons yesButton'
                  onClick={() => this.doLeaveFeedback()}
                  data-rh='Submit'
                  id='sellerOrderDetailsPage196'
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={this.state.leaveFeedsuccess}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='sellerConfirmOrderModal'
            id='sellerOrderDetailsPage197'
          >
            <div>
              <p className='confirmTitle' id='sellerOrderDetailsPage198'>
                Leave Feedback
              </p>
              <p className='confirmmsg' id='sellerOrderDetailsPage199'>
                Thank you for your feedback.{' '}
              </p>
              <div
                style={{ marginTop: 10, textAlign: 'center' }}
                id='sellerOrderDetailsPage200'
              >
                <StarRatingComponent
                  id='sellerOrderDetailsPage201'
                  name={'rating'}
                  starCount={5}
                  value={this.state.rating}
                  emptyStarColor={'#E4E4E4'}
                  starColor={'#F5D028'}
                  style={{ fontSize: '100px', margin: 0 }}
                  // onStarClick={this.onStarClick.bind (this)}
                  //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                />

                <textarea
                  className='textAreainput'
                  placeholder='Leave a review of your experience'
                  value={this.state.reviewText}
                  onChange={event => {
                    this.setState({
                      reviewText: event.target.value
                    })
                  }}
                  readOnly
                  id='sellerOrderDetailsPage202'
                />
              </div>
              <div className='buttonDiv' id='sellerOrderDetailsPage203'>
                <Button
                  className='buttons yesButton'
                  onClick={() => this.closeModal()}
                  data-rh='Close'
                  id='sellerOrderDetailsPage204'
                >
                  Ok
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={uploadInvoiceModal}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>Send Invoice....</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName
                          : '' + ' ' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>
                  {' '}
                  Please upload an invoice to confirm this order from buyer
                </div>
                <div className='invoiceFileTitle'>Invoice file:</div>

                <div style={{ textAlign: 'right' }}>
                  <label
                    className='sellerselectUploadProofButton'
                    data-rh='Select File'
                  >
                    Select
                    <input
                      type='file'
                      name='profilePicture'
                      accept='image/jpeg,application/pdf'
                      onChange={e => this.onChangeSelectInvoice(e)}
                    />
                  </label>
                </div>
                {!selectedInvoiceFile && (
                  <div
                    className='invoiceFileTitle'
                    style={{ marginTop: 10, marginBottom: 5, opacity: 0.5 }}
                  >
                    PDF or JPG files only. Max: 5 MB
                  </div>
                )}

                {selectedInvoiceFile && selectedInvoiceFile.name && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={this.state.previewInvoiceFile}
                      target='_blank'
                    >
                      {selectedInvoiceFile.name}
                    </a>

                    <Button
                      className='sellerfileRemoveIconStyle'
                      onClick={() => {
                        this.setState({
                          previewInvoiceFile: '',
                          selectedInvoiceFile: ''
                        })
                      }}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={fileRemoveIcon}
                        alt='fileRemoveIcon'
                      />
                    </Button>
                  </div>
                )}
                {selectedInvoiceFile && this.state.previewInvoiceFile && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={this.state.previewInvoiceFile}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='sellerSendInvoiceButton'
                    data-rh='Send Invoice'
                    onClick={() => this.uploadInvoice()}
                    disabled={!this.state.previewInvoiceFile}
                  >
                    Send Invoice
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>

          {/* <ModalBody className="sellerConfirmOrderModal" id="sellerOrderDetailsPageInvoice197">
            <div>
            <p className="confirmTitle" id="sellerOrderDetailsPageInvoice189" style={{textAlign:'center'}}>Confirm Order</p>
              <p className="confirmmsg" id="sellerOrderDetailsPage166">
                Do you wish to confirm this order from {' '}

                {productDetail &&
                  productDetail.buyerDetails &&
                  productDetail.buyerDetails.firstName
                  ? productDetail.buyerDetails.firstName
                  : ''}

                ?
              </p>

              <div style={{marginTop: 10, textAlign: 'center'}} id="sellerOrderDetailsPageInvoice200">

                          <label
                        className="selectInvoiceFileButton"
                        data-rh="Select File"
                      >
                        <input
                          type="file"
                          name="profilePicture"
                          accept="image/jpeg,application/pdf"
                          onChange={e => this.onChangeSelectInvoice (e)}
                        />Select File
                      </label>

                          <div className="uploadInvoiceText" style={{marginTop:20,marginBottom:15}}>
                            Please upload an invoice for this order
                            <div style={{opacity:0.5}}>PDF or JPG files only. Max: 5 MB</div>
                          </div>
                         {selectedInvoiceFile && this.state.previewInvoiceFile&&<iframe
                            src={this.state.previewInvoiceFile}
                            frameBorder="0"
                            scrolling="auto"
                            height="100%"
                            width="100%"
                        ></iframe>}
              </div>
              <div style={{textAlign:'center',marginTop:10}} id="sellerOrderDetailsPageInvoice203">
                <Button
                  className="sellermessagebuton"
                  onClick={() => this.uploadInvoice ()}
                  data-rh="Close"
                  id="sellerOrderDetailsPageInvoice204"
                  disabled={!this.state.previewInvoiceFile}
                >
                  Send Invoice
                </Button>
              </div>
            </div>
          </ModalBody> */}
        </Modal>

        <Modal
          size='sm'
          isOpen={viewInvoiceModal}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>Invoice Sent....</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName
                          : '' + ' ' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Invoice file:</div>

                {productDetail && productDetail.invoice && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={productDetail.invoiceURL}
                      target='_blank'
                    >
                      {productDetail.invoice}
                    </a>
                  </div>
                )}
                {productDetail && productDetail.invoiceURL && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={productDetail.invoiceURL}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='downloadInvoiceButton'
                    onClick={() => this.downloadInvoice()}
                    data-rh='Download'
                  >
                    <img
                      style={{ width: 15, marginRight: 5 }}
                      src={downloadInvoice}
                      alt='downloadIcon'
                    />
                    Download
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={confirmPaymentVerification}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>Verify Payment...</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName
                          : '' + ' ' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>
                  View the attached payment proof to verify you have received
                  the funds.
                </div>

                {productDetail && productDetail.receiptURL && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={productDetail.receiptURL}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='downloadInvoiceButton'
                    onClick={() => {
                      // this.downloadInvoice()
                      const { productDetail } = this.state
                      saveAs(productDetail.receiptURL, productDetail.receipt)
                    }}
                    data-rh='Download'
                  >
                    <img
                      style={{ width: 15, marginRight: 5 }}
                      src={downloadInvoice}
                      alt='downloadIcon'
                    />
                    Download
                  </Button>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      className='sellerSendInvoiceButton'
                      data-rh='Verify Payment'
                      onClick={() => this.doSellerVerifyPayment()}
                    >
                      Verify Payment
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={sellerViewDispute}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>View Dispute...</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName + ' '
                          : '' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              {!selectedDisputeOption && (
                <div>
                  <div className='invoiceFileTitle'>Dispute Reason :</div>

                  {productDetail && productDetail.disputeReason && (
                    <div style={{ opacity: 1 }} className='invoiceFileTitle'>
                      {productDetail.disputeReason}
                    </div>
                  )}

                  <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                      className='sellerSendInvoiceButton'
                      data-rh='Accept Dispute'
                      onClick={() => this.selectDisputeOption('refuse')}
                    >
                      Accept Dispute
                    </Button>
                  </div>

                  <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                      className='sellerSendInvoiceButton'
                      data-rh='Refuse Dispute'
                      onClick={() => this.selectDisputeOption('close')}
                    >
                      Refuse Dispute
                    </Button>
                  </div>
                </div>
              )}
              {selectedDisputeOption && (
                <div>
                  <div className='invoiceFileTitle'>Dispute Resolution :</div>

                  {selectedDisputeOption === 'close' && (
                    <div>
                      <textarea
                        className='sellerDisputeResolution'
                        placeholder='Enter Resolution...'
                        value={CapitalizeFirstLetter(disputeResolution)}
                        onChange={e =>
                          this.setState({ disputeResolution: e.target.value })
                        }
                      />

                      <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button
                          className='sellerSendInvoiceButton'
                          data-rh='Submit'
                          onClick={() => this.doCloseDispute()}
                          disabled={!disputeResolution}
                        >
                          Submit
                        </Button>

                        <Button
                          style={{ marginTop: 15 }}
                          className='sellerCancelBlackButton'
                          data-rh='Cancel'
                          onClick={() => {
                            this.setState({
                              disputeResolution: '',
                              selectedDisputeOption: ''
                            })
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                  {selectedDisputeOption === 'refuse' && (
                    <div style={{ marginTop: 10 }}>
                      <div className='headerTitle'>Return Shipping Address</div>

                      <div>
                        <div className=' sellerSideinputLabels'>RMA Number</div>
                        <input
                          type='text'
                          className='sellerinputItemFields'
                          placeholder='RMA Number*'
                          value={rmaNumber}
                          onChange={event => {
                            this.setState({
                              rmaNumber: event.target.value
                            })
                          }}
                        />
                      </div>
                      <div>
                        <div className=' sellerSideinputLabels'>Name</div>
                        <input
                          type='text'
                          className='sellerinputItemFields'
                          placeholder='Name*'
                          value={streetAddress}
                          onChange={event => {
                            this.setState({
                              streetAddress: event.target.value
                            })
                          }}
                        />
                      </div>
                      <div>
                        <div className=' sellerSideinputLabels'>Line 1</div>
                        <input
                          type='text'
                          className='sellerinputItemFields'
                          placeholder='Line 1*'
                          value={line1}
                          onChange={event => {
                            this.setState({
                              line1: event.target.value
                            })
                          }}
                        />
                      </div>
                      {/* <div>
                <div className=" sellerSideinputLabels">Country</div>
                <input type="text" className="sellerinputItemFields" placeholder="Country*"
                 value={country}
                onChange={event => {
                    this.setState ({
                      country: event.target.value,
                    });
                  }}/>
              </div> */}
                      <div>
                        <div className=' sellerSideinputLabels'>City</div>
                        <input
                          type='text'
                          className='sellerinputItemFields'
                          placeholder='City*'
                          value={city}
                          onChange={event => {
                            this.setState({
                              city: event.target.value
                            })
                          }}
                        />
                      </div>

                      <div>
                        <div className=' sellerSideinputLabels'>State</div>
                        <input
                          type='text'
                          className='sellerinputItemFields'
                          placeholder='State*'
                          value={state}
                          onChange={event => {
                            this.setState({
                              state: event.target.value
                            })
                          }}
                        />
                      </div>

                      <div>
                        <div className=' sellerSideinputLabels'>Zip code</div>
                        <input
                          type='text'
                          className='sellerinputItemFields'
                          placeholder='Zip code*'
                          value={zipcode}
                          onChange={event => {
                            this.setState({
                              zipcode: event.target.value
                            })
                          }}
                        />
                      </div>

                      <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button
                          className='sellerSendInvoiceButton'
                          data-rh='Submit'
                          onClick={() => this.doRefuseDispute()}
                          disabled={
                            !rmaNumber ||
                            !streetAddress ||
                            !city ||
                            !state ||
                            !zipcode ||
                            !line1
                          }
                        >
                          Submit
                        </Button>

                        <Button
                          style={{ marginTop: 15 }}
                          className='sellerCancelBlackButton'
                          data-rh='Cancel'
                          onClick={() => {
                            this.setState({
                              disputeResolution: '',
                              selectedDisputeOption: ''
                            })
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={sellerTrackOrder}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>Shipping Info....</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName
                          : '' + ' ' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Shipping Document:</div>

                {productDetail && productDetail.returnShippingDocument && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={productDetail.returnShippingDocumentURL}
                      target='_blank'
                    >
                      {productDetail.returnShippingDocument}
                    </a>
                  </div>
                )}
                {productDetail && productDetail.returnShippingDocumentURL && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={productDetail.returnShippingDocumentURL}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='downloadInvoiceButton'
                    onClick={() => {
                      // this.downloadInvoice()
                      const { productDetail } = this.state
                      saveAs(
                        productDetail.returnShippingDocumentURL,
                        productDetail.returnShippingDocument
                      )
                    }}
                    data-rh='Download'
                  >
                    <img
                      style={{ width: 15, marginRight: 5 }}
                      src={downloadInvoice}
                      alt='downloadIcon'
                    />
                    Download
                  </Button>
                </div>

                <div style={{ marginTop: 10 }}>
                  <div className='invoiceFileTitle'>
                    Shipped via{' '}
                    {productDetail && productDetail.returnShippingCompany}
                  </div>
                  <div
                    style={{ paddingLeft: 50, opacity: 1 }}
                    className='invoiceFileTitle'
                  >
                    <p style={{ margin: 0 }}>Tracking ID</p>
                    <div
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                      id='sellertrackingCode'
                    >
                      {productDetail && productDetail.returnTrackingNumber}

                      <Button
                        className='sellerfileRemoveIconStyle'
                        onClick={() => {
                          var copyText = document.getElementById(
                            'sellertrackingCode'
                          )
                          var textArea = document.createElement('textarea')
                          textArea.value = copyText.textContent
                          document.body.appendChild(textArea)
                          textArea.select()
                          document.execCommand('Copy')
                          textArea.remove()

                          this.setState(
                            {
                              copyText: true
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({
                                  copyText: false
                                })
                              }, 1500)
                            }
                          )
                        }}
                        style={{ paddingTop: 2 }}
                      >
                        <img
                          style={{ width: 20, height: 20 }}
                          src={copyIcon}
                          alt='fileRemoveIcon'
                        />
                      </Button>
                      {this.state.copyText && (
                        <span style={{ color: '#32CD32', marginLeft: 5 }}>
                          Copied!
                        </span>
                      )}
                    </div>
                  </div>

                  {productDetail && productDetail.returnTrackingURL && (
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                      <Button
                        className='sellerSendInvoiceButton'
                        onClick={() => {
                          window.open(productDetail.returnTrackingURL, '_blank')
                        }}
                        data-rh='View Tracking'
                      >
                        View Tracking
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={refundPayment}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>{this.state.isCancelBeforeshipment?'Cancel refund payment':'Shipping Info....'}</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.buyerDetails.profilePictureURL
                            ? productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName
                          : '' + ' ' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1DashedSeller'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                {!selectPaymentMethod && (
                  <div>
                    <div className='invoiceFileTitle'>
                      Select payment method:
                    </div>
  
                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                    {productDetail && productDetail.paymentType!== "internal" && <div>
                      <Button
                        className='sellerpayForExternalLinkButton'
                        onClick={() => {
                          this.setState({
                            paymentUploadInvoiceModal: true,
                            previewPayInvoiceFile: '',
                            selectedPayInvoiceFile: ''
                          })
                        }}
                        data-rh='External Payment'
                      >
                        External Payment
                      </Button>

                      <div
                        className='invoiceFileTitle'
                        style={{ marginTop: 10, fontSize: 14 }}
                      >
                        You can make a payment bank transfer or other
                        <p>agent outside of the R-DEE app.</p>
                      </div>
                      </div>}

                      {productDetail && productDetail.paymentType=== "internal" &&<div style={{ textAlign: 'center' }}>
                        <Button
                          className='sellerpayForInternalButton'
                          // onClick={() => this.setState ({ refundInternalPayment:true})}
                          onClick={() => {
                            this.setState({
                              payForOrderModal: true,
                              previewPayInvoiceFile: '',
                              selectedPayInvoiceFile: '',
                              refundInternalPayment: true
                            })
                          }}
                          data-rh='Pay via Wyre'
                        >
                          Pay via Wyre
                        </Button>
                        <div
                          className='invoiceFileTitle'
                          style={{ marginTop: 10, fontSize: 14 }}
                        >
                          You will be directed to a secure gateway via
                          <p>R-DEE using Wyre to complete payment.</p>
                        </div>
                      </div>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={paymentUploadInvoiceModal}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='confirmPlaceOrderModal'>
            <div>
              <p className='headerTitle'>Refund...</p>

              {productDetail &&
                productDetail.buyerDetails &&
                productDetail.buyerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.profilePictureURL
                            ? productDetail &&
                                productDetail.buyerDetails &&
                                productDetail.buyerDetails.profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.buyerDetails &&
                            productDetail.buyerDetails.firstName
                          ? productDetail.buyerDetails.firstName + ' '
                          : '' + productDetail &&
                              productDetail.buyerDetails &&
                              productDetail.buyerDetails.lastName
                            ? productDetail.buyerDetails.lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Upload proof of payment:</div>

                <div style={{ textAlign: 'right' }}>
                  <label
                    className='selectUploadProofButton'
                    data-rh='Select File'
                  >
                    Select
                    <input
                      type='file'
                      name='selectProof'
                      accept='image/jpeg,application/pdf'
                      onChange={e => this.onChangeInvoiceselectProof(e)}
                    />
                  </label>
                </div>
                {!selectedPayInvoiceFile && (
                  <div
                    className='invoiceFileTitle'
                    style={{ marginTop: 10, marginBottom: 5, opacity: 0.5 }}
                  >
                    PDF or JPG files only. Max: 5 MB
                  </div>
                )}

                {selectedPayInvoiceFile && selectedPayInvoiceFile.name && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={previewPayInvoiceFile}
                      target='_blank'
                    >
                      {selectedPayInvoiceFile.name}
                    </a>

                    <Button
                      className='fileRemoveIconStyle'
                      onClick={() => {
                        this.setState({
                          previewPayInvoiceFile: '',
                          selectedPayInvoiceFile: ''
                        })
                      }}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={fileRemoveIcon}
                        alt='fileRemoveIcon'
                      />
                    </Button>
                  </div>
                )}
                {previewPayInvoiceFile && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={previewPayInvoiceFile}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='sellerpayForInternalButton'
                    onClick={() => this.doRefundPayforOrder()}
                    data-rh='Payment Initiated'
                    disabled={!previewPayInvoiceFile}
                  >
                    Payment Initiated
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={refundInternalPayment}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='sellerConfirmOrderModal'
            id='sellerrefundOrderDetailsPage170'
          >
            <div>
              <p className='confirmTitle' id='sellerrefundOrderDetailsPage171'>
                Refund
              </p>
              {productDetail && (
                <div>
                        <p className='RDEEsellerRefund' id='placeorderDetailPageContentds65'>
                            <span>
                              Total Placed Order Quantity :{' '}
                              {productDetail && productDetail.productQuantity
                                ? productDetail.productQuantity
                                : 'N/A'}
                            </span>
                          </p>
                          <p
                            className='RDEEsellerRefund'
                            style={{ padding: 0 }}
                            id='placeorderDetailPageContent66ds'
                          >
                            <span>
                              Total Refund Order Price :{' '}
                              {productDetail && productDetail.totalOrderPrice
                                ? priceWithDecimal(productDetail.totalOrderPrice)
                                : 'N/A'}
                            </span>
                          </p>
                          <p
                            className='RDEEsellerRefund'
                            style={{ padding: 0 }}
                            id='placeorderDetailPageContent66dse'
                          >
                            <span>
                              Your Total RDEE Balance :{' '}
                              {rdeeCredit && rdeeCredit.currency
                                ? priceWithDecimal(rdeeCredit.amount) +' '+ rdeeCredit.currency
                                : 'N/A'}
                            </span>
                          </p>

{rdeeCredit  && rdeeCredit.amount && rdeeCredit.amount>0 && productDetail && productDetail.totalOrderPrice&&  (rdeeCredit.amount>priceWithDecimal(productDetail.totalOrderPrice))?
                 
                 <div style={{marginTop:10}}>
                  <p
                    className='confirmSellerPin'
                    id='sellerOrderDetailsPage173'
                  >
                    Enter your PIN to Refund.{' '}
                  </p>
                  <PinInput
                    length={4}
                    focus={false}
                    secret
                    // ref={p => (this.pin = p)}
                    type='numeric'
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    inputStyle={{
                      borderColor: 'transparent',
                      borderBottomColor: '#4AEA87',
                      borderBottomWidth: 2,
                      borderTopWidth: 0,
                      borderRadius: 0,
                      color: '#FFFFFF'
                    }}
                    inputFocusStyle={{
                      borderBottomColor: '#4AEA87',
                      color: '#FFFFFF'
                    }}
                    onChange={(value, index) => {
                      this.setState(
                        {
                          pinValue: value
                        },
                        () => {}
                      )
                    }}
                    id='sellerrefundOrderDetailsPage175'
                  />

                  {/* <p
                      className='sellerpurposeText'
                      id='sellerOrderDetailsPage176sa'
                      style={{ marginTop: 10 }}
                  >
                      Purpose
                    </p>

                  <input
                      className='sellerpurposeInput'
                      type='text'
                      placeholder='Enter Purpose *'
                      value={transfer_purposes}
                      onChange={e =>
                      this.setState({
                        transfer_purposes: e.target.value
                      })
                    }
                      id='sellerOrderDetailsPage177aw'
                  /> */}

                  {this.state.payOrderError && (
                    <div
                      className='payforwrongpinmessa'
                      style={{ width: 'auto' }}
                      id='sellerOrderrefundDetailsPage174'
                    >
                      {this.state.payOrderError}
                      {/* You have entered your PIN incorrectly
                more than 5 times. Your PIN has been reset.
                Kindly email support@radiologex.com
                to proceed further. */}
                    </div>
                  )}
                <div className='buttonDiv' style={{textAlign:'center'}}>
                  <Button
                    className='buttons'
                    onClick={() => this.closeModal()}
                    data-rh='Cancel'
                    id='sellerOrderDetailsPage178'
                  >
                    No
                  </Button>
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.doRefundInternal()}
                    data-rh='Yes'
                    id='sellerOrderrefundDetailsPage179'
                    // disabled={(!transfer_purposes || !this.state.pinValue || (this.state.pinValue && this.state.pinValue.length < 4))}
                    disabled={(!this.state.pinValue || (this.state.pinValue && this.state.pinValue.length < 4))}
                  >
                    Yes
                  </Button>
                </div>
                </div>: <div style={{marginTop:10}}>
                  <p
                    className='confirmSellerPin'
                    id='sellerOrderDetailsPsdage173'
                  >
                    You don't have sufficient balance for refund.Please add more balance in your RDEE wallet{' '}
                  </p>
                  <div className='buttonDiv' style={{textAlign:'center'}}>
                  <Button
                    className='buttons'
                    onClick={() => this.closeModal()}
                    data-rh='Cancel'
                    id='sellerOrderDetailsPafge178'
                  >
                    Close
                  </Button>
                  </div>
                </div>}
                </div>

              )}

              
            </div>
          </ModalBody>
        </Modal>

        {imagePreviewModal && (
          <ProductZoomDetail
            productDetail={productInfo}
            imgIndex={imgIndex}
            closeModal={this.closeModal}
            showImage={this.showImage}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer,
    payment: state.PaymentReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getSellerOrder,
      orderSellerConfirmStatus,
      getMarketPlaceOrder,
      cancelPlaceOrder,
      marketplaceOrderShippedStatus,
      sellerGiveReviewTobuyer,
      resetdoOrderValue,
      doCancelledRefundItem,
      sellerVerifyPayment,
      marketPlaceCloseDisputeSellerSide,
      marketPlaceRefuseDisputeSellerSide,
      marketplaceOrderRefundedStatus,
      marketPlaceInternalRefundSellerSide,
      getTransactionHistory,
      resetOrderList
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellerOrderDetails)
