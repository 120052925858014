import {
  getAllUserContacts,
  deleteUserContact,
  addUserContact,
  getUserNotificationHistory,
  addUserRequest,
  deleteUserNotificationHistory,
  cancelSentPendingContactRequestService,
  setUserNotificationHistoryStatus
} from '../services/Authservice';

import {
  getAllUserContacts_v2Service,
  searchUsersService,
  getUserDetailsService,
  uploadUserProfileService,
  getSentUserContactRequestsService,
  getUserPendingActionsService,
  getBusinessDetailsService
} from '../services/contactservice';
import {
  PROFILE_DETAIL,
  UPDATE_CONTACTS,
  OUR_CONTACT,
  SEARCH_CONTACTS,
  CONTACTS_DETAIL,
  UPLOAD_USER_IMAGE,
  ADD_CONTACT_SUCCESS,
  NOTIFICATION_LIST,
  DELETE_NOTIFICATION_LIST,
  RECEIVE_REQUEST_LIST,
  SEND_REQUEST_LIST,
  BUSINESS_DETAIL,
  ADD_CONTACT_FAILURE,
  CANCEL_SEND_REQUEST_SUCCESS,
  CANCEL_SEND_REQUEST_FAILURE,
  ACCEPT_CONTACT_SUCCESS,
  DELETE_CONTACT_SUCCESS,
} from '../reducers/ContactsReducer';
import {Loading} from './../actions/LoadingAction';
import {getProfileDashboard} from './Authaction';
import {createNotification} from '../constants/notificationtoast';

import {modifyPersonalInfoService} from '../services/Authservice';
import {getMessage} from '../constants/localMessage/errorMessage';
import {ACCEPT_DASHBOARD_REQUEST ,DELETE_DASH_NOTIFICATION_LIST} from '../reducers/Authreducer';
export const getAllUserContactsFunc = data => {
  return dispatch => {
    // dispatch (Loading (true));

    // getAllUserContacts (data)
  getAllUserContacts_v2Service(data).then (res => {
        if (res && res.status === 200) {
          if (res.data && res.data.result && res.data.result.status) {
            dispatch ({type: UPDATE_CONTACTS, payload: res.data.result.Data});
          }
        } else {
          // console.log ('Result **** ', res);
        }
        // dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const deleteUserContactFunc = data => {
  return dispatch => {

    dispatch ({type: DELETE_CONTACT_SUCCESS, payload: data});
    // dispatch (Loading (true));
    deleteUserContact (data)
      .then (res => {

        if (res && res.data && res.data.result.status === true) {
          
            const sendRequest = {
              data: {
                getPersonalDetails: true,
                getProfessionalDetails: true,
                getEducationalDetails: true,
                getBusinessProfile: true,
                getReputationDetails:true,
                docID: data.data.contactUID,
              },
            };
            dispatch (getUserDetail (sendRequest));

            let sendRequest1 = {
              data: {
                contactUID: '',
              },
            };
            dispatch (getAllUserContacts_v2 (sendRequest1));


            


            createNotification ('success',getMessage(res.data.result.responseCode));

        } else {
          createNotification ('error',getMessage(res.data.result.responseCode));
        }
        dispatch (Loading (false));


        // console.log (res);
        // if (res && res.data && res.data.result.status === true) {
        //   dispatch ({type: LOGIN, payload: res.data});
        //   dispatch(Loading(false));

        // } else {
        //   if (res && res.data && res.data.result) {
        //     createNotification ('error', res.data.result.message);
        //   }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};

export const addUserContactFunc = data => {
  return dispatch => {
    // dispatch (Loading (true));
    dispatch ({type: ADD_CONTACT_SUCCESS, payload: data});
   
    addUserContact (data)
      .then (res => {
        // console.log (res);
        if (res && res.data && res.data.result.status === true) {
          // let sendRequest = {
          //   data: {
          //     contactUID: '',
          //   },
          // };
          // dispatch (getAllUserContacts_v2 (sendRequest));
          // console.log(res.data.result.Data);
         
          createNotification ('success',getMessage(res.data.result.responseCode));
          data.data.messageID=res.data.result.Data.messageID;
          // console.log(data);
          dispatch ({type: ADD_CONTACT_SUCCESS, payload: data});

          dispatch (Loading (false));
        } else {
          createNotification ('error',getMessage(res.data.result.responseCode));
          
          dispatch ({type: ADD_CONTACT_FAILURE, payload: data});

        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const cancelSendUserContactRequest = (data,userID) => {
  return dispatch => {
    // dispatch (Loading (true));
    dispatch ({type: CANCEL_SEND_REQUEST_SUCCESS, payload: userID});
    cancelSentPendingContactRequestService (data)
      .then (res => {
      
        if (res && res.data && res.data.result.status === true) {

          createNotification ('success',getMessage(res.data.result.responseCode));
          
          // dispatch(getAllsendRequestusers({data:{}}))

        } else {
          if (res && res.data && res.data.result) {
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        dispatch (Loading (false));

        }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const acceptContactFunc = (data, acceptUserid) => {
  return dispatch => {
    // dispatch (Loading (true));
    // console.log(data);
    // data.data.targetUID=acceptUserid;
    let payload=  data.data;
    payload.targetUID=acceptUserid;
    dispatch ({type: ACCEPT_CONTACT_SUCCESS, payload: payload});
    dispatch ({type: ACCEPT_DASHBOARD_REQUEST, payload: payload});

    addUserRequest (data)
      .then (res => {
        // console.log (res);
        if (res && res.data && res.data.result.status === true) {
          let sendRequest = {
            data: {
              contactUID: '',
            },
          };
          createNotification ('success',getMessage(res.data.result.Data[0].responseCode));
          dispatch(getProfileDashboard({data:{}}));
          dispatch (getAllUserContacts_v2 (sendRequest));

          dispatch (Loading (false));
        } else {
          createNotification ('error',getMessage(res.data.result.responseCode));
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getAllUserContacts_v2 = data => {
  return dispatch => {
    dispatch (Loading (true));
    getAllUserContacts_v2Service (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          dispatch ({type: OUR_CONTACT, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          dispatch ({type: OUR_CONTACT, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getSearchUserList = data => {
  return dispatch => {
    dispatch (Loading (true));
    searchUsersService (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          dispatch ({type: SEARCH_CONTACTS, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          dispatch ({type: SEARCH_CONTACTS, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};

export const getUserDetail = payload => {
  // console.log (payload);
  return dispatch => {
    dispatch (Loading (true));
    getUserDetailsService (payload)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          if (payload && payload.data.docID) {
            dispatch ({type: CONTACTS_DETAIL, payload: res.data.result.Data});
            dispatch (Loading (false));
          } else {
            dispatch ({type: PROFILE_DETAIL, payload: res.data.result.Data});
            dispatch (Loading (false));
          }
        } else {
          dispatch ({type: CONTACTS_DETAIL, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};

export const getBusinessDetail = payload => {
  // console.log (payload);
  return dispatch => {
    dispatch (Loading (true));
    getBusinessDetailsService (payload)
      .then (res => {
        if (res && res.data && res.data.result && res.data.result.status === true) {
            dispatch ({type: BUSINESS_DETAIL, payload: res.data.result.Data});
            dispatch (Loading (false));
         
        } else {
          dispatch ({type: BUSINESS_DETAIL, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};


export const getUserProfileDetail = payload => {
  return dispatch => {
    dispatch (Loading (true));
    getUserDetailsService (payload)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          dispatch ({type: CONTACTS_DETAIL, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          dispatch ({type: CONTACTS_DETAIL, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
   
  };
};

export const uploadUserProfile = data => {
  return dispatch => {
    dispatch (Loading (true));
    uploadUserProfileService (data)
      .then (res => {
        dispatch (Loading (false));

        if (res && res.data && res.data.result && res.data.result.status === true) {
          dispatch ({type: UPLOAD_USER_IMAGE, payload: res.data.result.Data});

          const sendRequest = {
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile: true,
            },
          };
          dispatch (getUserDetail (sendRequest));
        } else {
          if(res.data && res.data.result && res.data.result.message){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
          
          dispatch ({type: UPLOAD_USER_IMAGE, payload: []});
          // dispatch (Loading (false));

        }
      })
      .catch (err => {
        console.log (err);

        dispatch (Loading (false));

        throw err;
      });
    
  };
};

export const updateUser = data => {

  return dispatch => {
    dispatch (Loading (true));
    modifyPersonalInfoService (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          createNotification ('success',getMessage(res.data.result.responseCode));
          // dispatch ({type: UPLOAD_USER_IMAGE, payload: res.data.result.Data});
          dispatch (Loading (false));

          const sendRequest = {
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile: true,
            },
          };
          dispatch (getUserDetail (sendRequest));
        } else {
          // dispatch ({type: UPLOAD_USER_IMAGE, payload: []});
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getUserNotifications = data => {
  return dispatch => {
    dispatch (Loading (true));
    getUserNotificationHistory (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          dispatch ({type: NOTIFICATION_LIST, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          dispatch ({type: NOTIFICATION_LIST, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};
export const readUserNotifications = data => {
  return dispatch => {
    setUserNotificationHistoryStatus (data)
      .then (res => {
        // if (res && res.data && res.data.result.status === true) {
        //   dispatch ({type: NOTIFICATION_LIST, payload: res.data.result.Data});
        //   dispatch (Loading (false));
        // } else {
        //   dispatch ({type: NOTIFICATION_LIST, payload: []});
        // }
        // dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};
export const deleteNotifications = data => {
  return dispatch => {
    // dispatch (Loading (true));
    dispatch ({type: DELETE_NOTIFICATION_LIST, payload: data.data});
    dispatch ({type: DELETE_DASH_NOTIFICATION_LIST, payload: data.data});
    
    deleteUserNotificationHistory (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          // dispatch ({type: DELETE_NOTIFICATION_LIST, payload: data.data});
          dispatch (Loading (false));
        }
        // else {
        //   dispatch ({type: DELETE_NOTIFICATION_LIST, payload: []});

        // }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const getAllsendRequestusers = data => {
  return dispatch => {
    dispatch (Loading (true));
    getSentUserContactRequestsService (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          dispatch ({type: SEND_REQUEST_LIST, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          dispatch ({type: SEND_REQUEST_LIST, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};
export const getAllreceiveRequestusers = data => {
  return dispatch => {
    dispatch (Loading (true));
    getUserPendingActionsService (data)
      .then (res => {
        if (res && res.data && res.data.result.status === true) {
          dispatch ({type: RECEIVE_REQUEST_LIST, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          dispatch ({type: RECEIVE_REQUEST_LIST, payload: []});
        }
        dispatch (Loading (false));

        // }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};

export const resetSearchContactReducer = () => {
  return dispatch => {
      dispatch ({type: SEARCH_CONTACTS, payload: []});

  }};