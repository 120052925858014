import React, {Component} from 'react';
import './contentApps.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Loading} from '../../actions/LoadingAction';
import leftbackarrow from '../../images/icons/leftbackarrowBlue.png';
import {Button} from 'reactstrap';
import ReactPlayer from 'react-player';
import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
import contentAppsComing from '../../images/comingsoon/contentAppsComing.svg';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';

class ContentApps extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      urls:[
        {url: 'https://www.youtube.com/watch?v=Klq1Txa_N84'},
        
        
        // {url: 'https://vimeo.com/478538309'},

      ],
      medicalurls:[
        {url: 'https://www.youtube.com/watch?v=9zL_6AsHJ3w'},
        {url: 'https://www.youtube.com/watch?v=qzsidFlfhMQ'},
        {url: 'https://www.youtube.com/watch?v=Nc04SkL8HR4'},
        {url: 'https://www.youtube.com/watch?v=Rp6kxFVxALI'},
        {url: 'https://www.youtube.com/watch?v=9BReDTawbFM'},
      ],
      coronavirus:[
        {url: 'https://www.youtube.com/watch?v=5DGwOJXSxqg'},
        {url: 'https://www.youtube.com/watch?v=lP_z9CjblXo'},
        {url: 'https://www.youtube.com/watch?v=GceNO4X4U0o'},
        {url: 'https://www.youtube.com/watch?v=I-Yd-_XIWJg'},
      ],
      research:[
        {url: 'https://www.youtube.com/watch?v=VxYmkTF8Ra8'},
        {url: 'https://www.youtube.com/watch?v=QrBN6twyfPg'},
        {url: 'https://www.youtube.com/watch?v=TUJiM92-i5U'},
        {url: 'https://www.youtube.com/watch?v=R79mLjsHP3c'},
      ],
      getResponseData:'',
      isAccess:true
    };
  }

  componentDidMount(){
    this.props.Loading(false);
    if(checkAvailableAccess ("contents_app") && checkAvailableAccess ("contents_app")=== 'contents_app'){
      this.setState({
        isAccess:true
      })
    }else{
      this.setState({
        isAccess:false
      })
    }
  }

  render () {
    const {isAccess}= this.state;
    return (
      <div className="contentAppContiner ">
      {isAccess &&  <div>
        
        <div style={{display:'flex',justifyContent:'space-between'}}>
        <div className="heading">R-DEE Stream </div>
   
      <Button
      className="appsbackbutton"
      onClick={() => this.props.history.goBack ()}
    >
      <div>
        <img src={leftbackarrow} alt="backIcon" />
      </div>
      <span style={{opacity:0.6}}> Go</span>
       <span> Back</span>
    </Button>
    </div>
    <div>
    {this.state.urls.map((item ,i)=>(

        <ReactPlayer key={i} className="playerCss" url={item.url} light={true} controls={true} playing={true}  playsinline={true}/>
      ))}
    </div>


    <div>
      <div className="heading">MEDICAL IMAGING </div>
      {this.state.medicalurls.map((item ,i)=>(

        <ReactPlayer key={i} className="playerCss" url={item.url} light={true} controls={true} playing={true}  playsinline={true}/>
        ))}

      </div>

      <div>
      <div className="heading">CORONAVIRUS </div>
      {this.state.coronavirus.map((item ,i)=>(

        <ReactPlayer key={i} className="playerCss" url={item.url} light={true} controls={true} playing={true}  playsinline={true}/>
        ))}

      </div>
      <div>
      <div className="heading">RESEARCH </div>
      {this.state.research.map((item ,i)=>(

        <ReactPlayer key={i} className="playerCss" url={item.url} light={true} controls={true} playing={true}  playsinline={true}/>
        ))}

      </div>
    
    </div>}

    {!isAccess  && <CommonAccessContainer bgImage={contentAppsComing} message={checkAvailableAccess('contents_app')} />}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // contact: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({
    Loading,
  }, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (ContentApps);
