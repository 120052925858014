import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Input} from 'reactstrap';
import {Carousel} from 'react-responsive-carousel';
import StarRatingComponent from 'react-star-rating-component';
import './sellerViewListing.css';
import leftbackarrow from '../../../../../images/icons/leftbackarrow.png';
import dropdownpro
  from '../../../../../images/marketPlaceBuyer/dropdownpro.png';
// import markefilter from '../../../images/marketPlaceBuyer/markefilter.png';
import {
  resetdoOrderValue,
  getSellerInventory,
  deListMarketPlace,
  publishOrder
} from '../../../../../actions/marketplaceaction';
import moment from 'moment';
import SellerConsoleSidemenu
  from '../../sellerConsole/sellerSidemenu/sellerConsoleSidemenu';
import placeholderProfile from '../../../../../images/profile/pronew.png';
import downBlackArrow from '../../../../../images/icons/downBlackArrow.png';

import productDetails
  from '../../../../../images/marketPlaceBuyer/productDetails.png';
import {dataTimeFormat} from '../../../../../utility/convertTimeString';
import * as routes from '../../../../../constants/routes';
import RightSideContainer
  from '../../../../../components/UI/rightSideContainer/rightSideContainer';
import {priceWithDecimal} from '../../../../../utility/commonFunctions';
import ProductZoomDetail from '../../../../UI/productZoom/productZoom';

class SellerViewListing extends Component {
  constructor (props) {
    super (props);
    this.state = {
      specification: true,
      logistics: true,
      titleInfo: true,
      inspection: true,
      overView: true,
      otherInfo: true,
      dimentions: true,
      compliance: true,
      condition: true,
      productDetail: [],
      productId: '',
      productCategoryType: '',
      productStatus: '',
    };
    this.closeModal = this.closeModal.bind (this);
    this.showImage = this.showImage.bind (this);
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {


      if (
        nextProps.product &&
        nextProps.product.successModels
      ) {
        let sendInventoryRequest = {
          data: {
            docID: this.state.productId,
          },
        };
        this.props.resetdoOrderValue ();
        this.props.getSellerInventory (sendInventoryRequest);

      }


      if (
        nextProps.product &&
        nextProps.product.sellerorders &&
        nextProps.product.sellerorders.length > 0
      ) {
        this.setState (
          {
            productDetail: nextProps.product.sellerorders[0],
          },
          () => {
            const {productDetail} = this.state;
            this.setState ({
              deviceTypeValue: productDetail.deviceType,
              manufactureValue: productDetail.manufacturer,
              deviceLocation: productDetail.location,
              workingcondition: productDetail.workingCondition,
              conditionValue: productDetail.condition,
              logisticslocaltionTypeValue: productDetail.logisticsLocation,
              logisticsaccessibilityValue: productDetail.accessibility,
              logisticsRiggingValue: productDetail.rigging,
              logisticsreporttypeValue: productDetail.inspectionReportType,
              manufacturedate: productDetail.dateOfManufacture
                ? new Date (productDetail.dateOfManufacture)
                : null,
              ownershipValue: productDetail.ownershipStatus,
              titleAvailabilityValue: productDetail.titleAvailability,
              LoanEquipmentValue: productDetail.equipmentLiensLoans,
              deviceName: productDetail.deviceSaleName,
              systemname: productDetail.systemName,
              consoletype: productDetail.consoleType,
              devicePrice: productDetail.priceUSD,
              titlesearch: productDetail.titleSearchLink,
              // inspectionReportFileType:'',
              inspectionReport: productDetail.inspectionReportURL,
              inspectionReportFileName: productDetail.inspectionReportName,
              productImageList: productDetail.productImageList,
              inventoryQuantity: productDetail.inventoryQuantity,
              // logisticsreporttypeValue:productDetail.reportType,
              productCategoryType: productDetail.productCategoryType,
              productStatus: productDetail.status,
            });
          }
        );
      }
    }
  }

  componentDidMount () {
    this.props.resetdoOrderValue ();

    if (
      this.props.match.params &&
      this.props.match.params.productId !== 'create'
    ) {
      let sendInventoryRequest = {
        data: {
          docID: this.props.match.params.productId,
        },
      };
      this.setState ({
        productId: this.props.match.params.productId,
      });


      // this.props.publishOrder(sendInventoryRequest)
      this.props.getSellerInventory (sendInventoryRequest);
    }
  }

  openDetailDropDown (status) {
    if (status === 'specification') {
      this.setState ({
        specification: !this.state.specification,
      });
    } else if (status === 'logistics') {
      this.setState ({
        logistics: !this.state.logistics,
      });
    } else if (status === 'titleInfo') {
      this.setState ({
        titleInfo: !this.state.titleInfo,
      });
    } else if (status === 'overView') {
      this.setState ({
        overView: !this.state.overView,
      });
    } else if (status === 'otherInfo') {
      this.setState ({
        otherInfo: !this.state.otherInfo,
      });
    } else if (status === 'dimentions') {
      this.setState ({
        dimentions: !this.state.dimentions,
      });
    } else if (status === 'compliance') {
      this.setState ({
        compliance: !this.state.compliance,
      });
    } else if (status === 'condition') {
      this.setState ({
        condition: !this.state.condition,
      });
    } else {
      this.setState ({
        inspection: !this.state.inspection,
      });
    }
  }
  showImage (index) {
    this.setState ({
      imagePreviewModal: true,
      imgIndex: index,
    });
  }
  closeModal () {
    this.setState ({
      imagePreviewModal: false,
    });
  }

  doDelistProduct () {
    let sendRequest = {
      data: {
        docID: this.state.productId,
      },
    };
    this.props.deListMarketPlace (sendRequest);
  }
  render () {
    const {
      devicePrice,
      productImageList,
      productDetail,
      manufactureValue,
      titleAvailabilityValue,
      systemname,
      manufacturedate,
      workingcondition,
      conditionValue,
      consoletype,
      logisticslocaltionTypeValue,
      logisticsaccessibilityValue,
      logisticsRiggingValue,
      ownershipValue,
      LoanEquipmentValue,
      logisticsreporttypeValue,
      inspectionReport,
      inspectionReportFileName,
      inventoryQuantity,
      productCategoryType,
      imagePreviewModal,
      imgIndex,
      productStatus,
    } = this.state;

    return (
      <div className="selleraddproContainer" id="sellerViewlistingPage">
        <div>
          <div style={{textAlign: 'right'}} id="sellerViewlistingPage1">
            <Button
              className="selleraddsbackbutton"
              onClick={() => this.props.history.goBack ()}
              id="sellerViewlistingPage2"
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" id="sellerViewlistingPage3"/>
              </div>

              Go <span> Back</span>
            </Button>
          </div>
          <div className="selleradddmaindiv" id="sellerViewlistingPage4">
            <div>
              <div className="viewlisgvSiemensAvapro" id="sellerViewlistingPage5">

                {productDetail &&
                  productDetail.productCategoryType === 'Radiology'
                  ? productDetail.deviceSaleName
                  : (productDetail && productDetail.productName
                      ? productDetail.productName
                      : '')}

                <p id="sellerViewlistingPage6">
                  {' '}
                  USD$
                  {' '}
                  {devicePrice ? priceWithDecimal (devicePrice) : 'N/A'}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 15,
                }}
                id="sellerViewlistingPage7"
              >
                <div id="sellerViewlistingPage8">
                  <p className="viewlistitemorder" id="sellerViewlistingPage9">
                    Item ID # :
                    {' '}
                    {productDetail && productDetail.docID
                      ? productDetail.docID
                      : 'N/A'}
                    {' '}
                  </p>
                  <p className="viewlistAdded" id="sellerViewlistingPage10">
                    Added to Marketplace:
                    {' '}

                    {productDetail && productDetail.lastUpdated
                      ? dataTimeFormat (productDetail.lastUpdated)
                      : 'N/A'}
                  </p>
                </div>
                <div style={{paddingLeft:15}} id="sellerViewlistingPage11">
                  {productStatus !== 'inactive' &&
                    <div className="viewlistAdded" id="sellerViewlistingPage12">
                      Please change the status of product to 'Draft' before editing.
                    </div>}
                    <div style={{display:'inline-flex',paddingTop:10}} id="sellerViewlistingPage13">
                  {productStatus !== 'inactive' &&
                    <Button
                      className="viewlistedibutton"
                      data-rh="Unlist Item"
                     
                      onClick={() => {
                        this.doDelistProduct();
                      }}
                      id="sellerViewlistingPage14"
                    >
                      Unlist Item
                    </Button>}
                  <Button
                    disabled={productStatus !== 'inactive'}
                    style={{cursor:productStatus !== 'inactive' ?'unset':''}}
                    className="viewlistedibutton"
                    data-rh="Edit"
                    onClick={() => {
                      // let productType = 'radiology';
                      // if (productCategoryType === 'generic') {
                      //   productType = 'generic';
                      // }
                      let productType = productCategoryType;

                      this.props.history.push (
                        routes.SELLER_ADD_PRODUCT +
                          `/${productType}/${this.state.productId}`
                      );
                    }}
                    id="sellerViewlistingPage15"
                  >
                    Edit Item
                  </Button>
                  </div>
                </div>

              </div>
              {productCategoryType === 'Radiology' &&
                <div id="sellerViewlistingPage16">
                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.specification
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage17"
                  >
                    <div className="viewlstSTIONSpro" id="sellerViewlistingPage18">
                      SPECIFICATIONS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown ('specification')}
                      id="sellerViewlistingPage19"
                    >

                      <img
                        alt="DownArrow"
                        src={
                          this.state.specification
                            ? dropdownpro
                            : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                        id="sellerViewlistingPage20"
                      />
                    </div>
                  </div>
                  {this.state.specification &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage21">
                      <div>
                        <div className="viewrMureproscdv" id="sellerViewlistingPage22">Manufacturer</div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage23">System Name</div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage24">
                          {' '}Date of Manufacture
                        </div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage25">
                          {' '}Working Condition
                        </div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage26"> Condition</div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage27"> Console Type</div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage28">
                          {' '}Available Quantity
                        </div>
                      </div>
                      <div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage29">
                          {' '}
                          {manufactureValue ? manufactureValue : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage30">
                          {' '}{' '}
                          {systemname ? systemname : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage31">
                          {' '}
                          {manufacturedate
                            ? moment (manufacturedate).format ('MM/DD/YYYY')
                            : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage32">
                          {workingcondition ? workingcondition : 'N/A'}

                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage33">
                          {' '}{' '}
                          {conditionValue ? conditionValue : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage34">
                          {' '}{' '}
                          {consoletype ? consoletype : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage35">
                          {' '}{' '}
                          {inventoryQuantity ? inventoryQuantity : 'N/A'}
                        </div>
                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing" id="sellerViewlistingPage36"/>
                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.logistics
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage37"
                  >
                    <div className="viewlstSTIONSpro" id="sellerViewlistingPage38">
                      LOGISTICS
                    </div>
                    <div onClick={() => this.openDetailDropDown ('logistics')} id="sellerViewlistingPage39">
                      <img
                        alt="DownArrow"
                        src={
                          this.state.logistics ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.logistics &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage40">
                      <div>
                        <div className="viewlstMcturerpro"  id="sellerViewlistingPage41">Location</div>
                        <div className="viewlistSystmepro"  id="sellerViewlistingPage42">Accessibility</div>
                        <div className="viewlistSystmepro"  id="sellerViewlistingPage43"> Rigging</div>
                      </div>
                      <div>
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage44">
                          {' '}{' '}
                          {logisticslocaltionTypeValue
                            ? logisticslocaltionTypeValue
                            : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof"  id="sellerViewlistingPage45">
                          {' '}
                          {logisticsaccessibilityValue
                            ? logisticsaccessibilityValue
                            : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof"  id="sellerViewlistingPage46">
                          {' '}
                          {logisticsRiggingValue
                            ? logisticsRiggingValue
                            : 'N/A'}
                        </div>
                      </div>
                    </div>}
                  <div className="bottomWhiteSpacing"  id="sellerViewlistingPage47"/>
                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.titleInfo
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage48"
                  >
                    <div className="viewlstSTIONSpro"  id="sellerViewlistingPage49">
                      TITLE INFORMATION
                    </div>
                    <div className="viewlisteTSeapro"  id="sellerViewlistingPage50">Title Search</div>
                    <div onClick={() => this.openDetailDropDown ('titleInfo')} id="sellerViewlistingPage51">

                      <img
                        alt="DownArrow"
                        src={
                          this.state.titleInfo ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.titleInfo &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage52">
                      <div>
                        <div className="viewlstMcturerpro" id="sellerViewlistingPage53">
                          Ownership Status
                        </div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage55">
                          Title Availability
                        </div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage56">
                          Title Search link
                        </div>
                        <div className="viewlistSystmepro" id="sellerViewlistingPage57">
                          {' '}Liens/Loans on Equipment
                        </div>
                      </div>
                      <div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage58">
                          {' '}
                          {ownershipValue ? ownershipValue : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof" id="sellerViewlistingPage59">
                          {titleAvailabilityValue
                            ? titleAvailabilityValue
                            : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof"  id="sellerViewlistingPage60">

                          {productDetail && productDetail.titleSearchLink
                            ? productDetail.titleSearchLink
                            : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof"  id="sellerViewlistingPage61">
                          {LoanEquipmentValue ? LoanEquipmentValue : 'N/A'}
                          {' '}
                        </div>
                      </div>
                    </div>}
                  <div className="bottomWhiteSpacing"  id="sellerViewlistingPage62"/>
                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.inspection
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage63"
                  >
                    <div className="viewlstSTIONSpro"  id="sellerViewlistingPage64">
                      INSPECTION REPORT
                    </div>
                    <div className="selleradrepipro"  id="sellerViewlistingPage65">

                      {inspectionReportFileName &&
                        inspectionReportFileName &&
                        inspectionReport
                        ? <a href={inspectionReport} target="_blank">

                            Download Report
                          </a>
                        : 'Download Report'}

                    </div>
                    <div onClick={() => this.openDetailDropDown ('inspection')}  id="sellerViewlistingPage66">
                      <img
                        alt="DownArrow"
                        src={
                          this.state.inspection ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.inspection &&
                    <div className="sellerViewListingContentBox"  id="sellerViewlistingPage67">

                      <div>
                        <div className="viewlstMcturerpro"  id="sellerViewlistingPage68">Type</div>
                        <div className="viewlistSystmepro"  id="sellerViewlistingPage69">File</div>

                      </div>
                      <div> 
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage70">
                          {' '}
                          {logisticsreporttypeValue
                            ? logisticsreporttypeValue
                            : 'N/A'}
                        </div>
                        <div className="viewlistSonyprof"  id="sellerViewlistingPage71">
                          {inspectionReportFileName
                            ? inspectionReportFileName
                            : 'N/A'}
                        </div>

                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing"  id="sellerViewlistingPage72"/>
                </div>}

              {productCategoryType !== 'Radiology' &&
                <div>
                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.overView
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage73"
                  >
                    <div className="viewlstSTIONSpro"  id="sellerViewlistingPage74">
                      OVERVIEW
                    </div>
                    <div onClick={() => this.openDetailDropDown ('overView')}  id="sellerViewlistingPage75">

                      <img
                        alt="DownArrow"
                        src={this.state.overView ? dropdownpro : downBlackArrow}
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.overView &&
                    <div className="sellerViewListingContentBox"  id="sellerViewlistingPage76">
                      <div> 
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage77">Seller SKU</div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage78">Brand</div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage79">
                          Expiration Date on Product?
                        </div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage80">Manufacturer</div>

                      </div>
                      <div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage81">
                          {productDetail && productDetail.productSku
                            ? productDetail.productSku
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage82">
                          {' '}{' '}
                          {productDetail && productDetail.brand
                            ? productDetail.brand
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage83">
                          {' '}
                          {productDetail && productDetail.productExpires
                            ? productDetail.productExpires
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage84">
                          {productDetail && productDetail.manufacturer
                            ? productDetail.manufacturer
                            : 'N/A'}

                        </div>

                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing" id="sellerViewlistingPage85"/>

                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.otherInfo
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage86"
                  >
                    <div className="viewlstSTIONSpro" id="sellerViewlistingPage87">
                      OTHER INFO
                    </div>
                    <div onClick={() => this.openDetailDropDown ('otherInfo')} id="sellerViewlistingPage88">

                      <img
                        alt="DownArrow"
                        src={
                          this.state.otherInfo ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.otherInfo &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage89">
                      <div>
                        <div className="viewrMureproscdv" id="sellerViewlistingPage90">Catalog Number</div>
                        <div className="viewrMureproscdv" id="sellerViewlistingPage91">Generic Keywords</div>
                        <div className="viewrMureproscdv" id="sellerViewlistingPage92">
                          Importer Nameoduct ID
                        </div>

                      </div>
                      <div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage93">
                          {productDetail && productDetail.catalogNumber
                            ? productDetail.catalogNumber
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage94">
                          {' '}{' '}
                          {productDetail && productDetail.genericKeywords
                            ? productDetail.genericKeywords
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage95">
                          {' '}
                          {productDetail && productDetail.importerName
                            ? productDetail.importerName
                            : 'N/A'}
                        </div>

                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing" id="sellerViewlistingPage96"/>

                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.dimentions
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage97"
                  >
                    <div className="viewlstSTIONSpro" id="sellerViewlistingPage98">
                      DIMENSIONS
                    </div>
                    <div onClick={() => this.openDetailDropDown ('dimentions')} id="sellerViewlistingPage99">

                      <img
                        alt="DownArrow"
                        src={
                          this.state.dimentions ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.dimentions &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage100">
                      <div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage101">Size</div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage102">Color</div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage103">Weight</div>

                      </div>
                      <div>
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage104">
                          {productDetail && productDetail.size
                            ? productDetail.size
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage105">
                          {' '}{' '}
                          {productDetail && productDetail.color
                            ? productDetail.color
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage106">
                          {' '}
                          {productDetail && productDetail.weight
                            ? productDetail.weight
                            : 'N/A'}
                        </div>

                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing"  id="sellerViewlistingPage107"/>

                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.compliance
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage108"
                  >
                    <div className="viewlstSTIONSpro"  id="sellerViewlistingPage109">
                      COMPLIANCE
                    </div>
                    <div onClick={() => this.openDetailDropDown ('compliance')}  id="sellerViewlistingPage110">

                      <img
                        alt="DownArrow"
                        src={
                          this.state.compliance ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.compliance &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage111">
                      <div>
                        <div className="viewrMureproscdv" id="sellerViewlistingPage112">
                          Country of Origin
                        </div>
                        <div className="viewrMureproscdv" id="sellerViewlistingPage113">
                          Specific Product Use
                        </div>

                      </div>
                      <div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage114">
                          {productDetail && productDetail.countryOfOrigin
                            ? productDetail.countryOfOrigin
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro" id="sellerViewlistingPage115">
                          {' '}{' '}
                          {productDetail && productDetail.specificProductUse
                            ? productDetail.specificProductUse
                            : 'N/A'}
                        </div>

                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing" id="sellerViewlistingPage116"/>

                  <div
                    className={
                      'viewlisordopownpro ' +
                        (!this.state.condition
                          ? ' sellerActiveYellowCollapse '
                          : '')
                    }
                    id="sellerViewlistingPage117"
                  >
                    <div className="viewlstSTIONSpro" id="sellerViewlistingPage118">
                      CONDITION
                    </div>
                    <div onClick={() => this.openDetailDropDown ('condition')} id="sellerViewlistingPage119">

                      <img
                        alt="DownArrow"
                        src={
                          this.state.condition ? dropdownpro : downBlackArrow
                        }
                        style={{marginRight: 15, width: 20}}
                      />
                    </div>
                  </div>
                  {this.state.condition &&
                    <div className="sellerViewListingContentBox" id="sellerViewlistingPage120">
                      <div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage121">Item Condition</div>
                        <div className="viewrMureproscdv"  id="sellerViewlistingPage122">Condition Note</div>

                      </div>
                      <div>
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage123">
                          {productDetail && productDetail.itemCondition
                            ? productDetail.itemCondition
                            : 'N/A'}
                        </div>
                        <div className="viewlistrordMRIpro"  id="sellerViewlistingPage124">
                          {' '}{' '}
                          {productDetail && productDetail.conditionNote
                            ? productDetail.conditionNote
                            : 'N/A'}
                        </div>

                      </div>

                    </div>}
                  <div className="bottomWhiteSpacing"  id="sellerViewlistingPage125"/>
                </div>}
            </div>

            <div style={{marginLeft: 50}}  id="sellerViewlistingPage126">
              <div style={{display: 'flex'}}  id="sellerViewlistingPage127">
                <div style={{width: 300}}>
                  <Carousel showThumbs={false}>
                    {productImageList && productImageList.length > 0
                      ? productImageList.map ((item, i) => (
                          <div key={i} id={`sellerViewlistingPageproductImageList${i}`}>
                            <img
                              id={`sellerViewlistingPageproductImageListimg${i}`}
                              src={item ? item : productDetails}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src = productDetails;
                              }}
                              alt="products"
                              style={{height: "100%", width: "100%",minHeight: 299, minWidth: 209}}
                              // style={{height: 299, width: 209}}
                            />

                          </div>
                        ))
                      : <div>
                          <img
                            id="sellerViewlistingPage128"
                            src={productDetails}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = productDetails;
                            }}
                            alt="products"
                            // style={{height: 299, width: 209}}
                            style={{height: "100%", width: "100%",minHeight: 299, minWidth: 209}}
                          />

                        </div>}
                  </Carousel>
                </div>
                <div>
                  <div className="viewlistigCircleImage">
                    {productImageList &&
                      productImageList.length > 0 &&
                      productImageList.map ((item, i) => (
                        <img
                          key={i}
                          id={`sellerViewlistingPageproductimg${i}`}
                          alt="ArrowIcon"
                          src={item}
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = productDetails;
                          }}
                          className="circleimage1"
                          onClick={() => this.showImage (i)}
                        />
                      ))}
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
        <RightSideContainer>
          <SellerConsoleSidemenu />
        </RightSideContainer>
        {imagePreviewModal &&
          <ProductZoomDetail
            productDetail={productDetail}
            imgIndex={imgIndex}
            closeModal={this.closeModal}
            showImage={this.showImage}
          />}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getSellerInventory,
      resetdoOrderValue,
      deListMarketPlace,
      publishOrder
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  SellerViewListing
);
