import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Media,
} from 'reactstrap';

import localtion from '../../../images/profile/location.svg';
import building from '../../../images/profile/building.svg';
import work from '../../../images/profile/work.svg';
import union from '../../../images/profile/union.png';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './profiledetail.css';

import {getUserDetail,addUserContactFunc,acceptContactFunc} from '../../../actions/contacts';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import moment from 'moment';


import * as routes from '../../../constants/routes';
class ProfileDetail extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      userid:'',
      personalDetail: [],
      educationDetail: [],
      professionalDetail: [],
      businessDetail:'',
      checkuserChatStatus:'',
      pendingContactRequest: ''

    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {

      if (
        nextProps.contacts &&
        nextProps.contacts.contactDetail &&
        nextProps.contacts.contactDetail.length > 0
      ) {
        let getData = nextProps.contacts.contactDetail;

        this.setState ({
          personalDetail: getData[0].personalDetails,
          professionalDetail: getData[1].professionalDetails,
          educationDetail: getData[2].educationDetails,
          businessDetail: getData[3].businessProfile &&
            getData[3].businessProfile.length > 0 &&
            getData[3].businessProfile[0].businessDetails
            ? getData[3].businessProfile[0].businessDetails
            : '',
            checkuserChatStatus:  getData[4].inContactList,
            pendingContactRequest: getData[5],
        });
      }
    }
  }

  componentDidMount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let geturl= this.props.match.params;

    if(geturl && geturl.userId){
      this.setState({
        userid: geturl.userId
      },()=>{
        let sendRequest={
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile:true,
              docID:this.state.userid
            }
        }
        this.props.getUserDetail(sendRequest)
      })

    }
  }

  goToBusinessprofile () {
    this.props.history.push (routes.BUSINESSDETAIL + `/${this.state.userid}`);
  }
  goToChat(){

    const {personalDetail} = this.state;
    let userChatInfo={
      oppositeUserUid:this.state.userid,
      profilePictureURL:(personalDetail && personalDetail.profilePictureURL ) ? personalDetail.profilePictureURL:  profilePlaceholder,
      oppositeUserDisplayName:(personalDetail && personalDetail.displayName ) ? personalDetail.displayName :  ((personalDetail && personalDetail.firstName ) ? personalDetail.firstName:  '' + ' '+  (personalDetail && personalDetail.lastName ) ? personalDetail.lastName:  '')
    }

    this.props.history.push(routes.MESSENGER, {userChatInfo: userChatInfo} );
  }


  addNewContact () {
    let sendRequest = {
      data: {
        contactUID: this.state.userid,
      },
    };
    this.props.addUserContactFunc (sendRequest);
  }
  acceptRequest(item, status){
    let sendRequest = {
      data: {
        messageID: item.pendingContactRequestMessageID,
        accepted: status
      },
    };



    this.props.acceptContactFunc(sendRequest,item.docID);
  }
  render () {
    const {
      personalDetail,
      professionalDetail,
      educationDetail,
      businessDetail,
      checkuserChatStatus,
      pendingContactRequest
    } = this.state;
    return (
      <div className="flex ">
        <div className="mainContainer">

         
          <p className="myProfileTitle">
          <Link to={routes.PROFILE}>
          <Button className="backButton">
            <img alt="union" src={union} /> 
          </Button>
          </Link>
           Profile
          </p>
          <div className="innerContainer">
              <div>
                <div className="userProfileBox">
                  <Media>
                    <Media left>
                      <Media
                        className="profileImage"
                        src={
                          personalDetail && personalDetail.profilePictureURL
                            ? personalDetail.profilePictureURL
                            : profilePlaceholder
                        }
                        alt="Generic placeholder image"
                      />
                    </Media>
                    <Media body>
                    <div className="buttonBox">
                    {checkuserChatStatus === 'false'  && pendingContactRequest && pendingContactRequest.pendingContactRequest !== 'true' && <Button className="ProfileButtons" onClick={()=>this.addNewContact()}>
                        Add Contact
                      </Button>}
                      {checkuserChatStatus === 'true' && pendingContactRequest && pendingContactRequest.pendingContactRequest !== 'true'  &&<Button className="ProfileButtons" style={{marginTop:0}}  onClick={()=>this.goToChat()}>
                      Message
                      </Button>}
                      {checkuserChatStatus === 'pending' && pendingContactRequest && pendingContactRequest.pendingContactRequest !== 'true'  && <Button className="ProfileButtons" >
                      Pending Request
                      </Button>}

                      {pendingContactRequest && pendingContactRequest.pendingContactRequest === 'true' &&
                        <div>
                          <Button
                            className="addContactButton"
                            onClick={() => this.acceptRequest (pendingContactRequest,true)}
                          >
                          Approve Contact 
                          </Button>

                        
                          <Button
                            className="removeContactButton"
                            onClick={() => this.acceptRequest (pendingContactRequest,false)}
                          >
                            Remove
                          </Button>
                        </div>

                      }

                      
                      </div>
                      <Media heading className="username">
                      {personalDetail && personalDetail.title
                        ? personalDetail.title
                        : ' '}
                      {' '}

                      {personalDetail && personalDetail.firstName
                        ? personalDetail.firstName
                        : ''}
                        {' '}
                        {personalDetail && personalDetail.lastName
                            ? personalDetail.lastName
                            : ''}
                 
                      </Media>
                      <p className="workplace">
                        {' '}<img src={work} />{professionalDetail &&
                          professionalDetail[0] &&
                          professionalDetail[0].position
                          ? professionalDetail[0].position
                          : ' '}

                      </p>
                      <p className="univercityEduplace">
                        {' '}
                        <img src={building} />
                        {' '}
                        {professionalDetail &&
                          professionalDetail[0] &&
                          professionalDetail[0].firmName
                          ? professionalDetail[0].firmName
                          : ' '}
                      </p>
                      <p className="localtionText">
                        {' '}
                        <img src={localtion} />
                        {' '}
                        {personalDetail && personalDetail.location
                          ? personalDetail.location
                          : 'N/A'}
                        {' '}
                       
                        {' '}
                      </p>

                    </Media>
                  </Media>
                </div>

                <div className="padd14">
                  <Row>
                    <Col xs="3">
                      <p className="txtColor columnTitle">Personal Details</p>
                      <div className="card">
                        <Row>
                          <Col xs="4">
                            <p className="titleP titlePColor">Title</p>
                            <p className="titleP nameStyle"> {personalDetail && personalDetail.title
                              ? personalDetail.title
                              : ' '}
                            {' '}</p>
                          </Col>
                          <Col xs="4">
                            <p className="titleP titlePColor">First Name</p>
                            <p className="titleP nameStyle"> {personalDetail && personalDetail.firstName
                              ? personalDetail.firstName
                              : ''}</p>
                          </Col>
                          <Col xs="4">
                            <p className="titleP titlePColor">Last Name</p>
                            <p className="titleP nameStyle"> {personalDetail && personalDetail.lastName
                              ? personalDetail.lastName
                              : ''}</p>
                          </Col>
                        </Row>
                        <div className="marginT10">
                          <p className="titleP titlePColor">Search Handle</p>
                          <p className="titleP nameStyle">{personalDetail && personalDetail.searchHandle
                            ? personalDetail.searchHandle
                            : ''}</p>
                        </div>
                        <div className="marginT10">
                          <p className="titleP titlePColor">About Me</p>
                          <p className="titleP nameStyle">
                          {personalDetail && personalDetail.aboutMe
                            ? personalDetail.aboutMe
                            : ''}
                            
                          </p>
                        </div>
                        <div className="marginT10">
                          <p className="titleP titlePColor">Service Offers</p>
                          <p className="titleP nameStyle">
                          {personalDetail && personalDetail.servicesOffered
                            ? personalDetail.servicesOffered
                            : ''}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xs="3">
                      <p className="txtColor columnTitle">Educational Details</p>
                      <div className="card">
                      {educationDetail.map ((item, index) => (<div className="marginT10 borderBotton">
                          <p className="titleP nameStyle">
                          {item.university ? item.university : ''}
                          {' '}
                          -
                          {' '}
                          {item.university ? item.yearCompleted : ''}
                          </p>
                          <p className="titleP titlePColor">
                          {item.courseMajor ? item.courseMajor : ''}
                          {' '}
                          ,
                          {' '}
                          {item.degreeType ? item.degreeType : ''}
                          </p>
                        </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs="3">

                      <p className="txtColor columnTitle">Working Experiences</p>
                      <div className="card">
                      {professionalDetail.map ((item, index) => (<div className="marginT10 borderBotton">
                          <p className="titleP nameStyle">
                          {item.firmName ? item.firmName : ''}
                          </p>
                          <p className="titleP dateText">
                            
                            {item.startDate
                              ? moment (item.startDate).format ('Do MMMM YYYY')
                              : ''}

                            {' '} - {' '}
                            {item.endDate
                              ? moment (item.endDate).format ('Do MMMM YYYY')
                              : item.currentlyWorking === 'true'
                                  ? ' Present'
                                  : ''}
                          </p>
                          <p className="titleP titlePColor">
                          {item.experienceType ? item.experienceType : ''}
                          {' '}
                          -
                          {' '}
                          {' '}
                          {item.position ? item.position : ''}
                          </p>
                        </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs="3">
                      <p className="txtColor columnTitle">Business Profile</p>
                      <div className="buttonCard">
                        <div className="marginT10 borderBotton padd10">
                          <p className="titleP titlePColor">
                            Registered Business Profile
                          </p>
                          <p className="titleP nameStyle">
                          {businessDetail &&
                            businessDetail.registeredBusinessName
                            ? businessDetail.registeredBusinessName
                            : ''}
                          </p>
                        </div>
                        <div className="marginT10 borderBotton padd10">
                          <p className="titleP titlePColor">Search Handle</p>
                          <p className="titleP nameStyle">{personalDetail && personalDetail.searchHandle
                            ? personalDetail.searchHandle
                            : ' '}</p>
                        </div>
                        <div className="marginT10 borderBotton padd10">
                          <Row>
                            <Col xs="6">
                              <p className="titleP titlePColor">City</p>
                              <p className="titleP nameStyle">{businessDetail && businessDetail.businessCity
                                ? businessDetail.businessCity
                                : ' '}</p>
                            </Col>
                            <Col xs="6">
                              <p className="titleP titlePColor">State</p>
                              <p className="titleP nameStyle"> {businessDetail && businessDetail.businessState
                                ? businessDetail.businessState
                                : ' '}</p>
                            </Col>
                          </Row>
                        </div>

                        <div className="marginT10 padd10">
                          <p className="titleP titlePColor">Country</p>
                          <p className="titleP nameStyle">{businessDetail && businessDetail.businessCountry
                            ? businessDetail.businessCountry
                            : ' '}</p>
                        </div>
                        <div>
                          <Button className="buttonStyle" onClick={()=>this.goToBusinessprofile()}>
                          Visit Business Profile
                          </Button>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            

          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserDetail,
      addUserContactFunc,
      acceptContactFunc
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (ProfileDetail);
