import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import Authreducer from './Authreducer';
import LoadingReducer from './Loadingreducer';
import MessengerReducer from './MessengerReducer';
import ContactsReducer from './ContactsReducer';
import NotificationsReducer from './NotificationsReducer';
import PaymentReducer from './Paymentreducer';
import MarketplaceReducer from './Marketplacereducer';
import serviceOnDemandReducer from './serviceOnDemandReducer';
import serviceRequestReducer from './serviceRequestReducer';
import vaultReducers from './VaultReducer';
import hightLightReducer from './HightLightReducer';




// import List from './list';
// import HOMECONTENT from './homecontent';
// import GETSERVICEDATA from './servicedata';
// import GETGALLERYDATA from './servicedata';


// export default combineReducers({
    // Authreducer,
    // LoadingReducer,
    // MessengerReducer,
    // routerReducer,
    // ContactsReducer,
    // NotificationsReducer,
    // PaymentReducer,
    // MarketplaceReducer
// });



const appReducer = combineReducers({
    Authreducer,
    LoadingReducer,
    MessengerReducer,
    routerReducer,
    ContactsReducer,
    NotificationsReducer,
    PaymentReducer,
    MarketplaceReducer,
    serviceOnDemandReducer,
    serviceRequestReducer,
    vaultReducers,
    hightLightReducer
  });
  
  const IndexReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'LOGOUT') {
      state = undefined;
    }
  
    return appReducer(state, action);
  };
  
  export default IndexReducer;