import ProductDetails from '../images/productDefault.PNG';
import { sortBynameCountry} from '../utility/commonFunctions'
let initialState = {
  productlist: {},
  placeOrder: false,
  placeOrderList: {},
  sellerorders: {},
  sellerordershistory: {},
};

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_LIST = 'PLACE_ORDER_LIST';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const PAY_ORDER_SUCCESS = 'PAY_ORDER_SUCCESS';
export const PAY_ORDER_ERROR = 'PAY_ORDER_ERROR';
export const SELLER_ORDER_LISTING = 'SELLER_ORDER_LISTING';
export const SELLER_ORDER_HISTORY = 'SELLER_ORDER_HISTORY';
export const CREATE_SELLER_ORDER = 'CREATE_SELLER_ORDER';
export const CONFIRM_SELLER_ORDER = 'CONFIRM_SELLER_ORDER';
export const PUBLISH_SELLER_ORDER = 'PUBLISH_SELLER_ORDER';
export const DELETE_SELLER_ORDER = 'DELETE_SELLER_ORDER';
export const LATEST_PRODUCT_ITEMS = 'LATEST_PRODUCT_ITEMS';
export const RECENT_VIEW_PRODUCTS = 'RECENT_VIEW_PRODUCTS';
export const LEAVE_BUYER_FEEDBACK = 'LEAVE_BUYER_FEEDBACK';
export const PLACE_ACTIVE_ORDER_LIST = 'PLACE_ACTIVE_ORDER_LIST';
export const PLACE_ACTION_ORDER_LIST = 'PLACE_ACTION_ORDER_LIST';
export const MARKET_PLACE_REVIEW = 'MARKET_PLACE_REVIEW';
export const SELLER_ACTIVE_ORDER = 'SELLER_ACTIVE_ORDER';
export const SELLER_ACTION_ORDER = 'SELLER_ACTION_ORDER';
export const MARKET_PRUDUCT_ACTION = 'MARKET_PRUDUCT_ACTION';
export const BULK_UPLOAD_ERROR_LIST = 'BULK_UPLOAD_ERROR_LIST';
export const BULK_UPLOAD_SUCCESS = 'BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_HISTORY = 'BULK_UPLOAD_HISTORY';
export const MARKETPLACE_COUNTRY_LIST = 'MARKETPLACE_COUNTRY_LIST';
export const MANUFACTUREPRODUCT_VALUES = 'MANUFACTUREPRODUCT_VALUES';
export const PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES';




export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      if (action.payload) {
        let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

        for (let item of action.payload) {
          let temproductImageListing = [];
          if (item.listedBy !== loginUser.docID) {
            item.isOrder = true;
          }
          if (
            item &&
            item.productImageList &&
            item.productImageList.length > 0
          ) {
            for (let img of item.productImageList) {
              temproductImageListing.push (img);
            }
          } else {
            temproductImageListing.push (ProductDetails);
          }
          if (item) {
            item.productImageListing = temproductImageListing;
          }
        }
      }

      return {
        ...state,
        productlist: action.payload,
        placeOrder: false,
        cancelOrder: false,
      };
    case PLACE_ORDER:
      return {
        ...state,
        placeOrder: action.payload,
        cancelOrder: false,
      };
 
      
    case PLACE_ORDER_LIST:
      // let getResponse=[]

      // if (action.payload) {
      // let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

      // for (let item of action.payload) {
      //   // console.log(item.productDetails[0] && item.productDetails[0].productImageList );
      //     let temproductImageListing = [];
      //     if(item.productDetails[0].listedBy !== loginUser.docID){
      //       item.productDetails[0].isOrder=true;
      //     }
      //     if (item.productDetails[0].productImageList && item.productDetails[0].productImageList.length > 0) {
      //       for (let img of item.productDetails[0].productImageList) {
      //         temproductImageListing.push (img);
      //       }
      //     } else {
      //       temproductImageListing.push (ProductDetails);
      //     }
      //     item.productDetails[0].productImageListing = temproductImageListing;
      //     getResponse.push(item)
      //   }

      // }
      return {
        ...state,
        placeOrderList: action.payload,
        cancelOrder: false,
      };

    case PLACE_ACTIVE_ORDER_LIST:
      return {
        ...state,
        placeActiveOrderList: action.payload,
      };
    case PLACE_ACTION_ORDER_LIST:
      return {
        ...state,
        placeActionOrderList: action.payload,
      };

    case CANCEL_ORDER:
      return {
        ...state,
        placeCancelOrder: action.payload,
        cancelOrder: true,
      };

    case PAY_ORDER_SUCCESS:
      return {
        ...state,
        payOrdersuccess: action.payload,
        payOrderError: '',
        cancelOrder: false,
      };
    case PAY_ORDER_ERROR:
      return {
        ...state,
        payOrdersuccess: '',
        payOrderError: action.payload,
        cancelOrder: false,
      };
    case SELLER_ORDER_LISTING:
      // if (action && action.payload) {
      //   console.log(action.payload);
      //   for (let item of action.payload) {
      //     let temproductImageListing = [];
      //     if (item.productImageList && item.productImageList.length > 0) {
      //       for (let img of item.productImageList) {
      //         temproductImageListing.push (img);
      //       }
      //     } else {
      //       temproductImageListing.push (ProductDetails);
      //     }
      //     item.productImageListing = temproductImageListing;
      //   }
      // }

      return {
        ...state,
        sellerorders: action.payload,
        publishorder: '',
        deleteproduct: '',
      };

    case SELLER_ORDER_HISTORY:
      if (action.payload) {
        for (let item of action.payload) {
          let temproductImageListing = [];
          if (item.productImageList && item.productImageList.length > 0) {
            for (let img of item.productImageList) {
              temproductImageListing.push (img);
            }
          } else {
            temproductImageListing.push (ProductDetails);
          }
          item.productImageListing = temproductImageListing;
        }
      }
      return {
        ...state,
        sellerordershistory: action.payload,
      };

      case SELLER_ACTIVE_ORDER:
      
      return {
        ...state,
        sellerActiveOrders: action.payload,
      };
      case SELLER_ACTION_ORDER:
      
      return {
        ...state,
        sellerActionOrders: action.payload,
      };

      


      
    case CREATE_SELLER_ORDER:
      return {
        ...state,
        createorder: action.payload,
        sellerorders: '',
      };
    case CONFIRM_SELLER_ORDER:
      return {
        ...state,
        confirmOrder: action.payload,
        publishorder: '',
      };
    case PUBLISH_SELLER_ORDER:
      return {
        ...state,
        publishorder: action.payload,
        confirmOrder: '',
      };
    case DELETE_SELLER_ORDER:
      return {
        ...state,
        deleteproduct: action.payload,
        confirmOrder: '',
      };
    case LATEST_PRODUCT_ITEMS:
      return {
        ...state,
        latestProducts: action.payload,
        confirmOrder: '',
      };

    case RECENT_VIEW_PRODUCTS:
      return {
        ...state,
        recentViews: action.payload,
      };
    case MARKET_PLACE_REVIEW:
      return {
        ...state,
        usersReview: action.payload,
        successModels:null
      };

      

    case LEAVE_BUYER_FEEDBACK:
      return {
        ...state,
        buyerFeedback: action.payload,
      };
    case MARKET_PRUDUCT_ACTION:
      return {
        ...state,
        successModels: action.payload,
      };

      case BULK_UPLOAD_ERROR_LIST:
      return {
        ...state,
        uploadError: action.payload,
      };
      case BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadSuccess: action.payload,
      };
      case BULK_UPLOAD_HISTORY:
      return {
        ...state,
        uploadedHistory: action.payload,
      };
      case MARKETPLACE_COUNTRY_LIST:
      let countryOriginList=[];
      if(action.payload && action.payload.length>0){
        for(let item of action.payload){
          countryOriginList.push({label: item, value: item})
        }
      }
      return {
        ...state,
        countryList: countryOriginList && countryOriginList.length>0 ?countryOriginList.sort (sortBynameCountry ()):[],
      };
      case MANUFACTUREPRODUCT_VALUES:
      return { ...state, manufactureItemValues: action.payload};
      case PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.payload};

      
    default:
      return state;
  }
};

 
