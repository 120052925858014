import React, {Component} from 'react';
import {Row, Col, Button, Modal, ModalBody, Container} from 'reactstrap';

import serviceondemandIcon from '../../../images/sidebar/serviceondemand.png';
import contactIcon from '../../../images/icons/messenger.png';

import marketplaceIcon from '../../../images/icons/marketPlace.png';
import pacsIcon from '../../../images/sidebar/pacs.png';
import systemIcon from '../../../images/sidebar/system.png';

import close from '../../../images/close_icon.png';
import backIcon from '../../../images/backicon.png';

import rdeePay from '../../../images/icons/wallet.png';
import profilePlaceholder from '../../../images/profile_placeholder.png';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import * as routes from '../../../constants/routes';

import './allnotification.css';

import {
  getUserNotifications,
  deleteNotifications,
  readUserNotifications,
} from '../../../actions/contacts';
import {notificationdataTimeFormat} from '../../../utility/convertTimeString';


class AllnotificationPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      show: false,
      modal: false,
      notificationList: [],
      notificationdetail: '',
    };
  }
  closeModal () {
    this.setState ({
      modal: false,
    });
  }
  deleteNotification () {
    let sendRequest = {
      data: {
        messageID: this.state.notificationdetail.messageID,
      },
    };

    this.props.deleteNotifications (sendRequest);
  }
  openModal (item) {
    this.setState ({
      modal: true,
      notificationdetail: item,
    });
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.contacts) {
      if (nextProps.contacts.notifications) {
        this.setState ({
          notificationList: this.sortByDate(nextProps.contacts.notifications),
          modal: false,
        });
      }
    }
  }

  sortByDate(lising){

    lising.sort(function(a,b){
      return Number(new Date(b.updatedAtISO)) - Number(new Date(a.updatedAtISO));
    });
    return lising;
  }

  componentDidMount () {
    const sendRequest = {
      data: {},
    };

    this.props.getUserNotifications (sendRequest);
  }
  _handleKeyVal (receiver) {
    let keyVal = '';
    let userId = this.props.userInfo.user.Data.userDetails[0].personalDetails
      .docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goToDetail (personDetail) {
    if (personDetail.status === 'unread') {
      let sendRequest = {
        data: {
          messageID: personDetail.messageID,
        },
      };
      this.props.readUserNotifications (sendRequest);
    }
    if (personDetail.source === 'messenger') {

      if(personDetail.chatType==="singleChat"){
      let personalDetail = personDetail;
      let userChatInfo = {
        oppositeUserUid: personalDetail.senderUID,
        profilePictureURL: personalDetail &&
          personalDetail.senderProfilePictureURL
          ? personalDetail.senderProfilePictureURL
          : profilePlaceholder,
        displayName: personalDetail && personalDetail.senderFirstName
          ? personalDetail.senderFirstName
          : '',
      };
      this.props.history.push (routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal (personalDetail.docID),
      });
    }else{
      let userChatInfo={
        chatType: "groupChat",
        displayName: personDetail.displayName,
        groupIcon: personDetail.groupIcon,
        groupId: personDetail.messengerGroupID,
        key: personDetail.messengerGroupID,
      }




      this.props.history.push (routes.MESSENGER, {
        userChatInfo: userChatInfo});
    }



    }
    if (personDetail.source === 'contacts') {
      this.props.history.push (routes.CONTACTUSERDETAIL + `/${personDetail.senderUID}`);

    }

    if (personDetail.source === 'rdeemarketplace') {
      if (personDetail.navigateToID) {
        if (personDetail.orderScreenType === 'buyer') {
          this.props.history.push (
            routes.PLACED_ORDERS_DETAILS + `/${personDetail.navigateToID}`
          );
        }
        if (personDetail.orderScreenType === 'seller') {
          this.props.history.push (
            routes.SELLER_ORDER_DETAIL + `/${personDetail.navigateToID}`
          );
        }
      }
    }
  }

  render () {
    const {notificationList} = this.state;

    return (
      <div>

        <div>
          <div className="mainNotificationView" id="mainNotificationView1">
            {/*<p className="notificationheading">
              My Notifications
              <Button
                className="closeButton"
                style={{float: 'right'}}
                onClick={() => this.props.history.goBack ()}
              >
                <img
                  src={backIcon}
                  alt="back"
                  style={{width: 23, height: 20}}
                />
              </Button>
    </p>*/}
            {notificationList &&
              notificationList.length > 0 &&
              notificationList.map ((item, idx) => (
                <Row
                  key={idx}
                  className="mainview"
                  style={{
                    background: item.status === 'unread'
                      ? '#17171D'
                      : '#333333',
                  }}
                  id={`mainNotificationViewRow${idx}`}
                >
                  {item.status === 'unread' && <div className="unread-dot" id={`mainNotificationViewRowdot${idx}`}/>}
                  {item.status !== 'unread' && <div style={{width: 10}} id={`mainNotificationViewRows${idx}`}/>}

                  {item.source === 'rdeemarketplace' &&
                    <img src={marketplaceIcon} className="notificationIcons" id={`mainNotificationViewRowrdeemarketplace${idx}`}/>}
                  {item.source === 'pacs' &&
                    <img src={pacsIcon} className="notificationIcons"  id={`mainNotificationViewRowspacs${idx}`}/>}
                  {item.source === 'system' &&
                    <img src={systemIcon} className="notificationIcons"  id={`mainNotificationViewRowssystem${idx}`}/>}
                  {item.source === 'rdeepay' &&
                    <img src={rdeePay} className="notificationIcons"  id={`mainNotificationViewRowsrdeepay${idx}`}/>}
                  {item.source === 'serviceondemand' &&
                    <img
                      src={serviceondemandIcon}
                      className="notificationIcons"
                      id={`mainNotificationViewRowsserviceondemand${idx}`}
                    />}
                  {(item.source === 'messenger' || item.source === 'contacts') &&
                    <img src={contactIcon} className="notificationIcons"  id={`mainNotificationViewRowsmessenger${idx}`}/>}

                  <div id={`mainNotificationViewRownotificationtitle${idx}`} className="notificationtitle"  onClick={() => this.goToDetail (item)}>
                    {item.source === 'rdeemarketplace' ? 'Market Place' : ''}
                    {item.source === 'messenger'  ? 'Messenger' : ''}
                    {item.source === 'contacts' ? 'Contact' : ''}

                    {item.source === 'system' ? 'System' : ''}
                    {item.source === 'serviceondemand'
                      ? 'Service-on-Demand'
                      : ''}
                    {item.source === 'pacs' ? 'PACS' : ''}
                    {item.source === 'rdeepay' ? 'R-DEE Pay' : ''}

                  </div>
                  <div id={`mainNotificationViewRownotificationDate${idx}`} className="notificationdate"  onClick={() => this.goToDetail (item)}>
                    {item.updatedAtISO  ? notificationdataTimeFormat (item.updatedAtISO): ''}
                  </div>
                  <div id={`mainNotificationViewRownotificationSubject${idx}`} className="confirm"  onClick={() => this.goToDetail (item)}> {item.subject}</div>
                  <div id={`mainNotificationViewRownotificationBody${idx}`} className="notificationmsg"  onClick={() => this.goToDetail (item)}>
                    {' '}
                    {item.body}
                  </div>
                  <Button
                    style={{height: 30}}
                    className="closeButton"
                    onClick={() => this.openModal (item)}
                    data-rh="Delete"
                    id={`mainNotificationViewRownotificationDeleteButton${idx}`}
                  >
                    <img src={close} alt="close"/>
                  </Button>

                </Row>
              ))}
            {notificationList.length == 0 &&
              <p className="noNotificationFound" id="mainNotificationView8">No Notifications </p>}
          </div>

          <Modal
            size="sm"
            isOpen={this.state.modal}
            centered={true}
            toggle={() => this.closeModal ()}
          >
            <ModalBody className="deleteModal" id="mainNotificationView2">
              <div>

                <p className="confirmTitle" id="mainNotificationView3">Delete Notification?</p>
                <p className="confirmmsg" id="mainNotificationView4">
                  Are you sure you wish to remove this
                  from your notifications?
                </p>
                <div className="buttonDiv" id="mainNotificationView5">
                  <Button
                    className="buttons noButton"
                    onClick={() => this.closeModal ()}
                    id="mainNotificationView6"
                  >
                    No
                  </Button>
                  <Button
                    className="buttons yesButton"
                    onClick={() => this.deleteNotification ()}
                    id="mainNotificationView7"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserNotifications,
      deleteNotifications,
      readUserNotifications,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  AllnotificationPage
);
