// capitalise first letter of the string 
export const  capitalfirstLetter = (stringName) => {
    if(stringName.trim() !== "" &&  stringName !== null && stringName !==undefined){
        return stringName.charAt(0).toUpperCase() + stringName.slice(1)
    }
    return stringName
}

// show first name and last name if its having small length
// else if first name and last name strings are quite big then 
// just show first name (eg.    "Manpreet Singh") full name should come like this
export const ShowNameAccordingToLength = (fullName) =>{
    let userNameArr = fullName.split(" ");
    let finalUserName = fullName.length < 20 ? userNameArr[0] + " " + (userNameArr.length>1 ? userNameArr[1]:''): userNameArr[0];
    return finalUserName;
}