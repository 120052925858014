import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from 'reactstrap';
import * as routes from '../../../../constants/routes';
import {Carousel} from 'primereact/carousel';
import './marketPlacePlacedOrders.css';
import {
  getMarketPlaceOrder,
  getMarketPlaceActiveOrder,
  resetdoOrderValue
} from '../../../../actions/marketplaceaction';
import leftbackarrow from '../../../../images/icons/leftbackarrow.png';
import MarketPlaceSidemenu from '../marketplaceSidemenu/marketSideMenu';
import productDetails
  from '../../../../images/marketPlaceBuyer/productDetails.png';
  import {dataTimeFormat} from '../../../../utility/convertTimeString';
import  RightSideContainer from '../../../../components/UI/rightSideContainer/rightSideContainer';

import {priceWithDecimal} from '../../../../utility/commonFunctions';

const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 4,
    numScroll: 4,
  },
  {
    breakpoint: '768px',
    numVisible: 4,
    numScroll: 4,
  },
  {
    breakpoint: '560px',
    numVisible: 4,
    numScroll: 4,
  },
];
class MarketPlacedOrders extends Component {
  constructor (props) {
    super (props);
    this.state = {
      placeOrderList: [],
      placeActiveOrderList: [],
    };
    this.productTemplate = this.productTemplate.bind (this);
  }
  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {

      
      if (
        nextProps &&
        nextProps.placedOrders &&
        nextProps.placedOrders.placeActiveOrderList
      ) {
        this.setState ({
             placeActiveOrderList: nextProps.placedOrders.placeActiveOrderList,
          });
      
      }
      if (
        nextProps &&
        nextProps.placedOrders &&
        nextProps.placedOrders.placeOrderList
      ) {
        this.setState (
          {
             placeOrderList: nextProps.placedOrders.placeOrderList,
          }
        );
      
      }
      
    }
  }
  componentDidMount () {
    this.props.resetdoOrderValue ();

    let sendRequest = {
      data: {
        limit: 10,
        offset: 0,
        orderType: 'active',
      },
    };
    this.props.getMarketPlaceActiveOrder (sendRequest);

    let sendRequestOrderHistory = {
      data: {
        limit: 10,
        offset: 0,
        orderType: 'completed',
      },
    };

    this.props.getMarketPlaceOrder (sendRequestOrderHistory);
    
   

    // let walletAddress = localStorage.getItem ('address');
    // if (walletAddress) {
    //   this.props.fetchUserBalance (walletAddress);
    // }
  }
  productTemplate (item) {
    return (
      <div id={`placeorderProductTemp${item.docID}`}>

        <div className="p-grid p-nogutter SliderBox2" id={`placeorderProductTempSliderBox2${item.docID}`}>
          <div
            className="p-col-12"
            onClick={() =>
              this.props.history.push (
                routes.PLACED_ORDERS_DETAILS + `/${item.docID}`
              )}
            // this.props.history.push (
            //   routes.PLACED_ORDERS_DETAILS +
            //     `/${item.productDetails[0].docID}`
            // )}
            id={`placeorderProductTempSliderBox2inner${item.docID}`}
          >
            <img
              className="sliderProductImg"
              src={
                item &&
                  item.productDetails[0] &&
                  item.productDetails[0].productImageList &&
                  item.productDetails[0].productImageList[0]
                  ? item.productDetails[0].productImageList[0]
                  : productDetails
              }
              alt={item.systemName}
              id={`placeorderProductTempSliderBox2sliderProductImg${item.docID}`}
            />
          </div>
          {
            <div
              className="p-col-12"
              style={{marginTop: 10, background: '#4453D6',paddingBottom:10,borderBottomRightRadius:10,borderBottomLeftRadius:10}}
              id={`placeorderProductTempSliderBoxontent${item.docID}`}
            >
              <p className="siemenavantomarke" id={`placeorderProductTempSliderBoxontentproductTypeName${item.docID}`}>

               
                  {item &&
                    item.productDetails &&
                    item.productDetails[0] &&
                    item.productDetails[0] ?
                    item && item.productDetails[0].productCategoryType &&  item.productDetails[0].productCategoryType==='Radiology' ? item.productDetails[0].deviceSaleName  : (item.productDetails[0] && item.productDetails[0].productName?item.productDetails[0].productName :'N/A' )
                    : 'N/A'}

              </p>

              <p className="siemenavantomarke" id={`placeorderProductTempSliderBoxcontentProductPrice${item.docID}`}>
                <span>
                  {' '}
                  USD$
                  {' '}
                  {/* {item && item.productDetails && item.productDetails[0]
                    ? priceWithDecimal(item.productDetails[0].priceUSD)
                    : 'N/A'} */}
                    {item && item.totalOrderPrice 
                    ? priceWithDecimal(item.totalOrderPrice)
                    : 'N/A'}
                </span>
              </p>
              <p className="DeviceType" id={`placeorderProductTempSliderBoxcontentProddeviceType${item.docID}`}>
                Order ID :
                {' '}
                
                {item && item.productDetails && item.productDetails[0] && item.productDetails[0].orderID
                  ? item.productDetails[0].orderID
                  : 'N/A'}
              </p>
              {item && item.productDetails && item.productDetails[0] && item.productDetails[0].productCategoryType &&  item.productDetails[0].productCategoryType==='Radiology' ?
              <div>
              <p className="DeviceType" id={`placeorderProductTempSliderBoxcontentProddeviceType${item.docID}`}>
                Device Type:
                {' '}
                {item && item.productDetails && item.productDetails[0]
                  ? item.productDetails[0].deviceType
                  : 'N/A'}
              </p>
              <p className="LocCafornian" id={`placeorderProductTempSliderBoxcontentProdLocation${item.docID}`}>
                Location:
                {' '}
                {item && item.productDetails && item.productDetails[0]
                  ? item.productDetails[0].location
                  : 'N/A'}
              </p>
              </div>
              :
              <div id={`placeorderProductTempSliderBoxcontentProdType${item.docID}`}>
              <p className="DeviceType" id={`placeorderProductTempSliderBoxcontentProdTypeVal${item.docID}`}>
              Product Type:
                {' '}
                {item && item.productDetails && item.productDetails[0] && item.productDetails[0].productCategoryType
                  ? item.productDetails[0].productCategoryType
                  : 'N/A'}
              </p>
              <p className="LocCafornian" id={`placeorderProductTempSliderBoxcontentProdTypeLoc${item.docID}`}>
              Category:
                {' '}
                {item && item.productDetails && item.productDetails[0] && item.productDetails[0].category
                  ? item.productDetails[0].category
                  : 'N/A'}
              </p>
              </div>
              
            
            }


            </div>
          }
        </div>

      </div>
    );
  }
  render () {
    const {placeOrderList ,placeActiveOrderList} = this.state;
    return (
      <div className="mainContainerPlaceOrders" id="placeorderProductTempSliderBoxcontent1">
        <div className="placeOrdersChildContainer" id="placeorderProductTempSliderBoxcontent2">
          <div
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
            id="placeorderProductTempSliderBoxcontent3"
          >
            <div className="marketPlaceTitle" id="placeorderProductTempSliderBoxcontent4">

              <p className="MainTitle" id="placeorderProductTempSliderBoxcontent5">My Marketplace</p>
              <p className="subTitle" id="placeorderProductTempSliderBoxcontent6">Placed Orders</p>

            </div>
            <div style={{textAlign: 'right'}} id="placeorderProductTempSliderBoxcontent7">
              <Button
                className="marketproducbackbutton"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
                id="placeorderProductTempSliderBoxcontent8"
              >
                <div id="placeorderProductTempSliderBoxcontent9">
                  <img src={leftbackarrow} alt="backIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>

          </div>

          <div className="maindivorderhistory" id="placeorderProductTempSliderBoxcontent10">
            <div className="marketplacedovox" id="placeorderProductTempSliderBoxcontent11"> 
              <div>
                <div className="placedorderhesoihjcl" id="placeorderProductTempSliderBoxcontent12">
                  <span> ACTIVE </span> ORDERS
                </div>
              </div>
              { placeActiveOrderList && placeActiveOrderList.length===0 &&<div style={{marginTop:50}} className="noOrderHistory" id="placeorderProductTempSliderBoxcontent13">
              No Active Orders
            </div>}
              {placeActiveOrderList &&
                placeActiveOrderList.length > 0 &&
                <div style={{marginTop: 22}} id="placeorderProductTempSliderBoxcontent14">
                  <Carousel
                    value={placeActiveOrderList}
                    itemTemplate={this.productTemplate}
                    responsiveOptions={responsiveOptions}
                    numVisible={4}
                    numScroll={4}
                    circular={true}
                  />

                </div>}
            </div>
            <div className="buyerSideorderhistory" id="placeorderProductTempSliderBoxcontent15">
              <div className="OrderHistoryButtonBox" id="placeorderProductTempSliderBoxcontent16">
                ORDER HISTORY
              </div>
              <div style={{marginTop:10}} id="placeorderProductTempSliderBoxcontent17">
              { placeOrderList && placeOrderList.length===0 &&<div style={{marginTop:30}} className="noOrderHistory" id="placeorderProductTempSliderBoxcontent18">
                  No Order History
                </div>}
               { placeOrderList && placeOrderList.length>0 && placeOrderList.map((item,i)=>(<div key={i} id={`placeorderProductTempSliderBoxcontentProdHis${i}`} className="buyerOrderHistoryList" onClick={() =>
                this.props.history.push (
                  routes.PLACED_ORDERS_DETAILS + `/${item.docID}`
                )}>
                <div style={{display:'inline-flex'}}>
                  <div className="orderDate" id={`placeorderProductTempSliderBoxcontentProdHisORderDate${i}`}>
                  {item && item.orderDate
                    ? dataTimeFormat(item.orderDate)
                    : 'N/A'}
                  </div>
                  <div className="orderID" id={`placeorderProductTempSliderBoxcontentProdHisOrderid${i}`}>Order # {item && item.orderID ? item.orderID: 'N/A'}</div>
                  <div className="orderName" id={`placeorderProductTempSliderBoxcontentProdHisOrderName${i}`}>
                  {item &&
                    item.productDetails &&
                    item.productDetails[0] &&
                    item.productDetails[0] ?
                    item && item.productDetails[0].productCategoryType &&  item.productDetails[0].productCategoryType==='Radiology' ? item.productDetails[0].deviceSaleName : (item.productDetails[0] && item.productDetails[0].productName?item.productDetails[0].productName:'') 
                    : 'N/A'}
                  
                  
                  </div>

                </div>
                <div style={{display:'inline-flex'}}>
                <div className="orderPrice" id={`placeorderProductTempSliderBoxcontentProdHisORrderQty${i}`}>Qty: {item && item.productQuantity  ? item.productQuantity
                  : 0}</div>
  
                  <div className="orderPrice" id={`placeorderProductTempSliderBoxcontentProdHisOrderPrice${i}`}>USD$  {item && item.totalOrderPrice  ? priceWithDecimal(item.totalOrderPrice)
                    : 0}</div>

                  <div className="orderStatus" id={`placeorderProductTempSliderBoxcontentProdHisOrderStatus${i}`}> 
                  {item && item.orderStatus && (item.orderStatus==='canceled'|| item.orderStatus==='refunded') &&  <div style={{background:'#FD4B4B'}} />}
                  {item && item.orderStatus && (item.orderStatus==='completed' || item.orderStatus==='delivered') &&  <div style={{background:'#C4C4C4'}} />}
                  {item && item.orderStatus && (item.orderStatus==='completed' ||item.orderStatus==='delivered')  ? 'Completed' :'Cancelled'}
                  
                  </div>

                </div>
                
                  
                </div>))}
                
                </div>
            </div>

          </div>
        </div>
        <RightSideContainer>
          <MarketPlaceSidemenu />
        </RightSideContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    placedOrders: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getMarketPlaceOrder,
      getMarketPlaceActiveOrder,
      resetdoOrderValue,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  MarketPlacedOrders
);
