import React, {Component} from 'react';
import {Modal, ModalBody, Button} from 'reactstrap';

import locIcon from '../../../images/loc.png';

import searchIcon from '../../../images/icons/search.svg';
import searchUserGroup from '../../../images/icons/searchUserGroup.svg';

import profilePlaceholder from './../../../images/profile_placeholder.png';
import evruslogobusi from './../../../images/icons/evruslogobusi.png';
import mail from '../../../images/profile/mail.png';
import call from '../../../images/profile/call.png';
import Arrow from '../../../images/profile/arrow.png';
import businessAdmin from '../../../images/icons/yellowcrown.png';
import leftbackarrow from '../../../images/icons/leftbackarrow.png';


import businessContactIcon from '../../../images/icons/businessContactIcon.svg';
import defaultContactRequest
  from '../../../images/icons/defaultContactRequest.svg';

import crossIcon from '../../../images/whiteCloseIcon.svg';

import connectIcon from '../../../images/icons/business/connect.svg';
import expertIcon from '../../../images/icons/business/expert.svg';
import managerIcon from '../../../images/icons/business/manager.svg';
import marketPlaceIcon from '../../../images/icons/business/marketPlace.svg';
import scanIcon from '../../../images/icons/business/scan.svg';
import settleIcon from '../../../images/icons/business/settle.svg';
import streamIcon from '../../../images/icons/business/stream.svg';
import vaultIcon from '../../../images/icons/business/vault.svg';
import removeBlack from '../../../images/icons/business/removeBlack.svg';
import {
  getBusinessDetail,
  addUserContactFunc,
  cancelSendUserContactRequest,
} from '../../../actions/contacts';
import * as routes from '../../../constants/routes';
import UserContactListPage
  from '../../../components/contacts/usercontacts/userList';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import RightSideContainer
  from '../../../components/UI/rightSideContainer/rightSideContainer';
import {ShowNameAccordingToLength} from '../../../utility/stringFunctions';
import './businessManagement.css';

class BusinessManagement extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      personalDetail: [],
      educationDetail: [],
      searchString: '',
      memberList: [],
      businessDetail: '',
      companyDetails: '',
      businessid: '',
      loginUserId: '',
      addnewGroup: false,
      editGroupInfo:'',
      removeFromGroup:false,
      permissionFromGroup:false,
      isAllow:false,
      permissionList: [
        {
          name: 'Connect',
          img: connectIcon,
        },
        {
          name: 'Marketplace',
          img: marketPlaceIcon,
        },
        {
          name: 'Settle',
          img: settleIcon,
        },
        {
          name: 'Expert',
          img: expertIcon,
        },
        {
          name: 'Scan',
          img: scanIcon,
        },
        {
          name: 'Vault',
          img: vaultIcon,
        },
        {
          name: 'Manager',
          img: managerIcon,
        },
        {
          name: 'Stream',
          img: streamIcon,
        },
      ],
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.contacts && nextProps.contacts.businessDetail) {
        console.log (nextProps.contacts.businessDetail);
        let getData = nextProps.contacts.businessDetail;
        if (getData) {
          this.setState (
            {
              companyDetails: getData,
              contactDetail: getData,
              companyAddress: getData,
              businessDetail: getData,
              memberList: getData.memberList,
            },
            () => {
              const {companyAddress, companyDetails} = this.state;
              if (
                companyAddress &&
                companyAddress.businessLocation &&
                companyAddress.businessLocation.lat
              ) {
                this.setState ({
                  mapLat: companyAddress.businessLocation.lat,
                  mapLng: companyAddress.businessLocation.lng,
                });

                let companyAddressLocation = [];
                companyAddressLocation.push ({
                  lat: companyAddress.businessLocation.lat,
                  lng: companyAddress.businessLocation.lng,
                  businessName: companyDetails.displayedBusinessName,
                  businessCity: companyAddress.businessCity,
                  businessCountry: companyAddress.businessCountry,
                });
                this.setState ({
                  companyAddressLocation: companyAddressLocation,
                });
              }
            }
          );
        }
      }
    }
  }

  componentDidMount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    if (loginUser) {
      this.setState ({
        loginUserId: loginUser.docID,
      });
    }
    // let keyVal = '';
    // let userId = loginUser.docID;

    let geturl = this.props.match.params;
    if (geturl && geturl.businessId) {
      this.setState ({
        businessid: geturl.businessId,
      });
      let sendRequest = {
        data: {
          businessDocID: geturl.businessId,
        },
      };

      this.props.getBusinessDetail (sendRequest);

      let memberList = [];

      for (let index = 0; index < 5; index++) {
        memberList.push (index);
      }
      this.setState ({
        memberList: memberList,
      });
    }
  }

  _handleKeyVal (receiver) {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let keyVal = '';
    let userId = loginUser.docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goToChat () {
    const {memberList, businessDetail} = this.state;
    let userInfo;
    for (let item of memberList) {
      if (item.memberInfo) {
        if (item.memberInfo.memberID === businessDetail.registeredBy) {
          userInfo = item;
        }
      }
    }
    if (userInfo) {
      let userChatInfo = {
        oppositeUserUid: userInfo.docID,
        profilePictureURL: userInfo && userInfo.profilePictureURL
          ? userInfo.profilePictureURL
          : profilePlaceholder,
        displayName: ShowNameAccordingToLength (
          userInfo.firstName + ' ' + userInfo.lastName
        ),
      };

      this.props.history.push (routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal (this.state.loginUserId),
      });
    }
  }
  goToSendMessage (item) {
    if (item) {
      let userChatInfo = {
        oppositeUserUid: item.docID,
        profilePictureURL: item && item.profilePictureURL
          ? item.profilePictureURL
          : profilePlaceholder,
        displayName: ShowNameAccordingToLength (
          item.firstName + ' ' + item.lastName
        ),
      };

      this.props.history.push (routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal (this.state.loginUserId),
      });
    }
  }

  addNewContact () {
    let sendRequest = {
      data: {
        contactUID: this.state.businessid,
      },
    };
    this.props.addUserContactFunc (sendRequest);
  }
  handleClick = (marker, event) => {
    this.setState ({selectedMarker: marker});
  };

  doCancelRequest (item) {
    let sendRequest = {
      data: {
        messageID: item.sentContactRequestMessageID,
        targetUID: item.docID,
      },
    };

    this.props.cancelSendUserContactRequest (sendRequest, item.docID);
  }
  closeModal () {
    this.setState ({
      sendRequestmodal: false,
      addnewGroup: false,
      removeFromGroup:false,
      permissionFromGroup:false
    });
  }

  openAddnewGroup (item) {
    this.setState ({
      addnewGroup: true,
      editGroupInfo: item?item:''
    });
  }
  giveAdminAccess(status){
    this.setState({
      isAllow: status,
      permissionFromGroup:true
    })
  }
  removeUserFromMember(){
    this.setState ({
      removeFromGroup:true,
    });
  }

  render () {
    const {
      personalDetail,
      professionalDetail,
      businessDetail,
      checkuserChatStatus,
      companyAddress,
      contactDetail,
      companyDetails,
      memberList,
      companyAddressLocation,
      loginUserId,
      searchString,
      addnewGroup,
      permissionList,
      editGroupInfo,
      removeFromGroup,
      permissionFromGroup,
      isAllow
    } = this.state;

    return (
      <div
        style={{display: 'inline-flex'}}
        className="businessManagementTopContainer"
      >
        <div>
          <div>
            <div style={{textAlign: 'right'}}>
              <Button
                className="backbuttonbusinessManage"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
                style={{float:'right'}}
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>
          </div>
          <div className="businessprofiemaindivManage">
            <div style={{display: 'inline-flex', alignItems: 'center'}}>

              <div style={{width: 600}}>

                <div className="editBusinessManageHeading">
                  {' '}Edit Business Members
                </div>

                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >

                  <div className="businessprofileDetailImageDivManage">
                    <img
                      style={{width: '100%', height: '100%', borderRadius: 86}}
                      src={
                        businessDetail &&
                          businessDetail.businessProfilePictureURL
                          ? businessDetail.businessProfilePictureURL
                          : evruslogobusi
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = evruslogobusi;
                      }}
                      alt="profilePlaceholder"
                    />
                  </div>
                  <div className="EverustextBusinessmanege">
                    {companyDetails && companyDetails.displayedBusinessName
                      ? companyDetails.displayedBusinessName
                      : ''}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'grid',
                  justifyContent: 'flex-end',
                  width: 360,
                }}
              >

                <Button
                  className="businessprofileEditbuttonmanegeBack"
                  onClick={() => {
                    // this.props.history.goBack();
                    this.props.history.push (
                      `${routes.BUSINESSDETAIL}/${this.state.businessid}`
                    );
                    // this.props.history.push (`${routes.EDIT_BUSINESS_DETAIL}/${this.state.businessid}`)
                  }}
                >
                  Return to Business Profile
                </Button>
                {businessDetail &&
                  businessDetail.registeredBy &&
                  loginUserId === businessDetail.registeredBy &&
                  <Button
                    className="businessprofileEditbuttonmanege"
                    onClick={() => {
                      this.props.history.push (
                        `${routes.EDIT_BUSINESS_DETAIL}/${this.state.businessid}`
                      );
                    }}
                  >
                    Edit Business Profile
                  </Button>}
              </div>
            </div>

            <div style={{marginTop: 20}} className="requestReceivedBox">
              <div className="businessReceiveRequestLabal">
                REQUESTS &nbsp;<b> RECEIVED </b>&nbsp;(4)
              </div>
              {memberList &&
                memberList.length > 0 &&
                memberList.map ((item, i) => (
                  <div key={i}>
                    <div className="memberManageListing">
                      <div className="memberUserNameBox">
                        <div className="businessManageUserMember">
                          <div className="memberProfileBox">
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 86,
                              }}
                              src={profilePlaceholder}
                              alt="memberProfileBox"
                            />
                          </div>
                          <div className="memberNameBox">
                            <div className="memberNames" style={{fontSize: 18}}>
                              Laura Walsh
                            </div>
                            <div className="memberNames" style={{fontSize: 14}}>
                              Certified Neurologist
                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="memberDateBox">

                        <div
                          style={{opacity: 0.8}}
                          className="memberRequestDate"
                        >
                          @username
                        </div>
                      </div>
                      <div className="memberDateBox">
                        <div
                          style={{opacity: 0.5}}
                          className="memberRequestDate"
                        >
                          9/24/2020
                        </div>

                      </div>
                      <div
                        className="memberUserNameBox"
                        style={{paddingLeft: 15}}
                      >

                        <Button className="businessMemberRequestApprove">
                          Approve Member
                        </Button>
                        <Button className="businessMemberRequestRemove">
                          Remove
                        </Button>

                      </div>

                    </div>
                    {memberList.length > i + 1 &&
                      <div className="requestMemberborder" />}
                  </div>
                ))}

            </div>

            <div className="manageGroupBox" style={{marginTop: 20}}>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <div className="businessmanageGroupBoxLabal">
                  MANAGE   &nbsp;<b> GROUPS </b>
                </div>
                <Button
                  className="BusinessAddnewGroup"
                  onClick={() => this.openAddnewGroup ()}
                >
                  Add New Group
                </Button>
              </div>

              <div className="businessMemberListing">

                {memberList &&
                  memberList.length > 0 &&
                  memberList.map ((item, i) => (
                    <div key={i} className="businessGroupsInfo"   onClick={() => this.openAddnewGroup (i)}>
                      <div>
                        <img src={defaultContactRequest} alt="memberIcon" />
                      </div>
                      <div style={{marginLeft: 10, marginRight: 10}}>
                        <div className="businessgroupNameTitle">
                          Senior Management
                        </div>
                        <div className="businessgroupMemberCounts">
                          5 members
                        </div>

                      </div>
                    </div>
                  ))}
              </div>

            </div>

            <div className="manageAdminMembersBox" style={{marginTop: 20}}>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <div className="businessmanageMemberGroupBoxLabal">
                  ADMINS &    &nbsp;<b> MEMBERS </b>
                </div>
                <div style={{marginRight: 25}}>
                  <div className="managebusinessmemberSearchbox">

                    <img
                      src={searchUserGroup}
                      alt="searchUserGroup"
                      style={{marginLeft: 15}}
                    />
                    <input
                      className="manageinputSearchBox"
                      type="text"
                      placeholder="Search by name or email"
                      value={searchString}
                      onChange={e =>
                        this.setState (
                          {searchString: e.target.value},
                          () => {}
                        )}
                    />

                  </div>

                </div>
              </div>
              <div className="businessManageAdminListing">
                <div className="memberUserNameBox" />
                <div className="memberGroupListing">
                  <div className="BusinessManagegroupMemberShipTitle">
                    <b>GROUP </b> &nbsp;MEMBERSHIP
                  </div>

                </div>
                <div
                  className="memberGroupActionBoxContent"
                  style={{paddingLeft: 15}}
                >

                  <div className="businessAdminRight">
                    <div><b style={{color: '#F5D028'}}>ADMIN</b></div>
                    <div>RIGHTS</div>
                  </div>
                  <div className="businessAdminRight">
                    <div><b>REMOVE</b> FROM </div>
                    <div>BUSINESS</div>

                  </div>

                </div>
              </div>

              {memberList &&
                memberList.length > 0 &&
                memberList.map ((item, i) => (
                  <div key={i}>
                    <div className="businessManageAdminListing">
                      <div className="memberUserNameBox">
                        <div className="businessManageUserMemberAdmin">
                          <div className="memberProfileBoxAdmin">
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 86,
                              }}
                              src={profilePlaceholder}
                              alt="memberProfileBox"
                            />
                          </div>
                          <div className="memberNameBoxAdmin">
                            <div
                              className="memberNamesAdmin"
                              style={{fontSize: 16}}
                            >
                              Laura Walsh
                            </div>
                            <div
                              className="memberNamesAdmin"
                              style={{
                                fontSize: 12,
                                opacity: 0.7,
                                fontStyle: 'italic',
                              }}
                            >
                              Certified Neurologist
                            </div>
                            <div
                              className="memberNamesAdmin"
                              style={{fontSize: 12, opacity: 0.7}}
                            >
                              Certified Neurologist
                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="memberGroupListing">

                        <div className="businessManageradioButtonsBox">

                          {memberList &&
                            memberList.length > 0 &&
                            memberList.slice (0, 4).map ((item, j) => (
                              <div className="businessManageradioButtonItem">
                                <input
                                  type="checkbox"
                                  id={`selectedItem${i}${j}`}
                                  name="radio-group"
                                />
                                <label htmlFor={`selectedItem${i}${j}`}>
                                  {j % 2
                                    ? 'Senior Management'
                                    : 'Middle Level Management'}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div
                        className="memberGroupActionBoxContent"
                        style={{paddingLeft: 15, width: 140}}
                      >

                        <Button className="manageBusinessAction" onClick={()=>this.giveAdminAccess(true)}> 
                          <img
                            style={{width: 28, height: 17}}
                            src={businessAdmin}
                            alt="businessAdmin"
                          />
                        </Button>
                        <Button className="manageBusinessAction" onClick={()=>this.removeUserFromMember()}>
                          <img
                            style={{width: 38, height: 30}}
                            src={crossIcon}
                            alt="businessAdmin"
                          />

                        </Button>

                      </div>

                    </div>
                    {memberList.length > i + 1 &&
                      <div
                        style={{
                          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                          marginRight: 25,
                          marginLeft: 25,
                        }}
                      />}
                  </div>
                ))}

            </div>
          </div>

          <RightSideContainer>
            <UserContactListPage history={this.props.history} />
          </RightSideContainer>
        </div>

        <Modal
          size={editGroupInfo? "md":"sm"}
          isOpen={addnewGroup}
          centered={true}
          toggle={() => this.closeModal ()}
          backdropClassName="contactModalBackdrops"
        >
          <ModalBody className="addnewBusinessGroupModal" style={{width:editGroupInfo?701:325}}>
          <div>
          <div className="modalLabalTitle">{editGroupInfo? 'Edit ' :'Create '} Business Group...</div>
            <div className="addBusinessGroupModal">
            <div>

            
              <div style={{marginLeft: 10}}>
                <div
                  className="modalgroupNameLabalTitle"
                  style={{marginTop: 10}}
                >
                  Group Name:
                </div>
                <div style={{marginTop: 5}}>
                  <input
                    className="businessGroupNameinput"
                    type="text"
                    placeholder="Group Name Goes Here"
                  />
                </div>
                <div>

                  <div className="groupListbox" style={{marginTop: 15}}>
                    <div
                      style={{width: 120, opacity: 0.8, fontSize: 15}}
                      className="labalTitles"
                    >
                      Permissions:
                    </div>
                    <div
                      style={{
                        width: 150,
                        opacity: 0.8,
                        fontSize: 15,
                        fontWeight: 'bold',
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        color:'#17171D'
                      }}
                      className="labalTitles"
                    >
                      <div> READ </div>
                      <div> WRITE </div>
                      <div> NONE</div>
                    </div>

                  </div>
                </div>
                </div>

                {permissionList &&
                  permissionList.length > 0 &&
                  permissionList.map ((item, i) => (
                    <div className="groupListbox" key={i} style={{borderBottom:'1px dotted #A2A2A5',paddingLeft:5}}>
                      <div
                        style={{width: 133, fontSize: 12}}
                        className="labalTitles"
                      >

                        <img src={item.img} alt="icons" style={{marginRight:10}}/>
                        {item.name}
                      </div>
                      <div
                        style={{
                          width: 140,
                          display: 'inline-flex',
                          justifyContent: 'space-between',
                          marginBottom:20
                        }}
                        className="labalTitles businessManageradioAccessButtonsBox"
                      >

                        <div>
                          <input type="radio" id={`read${i}`} name="radio-group" />
                          <label htmlFor={`read${i}`} />
                        </div>
                        <div>
                          <input type="radio" id={`write${i}`} name="radio-group" />
                          <label htmlFor={`write${i}`} />
                        </div>
                        <div className="businessManageradioAccessButtonsBox">
                          <input type="radio" id={`none${i}`} name="radio-group" />
                          <label htmlFor={`none${i}`} />
                        </div>
                      </div>
                      <div />
                    </div>
                  ))}

                 
                  </div>
                  
                  
                  {editGroupInfo && <div style={{marginLeft:30}}>

            
                  <div style={{marginLeft: 10}}>
                    <div
                      className="modalgroupNameLabalTitle"
                      style={{marginTop: 10}}
                    >
                    Members:
                    </div>
                    <div style={{marginTop: 5}}>
                      {/*<input
                        className="businessGroupNameinput"
                        type="text"
                        placeholder="Search by name or email"
                      />*/}
                      <div className="managebusinessEditmemberSearchbox" >

                      <img
                        src={searchUserGroup}
                        alt="searchUserGroup"
                        style={{marginLeft: 15}}
                      />
                      <input
                        className="manageinputSearchBox"
                        type="text"
                        placeholder="Search by name or email"
                        value={searchString}
                        onChange={e =>
                          this.setState (
                            {searchString: e.target.value},
                            () => {}
                          )}
                      />
  
                    </div>
                    </div>
                  
                    </div>
    
                    {memberList &&
                      memberList.length > 0 &&
                      memberList.map ((item, i) => (
                          <div key={i} className="businessManageeditBusinessMemberListing" style={{borderBottom:memberList.length === (i+1) ?'1px dotted #A2A2A5' :0}}>
                            <div className="memberUserNameBox">
                              <div className="editBusinessMemberListing">
                                <div className="memberProfileBoxImage">
                                  <img
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: 86,
                                    }}
                                    src={profilePlaceholder}
                                    alt="memberProfileBox"
                                  />
                                </div>
                                <div className="memberNameBoxAdmin">
                                  <div
                                    className="memberNamesAdmin"
                                    style={{fontSize: 18}}
                                  >
                                    Laura Walsh 
                                  </div>
                                  <div
                                    className="memberNamesAdmin"
                                    style={{
                                      fontSize: 12,
                                      color: '#C4C4C4',
                                    }}
                                  >
                                    Certified Neurologist
                                  </div>
      
                                </div>
      
                              </div>
      
                            </div>
                           
                            <div
                              className="memberGroupActionBox"
                            >
                              <Button className="manageBusinessAction">
                                <img
                                  style={{width: 18, height: 18}}
                                  src={removeBlack}
                                  alt="businessAdmin"
                                />
      
                              </Button>
      
                            </div>
      
                          </div>
                      ))}
                     
                      </div>}
                    
                      </div>
                  <div style={{textAlign:editGroupInfo?'center':'right',marginTop:10}}>
                    {editGroupInfo ? <Button onClick={()=>this.closeModal()} className="createBusinessGroupButton">Save Changes </Button>:<Button className="createBusinessGroupButton" onClick={()=>this.closeModal()} >Create Group</Button>}
                  </div>
            </div>

          </ModalBody>
        </Modal>
        <Modal
          size="sm"
          isOpen={removeFromGroup}
          centered={true}
          toggle={() => this.closeModal ()}
          backdropClassName="contactModalBackdrops"
        >
          <ModalBody className="businessManageConfirmationModal">
            <div>

              <p className="confirmTitle">Remove From Business</p>
              <p className="confirmmsg">
              Are you sure you want to remove Elvie Marvin from this business?
              </p>
              <div className="buttonDiv">
                <Button
                  className="buttons noButton"
                  onClick={() => this.closeModal ()}
                >
                  No
                </Button>
                <Button
                  className="buttons yesButton"
                  onClick={() => {
                    setTimeout (() => {
                      this.closeModal ();
                    }, 2000);
                    
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>


        <Modal
        size="sm"
        isOpen={permissionFromGroup}
        centered={true}
        toggle={() => this.closeModal ()}
        backdropClassName="contactModalBackdrops"
      >
        <ModalBody className="businessManageConfirmationModal">
          <div>

            <p className="confirmTitle"> Admin Rights</p>
            <p className="confirmmsg">
           
            Are you sure you want to {isAllow?'grant ':'remove '} administrative rights to Elvie Marvin?
            </p>
            <div className="buttonDiv">
              <Button
                className="buttons noButton"
                onClick={() => this.closeModal ()}
              >
                No
              </Button>
              <Button
                className="buttons yesButton"
                onClick={() => {
                  setTimeout (() => {
                    this.closeModal ();
                  }, 2000);
                  // let sendRequest = {
                  //   data: {
                  //     contactUID: this.state.sendRequestData.docID,
                  //   },
                  // };
                  // this.props.addUserContactFunc (sendRequest);
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getBusinessDetail,
      addUserContactFunc,
      cancelSendUserContactRequest,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  BusinessManagement
);
