let initialState = {
  user: {},
  authToken: '',
  registeruser: {},
  successKYC: null,
};

export const LOGIN = 'LOGIN';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const ADD_ERROR_CODE = 'ADD_ERROR_CODE';

export const REGISTER = 'REGISTER';
export const GETEDUCATIONDETAIL = 'GETEDUCATIONDETAIL';
export const GETPROFESSIONALDETAIL = 'GETPROFESSIONALDETAIL';
export const FIND_BUSINESS_DETAIL = 'FIND_BUSINESS_DETAIL';
export const ADD_NEW_BUSINESS = 'ADD_NEW_BUSINESS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN';
export const GET_DASHBOARD_PROFILE = 'GET_DASHBOARD_PROFILE';
export const SEND_RESETPASSWORD_OTP = 'SEND_RESETPASSWORD_OTP';
export const SEND_CONFIRM_PASSWORD_OTP = 'SEND_CONFIRM_PASSWORD_OTP';
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RECENT_MESSAGES = 'RECENT_MESSAGES';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const ACCEPT_DASHBOARD_REQUEST = 'ACCEPT_DASHBOARD_REQUEST';
export const DELETE_EDUCATION_PROFILE = 'DELETE_EDUCATION_PROFILE';
export const DELETE_PROFESSIONAL_PROFILE = 'DELETE_PROFESSIONAL_PROFILE';
export const CHECK_ENABLE_FEATURE = 'CHECK_ENABLE_FEATURE';
export const USER_KYC_SUCCESS = 'USER_KYC_SUCCESS';
export const BUSINESS_PROFILE_CHECK = 'BUSINESS_PROFILE_CHECK';
export const APPLICANT_TOKEN = 'APPLICANT_TOKEN';
export const DELETE_DASH_NOTIFICATION_LIST = 'DELETE_DASH_NOTIFICATION_LIST';
export const UPDATE_KYC_DASHBOARD_PROFILE = 'UPDATE_KYC_DASHBOARD_PROFILE';



export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {...state, user: action.payload, userRole: action.payload.role};
    case CHECK_EMAIL:
      return {...state, userExist: action.payload};

    case REGISTER:
      return {...state, newuser: action.payload, otpVerify: ''};
    case GET_DASHBOARD_PROFILE:
      return {...state, dashboardprofile: action.payload, successKYC: null};
    case UPDATE_KYC_DASHBOARD_PROFILE:

        let kycObject = state.dashboardprofile;
        if(kycObject&& kycObject.profileCompletion){
          if(action.payload && action.payload && action.payload.OnfidoStatus==="completed"){
            kycObject.profileCompletion.kycSetup = true;
            kycObject.profileCompletion.kycrealtimeStatus =  action.payload;
            // kycObject.profileCompletion.percentageComplete =kycObject.profileCompletion.percentageComplete+33;
          
          }else{
            kycObject.profileCompletion.kycSetup = false;
            kycObject.profileCompletion.kycrealtimeStatus =  action.payload;
          }
        }

      return {...state,dashboardprofile:kycObject,successKYC: null};
        
    case GETEDUCATIONDETAIL:
      return {...state, educationDetails: action.payload};
    case GETPROFESSIONALDETAIL:
      return {...state, professionalDetails: action.payload};
    case FIND_BUSINESS_DETAIL:
      return {...state, findBusiness: action.payload};
    case ADD_NEW_BUSINESS:
      return {...state, registeruser: action.payload};
    case UPDATE_AUTH_TOKEN:
      return {...state, authToken: action.payload};
    case SEND_RESETPASSWORD_OTP:
      return {...state, sendOtp: action.payload};
    case SEND_CONFIRM_PASSWORD_OTP:
      return {...state, confirmOtp: action.payload};
    case SEND_RESET_PASSWORD:
      return {...state, resetPassword: action.payload};
    case CHANGE_PASSWORD_SUCCESS:
      return {...state, changePassword: action.payload};
    case ADD_ERROR_CODE:
      return {...state, errorList: action.payload};
    case VERIFY_OTP:
      return {...state, otpVerify: action.payload};
    case RECENT_MESSAGES:
      return {...state, recentMessages: action.payload};
    case VERIFY_EMAIL:
      return {...state, emailVerification: action.payload};
    case ACCEPT_DASHBOARD_REQUEST:
      let acceptRequestindex = state.dashboardprofile.pendingContactRequests.findIndex (
        item =>
          (item.contactDetails && item.contactDetails.docID) ===
          action.payload.targetUID
      );
      if (acceptRequestindex > -1) {
        state.dashboardprofile.pendingContactRequests.splice (
          acceptRequestindex,
          1
        );
      }
      return {...state, dashboardprofile: state.dashboardprofile};

    case DELETE_EDUCATION_PROFILE:
      if (
        state.dashboardprofile &&
        state.dashboardprofile.educationDetails &&
        state.dashboardprofile.educationDetails.length > 0
      ) {
        let deleteEductionList = state.dashboardprofile.educationDetails.findIndex (
          item => item.docID === action.payload.docID
        );
        if (deleteEductionList > -1) {
          state.dashboardprofile.educationDetails.splice (
            deleteEductionList,
            1
          );
        }
      }

      return {...state, dashboardprofile: state.dashboardprofile};

    case DELETE_PROFESSIONAL_PROFILE:
      if (
        state.dashboardprofile &&
        state.dashboardprofile.professionalDetails &&
        state.dashboardprofile.professionalDetails.length > 0
      ) {
        let deleteProfessionalList = state.dashboardprofile.professionalDetails.findIndex (
          item => item.docID === action.payload.docID
        );
        if (deleteProfessionalList > -1) {
          state.dashboardprofile.professionalDetails.splice (
            deleteProfessionalList,
            1
          );
        }
      }

      return {...state, dashboardprofile: state.dashboardprofile};
    case CHECK_ENABLE_FEATURE:
      return {...state, checkEnableFeature: action.payload};

    case USER_KYC_SUCCESS:
      return {...state, successKYC: action.payload};
    case BUSINESS_PROFILE_CHECK:
      return {...state, isbusinessProfile: action.payload};

    case APPLICANT_TOKEN:
      return {...state, applicantKYCToken: action.payload};

    case DELETE_DASH_NOTIFICATION_LIST:
      let index = state.dashboardprofile.notifications.findIndex (
        item => item.messageID === action.payload.messageID
      );
      if (index > -1) {
        state.dashboardprofile.notifications.splice (index, 1);
      }

      return {...state, dashboardprofile: state.dashboardprofile};

    case LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};
