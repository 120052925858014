import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

import messenger from '../../../images/icons/messenger.png';
import marketPlace from '../../../images/icons/marketPlace.png';

import wallet from '../../../images/icons/wallet.png';
import * as routes from '../../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {checkActiveUrl ,checkHeaderMenuColor} from '../../../utility/checkActivePage';

import './userStatus.css';

  class UserStatus extends Component {
    constructor (props) {
      super (props);
      this.state={
        isOpenSidebar: false,
        showInput: false,
        userStatusValue:''

      }
      this.changeStatus = React.createRef ();

    }

    componentWillUnmount () {
      document.removeEventListener ('mousedown', this.handleClickOutside);
    }

    componentDidMount () {
      document.addEventListener ('mousedown', this.handleClickOutside);
    }
    handleClickOutside = event => {
      if (
        this.changeStatus.current &&
        !this.changeStatus.current.contains (event.target)
      ) {
        this.setState ({
          isOpenSidebar: false,
        });
      }

    }



    toggleMenu(){
      this.setState({
        isOpenSidebar: !this.state.isOpenSidebar,
        showInput: false,
        // userStatusValue:'',

      })
    }

    updateStatus(value){
      this.setState({
        isOpenSidebar:false,

      },()=>{
        this.props._hadleUpdateStatus(value);

      })
    }

    

    render(){
      const {userStatus, isOpenSidebarStatus} = this.props;

      
      const {isOpenSidebar,userStatusValue} =this.state;
      return(
        <div  ref={this.changeStatus} >
            <div style={{cursor:'pointer'}}  >
                <div style={{display:'flex',paddingLeft:3,alignItems:'center',maxHeight:26,minHeight:26}} onClick={()=>this.toggleMenu()}>
                <div >
                {(userStatus==="Active" || userStatus==="active") &&<div className="userActiveStatusCircle headerCircle" style={{background:'#4AEA87'}} />}
                {userStatus==="Away" &&<div className="userActiveStatusCircle headerCircle" style={{background:'#FFBF00'}} />}
                {userStatus==="Do Not Disturb" &&<div className="userActiveStatusCircle headerCircle" style={{background:'#ff0b34'}} />}
                {userStatus==="Invisible" &&<div className="userActiveStatusCircle headerCircle" style={{border:'1px solid #4AEA87'}} />}

                {((userStatus!=="Active" && userStatus!=="active") && userStatus!=="Away" && userStatus!=="Do Not Disturb"  && userStatus!=="Invisible") &&<div className="userActiveStatusCircle headerCircle" style={{background:'#4453D6'}} />}
                </div>

                  {/*<div className={(checkHeaderMenuColor() && !isOpenSidebar)?"statusText":  "statusTextLable" }>Status: {userStatus?userStatus:''}  </div> 
                    <FontAwesomeIcon
                    style={{ color:(checkHeaderMenuColor() && !isOpenSidebar)? '#000000': '#FFFFFF', fontSize: 18 ,marginTop:3}}
                    icon={faChevronDown}
      />*/}

                  <div className={(checkHeaderMenuColor() && !isOpenSidebarStatus)? " statusText ":  " statusText " }>Status: <span style={{textTransform:'capitalize'}}>{userStatus?userStatus:''}  </span></div> 
                 {!this.props.iconHide && <FontAwesomeIcon
                  style={{ color:(checkHeaderMenuColor() && !isOpenSidebarStatus)? '#000000': '#FFFFFF', fontSize: 18 ,marginTop:3}}
                  icon={faChevronDown}
                />}
                </div>
              </div>

              <div
              id="activeUserStatus"
              className={`dropdownnewwrapper ${isOpenSidebar ? '' : 'hide'}`}>
              <svg className="dropActiveStatusBoxArrow" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.8" d="M9 0L0 18H18L9 0Z" fill="#17171D"/>
              </svg>
          
              <div
                className={`dropActiveStatusBox checkActivedropMenu`}
                // isOpened={isOpened}
                // onClick={toggle}
              >
               <div className="boxInline" onClick={()=>this.updateStatus('Active')}>
                  <div className="userActiveStatusCircle" style={{background:'#4AEA87'}}/>
                  <div className="statusTextLable"> Active  </div>
                </div>
                <div className="boxInline" onClick={()=>this.updateStatus('Away')}>
                <div className="userActiveStatusCircle" style={{background:'#FFBF00'}}/>
                <div className="statusTextLable"> Away  </div>
                </div>
                <div className="boxInline" onClick={()=>this.updateStatus('Do Not Disturb')}>
                <div className="userActiveStatusCircle" style={{background:'#ff0b34'}}/>
                <div className="statusTextLable"> Do not disturb  </div>
                </div>

                <div className="boxInline" onClick={()=>this.updateStatus('Invisible')}>
                <div className="userActiveStatusCircle" style={{border:'1px solid #4AEA87'}} />
                <div className="statusTextLable">Invisible </div>
                </div>
                {/*<div className="boxInline" onClick={()=>this.setState({
                  showInput:true
                })}>
                
                  <div className="userActiveStatusCircle" style={{background:'#4453D6'}} />
                  <div className="statusTextLable" >Custom: </div>
                  
                </div>*/}
                {this.state.showInput && <div>

                <textarea className="todayStatus userStatusInput" value={userStatusValue} maxLength="40" onChange={(e) => {
                  this.setState({
                    userStatusValue:e.target.value
                  })
                }
                
    
                } onBlur={() =>{
                  this.updateStatus(userStatusValue);
                  // toggleMenu();
                  // setshowInput(false);
                }} placeholder="What would you like to do today ?"></textarea>
                
                </div>}
              </div>
              
            </div>
            </div>

              
      )
    }


  }
export default UserStatus;
