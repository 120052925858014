
import store,{history} from '../store';
import firebase from '../config/firebase';

import {getMessage} from '../constants/localMessage/errorMessage';
import { getVaultListService ,vaultGetSignedURLService ,vaultStarredService,vaultCreateUserFolderService,vaultManageFileSharesService,vaultDeleteFileService,vaultGetFileDetailsService ,vaultBulkDeleteFileService,vaultDeleteUserFolderService ,vaultBulkDownloadFileService ,vaultManageShareFileExternallyService,vaultCreateExternalShareLinkService,vaultSearchFilesSharedWithUserService} from '../services/vaultService';
import {createNotification} from '../constants/notificationtoast';
import {GET_FILES,DOWNLOAD_SUCCESS,STARRED_SUCCESS,CREATE_NEW_FOLDER,DELETED_FILE_DATA,GET_LATEST_FILES_DETAIL,DELETED_BULK_FILE_DATA ,EXTERNAL_FILE_SHARE ,SHARED_FILE_SUCCESS,SHARED_WITH_ME_FILE_SUCCESS} from '../reducers/VaultReducer';
import {saveAs} from 'file-saver';
import {getDataFromBlob ,getBlobFromUrl ,arrayBufferToBase64} from '../constants/helper';
import {Loading} from './LoadingAction';
import axios from 'axios';

import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
const proxyurl = 'https://cors-anywhere.herokuapp.com/';

export const getVaultListing = request => {
  return dispatch => {
    dispatch (Loading (true));
    getVaultListService (request)
      .then (res => {
       
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
        
          dispatch ({type: GET_FILES, payload: res.data.result.Data});
         

         
          dispatch (Loading (false));
        } else {
          dispatch ({type: GET_FILES, payload: []});
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));


          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};


export const getVaultFileDownload = (request) => {
  return dispatch => {
    dispatch (Loading (true));
    vaultGetSignedURLService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
       
          // dispatch ({type: DOWNLOAD_SUCCESS, payload: res.data.result.Data});
          saveAs(res.data.result.Data.signedURL);
          // if(redirectStatus){
            
          //   // window.location.href='/home';
          // }
          dispatch ({type: DOWNLOAD_SUCCESS, payload: res.data.result.Data});

          
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            if(!getMessage(res.data.result.responseCode)){
             createNotification ('error',res.data.result.message);
            }
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};

export const putStar = request => {
  return dispatch => {
    dispatch ({type: STARRED_SUCCESS,payload: request.data});
    vaultStarredService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch ({type: STARRED_SUCCESS, payload:request.data});
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};



export const createNewfolder = request => {
  return dispatch => {
    dispatch (Loading (true));

    vaultCreateUserFolderService (request)
      .then (res => {
        if (res && res.data &&  res.data.result && res.data.result.status) {
          let sendData = res.data.result.data;
          dispatch ({type: CREATE_NEW_FOLDER, payload:sendData});
          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};




export const deleteVaultfolder = request => {
  return dispatch => {

    vaultDeleteUserFolderService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status===true ) {
          dispatch (Loading (false));
          createNotification ('success', 'Deleted Successfully');
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};


export const deleteVaultFiles = request => {
  return dispatch => {
    vaultDeleteFileService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status===true ) {
          dispatch (Loading (false));
          createNotification ('success', 'Deleted Successfully');
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};

export const deleteVaultBulkFiles = request => {
  return dispatch => {

    vaultBulkDeleteFileService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status===true ) {
          dispatch (Loading (false));
          createNotification ('success', 'Deleted Successfully');
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};


export const deleteVaultAction = request => {
  return dispatch => {
    dispatch ({type: DELETED_FILE_DATA, payload:request});

  }
}

export const deleteBulkFilesVaultAction = request => {
  return dispatch => {
    dispatch ({type: DELETED_BULK_FILE_DATA, payload:request});

  }
}


export const shareFileWithUsers = request => {
  return dispatch => {
    dispatch ({type: DOWNLOAD_SUCCESS, payload: []});
    dispatch (Loading (true));
    vaultManageFileSharesService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          // let sendData= res.data.result.Data;
          // sendData.rawObjectDetails=res.data.result.Data;
          // dispatch ({type: CREATE_NEW_FOLDER, payload:sendData});
          // console.log(res.data.result.Data);
          dispatch ({type: SHARED_FILE_SUCCESS, payload: request.data});


          dispatch (Loading (false));
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};





export const getvaultGetFileDetails = (request,isSelected) => {
  return dispatch => {
    dispatch (Loading (true));

    vaultGetFileDetailsService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch (Loading (false));
          if(isSelected){
            res.data.result.Data.isSelected=isSelected;
          }
          dispatch ({type: GET_LATEST_FILES_DETAIL, payload: res.data.result.Data});
          // createNotification ('success', 'Deleted Successfully');
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};



export const getVaultBulkDownload = (request) => {
  return dispatch => {
    // console.log(request);
    dispatch (Loading (true));
    vaultBulkDownloadFileService (request)
      .then ( async res => {
        // console.log(res);
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          
          let filterData=res.data.result.Data;
            const currentState = store.getState();
            const { Authreducer } = currentState;

            Promise.all(filterData.map((url) => axios(url.downloadLink, {
              method: 'GET',
              responseType : "blob",
              headers: {
                'Authorization': 'Bearer ' +  Authreducer.authToken,
              }}))).then((responses) => {

                 var zip = new JSZip ();
                 let count = 0;
                 responses.forEach (async (fileData, i) => {
                  var filename = `${filterData[i].fileName}`;
                  let myImageData = await getDataFromBlob (fileData.data);
                  JSZipUtils.getBinaryContent (myImageData, (err, data) => {
                    if (err) {
                      createNotification ('error', err);
                      dispatch (Loading (false));
                      throw err; // or handle the error
                    }
                    // zip.file(filename, data, { binary: true });
                    zip.file (filename, data, {base64: true});
                    count++;
                    if (count == filterData.length) {
                      zip.generateAsync ({type: 'blob'}).then (content => {
                        // console.log(content);
                        // Force down of the Zip file
                        saveAs (content, `vault.zip`);
                        createNotification ('success', 'File downloaded Successfully');
                        dispatch (Loading (false));
                      });
                    }
                  });
                });

            });
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};







export const vaultExternalFileSharing = (request) => {
  return dispatch => {
    dispatch (Loading (true));

    vaultManageShareFileExternallyService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch (Loading (false));
         
          dispatch ({type: EXTERNAL_FILE_SHARE, payload: res.data.result.Data});
        } else {
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};



export const vaultExternalFileSharingCheck = (request) => {
  return dispatch => {
    dispatch (Loading (true));

    vaultCreateExternalShareLinkService (request)
      .then (res => {
      
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch (Loading (false));
          // console.log(res.data.result.Data);
          // console.log(res.data.result.Data.externalShareURL);
          saveAs (res.data.result.Data.externalShareURL,res.data.result.Data.fileName);
         
          // dispatch ({type: EXTERNAL_FILE_SHARE, payload: res.data.result.Data});
        } else {
          if (res && res.data &&  res.data.result && res.data.result.responseCode){
            if(getMessage(res.data.result.responseCode)){
              createNotification ('error',getMessage(res.data.result.responseCode));
            }else{
              createNotification ('error',res.data.result.message);
            }
            // responseCode
           
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};

export const getVaultSharedWithFiles = (request) => {
  return dispatch => {
    dispatch (Loading (true));
    vaultSearchFilesSharedWithUserService (request)
      .then (res => {
        dispatch (Loading (false));

        

        if (res && res.data &&  res.data.result && res.data.result.status === true) {
          dispatch (Loading (false));
         
          dispatch ({type: SHARED_WITH_ME_FILE_SUCCESS, payload: res.data.result.Data});
        } else {
          dispatch ({type: SHARED_WITH_ME_FILE_SUCCESS, payload: []});

        }

      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};








export const externalShareFileReset = request => {
  return dispatch => {
    dispatch ({type: EXTERNAL_FILE_SHARE, payload: null});

  }
}

export const resetVaultListAction = request => {
  return dispatch => {
    dispatch ({type: GET_FILES, payload: []});
    dispatch ({type: GET_LATEST_FILES_DETAIL, payload: null});

  }
}

export const resetCreatedItem = request => {
  return dispatch => {
    dispatch ({type: GET_LATEST_FILES_DETAIL, payload: null});

  }
}

export const addNewFile = request => {
  return dispatch => {
    // console.log(request);
    dispatch ({type: CREATE_NEW_FOLDER, payload: request});

  }
}













